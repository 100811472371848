import { LoggedApiConnector } from 'Connectors/LoggedApiConnector';
import ConfigProxy            from 'tools/ConfigProxy';
import AbstractApiModel       from './AbstractApiModel';

export const rhLoggedConnector = new LoggedApiConnector({
	baseURL: ConfigProxy.getServiceConfig('rh', 'api_endpoint'),
});

export default class RhPrivateApiModel extends AbstractApiModel {
	public static get connector() {
		return rhLoggedConnector;
	}

	public static serviceName: ServiceName = 'rh';
}
