import { observer }           from 'mobx-react';
import React                  from 'react';
import { layoutSidebarStore } from 'stores';
import '../css/LayoutSidebar.scss';

export default observer(() => {
	return layoutSidebarStore.isOpen ? (
		<div className="LayoutSidebar" key={layoutSidebarStore.key}>
			{layoutSidebarStore.content}
		</div>
	) : null;
});