import ContactModel                 from 'Models/directory/ContactModel';
import EnterpriseModel              from 'Models/directory/EnterpriseModel';
import EnterprisePartitionModel     from 'Models/directory/EnterprisePartitionModel';
import InterventionContactTypeModel from 'Models/intervention/ContactTypeModel';
import TaskZoneModel                from 'Models/intervention/TaskZoneModel';
import ZoneContactModel             from 'Models/intervention/ZoneContactModel';
import InvoiceContactTypeModel      from 'Models/invoice/ContactTypeModel';
import InvoicingContactModel        from 'Models/invoice/InvoicingContactModel';
import SalesContactModel            from 'Models/sales/ContactModel';
import SalesContactTypeModel        from 'Models/sales/ContactTypeModel';
import { computed }                 from 'mobx';
import { ApiCollection }            from 'modelx/collections/ApiCollection';
import AbstractModelXStore          from 'stores/AbstractModelXStore';
import { appStore }                 from 'stores/index';

export default class ContactDashboardStore extends AbstractModelXStore {

	public contact = new ContactModel();
	public contacts = new ApiCollection(ContactModel);
	public enterprise = new EnterpriseModel();
	public enterprisePartition = new EnterprisePartitionModel();
	public interventionContactTypeCollection = new ApiCollection(InterventionContactTypeModel);
	public invoiceContactTypeCollection = new ApiCollection(InvoiceContactTypeModel);
	public invoiceEnterpriseCollection = new ApiCollection(EnterpriseModel);
	public invoiceEnterprisePartitionCollection = new ApiCollection(EnterprisePartitionModel);
	public invoicingContactCollection = new ApiCollection(InvoicingContactModel);
	public invoicingContactsCollection = new ApiCollection(InvoicingContactModel);
	public salesContactCollection = new ApiCollection(SalesContactModel);
	public salesContactTypeCollection = new ApiCollection(SalesContactTypeModel);
	public salesContactsCollection = new ApiCollection(SalesContactModel);
	public taskZoneCollection = new ApiCollection(TaskZoneModel);
	public zoneContactCollection = new ApiCollection(ZoneContactModel);
	public zoneContactsCollection = new ApiCollection(ZoneContactModel);

	public async initAsync(contactId: id) {
		this.clear();

		this.contact.set({ id: contactId });

		await this.contact.fetch().then(async () => {
			await Promise.all([
				this.listContacts(),

				this.enterprisePartition
					.set({ id: this.contact.getId('enterprisePartition') })
					.fetch()
					.then(async () => {
						await this.enterprise
							.set({
								id: this.enterprisePartition.getId('enterprise'),
							})
							.fetch();
					}),
			]);
		});
	}

	public listContacts = async () => {

		await this.contacts.setRequiredFilter('globalContact', this.contact.getId('globalContact')).list(),

		await Promise.all([
			
			this.invoicingContactsCollection
				.setRequiredFilter('contactUrn', this.contacts.urns)
				.list()
				.then(async () => {
					await Promise.all([
						this.invoiceEnterprisePartitionCollection
							.listByFromCollection(this.invoicingContactsCollection, 'enterprisePartitionId')
							.then(async () => {
								await this.invoiceEnterpriseCollection.listBy(
									this.invoiceEnterprisePartitionCollection.map(m => m.getId('enterprise')),
								);
							}),

						this.invoiceContactTypeCollection
							.listByFromCollection(this.invoicingContactsCollection, 'contactTypeId'),
					]);
				}),

			this.salesContactsCollection
				.setRequiredFilter('owner.ownerSubPartition.subPartitionUrn', appStore.subPartitionUrn)
				.setRequiredFilter('contactUrn', this.contacts.urns)
				.list()
				.then(async () => {
					await this.salesContactTypeCollection
						.listByFromCollection(this.salesContactsCollection, 'contactTypeId');
				}),

			this.zoneContactsCollection
				.setRequiredFilter('contactUrn', this.contacts.urns)
				.list()
				.then(async () => {
					if (this.zoneContactsCollection.length < 1) {
						return;
					}

					await Promise.all([
						this.interventionContactTypeCollection
							.listByFromCollection(this.zoneContactsCollection, 'contactTypeId'),

						this.taskZoneCollection
							.setRequiredFilter('owner.ownerSubPartition.subPartitionUrn', appStore.subPartitionUrn)
							.listByFromCollection(this.zoneContactsCollection, 'taskZoneId'),
					]);
				}),
		]);
	};

	@computed
	public get hasContactType() {
		return (
			!this.salesContactTypeCollection.isEmpty ||
			!this.invoiceContactTypeCollection.isEmpty ||
			!this.interventionContactTypeCollection.isEmpty
		);
	}

	@computed
	public get hasInterfaceAccess() {
		return this.hasContactType && this.contact.hasInterfaceAccess;
	}
}
