import { resolvable }              from '@mathquis/modelx-resolvables';
import InterventionModel           from 'Models/intervention/InterventionModel';
import InterventionNoteTypeModel   from 'Models/intervention/InterventionNoteTypeModel';
import { urn }                     from 'helpers/ModelDictionary';
import { Blamable }                from 'helpers/traits';
import { Timestampable }           from 'helpers/traits';
import InterventionPrivateApiModel from '../../abstracts/InterventionPrivateApiModel';

@urn('$', 'intervention', 'intervention_note')
export default class InterventionNoteModel extends Blamable(Timestampable(InterventionPrivateApiModel)) {

	static get path(): string {
		return '/intervention_notes/{?id}';
	}

	public _filters: ModelFiltersExtended<{
		'intervention': id;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'updatedAt': string;
	}> = {};

	public get content(): string {
		return this.get('content', '');
	}

	@resolvable(InterventionModel, { attributeName: 'intervention' })
	declare intervention: InterventionModel;

	@resolvable(InterventionNoteTypeModel, { attributeName: 'interventionNoteType', cache: true })
	declare interventionNoteType: InterventionNoteTypeModel;
}