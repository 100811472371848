import lazyWithRetry from 'tools/lazyWithRetry';

const InterventionGroupListPage = lazyWithRetry(() => import(
	/* webpackChunkName: "InterventionGroupListPage" */
	'../../pages/InterventionGroup/InterventionGroupListPage'));

const InterventionGroupDashboardPage = lazyWithRetry(() => import(
	/* webpackChunkName: "InterventionGroupDashboardPage" */
	'../../pages/InterventionGroup/InterventionGroupDashboardPage'));

export default [
	{
		component: InterventionGroupListPage,
		exact: true,
		path: '/sub_partitions/:subPartitionId/intervention_groups',
	},
	{
		component: InterventionGroupDashboardPage,
		exact: true,
		path: '/intervention_groups/:interventionGroupId',
	},
];
