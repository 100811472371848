import AntModal, { ModalProps as AntModalProps } from 'antd/lib/modal';
import _omit                                     from 'lodash/omit';
import React                                     from 'react';

export interface IModalProps extends AntModalProps {
	defaultVisible?: boolean;
	width?: number;
}

export default class Modal extends React.Component<IModalProps> {
	public static defaultProps = {
		cancelButtonProps: {},
		cancelText: 'Fermer',
		destroyOnClose: true,
		okButtonProps: {},
		okText: 'Ok',
		width: 750,
	};

	public static info = AntModal.info;

	public state = { visible: !!this.props.defaultVisible };
	public hide = () => this.setState({ visible: false });

	public render() {
		return (
			<AntModal
				onCancel={this.hide}
				open={this.state.visible}
				width={this.props.width || Modal.defaultProps.width }

				{..._omit(this.props, ['defaultVisible'])}
			>
				{this.props.children}
			</AntModal>
		);
	}

	public show = () => this.setState({ visible: true });
}