import FileModel         from 'Models/file/FileModel';
import reverseResolvable from 'decorators/reverseResolvable';
import { whenAsync }     from 'tools/modelxTools';

export default (
	reference: FileTypeReference,
	singleRequest = true,
	first = true,
) => {
	return function (target, propertyKey: string) {
		return reverseResolvable<FileModel>({
			filters: (models) => ({
				'typeSource.source.entityUrn': models.map(m => m.urn),
				'typeSource.type.reference': reference,
			}),
			mapping: async (m1, m2) => {
				await whenAsync(() => m2.typeSource.source.isLoaded);
				return m1.urn === m2.typeSource.source.entityUrn;
			},
			singleRequest,
			sortWay: first ? 'asc' : 'desc',
			urn: '$:file:file',
		})(target, propertyKey);
	};
};