import { urn }                  from 'helpers/ModelDictionary';
import { computed }             from 'mobx';
import PrivateDirectoryApiModel from '../../abstracts/DirectoryPrivateApiModel';

@urn('$', 'directory', 'naf_code')
export default class NafCodeModel extends PrivateDirectoryApiModel {
	public _filters: ModelFiltersExtended<{
		'code': string;
		'label': string;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'code': string;
	}> = {};

	static get path(): string {
		return '/naf_codes/{?id}';
	}

	@computed
	public get code(): string {
		return this.get('code', '');
	}

	@computed
	public get label(): string {
		return this.get('label', '');
	}
}
