import model                from 'decorators/model';
import moonSvg              from 'icons/moon.svg';
import SalesPrivateApiModel from '../../abstracts/SalesPrivateApiModel';

@model.staticLabel('Tag')
@model.urnResource('tag')
export default class TagModel extends SalesPrivateApiModel {
	public _filters: ModelFiltersExtended<{
		'targetUrn': string,
	}> = {};

	public _sorts: ModelSortsExtended<unknown> = {};

	static get path(): string {
		return '/stonecode_tag_taggable_tags/{?id}';
	}

	public get targetUrn(): Urn {
		return this.get('targetUrn');
	}

	public get tagReference(): TagSalesReference {
		return this.get('tagReference');
	}

	public get tagValue(): TagSalesScheduleOptionValue {
		return this.get('tagValue');
	}

	public get tagReferenceLabel(): string {
		switch (this.tagReference) {
			case 'schedule_option':
				return 'Option de planification';
		}
	}

	public get tagValueLabel(): string {
		switch (this.tagValue) {
			case 'multi_day':
				return 'Intervention de nuit';
			default:
				return this.tagReferenceLabel;
		}
	}

	public getTagValueIcon() {
		switch (this.tagValue) {
			case 'multi_day':
				return moonSvg;
		}
	}
}
