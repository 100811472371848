import doc                    from 'decorators/doc';
import model                  from 'decorators/model';
import VehiclePrivateApiModel from '../../abstracts/VehiclePrivateApiModel';

@model.staticLabel('Habillage')
@model.urnResource('dressing')
@doc.path('/dressings/{?id}')
export default class DressingModel extends VehiclePrivateApiModel {
	public _filters: ModelFiltersExtended<{
		'enabled': '0' | '1';
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'title': string;
	}> = {};

	@doc.boolean declare enabled: boolean;
	@doc.string declare title: string;
}