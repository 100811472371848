import TaskZoneModel        from 'Models/intervention/TaskZoneModel';
import nestedResolvable     from 'decorators/nestedResolvable';
import resolvableUrn        from 'decorators/resolvableUrn';
import { EmptyModel }       from 'decorators/resolvableUrn';
import { urn }              from 'helpers/ModelDictionary';
import { Timestampable }    from 'helpers/traits';
import { Blamable }         from 'helpers/traits';
import SalesPrivateApiModel from '../../abstracts/SalesPrivateApiModel';

@urn('$', 'sales', 'owner_block_request_target')
export default class OwnerBlockRequestTargetModel extends Blamable(Timestampable(SalesPrivateApiModel)) {

	static get path(): string {
		return '/owner_block_request_targets/{?id}';
	}

	public _filters: ModelFiltersExtended<{
		'ownerBlockRequestTargetStatus': id;
		'ownerBlockRequestTargetStatus.reference': SalesOwnerBlockRequestTargetStatusReference;
		'blockRequestTargets.blockRequest.blockRequestStatus': id;
		'blockRequestTargets.blockRequest.blockRequestStatus.reference': BlockRequestStatusReference;
		'blockRequestTargets.blockRequest.blockRequestType': id;
		'blockRequestTargets.blockRequest.blockRequestType.reference': string;
		'targetUrn': string;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'updatedAt': string;
	}> = {};

	@resolvableUrn({ attributeName: 'targetUrn' })
	declare target: TaskZoneModel | EmptyModel;

	@nestedResolvable()
	public get taskZone() {
		if (this.target instanceof TaskZoneModel) {
			return this.target;
		}

		return new TaskZoneModel();
	}
}