import lazyWithRetry from 'tools/lazyWithRetry';

const InterventionPlanningPage = lazyWithRetry(() => import(
	/* webpackChunkName: "interventionPlanningPage" */
	`../../pages/Intervention/InterventionPlanningPage/InterventionPlanningPage`));

const InterventionListPage = lazyWithRetry(() => import(
	/* webpackChunkName: "interventionListPage" */
	'../../pages/Intervention/InterventionListPage'));

const InterventionStatisticsPage = lazyWithRetry(() => import(
	/* webpackChunkName: "interventionStatisticsPage" */
	'../../pages/Intervention/InterventionStatisticsPage/InterventionStatisticsPage'));

const InterventionDashboardPage = lazyWithRetry(() => import(
	/* webpackChunkName: "interventionDashboardPage" */
	'../../pages/Intervention/InterventionDashboardPage'));

export default [
	{
		component: InterventionListPage,
		exact: true,
		path: '/sub_partitions/:subPartitionId/interventions',
	},
	{
		component: InterventionStatisticsPage,
		exact: true,
		path: '/sub_partitions/:subPartitionId/intervention_statistics',
	},
	{
		component: InterventionPlanningPage,
		exact: true,
		path: '/sub_partitions/:subPartitionId/planning',
	},
	{
		component: InterventionPlanningPage,
		exact: true,
		path: '/plannings/:planningId/planning-view',
	},
	{
		component: InterventionDashboardPage,
		exact: true,
		path: '/interventions/:interventionId',
	},
];