import { computed }                from 'mobx';
import InterventionPrivateApiModel from '../../abstracts/InterventionPrivateApiModel';

export default class CollectableModel extends InterventionPrivateApiModel {
	public _filters: ModelFiltersExtended<{
		collectableType: id;
		partitionUrn: string;
	}> = {};

	static get path(): string {
		return '/collectables/{?id}';
	}

	@computed
	public get collectableTypeId(): id {
		return this.get('collectableType.id');
	}

	@computed
	public get collectableTypeIri(): string {
		return this.get('collectableType.@id');
	}

	@computed
	public get label(): string {
		return this.get('label', '');
	}

	@computed
	public get description(): string {
		return this.get('description', '');
	}

	public _sorts: ModelSortsExtended<{
		'id': string;
		'label': string;
		'updatedAt': string;
	}> = {};
}
