import lazyWithRetry from 'tools/lazyWithRetry';

const StaffMemberListPage = lazyWithRetry(() => import(
	/* webpackChunkName: "staffMemberListPage" */
	'../../pages/StaffMember/StaffMemberListPage'));

const StaffMemberDashboardPage = lazyWithRetry(() => import(
	/* webpackChunkName: "staffMemberDashboardPage" */
	'../../pages/StaffMember/StaffMemberDashboardPage'));

export default [
	{
		component: StaffMemberListPage,
		exact: true,
		path: '/sub_partitions/:subPartitionId/staff_members',
	},
	{
		component: StaffMemberDashboardPage,
		path: '/sub_partitions/:subPartitionId/staff_members/:staffMemberId',
	},
];