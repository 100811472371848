import EnterpriseGroupCategoryModel from 'Models/directory/EnterpriseGroupCategoryModel';
import doc                          from 'decorators/doc';
import model                        from 'decorators/model';
import { Timestampable }            from 'helpers/traits';
import { Blamable }                 from 'helpers/traits';
import DirectoryPrivateApiModel     from 'modelx/models/abstracts/DirectoryPrivateApiModel';

@model.staticLabel('Grand compte', true)
@model.urnResource('enterprise_group')
@doc.path('/enterprise_groups/{?id}')
export default class EnterpriseGroupModel extends Blamable(Timestampable(DirectoryPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'enterpriseGroupCategory': id;
		'enterpriseGroupCategory.reference': DirectoryEnterpriseGroupCategoryReference;
		'enterprises': id;
		'enterprises.enterprisePartitions.clientPartitions.clients.subPartitionUrn': Urn;
		'enterprises.enterprisePartitions.partitionUrn': Urn;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'name': string;
	}> = {};

	@doc.model(EnterpriseGroupCategoryModel) declare enterpriseGroupCategory: EnterpriseGroupCategoryModel;
	@doc.string declare name: string;
}
