import { resolvable }         from '@mathquis/modelx-resolvables';
import OwnerSubPartitionModel from 'Models/rh/OwnerSubPartitionModel';
import RootGroupModel         from 'Models/rh/RootGroupModel';
import StaffMemberModel       from 'Models/rh/StaffMemberModel';
import { urn }                from 'helpers/ModelDictionary';
import { Blamable }           from 'helpers/traits';
import { Timestampable }      from 'helpers/traits';
import RhPrivateApiModel      from '../../abstracts/RhPrivateApiModel';

@urn('$', 'rh', 'staff_member_root_group')
export default class StaffMemberRootGroupModel extends Blamable(Timestampable(RhPrivateApiModel)) {

	static get path(): string {
		return '/staff_member_root_groups/{?id}';
	}

	public _filters: ModelFiltersExtended<{
		'ownerSubPartition': id;
		'ownerSubPartition.partitionUrn': string;
		'ownerSubPartition.subPartitionUrn': string;
		'rootGroup': id;
		'staffMember': id;
		'staffMember.staff': id;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'staffMember.staff.firstName': string;
		'staffMember.staff.lastName': string;
	}> = {};

	public get staffMemberId(): id {
		return this.get('staffMember.id', '') || '';
	}

	@resolvable(OwnerSubPartitionModel, { attributeName: 'ownerSubPartition' })
	declare ownerSubPartition: OwnerSubPartitionModel;

	@resolvable(RootGroupModel, { attributeName: 'rootGroup' })
	declare rootGroup: RootGroupModel;

	@resolvable(StaffMemberModel, { attributeName: 'staffMember' })
	declare staffMember: StaffMemberModel;
}