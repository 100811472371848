import doc                    from 'decorators/doc';
import model                  from 'decorators/model';
import InvoicePrivateApiModel from '../../abstracts/InvoicePrivateApiModel';

@model.staticLabel(`Mode d'affichage des articles`)
@model.urnResource('item_display_mode')
@doc.path('/item_display_modes/{?id}')
export default class ItemDisplayModeModel extends InvoicePrivateApiModel {
	public _filters: ModelFiltersExtended<{
		'reference': InvoiceItemDisplayModeReference;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'label': string;
	}> = {};

	@doc.string declare label: string;
	@doc.string declare reference: InvoiceItemDisplayModeReference;
}
