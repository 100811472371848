import { urn }                     from 'helpers/ModelDictionary';
import { Timestampable }           from 'helpers/traits';
import { Blamable }                from 'helpers/traits';
import InterventionPrivateApiModel from '../../abstracts/InterventionPrivateApiModel';

export type TaskLevelReference = 'emergency' | 'normal';

@urn('$', 'intervention', 'task_level')
export default class TaskLevelModel extends Blamable(Timestampable(InterventionPrivateApiModel)) {

	static get path(): string {
		return '/task_levels/{?id}';
	}

	public _filters: ModelFiltersExtended<{
		'reference': TaskLevelReference;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'label': string;
		'level': string;
		'reference': string;
	}> = {};

	public get label(): string {
		return this.get('label', '');
	}

	public get level(): number {
		return this.get('level', 0);
	}

	public get reference(): TaskLevelReference {
		return this.get('reference', '');
	}
}