import doc                     from 'decorators/doc';
import model                   from 'decorators/model';
import SupplierPrivateApiModel from 'modelx/models/abstracts/SupplierPrivateApiModel';

@model.cacheDuration()
@model.staticLabel('Statut')
@model.urnResource('supplier_enterprise_purchase_condition_status')
@doc.path('/supplier_enterprise_purchase_condition_statuses/{?id}')
export default class SupplierEnterprisePurchaseConditionStatusModel extends SupplierPrivateApiModel {
	public _filters: ModelFiltersExtended<{
		'reference': SupplierSupplierEnterprisePurchaseConditionStatusReference;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
	}> = {};

	@doc.string declare label: string;
	@doc.string declare reference: SupplierSupplierEnterprisePurchaseConditionStatusReference;

	public get color() {
		switch (this.reference) {
			case 'pending':
				return 'orange';
			case 'disabled':
				return 'red';
			case 'validated':
				return 'green';
		}
	}
}