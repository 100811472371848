import doc from 'decorators/doc';
import model from 'decorators/model';
import { Timestampable }      from 'helpers/traits';
import { Blamable }           from 'helpers/traits';
import VehiclePrivateApiModel from '../../abstracts/VehiclePrivateApiModel';

@model.staticLabel('Version', false)
@model.urnResource('vehicle_version')
@doc.path('/vehicle_versions/{?id}')
export default class VehicleVersionModel extends Blamable(Timestampable(VehiclePrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'reference': VehicleVehicleVersionReference;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'label': string;
	}> = {};

	@doc.string declare label: string;
	@doc.string declare reference: VehicleVehicleVersionReference;
}