import SubPartitionModel        from 'Models/partition/SubPartitionModel';
import doc                      from 'decorators/doc';
import model                    from 'decorators/model';
import { Timestampable }        from 'helpers/traits';
import { Blamable }             from 'helpers/traits';
import PartitionPrivateApiModel from 'modelx/models/abstracts/PartitionPrivateApiModel';

@model.staticLabel('Paramétrage')
@model.urnResource('sub_partition_config')
@doc.path('/sub_partition_configs/{?id}')
export default class SubPartitionConfigModel extends Blamable(Timestampable(PartitionPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'subPartition': id;
		'reference': PartitionSubPartitionConfigReference;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
	}> = {};

	@doc.string declare reference: PartitionSubPartitionConfigReference;
	@doc.model(SubPartitionModel) declare subPartition: SubPartitionModel;
	@doc.string declare value: string;
}