import OperationCategoryModel    from 'Models/intervention/OperationCategoryModel';
import PlanningModel             from 'Models/intervention/PlanningModel';
import PartitionModel            from 'Models/partition/PartitionModel';
import SubPartitionConfigModel   from 'Models/partition/SubPartitionConfigModel';
import SubPartitionModel         from 'Models/partition/SubPartitionModel';
import StaffMemberModel          from 'Models/rh/StaffMemberModel';
import StaffMemberRootGroupModel from 'Models/rh/StaffMemberRootGroupModel';
import _uniqBy                   from 'lodash/uniqBy';
import { when }                  from 'mobx';
import { computed }              from 'mobx';
import { observable }            from 'mobx';
import { action }                from 'mobx';
import { makeObservable }        from 'mobx';
import { ApiCollection }         from 'modelx/collections/ApiCollection';
import AbstractModelXStore       from 'stores/AbstractModelXStore';
import ConfigProxy               from 'tools/ConfigProxy';
import { getIdFromUrn }          from 'tools/UrnTools';
import getPartitionColor         from 'tools/getPartitionColor';

export default class AppStore extends AbstractModelXStore {

	public collectionPlanning = new ApiCollection(PlanningModel);
	public collectionStaffMemberRootGroup = new ApiCollection(StaffMemberRootGroupModel);
	public collectionSubPartitionConfig = new ApiCollection(SubPartitionConfigModel);
	public operationCategoryCollection = new ApiCollection(OperationCategoryModel);
	public partition = new PartitionModel();
	public staffMember = new StaffMemberModel();

	@observable
	private _subPartitionId?: id;

	public constructor() {
		super();

		makeObservable(this);
	}

	@action
	public clear() {
		this._subPartitionId = undefined;

		localStorage.removeItem('SubPartitionId');

		return super.clear();
	}

	public fetchPlannings() {
		return this.collectionPlanning
			.setSort('label')
			.listBy([this.subPartitionUrn], 'ownerSubPartition.subPartitionUrn');
	}

	public fetchSubPartitionColor() {
		return this.collectionSubPartitionConfig
			.setFilters({
				reference: 'color',
				subPartition: this.subPartitionId,
			}).list();
	}

	public load() {
		return Promise
			.all([
				this.partition
					.setId(getIdFromUrn(ConfigProxy.get('PARTITION_URN')))
					.fetch()
					.then(() => {
						document.title = `AURA ${this.partition.name}`;
					}),

				this.staffMember.fetch(),

				this.collectionStaffMemberRootGroup
					.setFilter('staffMember', this.staffMember.id)
					.list()
					.then(async () => {
						await this.collectionStaffMemberRootGroup.whenIsLoaded(m => m.ownerSubPartition.subPartition);

						const p = window.location.pathname.split('/');

						if (
							this.storedSubPartitionId
							|| this.subPartitions.length === 1
							|| p[1] === 'sub_partitions'
						) {
							if (p[1] === 'sub_partitions' && Number.isInteger(parseInt(p[2]))) {
								this.setSubPartitionId(p[2]);
							} else {
								const storedPartitionId = this.storedSubPartitionId;

								if (storedPartitionId) {
									this.setSubPartitionId(storedPartitionId);
								} else if (this.collectionStaffMemberRootGroup.length) {
									await this.collectionStaffMemberRootGroup
										.whenIsLoaded(m => m.ownerSubPartition.subPartition);

									this.setSubPartitionId(
										this.collectionStaffMemberRootGroup.models[0].ownerSubPartition.subPartition.id,
									);
								}
							}

							await this.fetchSubPartitionColor();
							document.documentElement.style.setProperty('--primary-color', this.primaryColor);
						}
					}),
			])
			.then(() => {
				return Promise.all([
					this.operationCategoryCollection
						.setSort('label')
						.setFilters({ partitionUrn: ConfigProxy.get('PARTITION_URN') })
						.list(),
				]);
			});
	}

	@computed
	public get subPartitions() {
		return _uniqBy(
			this.collectionStaffMemberRootGroup.models.map(smrg => smrg.ownerSubPartition.subPartition),
			m => m.urn,
		);
	}

	@computed
	public get subPartitionIds() {
		return this.subPartitions.map(sp => sp.id);
	}

	@computed
	public get subPartitionUrns() {
		return this.subPartitions.map(sp => sp.urn);
	}

	@computed
	public get subPartition() {
		if (this._subPartitionId) {
			return this.subPartitions
				.find(sp => sp.id.toString() === this._subPartitionId?.toString()) || new SubPartitionModel();
		}

		return new SubPartitionModel();
	}

	@computed
	public get subPartitionId() {
		return this._subPartitionId;
	}

	@computed
	public get subPartitionUrn(): Urn {
		return this.subPartition.urn;
	}

	@action
	public setSubPartitionId(id: id) {
		this._subPartitionId = id;

		localStorage.setItem('SubPartitionId', id.toString());

		when(() => this.subPartition.isLoaded, () => document.title = `AURA ${this.subPartition.name}`);

		return this;
	}

	public get storedSubPartitionId() {
		return localStorage.getItem('SubPartitionId');
	}

	public get subPartitionColor() {
		const color = this.collectionSubPartitionConfig.find(elem => elem.reference === 'color');
		return color ? color['value'] : undefined;
	}

	public get primaryColor(): string {
		return this.subPartitionColor || getPartitionColor();
	}

}
