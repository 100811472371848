import { computed }         from 'mobx';
import SalesPrivateApiModel from '../../abstracts/SalesPrivateApiModel';

export default class ChargeableModel extends SalesPrivateApiModel {
	static get path(): string {
		return '/chargeables/{?id}';
	}

	@computed
	public get budgetPrice(): number {
		return this.get('budgetPrice');
	}

	@computed
	public get label(): string {
		return this.get('label');
	}

	@computed
	public get vatUrn(): string {
		return this.get('vatUrn');
	}

	@computed
	public get collectableUrn(): string {
		return this.get('collectableUrn');
	}


}
