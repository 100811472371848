import doc                         from 'decorators/doc';
import model                       from 'decorators/model';
import { Timestampable }           from 'helpers/traits';
import NotificationPrivateApiModel from '../../abstracts/NotificationPrivateApiModel';

@model.cacheDuration()
@model.staticLabel('NotificationLevel')
@model.urnResource('notification_level')
@doc.path('/stonecode_notification_notification_levels/{?id}')
export default class NotificationLevelModel extends Timestampable(NotificationPrivateApiModel) {
	public _filters: ModelFiltersExtended<unknown> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'label': string;
		'reference': string;
	}> = {};

	@doc.string declare label: string;
	@doc.string declare reference: NotificationNotificationLevelReference;
}

export const getNotificationLevelColor = (reference) => {
	switch (reference) {
		case 'low':
			return '#2ecc71';
		case 'medium':
			return '#f1c40f';
		case 'high':
			return '#e67e22';
		case 'urgent':
			return '#e74c3c';
		default:
			return '#34495e';
	}
};

export const getNotificationLevelLabel = (label) => {
	switch (label) {
		case 'low':
			return 'Faible';
		case 'medium':
			return 'Moyenne';
		case 'high':
			return 'Haute';
		case 'urgent':
			return 'Urgent';
		default:
			return label;
	}
};
