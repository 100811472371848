import { resolvable }         from '@mathquis/modelx-resolvables';
import PaymentModeModel       from 'Models/invoice/PaymentModeModel';
import { urn }                from 'helpers/ModelDictionary';
import { computed }           from 'mobx';
import PrivateInvoiceApiModel from '../../abstracts/InvoicePrivateApiModel';

@urn('$', 'invoice', 'payment_mode_rule')
export default class PaymentModeRuleModel extends PrivateInvoiceApiModel {
	public _filters: ModelFiltersExtended<{
		'paymentMode.partitionUrn': string;
		targetUrn: string;
	}> = {};

	public _sorts: ModelSortsExtended<unknown> = {};

	static get path(): string {
		return '/payment_mode_rules/{?id}';
	}

	@computed
	public get withEnterpriseGroupExcluded(): boolean {
		return this.get('withEnterpriseGroupExcluded', false);
	}

	@computed
	public get targetUrn(): string {
		return this.get('targetUrn', '');
	}

	@computed
	public get paymentModeId(): id {
		return this.get('paymentMode.id');
	}

	@computed
	public get paymentModeUrn(): string {
		return this.get('paymentMode.@urn');
	}

	@resolvable(PaymentModeModel, { attributeName: 'paymentMode', cache: true })
	declare paymentMode: PaymentModeModel;
}
