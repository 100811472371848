import doc                    from 'decorators/doc';
import model                  from 'decorators/model';
import { Timestampable }      from 'helpers/traits';
import { Blamable }           from 'helpers/traits';
import InvoicePrivateApiModel from 'modelx/models/abstracts/InvoicePrivateApiModel';

@model.staticLabel('TVA', false)
@model.urnResource('vat')
@doc.path('/vats/{?id}')
export default class VatModel extends Blamable(Timestampable(InvoicePrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'createdAt[after]': string;
		'createdAt[before]': string;
		'createdAt[strictly_after]': string;
		'createdAt[strictly_before]': string;
		'rate': id;
		'updatedAt[after]': string | Date;
		'updatedAt[before]': string | Date;
		'updatedAt[strictly_after]': string | Date;
		'updatedAt[strictly_before]': string | Date;
		'vatInfos.partitionUrn': string;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'accountingVatCode': string;
		'id': string;
		'label': string;
		'rate': string;
		'ttcVatCode': string;
		'updatedAt': string;
	}> = {};

	@doc.string declare accountingVatCode: string;
	@doc.string declare label: string;
	@doc.string declare rate: string;
	@doc.string declare ttcVatCode: string;
}