import doc from 'decorators/doc';
import model from 'decorators/model';
import VehiclePrivateApiModel from '../../abstracts/VehiclePrivateApiModel';

@model.cacheDuration()
@model.staticLabel('Statut')
@model.urnResource('damage_status')
@doc.path('/damage_statuses/{?id}')
export default class DamageStatusModel extends VehiclePrivateApiModel {
	public _filters: ModelFiltersExtended<{
		'reference': VehicleDamageStatusReference;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'label': string;
	}> = {};

	@doc.string declare label: string;
	@doc.string declare reference: VehicleDamageStatusReference;

	public get color() {
		switch (this.reference) {
			case 'recorded':
				return 'lightblue';
			case 'transmitted':
				return 'blue';
			case 'to_pay':
				return 'purple';
			case 'to_quote':
				return 'cyan';
			case 'pending_informations':
				return 'yellow';
			case 'to_repair':
				return 'red';
			case 'closed':
				return 'black';
		}
	}
}