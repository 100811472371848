import lazyWithRetry from 'tools/lazyWithRetry';

const InvoiceDashboardPage = lazyWithRetry(() => import(
	/* webpackChunkName: "invoiceDashboardPage" */
	'../../pages/Invoice/InvoiceDashboardPage/InvoiceDashboardPage'));

const InvoiceListPage = lazyWithRetry(() => import(
	/* webpackChunkName: "invoiceListPage" */
	'../../pages/Invoice/InvoiceListPage/InvoiceListPage'));

const AgedTrialBalanceListPage = lazyWithRetry(() => import(
	/* webpackChunkName: "agedTrialBalanceListPage" */
	'../../pages/Invoice/AgedTrialBalanceListPage'));

const BillingSummaryPage = lazyWithRetry(() => import(
	/* webpackChunkName: "billingSummaryPage" */
	'../../pages/BillingSummary/BillingSummaryPage'));

export default [
	{
		component: AgedTrialBalanceListPage,
		exact: true,
		path: '/sub_partitions/:subPartitionId/aged_trial_balance',
	},
	{
		component: InvoiceDashboardPage,
		exact: true,
		path: '/invoices/:invoiceId',
	},
	{
		component: InvoiceListPage,
		exact: true,
		path: '/sub_partitions/:subPartitionId/invoices',
	},
	{
		component: BillingSummaryPage,
		exact: true,
		path: '/billing_summary',
	},
];
