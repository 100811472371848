import { resolvable }           from '@mathquis/modelx-resolvables';
import ClientModel              from 'Models/directory/ClientModel';
import ClientPartitionModel     from 'Models/directory/ClientPartitionModel';
import CompanyModel             from 'Models/directory/CompanyModel';
import EnterpriseModel          from 'Models/directory/EnterpriseModel';
import EnterprisePartitionModel from 'Models/directory/EnterprisePartitionModel';
import InvoicingOwnerModel      from 'Models/invoice/InvoicingOwnerModel';
import OwnerSubPartitionModel   from 'Models/invoice/OwnerSubPartitionModel';
import PartitionModel           from 'Models/partition/PartitionModel';
import SubPartitionModel        from 'Models/partition/SubPartitionModel';
import { urn }                  from 'helpers/ModelDictionary';
import { Timestampable }        from 'helpers/traits';
import { Blamable }             from 'helpers/traits';
import { computed }             from 'mobx';
import { getIdFromUrn }         from 'tools/UrnTools';
import InvoicePrivateApiModel   from '../../abstracts/InvoicePrivateApiModel';

@urn('$', 'invoice', 'billing_owner')
export default class BillingOwnerModel extends Blamable(Timestampable(InvoicePrivateApiModel)) {

	public _filters: ModelFiltersExtended<{
		'billingOwnerBillingOwnerTags': id;
		'billingOwnerBillingOwnerTags.billingOwnerTag': id;
		'billingOwnerBillingOwnerTags.billingOwnerTag.reference': string;
		'clientUrn': string;
		'companyUrn': string;
		'enterprisePartitionUrn': string;
		'ownerSubPartition': id;
		'ownerSubPartition.partitionUrn': string;
		'ownerSubPartition.subPartitionUrn': string;
	}> = {};

	static get path(): string {
		return '/billing_owners/{?id}';
	}

	@computed
	public get companyUrn(): string {
		return this.get('companyUrn', '');
	}

	@computed
	public get companyId(): id {
		return getIdFromUrn(this.companyUrn);
	}

	@computed
	public get clientUrn(): string {
		return this.get('clientUrn', '');
	}

	@computed
	public get clientId(): id {
		return getIdFromUrn(this.clientUrn);
	}

	@computed
	public get enterprisePartitionUrn(): string {
		return this.get('enterprisePartitionUrn', '');
	}

	@computed
	public get enterprisePartitionId(): id {
		return getIdFromUrn(this.enterprisePartitionUrn);
	}

	@resolvable(ClientPartitionModel, { attributeName: 'clientPartitionUrn' })
	declare clientPartition: ClientPartitionModel;

	@resolvable(CompanyModel, { attributeName: 'companyUrn' })
	declare company: CompanyModel;

	@resolvable(ClientModel, { attributeName: 'clientUrn' })
	declare client: CompanyModel;

	@resolvable(EnterpriseModel, { attributeName: 'enterpriseUrn' })
	declare enterprise: EnterpriseModel;

	@resolvable(EnterprisePartitionModel, { attributeName: 'enterprisePartitionUrn' })
	declare enterprisePartition: EnterprisePartitionModel;

	@resolvable(InvoicingOwnerModel, { attributeName: 'invoicingOwner' })
	declare invoicingOwner: InvoicingOwnerModel;

	@resolvable(OwnerSubPartitionModel, { attributeName: 'ownerSubPartition' })
	declare ownerSubPartition: OwnerSubPartitionModel;

	@resolvable(PartitionModel, { attributeName: 'partitionUrn', cache: true })
	declare partition: PartitionModel;

	@resolvable(SubPartitionModel, { attributeName: 'subPartitionUrn', cache: true })
	declare subPartition: SubPartitionModel;
}
