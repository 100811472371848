import lazyWithRetry from 'tools/lazyWithRetry';

const TaskListPage = lazyWithRetry(() => import(
	/* webpackChunkName: "taskListPage" */
	'../../pages/Task/TaskListPage'));

const TaskDashboardPage = lazyWithRetry(() => import(
	/* webpackChunkName: "taskDashboardPage" */
	'../../pages/Task/TaskDashboardPage/TaskDashboardPage'));

export default [
	{
		component: TaskListPage,
		exact: true,
		path: '/sub_partitions/:subPartitionId/tasks',
	},
	{
		component: TaskDashboardPage,
		exact: true,
		path: '/tasks/:taskId',
	},
];
