import ClientModel            from 'Models/directory/ClientModel';
import BillingFrequencyModel  from 'Models/invoice/BillingFrequencyModel';
import BillingOwnerModel      from 'Models/invoice/BillingOwnerModel';
import InvoicingGroupModel    from 'Models/invoice/InvoicingGroupModel';
import PaymentModeModel       from 'Models/invoice/PaymentModeModel';
import doc                    from 'decorators/doc';
import model                  from 'decorators/model';
import { Timestampable }      from 'helpers/traits';
import { Blamable }           from 'helpers/traits';
import InvoicePrivateApiModel from '../../abstracts/InvoicePrivateApiModel';

@model.staticLabel('Période de facturation')
@model.urnResource('billing_group')
@doc.path('/billing_groups/{?id}')
export default class BillingGroupModel extends Blamable(Timestampable(InvoicePrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'billables': id;
		'billables.billableItems.billableItemMetas.reference': InvoiceBillableItemMetaReference;
		'billables.billableItems.billableItemMetas.value': id;
		'billables.billableItems.locationUrn': string;
		'billables.billableStatus': id;
		'billables.billableStatus.reference': BillableStatusReference;
		'billables.invoiceableAt[after]': string;
		'billables.invoiceableAt[before]': string;
		'billables.invoiceableAt[strictly_after]': string;
		'billables.invoiceableAt[strictly_before]': string;
		'billingGroupMetas.reference': BillingGroupMetaReference;
		'billingGroupMetas.value': id;
		'billingOwner.clientUrn': string;
		'billingOwner.companyUrn': string;
		'billingOwner.invoicingOwner.enterpriseUrn': string;
		'billingOwner.ownerSubPartition': id;
		'billingOwner.ownerSubPartition.partitionUrn': string;
		'billingOwner.ownerSubPartition.subPartitionUrn': string;
		'createdAt[after]': string;
		'createdAt[before]': string;
		'createdAt[strictly_after]': string;
		'createdAt[strictly_before]': string;
		'invoices.billingPeriod': id;
		'invoicingGroup': id;
		'invoicingGroup.invoicingOwner.enterpriseUrn': string;
		'sourceUrn': string;
		'updatedAt[after]': string | Date;
		'updatedAt[before]': string | Date;
		'updatedAt[strictly_after]': string | Date;
		'updatedAt[strictly_before]': string | Date;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'updatedAt': string;
	}> = {};

	@doc.model(BillingFrequencyModel) declare billingFrequency: BillingFrequencyModel;
	@doc.model(BillingOwnerModel) declare billingOwner: BillingOwnerModel;
	@doc.modelUrn(ClientModel) declare client: ClientModel;
	@doc.string declare freeReference: string;
	@doc.model(InvoicingGroupModel) declare invoicingGroup: InvoicingGroupModel;
	@doc.string declare name: string;
	@doc.string declare note: string;
	@doc.model(PaymentModeModel) declare paymentMode: PaymentModeModel;
}