import ConstraintGroupModel        from 'Models/intervention/ConstraintGroupModel';
import PropertyModel               from 'Models/intervention/PropertyModel';
import doc                         from 'decorators/doc';
import model                       from 'decorators/model';
import InterventionPrivateApiModel from 'modelx/models/abstracts/InterventionPrivateApiModel';

@model.staticLabel('ConstraintGroupProperty')
@model.urnResource('constraint_group_property')
@doc.path('/constraint_group_properties/{?id}')
export default class ConstraintGroupPropertyModel extends InterventionPrivateApiModel {
	public _filters: ModelFiltersExtended<{
		'constraintGroup': id;
		'property': id;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
	}> = {};

	@doc.model(ConstraintGroupModel) declare constraintGroup: ConstraintGroupModel;
	@doc.model(PropertyModel) declare property: PropertyModel;
}