import doc                                       from 'decorators/doc';
import model                                     from 'decorators/model';
import { computed }                              from 'mobx';
import { IInterventionOperationStatusNameProps } from 'modelNames/intervention/InterventionOperationStatusName';
import InterventionPrivateApiModel               from '../../abstracts/InterventionPrivateApiModel';

export type InterventionOperationStatusReference = 'to_do' | 'not_done' | 'to_reschedule' | 'done' | 'incomplete';

export const interventionOperationStatusColors: Record<InterventionOperationStatusReference, string> = {
	done: '#118E55',
	incomplete: '#000000',
	not_done: '#ff4d4f',
	to_do: '#3473E2',
	to_reschedule: '#F59A22',
};

@model.staticLabel('Statut')
@model.urnResource('intervention_operation_status')
@doc.path('/intervention_operation_statuses/{?id}')
export default class InterventionOperationStatusModel extends InterventionPrivateApiModel {
	static get path(): string {
		return '/intervention_operation_statuses/{?id}';
	}

	@computed
	public get label(): string {
		return this.get('label', '');
	}

	@computed
	public get color(): string {
		return interventionOperationStatusColors[this.reference];
	}

	@computed
	public get reference(): InterventionOperationStatusReference {
		return this.get('reference', '');
	}

	public _sorts: ModelSortsExtended<{
		'id': string;
		'label': string;
		'position': string;
	}> = {};

	public renderName(props?: Omit<IInterventionOperationStatusNameProps, 'model'>) {
		return super.renderName(props);
	}
}
