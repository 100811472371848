import { computed }                from 'mobx';
import InterventionPrivateApiModel from '../../abstracts/InterventionPrivateApiModel';

export default class InterventionOperationCollectableModel extends InterventionPrivateApiModel {
	static get path(): string {
		return '/intervention_operation_collectables/{?id}';
	}

	public _filters: ModelFiltersExtended<{
		'interventionOperation': id;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'updatedAt': string;
	}> = {};

	@computed
	public get interventionOperationId(): id {
		return this.get('interventionOperation.id', 0);
	}

	@computed
	public get required(): boolean {
		return this.get('required', false);
	}

	@computed
	public get note(): string {
		return this.get('note', '');
	}

	@computed
	public get collectableId(): id {
		return this.get('collectable.id', 0);
	}

	@computed
	public get collectedQuantity(): number {
		return this.get('collectedQuantity', 0);
	}
}
