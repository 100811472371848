import PartitionModel         from 'Models/partition/PartitionModel';
import SubPartitionModel      from 'Models/partition/SubPartitionModel';
import doc from 'decorators/doc';
import model from 'decorators/model';
import { Timestampable }      from 'helpers/traits';
import { Blamable }           from 'helpers/traits';
import VehiclePrivateApiModel from '../../abstracts/VehiclePrivateApiModel';

@model.staticLabel('OwnerSubPartition')
@model.urnResource('owner_sub_partition')
@doc.path('/owner_sub_partitions/{?id}')
export default class OwnerSubPartitionModel extends Blamable(Timestampable(VehiclePrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'partitionUrn': string;
		'subPartitionUrn': string;
	}> = {};

	public _sorts: ModelSortsExtended<unknown> = {};

	@doc.modelUrn(PartitionModel) declare partition: PartitionModel;
	@doc.modelUrn(SubPartitionModel) declare subPartition: SubPartitionModel;
}