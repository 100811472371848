import doc                      from 'decorators/doc';
import model                    from 'decorators/model';
import DirectoryPrivateApiModel from 'modelx/models/abstracts/DirectoryPrivateApiModel';

@model.staticLabel('Catégorie de poste', false)
@model.urnResource('function_family')
@doc.path('/function_families/{?id}')
export default class FunctionFamilyModel extends DirectoryPrivateApiModel {
	public _filters: ModelFiltersExtended<{
		'reference': DirectoryFunctionFamilyReference;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'label': string;
	}> = {};

	@doc.string declare label: string;
	@doc.string declare reference: DirectoryFunctionFamilyReference;
}
