import { urn }               from 'helpers/ModelDictionary';
import { Blamable }          from 'helpers/traits';
import { Timestampable }     from 'helpers/traits';
import RightsPrivateApiModel from '../../abstracts/RightsPrivateApiModel';

export type ContextTypeReference = 'global' | 'partition' | 'client' | 'task_zone' | 'contract' | 'sub_partition';

@urn('$', 'rights', 'context_type')
export default class ContextTypeModel extends Blamable(Timestampable(RightsPrivateApiModel)) {

	static get path(): string {
		return '/context_types/{?id}';
	}

	public _filters: ModelFiltersExtended<{
		'actionGroups': id;
		'reference': ContextTypeReference;
		'rights': id;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
	}> = {};

	public static cacheDuration = 3600;

	public get binaryFlag(): number {
		return this.get('binaryFlag', 0);
	}

	public get label(): string {
		return this.get('label', '');
	}

	public get mask(): string {
		return this.get('mask', '');
	}

	public get reference(): ContextTypeReference {
		return this.get('reference', '');
	}
}