import Col               from 'antd/lib/col';
import Row               from 'antd/lib/row';
import classNames        from 'classnames';
import Value             from 'components/Value';
import { IValueProps }   from 'components/Value';
import React             from 'react';
import { CSSProperties } from 'react';
import './GridInfo.scss';

export interface IGridInfoItem extends IValueProps {
	empty?: boolean;
	hidden?: boolean | ((column: IGridInfoItem) => boolean);
	label?: React.ReactNode;
	link?: string;
	loading?: boolean;
	style?: CSSProperties;
}

export interface IGridInfoProps {
	className?: string;
	data: IGridInfoItem[][];
	labelVertical?: boolean;
	spaceVertical?: number;
	style?: CSSProperties;
}

export default React.forwardRef((props: IGridInfoProps, ref: React.LegacyRef<HTMLDivElement>) => {
	const { className, data, labelVertical, style } = props;

	const columns = data.map((column, colKey) => {
		return (
			<Col flex={1} key={colKey}>
				<div className="GridInfo__table">
					{column
						.filter(item => typeof item.hidden === 'function' ? !item.hidden(item): !item.hidden)
						.map((item, itemKey) => (
							<div key={itemKey}>
								{!!item.label && (
									<div>
										<span className="GridInfo__label">{item.label}</span>
									</div>
								)}

								<div>
									{renderValue(item)}
								</div>
							</div>
						))}
				</div>
			</Col>
		);
	});

	return (
		<div className={classNames('GridInfo', className, { labelVertical })} ref={ref} style={style}>
			<Row gutter={[20, 20]} style={{ flex: 1 }}>
				{columns}
			</Row>
		</div>
	);
});

const renderValue = (item: IGridInfoItem) => {
	return (
		<Value
			empty={item.empty}
			link={item.link}
			linkTarget={item.linkTarget}
			loading={item.loading}
			style={item.style}
			type={item.type}
			value={item.value}
		/>
	);
};