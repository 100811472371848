import { ApiConnector } from 'Connectors/ApiConnector';
import ConfigProxy      from '../../../tools/ConfigProxy';
import AbstractApiModel from './AbstractApiModel';

export const directoryConnector = new ApiConnector({
	baseURL: ConfigProxy.getServiceConfig('directory', 'api_endpoint'),
});

export default class DirectoryApiModel extends AbstractApiModel {
	static get connector() {
		return directoryConnector;
	}

	public static serviceName: ServiceName = 'directory';
}
