import doc                  from 'decorators/doc';
import model                from 'decorators/model';
import SalesPrivateApiModel from 'modelx/models/abstracts/SalesPrivateApiModel';
import SalesPrivateApiModel from '../../abstracts/SalesPrivateApiModel';

@model.cacheDuration()
@model.staticLabel('ContractIterationStatus')
@model.urnResource('contract_iteration_status')
@doc.path('/contract_iteration_statuses/{?id}')
export default class ContractIterationStatusModel extends SalesPrivateApiModel {
	public _filters: ModelFiltersExtended<{
		'reference': SalesContractIterationStatusReference;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'position': string;
	}> = {};

	@doc.string declare label: string;
	@doc.number declare position: number;
	@doc.string declare reference: SalesContractIterationStatusReference;

	public get color() {
		switch (this.reference) {
			case 'blocked':
			case 'suspended':
				return '#ff0000';
			case 'activated':
				return '#008000';
			default:
				return '#808080';
		}
	}
}