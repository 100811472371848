import { Timestampable }           from 'helpers/traits';
import { computed }                from 'mobx';
import moment                      from 'moment';
import InterventionPrivateApiModel from '../../abstracts/InterventionPrivateApiModel';

export default class InterventionScheduleModel extends Timestampable(InterventionPrivateApiModel) {
	public _filters: ModelFiltersExtended<{
		'intervention': id;
	}> = {};

	static get path(): string {
		return '/intervention_schedules/{?id}';
	}

	@computed
	public get displayDate(): string {
		const startDate = moment(this.scheduleStartDate);
		const endDate = moment(this.scheduleEndDate);

		return `${startDate.format('LT')} à ${endDate.format('LT')}`;
	}

	@computed
	public get scheduleStartDate(): string {
		return this.get('scheduleStartDate', '');
	}

	@computed
	public get scheduleStartDateMoment(): Moment {
		return moment(this.scheduleStartDate);
	}

	@computed
	public get scheduleEndDate(): string {
		return this.get('scheduleEndDate', '');
	}

	@computed
	public get scheduleEndDateMoment(): Moment {
		return moment(this.scheduleEndDate);
	}

	@computed
	public get technicianNotification(): boolean {
		return this.get('technicianNotification', false);
	}

	@computed
	public get clientNotification(): boolean {
		return this.get('clientNotification', false);
	}

	@computed
	public get note(): string {
		return this.get('note', '');
	}

	public _sorts: ModelSortsExtended<{
		'id': string;
		'scheduleEndDate': string;
		'scheduleStartDate': string;
	}> = {};
}
