import ConfigProxy           from 'tools/ConfigProxy';
import { setPartitionColor } from 'tools/getPartitionColor';
import './App.scss';

const setFavicon = (href: string) => {
	const linkIconList: NodeListOf<HTMLLinkElement> = document.querySelectorAll('link[rel~=\'icon\']');

	linkIconList.forEach(linkIcon => {
		if (linkIcon && linkIcon.href) {
			linkIcon.href = href;
		}
	});
};

export default async () => {
	switch (ConfigProxy.get('PARTITION_URN')) {
		case '$:partition:partition:1':
			setPartitionColor('#00a28b');
			setFavicon(new URL('/favicons/favicon.png', import.meta.url).href);
			break;

		case '$:partition:partition:2':
			setPartitionColor('#c0024a');
			setFavicon(new URL('/favicons/favicon-dicop.png', import.meta.url).href);
			break;

		case '$:partition:partition:3':
			setPartitionColor('#00a28b');
			setFavicon(new URL('/favicons/favicon.png', import.meta.url).href);
			break;

		case '$:partition:partition:4':
			setPartitionColor('#00a28b');
			setFavicon(new URL('/favicons/favicon.png', import.meta.url).href);
			break;

		case '$:partition:partition:5':
			setPartitionColor('#00a28b');
			setFavicon(new URL('/favicons/favicon.png', import.meta.url).href);
			break;

		case '$:partition:partition:6':
			setPartitionColor('#026D70');
			setFavicon(new URL('/favicons/favicon-rcc.png', import.meta.url).href);
			break;

		case '$:partition:partition:7':
			setPartitionColor('#00a28b');
			setFavicon(new URL('/favicons/favicon.png', import.meta.url).href);
			break;

		case '$:partition:partition:8':
			setPartitionColor('#c0024a');
			setFavicon(new URL('/favicons/favicon-adiams.png', import.meta.url).href);
			break;

		case '$:partition:partition:9':
			setPartitionColor('#00aeef');
			setFavicon(new URL('/favicons/favicon.png', import.meta.url).href);
			break;

		case '$:partition:partition:10':
			setPartitionColor('#0A74BB');
			setFavicon(new URL('/favicons/favicon-mapclim.png', import.meta.url).href);
			break;

		case '$:partition:partition:11':
			setPartitionColor('#009EE2');
			setFavicon(new URL('/favicons/favicon-d2h.png', import.meta.url).href);
			break;

		case '$:partition:partition:12':
			setPartitionColor('#009EE2');
			setFavicon(new URL('/favicons/favicon-zaack.png', import.meta.url).href);
			break;

		case '$:partition:partition:13':
			setPartitionColor('#009EE2');
			setFavicon(new URL('/favicons/favicon-nanosense.png', import.meta.url).href);
			break;

		case '$:partition:partition:14':
			setPartitionColor('#0A74BB');
			setFavicon(new URL('/favicons/favicon-addd.png', import.meta.url).href);
			break;

		case '$:partition:partition:15':
			setPartitionColor('#2A96D4');
			setFavicon(new URL('/favicons/favicon-agetep.png', import.meta.url).href);
			break;

		case '$:partition:partition:16':
			setPartitionColor('#00a28b');
			setFavicon(new URL('/favicons/favicon-igc.png', import.meta.url).href);
			break;

		case '$:partition:partition:17':
			setPartitionColor('#009FE3');
			setFavicon(new URL('/favicons/favicon-inter.png', import.meta.url).href);
			break;

		case '$:partition:partition:18':
			setPartitionColor('#00a28b');
			setFavicon(new URL('/favicons/favicon-airplusnetouest.png', import.meta.url).href);
			break;
	}

	return import(`./AntdCustomTheme.less`);
};
