import ActivityCategoryModel from 'Models/sales/ActivityCategoryModel';
import ActivityTypeModel     from 'Models/sales/ActivityTypeModel';
import doc                   from 'decorators/doc';
import model                 from 'decorators/model';
import { Timestampable }     from 'helpers/traits';
import { Blamable }          from 'helpers/traits';
import SalesPrivateApiModel  from 'modelx/models/abstracts/SalesPrivateApiModel';

@model.staticLabel('ActivityTypeActivityCategory')
@model.urnResource('activity_type_activity_category')
@doc.path('/activity_type_activity_categories/{?id}')
export default class ActivityTypeActivityCategoryModel extends Blamable(Timestampable(SalesPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'activityCategory': id;
		'activityType': id;
		'activityType.activities': id;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'updatedAt': string;
	}> = {};

	@doc.model(ActivityCategoryModel) declare activityCategory: ActivityCategoryModel;
	@doc.model(ActivityTypeModel) declare activityType: ActivityTypeModel;
}
