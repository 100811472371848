import { resolvable }         from '@mathquis/modelx-resolvables';
import InvoiceItemModel       from 'Models/invoice/InvoiceItemModel';
import { urn }                from 'helpers/ModelDictionary';
import { Blamable }           from 'helpers/traits';
import { Timestampable }      from 'helpers/traits';
import { computed }           from 'mobx';
import { getIdFromUrn }       from 'tools/UrnTools';
import InvoicePrivateApiModel from '../../abstracts/InvoicePrivateApiModel';

@urn('$', 'invoice', 'invoice_item_meta')
export default class InvoiceItemMetaModel extends Blamable(Timestampable(InvoicePrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'invoiceItem': id;
		'invoiceItem.invoiceItemGroup': id;
		'invoiceItem.invoiceItemGroup.invoice': id;
		'reference': InvoiceItemMetaReference;
		'value': id;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'reference': string;
		'value': string;
	}> = {};

	static get path(): string {
		return '/invoice_item_metas/{?id}';
	}

	@computed
	public get reference(): InvoiceItemMetaReference {
		return this.get('reference');
	}

	@computed
	public get value(): number | string {
		return this.get('value');
	}

	@computed
	public get interventionId(): id {
		return parseInt(getIdFromUrn(this.get('value')));
	}

	public get service(): string {
		return this.get('service', '');
	}

	@computed
	public get invoiceItemId(): id {
		return this.get('invoiceItem.id');
	}

	@resolvable(InvoiceItemModel, { attributeName: 'invoiceItem' })
	declare invoiceItem: InvoiceItemModel;
}
