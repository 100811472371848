import { urn }                from 'helpers/ModelDictionary';
import { Blamable }           from 'helpers/traits';
import { Timestampable }      from 'helpers/traits';
import { computed }           from 'mobx';
import InvoicePrivateApiModel from '../../abstracts/InvoicePrivateApiModel';

export type InvoiceTypeReference = 'invoice' | 'refund' | 'proforma';

@urn('$', 'invoice', 'invoice_type')
export default class InvoiceTypeModel extends Blamable(Timestampable(InvoicePrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'reference': InvoiceTypeReference;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'label': string;
	}> = {};

	public static cacheDuration = 3600;

	static get path(): string {
		return '/invoice_types/{?id}';
	}

	@computed
	public get label(): string {
		return this.get('label');
	}

	@computed
	public get theLabel(): string {
		let the = '';

		switch (this.reference) {
			case 'invoice':
				the = 'la ';
				break;
			case 'proforma':
				the = 'le ';
				break;
			case 'refund':
				the = 'l\'';
				break;
		}

		return `${the}${this.label?.toLowerCase()}`;
	}

	@computed
	public get reference(): InvoiceTypeReference {
		return this.get('reference');
	}
}
