import axios                   from 'axios';
import { BlobOptions }         from 'buffer';
import { authenticationStore } from 'stores';
import { notificationError }   from 'tools/notification';
import { displayBlob }         from './blobTools';

export default async (url: string, mimeType: string | null, name: string, callback?: () => void) => {
	try {
		const token = authenticationStore.session.token;
		const response = await axios.get(
			url,
			{ headers: { 'X-Platform-Authorization': `Bearer ${token}` }, responseType: 'arraybuffer' },
		);
		const options: BlobOptions = {};
		
		if (mimeType) {
			options.type = mimeType;
		}
		const file = new Blob([response.data], options);

		displayBlob(file, name);

		if (callback) {
			callback();
		}
	} catch (error) {
		notificationError({ message: `Une erreur s'est produite` });
	}
};