import ApiCollection                  from 'Collections/ApiCollection';
import OwnerSubPartitionModel         from 'Models/intervention/OwnerSubPartitionModel';
import PlanningOperationCategoryModel from 'Models/intervention/PlanningOperationCategoryModel';
import PlanningResourceModel          from 'Models/intervention/PlanningResourceModel';
import doc                            from 'decorators/doc';
import model                          from 'decorators/model';
import { Timestampable }              from 'helpers/traits';
import { Blamable }                   from 'helpers/traits';
import InterventionPrivateApiModel    from 'modelx/models/abstracts/InterventionPrivateApiModel';

@model.cacheDuration()
@model.staticLabel('Planning')
@model.urnResource('planning')
@doc.path('/plannings/{?id}')
export default class PlanningModel extends Blamable(Timestampable(InterventionPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'createdAt[after]': string;
		'createdAt[before]': string;
		'createdAt[strictly_after]': string;
		'createdAt[strictly_before]': string;
		'label': string;
		'ownerSubPartition': id;
		'ownerSubPartition.partitionUrn': Urn;
		'ownerSubPartition.subPartitionUrn': Urn;
		'updatedAt[after]': string | Date;
		'updatedAt[before]': string | Date;
		'updatedAt[strictly_after]': string | Date;
		'updatedAt[strictly_before]': string | Date;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'label': string;
		'updatedAt': string;
	}> = {};

	@doc.string declare label: string;
	@doc.model(OwnerSubPartitionModel) declare ownerSubPartition: OwnerSubPartitionModel;

	@model.nestedResolvable()
	public get subPartition() {
		return this.ownerSubPartition.subPartition;
	}

	public get pathToView() {
		return this.pathTo() + '/planning-view';
	}

	@model.collectionResolvable<PlanningOperationCategoryModel>({
		filters: m => ({ planning: m.id }),
		urn: '$:intervention:planning_operation_category',
	})
	declare collPlanningOperationCategory: ApiCollection<PlanningOperationCategoryModel>;

	@model.collectionResolvable<PlanningResourceModel>({
		filters: m => ({
			'planning': m.id,
			'resource.resourceType.reference': 'vehicle',
		}),
		urn: '$:intervention:planning_resource',
	})
	declare collPlanningResourceVehicle: ApiCollection<PlanningResourceModel>;
}