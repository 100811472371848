import { computed }           from 'mobx';
import AuditerPrivateApiModel from '../../abstracts/AuditerPrivateApiModel';

export const EVENT_IDENTIFIER_CONTRACT_ITERATION_TO_COMMERCIAL_CONTACT = 'contract_iteration_to_commercial_contact';
export const EVENT_IDENTIFIER_CONTRACT_ITERATION_SUSPENDED = 'contract_iteration_suspended';
export const EVENT_IDENTIFIER_CONTRACT_ITERATION_ACTIVATED = 'contract_iteration_activated';
export const EVENT_IDENTIFIER_CONTRACT_ITERATION_VALIDATED_BY_CLIENT = 'contract_iteration_validated_by_client';
export const EVENT_IDENTIFIER_QUOTATION_TO_COMMERCIAL_CONTACT = 'quotation_to_commercial_contact';
export const EVENT_IDENTIFIER_QUOTATION_SUSPENDED = 'quotation_suspended';
export const EVENT_IDENTIFIER_QUOTATION_ACTIVATED = 'quotation_activated';
export const EVENT_IDENTIFIER_QUOTATION_VALIDATED_BY_CLIENT = 'quotation_validated_by_client';

export const EVENT_IDENTIFIER_SEND_INVOICE = 'send_invoice';
export const EVENT_IDENTIFIER_SEND_INVOICE_REMINDER = 'send_invoice_reminder';

export const EVENT_IDENTIFIER_INTERVENTION_CONTACT_NOTIFICATION = 'intervention_contact_notification';
export const EVENT_IDENTIFIER_SEND_REPORT = 'send_report';
export const EVENT_IDENTIFIER_SEND_REPORT_SHEET = 'send_report_sheet';

export const EVENT_IDENTIFIER_SEND_SUPPLIER_TO_VALIDATE = 'order_part_to_validate';
export const EVENT_IDENTIFIER_SEND_SUPPLIER_VALIDATED = 'order_part_validated';

export const SALES_CONTRACT_EVENT_IDENTIFIERS = [
	EVENT_IDENTIFIER_CONTRACT_ITERATION_TO_COMMERCIAL_CONTACT,
	EVENT_IDENTIFIER_CONTRACT_ITERATION_SUSPENDED,
	EVENT_IDENTIFIER_CONTRACT_ITERATION_ACTIVATED,
	EVENT_IDENTIFIER_CONTRACT_ITERATION_VALIDATED_BY_CLIENT,
];

export const SALES_QUOTATION_EVENT_IDENTIFIERS = [
	EVENT_IDENTIFIER_QUOTATION_TO_COMMERCIAL_CONTACT,
	EVENT_IDENTIFIER_QUOTATION_SUSPENDED,
	EVENT_IDENTIFIER_QUOTATION_ACTIVATED,
	EVENT_IDENTIFIER_QUOTATION_VALIDATED_BY_CLIENT,
];

export const INVOICE_EVENT_IDENTIFIERS = [
	EVENT_IDENTIFIER_SEND_INVOICE,
	EVENT_IDENTIFIER_SEND_INVOICE_REMINDER,
];

export const INTERVENTION_EVENT_IDENTIFIERS = [
	EVENT_IDENTIFIER_INTERVENTION_CONTACT_NOTIFICATION,
	EVENT_IDENTIFIER_SEND_REPORT,
	EVENT_IDENTIFIER_SEND_REPORT_SHEET,
];

export const SUPPLIER_EVENT_IDENTIFIERS = [
	EVENT_IDENTIFIER_SEND_SUPPLIER_TO_VALIDATE,
	EVENT_IDENTIFIER_SEND_SUPPLIER_VALIDATED,
];

export const identifierConverter = identifier => {
	switch (identifier) {
		case 'contract_iterations_to_reactivate': {
			return '';
		}
		case 'contract_iterations_for_reconduction': {
			return '';
		}
		case 'contract_iteration_to_commercial_contact': {
			return 'Envoi au contact achat';
		}
		case 'contract_iteration_suspended': {
			return 'Contrat suspendu';
		}
		case 'contract_iteration_activated': {
			return 'Contrat activé';
		}
		case 'contract_iteration_validated_by_client': {
			return 'Contrat validé par le client';
		}
		case 'contract_iteration_long_delay_in_payment_yes': {
			return '';
		}
		case 'contract_iteration_long_delay_in_payment_no': {
			return '';
		}
		case 'quotations_to_reactivate': {
			return '';
		}
		case 'quotation_to_commercial_contact': {
			return 'Devis envoyé au contact achat';
		}
		case 'quotation_suspended': {
			return 'Devis suspendu';
		}
		case 'quotation_activated': {
			return 'Devis activé';
		}
		case 'quotation_validated_by_client': {
			return 'Devis validé par le client';
		}
		case 'quotation_long_delay_in_payment_yes': {
			return '';
		}
		case 'quotation_long_delay_in_payment_no': {
			return '';
		}
		case 'send_export': {
			return '';
		}

		case 'send_invoice': {
			return 'Facture envoyée';
		}
		case 'send_invoice_reminder': {
			return 'Rappel envoyé';
		}
		// case 'send_export': {
		// 	return '';
		// }

		case 'intervention_contact_notification': {
			return 'Mail pré-intervention envoyée';
		}
		case 'send_report': {
			return `Rapport d'intervention envoyé`;
		}
		case 'send_report_sheet': {
			return `Fiche d'intervention envoyée`;
		}
		case 'send_certificate': {
			return `Attestation d'intervention envoyée`;
		}
		case 'send_report_certificate': {
			return `Attestation d'intervention envoyée`;
		}
		case 'order_part_to_validate': {
			return `Commande à valider`;
		}
		case 'order_part_validated': {
			return `Commande validée`;
		}
		case 'send_order_ordered': {
			return `Bon de commande envoyée`;
		}
		// case 'send_export': {
		// 	return '';
		// }

		default: {
			return '';
		}
	}
};

export default class EventModel extends AuditerPrivateApiModel {
	public _filters: ModelFiltersExtended<{
		'subject.metadata.contract_iteration_urn': string;
		'subject.metadata.intervention_urn': string;
		'subject.metadata.invoice_urn': string;
		'subject.metadata.invoice_urn[eq]': string;
		'subject.metadata.quotation_urn': string;
		'subject.metadata.recipient_type': string;
		'subject.metadata.order_group_urn': string;
		'subject.urn[urn]': string;
		'subject.urn.identifier': string;
	}> = {};

	static get path(): string {
		return '/events/{?id}';
	}

	@computed
	public get timestamp(): number {
		return this.get('timestamp');
	}

	@computed
	public get senderEmail(): string {
		return this.get('context.metadata.sender_email');
	}

	@computed
	public get recipientEmail(): string {
		return this.get('subject.metadata.recipient_email');
	}

	@computed
	public get recipientUrn(): string {
		return this.get('subject.metadata.recipient_urn');
	}

	@computed
	public get identifier(): string {
		return this.get('subject.urn.identifier');
	}
}
