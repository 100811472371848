/* eslint-disable @typescript-eslint/no-explicit-any,@typescript-eslint/ban-ts-comment */

import { FormProps as AntdFormProps } from 'antd/lib/form';
import { FormItemProps as FIP }       from 'antd/lib/form';
import { FormInstance as FI }         from 'antd/lib/form';
import AntForm                        from 'antd/lib/form';
import _omit                          from 'lodash/omit';
import React                          from 'react';

export interface FormItemProps extends FIP {
}

export interface FormProps extends AntdFormProps {
	onMount?: () => void;
}

export const FormItem = (props: FormItemProps) => {
	const rules = props.required ? [
		...(props.rules || []),
		{ message: 'Veuillez renseigner cette information', required: true },
	] : props.rules;

	return <AntForm.Item labelCol={{ span: 24 }} {...props} rules={rules} />;
};

export default class Form extends React.Component<FormProps> {
	public static ErrorList = AntForm.ErrorList;
	public static Item = FormItem;
	public static List = AntForm.List;

	private _formRef = React.createRef<FI>();

	public componentDidMount() {
		const { onMount } = this.props;

		if (onMount) {
			onMount();
		}
	}

	public getFieldInstance: FI['getFieldInstance'] = (...args) => this._fn('getFieldInstance', args);
	public getFieldValue: FI['getFieldValue'] = (...args) => this._fn('getFieldValue', args);
	public getFieldsValue: FI['getFieldsValue'] = (...args) => this._fn('getFieldsValue', args);
	// @ts-ignore
	public render = () => <AntForm {..._omit(this.props, 'onMount')} ref={this._formRef} />;
	public resetFields: FI['resetFields'] = (...args) => this._fn('resetFields', args);
	public setFieldValue: FI['setFieldValue'] = (...args) => this._fn('setFieldValue', args);
	public setFields: FI['setFields'] = (...args) => this._fn('setFields', args);
	public setFieldsValue: FI['setFieldsValue'] = (...args) => this._fn('setFieldsValue', args);
	public submit: FI['submit'] = (...args) => this._fn('submit', args);
	public validateFields: FI['validateFields'] = (...args) => this._fn('validateFields', args);

	private _fn = (method: string, args) => this._formRef.current?.[method](...args);
}