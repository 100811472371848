import { Model }                     from '@mathquis/modelx';
import { ApiCollection }             from 'Collections/ApiCollection';
import { AutoloadedPagedCollection } from 'Collections/AutoloadedPagedCollection';
import { PagedCollection }           from 'Collections/PagedCollection';
import AbstractApiModel              from '../modelx/models/abstracts/AbstractApiModel';

export type GroupedCollectionByProperty<T extends Model> = Record<number | string, T[]>;
export type IndexedCollectionByProperty<T extends Model> = Record<number | string, T>;

export function groupCollectionByProperty<T extends AbstractApiModel>(
	collection: ApiCollection<T> | AutoloadedPagedCollection<T>,
	propertyName
): GroupedCollectionByProperty<T> {
	const groupedCollection = {};
	collection.forEach(model => {
		const index = model.get(propertyName, 'none');
		if (!groupedCollection[index]) {
			groupedCollection[index] = [];
		}
		groupedCollection[index].push(model);
	});
	return groupedCollection;
}

export function indexCollectionByProperty<T extends AbstractApiModel>(collection: ApiCollection<T> | T[], propertyName): IndexedCollectionByProperty<T> {
	const indexedCollection = {};
	collection.forEach(model => {
		const index = model.get(propertyName, 'none');
		indexedCollection[index] = model;
	});
	return indexedCollection;
}

export function getUniqueListForProperty<T extends AbstractApiModel>(
	collection: ApiCollection<T> | T[] | PagedCollection<T>, propertyName, callback,
): string[] {
	const list = {};
	collection.forEach(model => {
		const value = callback(model.get(propertyName));
		if (value) {
			list[value] = true;
		}
	});
	return Object.keys(list);
}