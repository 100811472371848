import OwnerResourceModel          from 'Models/intervention/OwnerResourceModel';
import OwnerSubPartitionModel      from 'Models/intervention/OwnerSubPartitionModel';
import ResourceTypeModel           from 'Models/intervention/ResourceTypeModel';
import SubPartitionModel           from 'Models/partition/SubPartitionModel';
import doc                         from 'decorators/doc';
import model                       from 'decorators/model';
import { Timestampable }           from 'helpers/traits';
import { Blamable }                from 'helpers/traits';
import InterventionPrivateApiModel from '../../abstracts/InterventionPrivateApiModel';

@model.staticLabel('Ressource', false)
@model.urnResource('resource')
@doc.path('/resources/{?id}')
export default class ResourceModel extends Blamable(Timestampable(InterventionPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'enabled': '0' | '1';
		'ownerResource': id;
		'ownerResource.entityUrn': string;
		'ownerSubPartition': id;
		'ownerSubPartition.partitionUrn': string;
		'ownerSubPartition.subPartitionUrn': string;
		'resourceAssignments.endDate[after]': string | Date;
		'resourceAssignments.endDate[before]': string | Date;
		'resourceAssignments.endDate[strictly_after]': string | Date;
		'resourceAssignments.endDate[strictly_before]': string | Date;
		'resourceAssignments.startDate[after]': string | Date;
		'resourceAssignments.startDate[before]': string | Date;
		'resourceAssignments.startDate[strictly_after]': string | Date;
		'resourceAssignments.startDate[strictly_before]': string | Date;
		'resourceOperationCategories.operationCategory': id;
		'resourceType': id;
		'resourceType.reference': InterventionResourceTypeReference;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'updatedAt': string;
	}> = {};

	@doc.boolean declare enabled: boolean;
	@doc.model(OwnerResourceModel) declare ownerResource: OwnerResourceModel;
	@doc.model(OwnerSubPartitionModel) declare ownerSubPartition: OwnerSubPartitionModel;
	@doc.model(ResourceTypeModel) declare resourceType: ResourceTypeModel;
	@doc.modelUrn(SubPartitionModel) declare subPartition: SubPartitionModel;

	@model.nestedResolvable()
	public get entity() {
		return this.ownerResource.entity;
	}
}
// TODO DOC - Ajouter la propriété "source"