import { resolvable }                from '@mathquis/modelx-resolvables';
import EnterprisePartitionModel      from 'Models/directory/EnterprisePartitionModel';
import FrameworkAgreementStatusModel from 'Models/sales/FrameworkAgreementStatusModel';
import OwnerPartitionModel           from 'Models/sales/OwnerPartitionModel';
import doc                           from 'decorators/doc';
import model                         from 'decorators/model';
import { Timestampable }             from 'helpers/traits';
import { Blamable }                  from 'helpers/traits';
import SalesPrivateApiModel          from 'modelx/models/abstracts/SalesPrivateApiModel';
import FrameworkAgreementActorModel  from './FrameworkAgreementActorModel';

@model.staticLabel(`Accord-cadre`)
@model.urnResource('framework_agreement')
@doc.path('/framework_agreements/{?id}')
export default class FrameworkAgreementModel extends Blamable(Timestampable(SalesPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'frameworkAgreementEnterprisePartitions': id;
		'frameworkAgreementEnterprisePartitions.ownerEnterprisePartition': id;
		'frameworkAgreementEnterprisePartitions.ownerEnterprisePartition.enterprisePartitionUrn': string;
		'frameworkAgreementIterations': id;
		'frameworkAgreementStatus': id;
		'frameworkAgreementStatusHistories': id;
		'frameworkAgreementSubPartitions': id;
		'ownerPartition': id;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
	}> = {};

	@doc.model(FrameworkAgreementStatusModel) declare frameworkAgreementStatus: FrameworkAgreementStatusModel;
	@doc.string declare label: string;
	@doc.number declare number: number;
	@doc.string declare oldNumber: string;
	@doc.model(OwnerPartitionModel) declare ownerPartition: OwnerPartitionModel;
	@doc.string declare reference: SalesFrameworkAgreementReference;

	@resolvable(EnterprisePartitionModel, { attributeName: 'signingEnterprisePartitionUrn', cache: true })
	declare signingEnterprisePartition: EnterprisePartitionModel;

	@model.reverseResolvable<FrameworkAgreementActorModel>({
		filters: {
			'frameworkAgreementActorGroup.reference': 'FRAMEWORK_AGREEMENT_ACTOR_GROUP_COMMERCIAL',
		},
		urn: '$:sales:framework_agreement_actor',
	})
	public declare commercialActor: FrameworkAgreementActorModel;
}
