import SourceModel            from 'Models/comment/SourceModel';
import TypeModel              from 'Models/comment/TypeModel';
import doc                    from 'decorators/doc';
import model                  from 'decorators/model';
import { Timestampable }      from 'helpers/traits';
import { Blamable }           from 'helpers/traits';
import CommentPrivateApiModel from '../../abstracts/CommentPrivateApiModel';

@model.staticLabel('TypeSource')
@model.urnResource('type_source')
@doc.path('/stonecode_comment_type_sources/{?id}')
export default class TypeSourceModel extends Blamable(Timestampable(CommentPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'locked': '0' | '1';
		'source': id;
		'source.entityUrn': string;
		'type': id;
		'type.deprecated': '0' | '1';
		'type.reference': CommentTypeReference;
		'type.service': id;
		'type.service.serviceUrn': string;
		'type.system': '0' | '1';
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'updatedAt': string;
	}> = {};

	@doc.boolean declare locked: boolean;
	@doc.model(SourceModel) declare source: SourceModel;
	@doc.model(TypeModel) declare type: TypeModel;
}
