import DamageModel                from 'Models/vehicle/DamageModel';
import MaintenanceDutyStatusModel from 'Models/vehicle/MaintenanceDutyStatusModel';
import MaintenanceModel           from 'Models/vehicle/MaintenanceModel';
import MaintenanceTypeModel       from 'Models/vehicle/MaintenanceTypeModel';
import VehicleModel               from 'Models/vehicle/VehicleModel';
import doc                        from 'decorators/doc';
import model                      from 'decorators/model';
import resolvableUrn              from 'decorators/resolvableUrn';
import { EmptyModel }             from 'decorators/resolvableUrn';
import { Timestampable }          from 'helpers/traits';
import { Blamable }               from 'helpers/traits';
import VehiclePrivateApiModel     from 'modelx/models/abstracts/VehiclePrivateApiModel';

@model.staticLabel(`Entretien`)
@model.urnResource('maintenance_duty')
@doc.path('/maintenance_duties/{?id}')
export default class MaintenanceDutyModel extends Blamable(Timestampable(VehiclePrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'fromDate[after]': string | Date;
		'fromDate[before]': string | Date;
		'fromDate[strictly_after]': string | Date;
		'fromDate[strictly_before]': string | Date;
		'maintenanceDutyStatus': id;
		'maintenanceDutyStatus.reference': VehicleMaintenanceDutyStatusReference;
		'maintenanceType': id;
		'maintenanceType.reference': VehicleMaintenanceTypeReference;
		'maintenances.supplierUrn': string;
		'vehicle': id;
		'vehicle.vehicleSubPartitions.ownerSubPartition.subPartitionUrn': string;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'agreementReference': string;
	}> = {};

	@doc.date declare fromDate: Moment;
	@doc.model(MaintenanceDutyStatusModel) declare maintenanceDutyStatus: MaintenanceDutyStatusModel;
	@doc.model(MaintenanceTypeModel) declare maintenanceType: MaintenanceTypeModel;
	@doc.model(VehicleModel) declare vehicle: VehicleModel;

	@resolvableUrn({ attributeName: 'sourceUrn' })
	public declare source: DamageModel | EmptyModel;

	@model.reverseResolvable<MaintenanceModel, MaintenanceDutyModel>({
		filters: (models) => ({ 'maintenanceDuty': models.map(m => m.id) }),
		singleRequest: false,
		urn: '$:vehicle:maintenance',
	})
	public declare firstMaintenance: MaintenanceModel;
}