import SubPartitionModel       from 'Models/partition/SubPartitionModel';
import OwnerPartitionModel     from 'Models/supplier/OwnerPartitionModel';
import doc                     from 'decorators/doc';
import model                   from 'decorators/model';
import { Timestampable }       from 'helpers/traits';
import { Blamable }            from 'helpers/traits';
import SupplierPrivateApiModel from '../../abstracts/SupplierPrivateApiModel';

@model.staticLabel('OwnerSubPartition')
@model.urnResource('owner_sub_partition')
@doc.path('/owner_sub_partitions/{?id}')
export default class OwnerSubPartitionModel extends Blamable(Timestampable(SupplierPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'subPartitionUrn': string;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'updatedAt': string;
	}> = {};

	@doc.model(OwnerPartitionModel) declare ownerPartition: OwnerPartitionModel;
	@doc.modelUrn(SubPartitionModel) declare subPartition: SubPartitionModel;
}