import { ISelectCollectionProps } from 'components/SelectCollection';
import AbstractApiModel           from 'modelx/models/abstracts/AbstractApiModel';
import React                      from 'react';

export default <T extends AbstractApiModel, P = object>(
	content: React.FC<Partial<ISelectCollectionProps<T>> & P>,
) => {
	return (
		React.forwardRef((props: Partial<ISelectCollectionProps<T>> & P, ref) => {
			return content({ ref, ...props });
		})
	);
};