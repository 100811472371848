import doc from 'decorators/doc';
import model from 'decorators/model';
import { Blamable }         from 'helpers/traits';
import { Timestampable }    from 'helpers/traits';
import SalesPrivateApiModel from '../../abstracts/SalesPrivateApiModel';

@model.staticLabel('Type de blocage')
@model.urnResource('block_request_type')
@doc.path('/block_request_types/{?id}')
export default class BlockRequestTypeModel extends Blamable(Timestampable(SalesPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'reference': BlockRequestTypeReference;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'updatedAt': string;
	}> = {};

	public static cacheDuration = 3600;

	@doc.string declare label:string;
	@doc.string declare reference:BlockRequestTypeReference;
}