import doc                         from 'decorators/doc';
import model                       from 'decorators/model';
import { Timestampable }           from 'helpers/traits';
import NotificationPrivateApiModel from '../../abstracts/NotificationPrivateApiModel';

@model.cacheDuration()
@model.staticLabel('Statut')
@model.urnResource('notification_status')
@doc.path('/stonecode_notification_notification_statuses/{?id}')
export default class NotificationStatusModel extends Timestampable(NotificationPrivateApiModel) {
	public _filters: ModelFiltersExtended<unknown> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'label': string;
		'reference': string;
	}> = {};

	@doc.string declare label: string;
	@doc.string declare reference: NotificationNotificationStatusReference;

	public get color() {
		switch (this.reference) {
			case 'sent':
				return 'green';
			case 'pending':
				return 'grey';
			case 'sending':
				return 'blue';
		}
	}
}
