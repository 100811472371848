import Spin                    from 'antd/lib/spin';
import { observer }            from 'mobx-react';
import LoginPage               from 'pages/LoginPage/LoginPage';
import SelectSubPartitionPage  from 'pages/SelectSubPartitionPage/SelectSubPartitionPage';
import React                   from 'react';
import { authenticationStore } from 'stores';
import { appStore }            from 'stores';
import Layout                  from '../Layout/Layout';

const Loading = () => (
	<div style={{
		alignItems: 'flex-end',
		display: 'flex',
		height: '100%',
		justifyContent: 'center',
		padding: 40,
	}}>
		<Spin className="app-centered-spin" size="large" />

		<a onClick={() => authenticationStore.logout()} style={{ zIndex: 2 }}>
			Déconnexion
		</a>
	</div>
);

export default observer(props => {
	if (!authenticationStore.isReady) {
		return <Spin className="app-centered-spin" size="large" />;
	}

	if (!authenticationStore.isAuthenticated) {
		return <LoginPage {...props} />;
	}

	if (
		!appStore.collectionStaffMemberRootGroup.isLoaded
		|| appStore.collectionStaffMemberRootGroup.some(m => !m.ownerSubPartition.subPartition.isLoaded)
	) {
		return <Loading />;
	}

	if (
		!appStore.subPartitionUrn
		&& appStore.subPartitionUrns.length > 1
	) {
		return <SelectSubPartitionPage />;
	}

	if (
		!appStore.collectionPlanning.isLoaded
	) {
		return <Loading />;
	}

	return <Layout {...props} />;
});
