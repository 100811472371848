import doc                    from 'decorators/doc';
import model                  from 'decorators/model';
import { urn }                from 'helpers/ModelDictionary';
import InvoicePrivateApiModel from '../../abstracts/InvoicePrivateApiModel';

export type ContactTypeReference = 'invoicing';

@doc.path('/contact_types/{?id}')
@model.cacheDuration()
@urn('$', 'invoice', 'contact_type')
export default class ContactTypeModel extends InvoicePrivateApiModel {
	public _filters: ModelFiltersExtended<{
		'reference': SupplierContactTypeReference;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'label': string;
		'position': string;
	}> = {};

	@doc.string declare description: string;
	@doc.string declare label: string;
	@doc.string declare reference: ContactTypeReference;
}
