import staticLabel                 from 'decorators/staticLabel';
import { urn }                     from 'helpers/ModelDictionary';
import { computed }                from 'mobx';
import InterventionPrivateApiModel from '../../abstracts/InterventionPrivateApiModel';

@staticLabel('Type de document')
@urn('$', 'intervention', 'report_type')
export default class ReportTypeModel extends InterventionPrivateApiModel {
	static get path(): string {
		return '/report_types/{?id}';
	}

	public _filters: ModelFiltersExtended<{
		'reference': string;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'label': string;
	}> = {};

	public static cacheDuration = 3600;

	@computed
	public get reference(): ReportTypeReference {
		return this.get('reference', '');
	}

	public get label(): string {
		return this.get('label', '');
	}
}
