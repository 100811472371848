import { Timestampable }           from 'helpers/traits';
import { computed }                from 'mobx';
import InterventionPrivateApiModel from '../../abstracts/InterventionPrivateApiModel';

export default class InterventionStatusHistoryModel extends Timestampable(InterventionPrivateApiModel) {
	public _filters: ModelFiltersExtended<{ intervention: id }> = {};

	static get path(): string {
		return '/intervention_status_histories/{?id}';
	}

	@computed
	public get interventionId(): id {
		return this.get('intervention.id', 0);
	}

	@computed
	public get interventionStatusId(): id {
		return this.get('interventionStatus.id', 0);
	}

	@computed
	public get chiefNote(): string {
		return this.get('note', '');
	}

	public _sorts: ModelSortsExtended<{
		'id': string;
		'updatedAt': string;
	}> = {};
}
