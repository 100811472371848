import { ApiConnector } from 'Connectors/ApiConnector';
import ConfigProxy      from 'tools/ConfigProxy';
import AbstractApiModel from './AbstractApiModel';

export const vehicleConnector = new ApiConnector({
	baseURL: ConfigProxy.getServiceConfig('vehicle', 'api_endpoint'),
});

export default class VehicleApiModel extends AbstractApiModel {
	static get connector() {
		return vehicleConnector;
	}

	public static serviceName: ServiceName = 'vehicle';
}
