import { LoggedApiConnector } from 'Connectors/LoggedApiConnector';
import ConfigProxy            from 'tools/ConfigProxy';
import AbstractApiModel       from './AbstractApiModel';

export const notificationLoggedConnector = new LoggedApiConnector({
	baseURL: ConfigProxy.getServiceConfig('notification', 'api_endpoint'),
});

export default class NotificationPrivateApiModel extends AbstractApiModel {
	public static get connector() {
		return notificationLoggedConnector;
	}

	public static serviceName: ServiceName = 'notification';
}
