import { TextProps }          from 'components/Text';
import { styleFromTextProps } from 'components/Text';
import React                  from 'react';

const DEFAULT_SEPARATOR_COLOR = 'rgba(0,0,0,0.1)';

export interface ViewProps {
	abs?: boolean; // position "absolute"
	absB?: number | string;
	absF?: boolean; // position "absolute" with full width and height
	absH?: boolean; // position "absolute" with full width
	absL?: number | string;
	absR?: number | string;
	absT?: number | string;
	absV?: boolean; // position "absolute" with full height
	bg?: string; // "backgroundColor"
	bottom?: boolean;
	br?: number | string; // borderRadius
	center?: boolean;
	centerH?: boolean;
	centerV?: boolean;
	className?: string;
	flex?: boolean | number;
	gap?: number | string; // set space between children
	gapH?: number | string; // set space between children
	gapV?: number | string; // set space between children
	height?: string | number;
	heightF?: boolean;
	hidden?: boolean;
	hover?: Props;
	id?: string;
	inline?: boolean;
	left?: boolean;
	margin?: string | number;
	marginB?: string | number;
	marginH?: string | number;
	marginL?: string | number;
	marginR?: string | number;
	marginT?: string | number;
	marginV?: string | number;
	maxHeight?: string | number;
	maxHeightF?: boolean;
	maxWidth?: string | number;
	maxWidthF?: boolean;
	minHeight?: string | number;
	minHeightF?: boolean;
	minWidth?: string | number;
	minWidthF?: boolean;
	noWrap?: boolean // white-space nowrap
	onClick?: (e) => void;
	onKeyDown?: (e) => void;
	onKeyPress?: (e) => void;
	onKeyUp?: (e) => void;
	opacity?: string | number;
	padding?: string | number;
	paddingB?: string | number;
	paddingH?: string | number;
	paddingL?: string | number;
	paddingR?: string | number;
	paddingT?: string | number;
	paddingV?: string | number;
	pointer?: boolean;
	rel?: boolean;
	right?: boolean;
	row?: boolean; // "column" by default
	separatorB?: boolean;
	separatorL?: boolean;
	separatorProps?: ViewProps;
	separatorR?: boolean;
	separatorT?: boolean;
	space?: number | string; // "space-between" + gap
	split?: (k: string) => React.ReactNode;
	splitSeparator?: boolean;
	spread?: boolean; // "space-between"
	top?: boolean;
	transition?: boolean;
	width?: string | number;
	widthF?: boolean; // width 100%
	wrap?: boolean; // flexWrap wrap
}

export const styleFromViewProps = (props: ViewProps) => {
	const separatorColor = props.separatorProps?.bg || DEFAULT_SEPARATOR_COLOR;

	const style: React.CSSProperties = {
		backgroundColor: props.bg,
		borderRadius: props.br,
		display: 'flex',
		flex: (typeof props.flex === 'boolean') ? 1 : props.flex,
		flexDirection: props.row ? 'row' : 'column',
		gap: props.gap,
		height: props.height,
		marginBottom: props.marginB,
		marginLeft: props.marginL,
		marginRight: props.marginR,
		marginTop: props.marginT,
		maxHeight: props.maxHeight,
		maxWidth: props.maxWidth,
		minHeight: props.minHeight,
		minWidth: props.minWidth,
		opacity: props.opacity,
		paddingBottom: props.paddingB,
		paddingLeft: props.paddingL,
		paddingRight: props.paddingR,
		paddingTop: props.paddingT,
		width: props.width,
	};

	if (props.rel) {
		style.position = 'relative';
	}

	if (props.noWrap) {
		style.whiteSpace = 'nowrap';
	}

	if (props.inline) {
		style.display = 'inline-flex';
	}

	if (props.gapV) {
		style.rowGap = props.gapV;
	}

	if (props.gapH) {
		style.columnGap = props.gapH;
	}

	if (props.wrap) {
		style.flexWrap = 'wrap';
	}

	if (props.pointer) {
		style.cursor = 'pointer';
	}

	if (props.hidden) {
		style.display = 'none';
	}

	if (props.widthF) {
		style.width = '100%';
	}

	if (props.minWidthF) {
		style.minWidth = '100%';
	}

	if (props.maxWidthF) {
		style.maxWidth = '100%';
	}

	if (props.minHeightF) {
		style.minHeight = '100%';
	}

	if (props.maxHeightF) {
		style.maxHeight = '100%';
	}

	if (props.heightF) {
		style.height = '100%';
	}

	// noinspection DuplicatedCode
	if (props.margin) {
		style.margin = props.margin;
	} else {
		if (props.marginH) {
			style.marginLeft = props.marginH;
			style.marginRight = props.marginH;
		}

		if (props.marginV) {
			style.marginTop = props.marginV;
			style.marginBottom = props.marginV;
		}
	}

	// noinspection DuplicatedCode
	if (props.padding) {
		style.padding = props.padding;
	} else {
		if (props.paddingH) {
			style.paddingLeft = props.paddingH;
			style.paddingRight = props.paddingH;
		}

		if (props.paddingV) {
			style.paddingTop = props.paddingV;
			style.paddingBottom = props.paddingV;
		}
	}

	const centerH = props.center || props.centerH;
	const centerV = props.center || props.centerV;

	if (centerH) {
		if (props.row) {
			style.justifyContent = 'center';
		} else {
			style.alignItems = 'center';
		}
	}

	if (centerV) {
		if (props.row) {
			style.alignItems = 'center';
		} else {
			style.justifyContent = 'center';
		}
	}

	if (props.bottom) {
		if (props.row) {
			style.alignItems = 'flex-end';
		} else {
			style.justifyContent = 'flex-end';
		}
	}

	if (props.right) {
		if (props.row) {
			style.justifyContent = 'flex-end';
		} else {
			style.alignItems = 'flex-end';
		}
	}

	if (props.left) {
		if (props.row) {
			style.justifyContent = 'flex-start';
		} else {
			style.alignItems = 'flex-start';
		}
	}

	if (props.top) {
		if (props.row) {
			style.alignItems = 'flex-start';
		} else {
			style.justifyContent = 'flex-start';
		}
	}

	if (props.spread) {
		style.justifyContent = 'space-between';
	}

	if (props.space) {
		style.justifyContent = 'space-between';
		style.gap = props.space;
	}

	if (Object.keys(props).some(name => name.startsWith('abs'))) {
		style.position = 'absolute';
	}

	if (typeof props.absL !== 'undefined') {
		style.left = props.absL;
	}

	if (typeof props.absR !== 'undefined') {
		style.right = props.absR;
	}

	if (typeof props.absT !== 'undefined') {
		style.top = props.absT;
	}

	if (typeof props.absB !== 'undefined') {
		style.bottom = props.absB;
	}

	if (props.absV) {
		style.height = '100%';
	}

	if (props.absH) {
		style.width = '100%';
	}

	if (props.absF) {
		style.height = '100%';
		style.width = '100%';
	}

	if (props.separatorB) {
		style.borderBottom = `1px solid ${separatorColor}`;
	}

	if (props.separatorT) {
		style.borderTop = `1px solid ${separatorColor}`;
	}

	if (props.separatorR) {
		style.borderRight = `1px solid ${separatorColor}`;
	}

	if (props.separatorL) {
		style.borderLeft = `1px solid ${separatorColor}`;
	}

	return style;
};

const interleave = (arr, c: (k: string) => React.ReactNode) => [].concat(...arr.map((n, k) => [n, c(k)])).slice(0, -1);

type Props = ViewProps & { style?: React.CSSProperties; } & TextProps;

const styleFromProps = (props: Props) => ({
	...styleFromTextProps(props),
	...styleFromViewProps(props),
	...props.style,
});

const View = (props: React.PropsWithChildren<Props>) => {
	const [isHover, setIsHover] = React.useState(false);

	let children = props.children;

	if (props.splitSeparator) {
		children = interleave(React.Children.toArray(props.children), k => (
			<View
				bg={DEFAULT_SEPARATOR_COLOR}
				height={props.row ? undefined : 1}
				key={'VIEW_SEPARATOR_' + k}
				marginH={props.row ? (typeof props.gap === 'undefined' ? 10 : props.gap) : 0}
				marginV={props.row ? 0 : (typeof props.gap === 'undefined' ? 10 : props.gap)}
				width={props.row ? 1 : undefined}
				{...props.separatorProps}
			/>
		));
	}

	if (props.split) {
		children = interleave(React.Children.toArray(props.children), props.split);
	}

	const transition = typeof props.transition == 'undefined' ? true : props.transition;

	return (
		<div
			className={props.className}
			id={props.id}
			onClick={props.onClick}
			onKeyDown={props.onKeyDown}
			onKeyPress={props.onKeyPress}
			onKeyUp={props.onKeyUp}
			onMouseEnter={props.hover ? () => setIsHover(true) : undefined}
			onMouseLeave={props.hover ? () => setIsHover(false) : undefined}
			style={{
				...((props.hover && isHover) ? styleFromProps({ ...props, ...props.hover }) : styleFromProps(props)),
				transition: transition ? 'all 0.3s ease' : undefined,
			}}
		>
			{children}
		</div>
	);
};

export default View;
