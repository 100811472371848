import lazyWithRetry from 'tools/lazyWithRetry';

const InvoiceItemListPage = lazyWithRetry(() => import(
	/* webpackChunkName: "invoiceItemListPage" */
	'../../pages/InvoiceItem/InvoiceItemListPage'));

export default [
	{
		component: InvoiceItemListPage,
		exact: true,
		path: '/invoice_items',
	},
];
