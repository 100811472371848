import SubPartitionModel from 'Models/partition/SubPartitionModel';
import SelectCollection  from 'components/SelectCollection';
import React             from 'react';
import ConfigProxy       from 'tools/ConfigProxy';
import createSelect      from 'tools/createSelect';

export default createSelect<SubPartitionModel>(props => (
	<SelectCollection
		modelClass={SubPartitionModel}
		placeholder={`Veuillez sélectionner une agence`}
		renderOption={m => m.renderName()}
		sortName="position"
		sortWay="asc"

		{...props}

		filters={{
			'partition': ConfigProxy.get('PARTITION_URN'),
			'subPartitionStatus.reference': 'created',
			...props.filters,
		}}
	/>
));