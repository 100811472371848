import InterventionGroupModel      from 'Models/intervention/InterventionGroupModel';
import InterventionModel           from 'Models/intervention/InterventionModel';
import doc                         from 'decorators/doc';
import model                       from 'decorators/model';
import { Timestampable }           from 'helpers/traits';
import { Blamable }                from 'helpers/traits';
import InterventionPrivateApiModel from 'modelx/models/abstracts/InterventionPrivateApiModel';

@model.staticLabel("Intervention - Groupe d'intervention")
@model.urnResource('intervention_group_intervention')
@doc.path('/intervention_group_interventions/{?id}')
export default class InterventionGroupInterventionModel extends Blamable(Timestampable(InterventionPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'createdAt[after]': string;
		'createdAt[before]': string;
		'createdAt[strictly_after]': string;
		'createdAt[strictly_before]': string;
		'intervention': id;
		'interventionGroup': id;
		'updatedAt[after]': string | Date;
		'updatedAt[before]': string | Date;
		'updatedAt[strictly_after]': string | Date;
		'updatedAt[strictly_before]': string | Date;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'updatedAt': string;
		'intervention.scheduleStartDate': string;
	}> = {};

	@doc.model(InterventionModel) declare intervention: InterventionModel;
	@doc.model(InterventionGroupModel) declare interventionGroup: InterventionGroupModel;
}
