import lazyWithRetry from 'tools/lazyWithRetry';

const OperationListPage = lazyWithRetry(() => import(
	/* webpackChunkName: "operationListPage" */
	'../../pages/Operation/OperationListPage'));

const OperationDashboardPage = lazyWithRetry(() => import(
	/* webpackChunkName: "operationDashboardPage" */
	'../../pages/Operation/OperationDashboardPage'));

export default [
	{
		component: OperationListPage,
		exact: true,
		path: '/operations',
	},
	{
		component: OperationDashboardPage,
		exact: true,
		path: '/operations/:operationId',
	},
];
