import doc                     from 'decorators/doc';
import model                   from 'decorators/model';
import SupplierPrivateApiModel from '../../abstracts/SupplierPrivateApiModel';

@model.cacheDuration()
@model.staticLabel('Type de délai')
@model.urnResource('payment_deadline_type')
@doc.path('/payment_deadline_types/{?id}')
export default class PaymentDeadlineTypeModel extends SupplierPrivateApiModel {
	public _filters: ModelFiltersExtended<{
		'reference': SupplierPaymentDeadlineTypeReference;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'label': string;
		'reference': string;
	}> = {};

	@doc.string declare label: string;
	@doc.string declare reference: SupplierPaymentDeadlineTypeReference;
}