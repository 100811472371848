import { ModelClass }      from '@mathquis/modelx/lib/types/collection';
import { SortWaySet }      from 'Collections/AbstractApiCollection';
import _flatten            from 'lodash/flatten';
import { observable }      from 'mobx';
import { computed }        from 'mobx';
import { isFailed }        from 'tools/modelxTools';
import { isLoaded }        from 'tools/modelxTools';
import { isLoading }       from 'tools/modelxTools';
import AbstractApiModel    from '../models/abstracts/AbstractApiModel';
import { PagedCollection } from './PagedCollection';

export class LoadMoreCollection<T extends AbstractApiModel> {

	private _itemsPerPage = 5;
	private readonly _model: ModelClass<T>;
	private _page = 1;
	private _pagedCollections = observable<PagedCollection<T>>([]);
	private _requestCollection: PagedCollection<T>;

	public constructor(model: ModelClass<T>) {
		this._requestCollection = new PagedCollection(model);
		this._model = model;
	}

	public clear() {
		this._page = 1;
		this._pagedCollections.clear();
		this._requestCollection.clear();
	}

	public findBy<KeyName extends keyof T>(key: KeyName, value: T[KeyName] | T[KeyName][]) {
		return this.models.find(model => Array.isArray(value) ? value.includes(model[key]) : model[key] === value)
			|| null;
	}

	public first() {
		if (this._pagedCollections.length) {
			return this._pagedCollections[0].first();
		}

		return null;
	}

	public getById(id: id) {
		return this.models.find(m => m.id == id);
	}

	public hasFilter(name: ModelFilterName<T>) {
		return this._requestCollection.hasFilter(name);
	}

	public list(options?: ApiConnectorOptions<T>) {
		this._page = 1;
		this._pagedCollections.clear();

		return this.loadMore(options);
	}

	public async loadMore(options?: ApiConnectorOptions<T>) {
		if (!this._pagedCollections.length || this.hasNextPage) {
			if (this._pagedCollections.length) {
				this._page++;
			}

			const coll = this._requestCollection.clone();

			this._pagedCollections.push(coll);

			await coll
				.setItemsPerPage(this._itemsPerPage)
				.setPage(this._page)
				.list({
					...options,
					params: {
						...options?.params,
						...this._requestCollection.getSorts(),
						...this._requestCollection.getFilters(),
					},
				});
		}

		return this;
	}

	@computed
	public get models() {
		return _flatten(this._pagedCollections.map(coll => coll.models));
	}

	@computed
	public get ids() {
		return this.models.map(m => m.id);
	}

	@computed
	public get isLoaded() {
		return this._pagedCollections.length && isLoaded(this._pagedCollections);
	}

	@computed
	public get isLoading() {
		return isLoading(this._pagedCollections);
	}

	public get isFailed() {
		return isFailed(this._pagedCollections);
	}

	public get total() {
		return this._pagedCollections.length;
	}

	public get hasNextPage() {
		if (this._pagedCollections.length) {
			const lastColl = this._pagedCollections[this._pagedCollections.length - 1];

			return lastColl.total > this._page * this._itemsPerPage;
		}

		return false;
	}

	public push(model: T) {
		const coll = new PagedCollection(this._model, [model]);
		coll.isLoaded = true;

		this._pagedCollections.push(coll);
	}

	public replace(model: T): this {
		const found = this.models.find(m => m.id == model.id);

		if (found) {
			found.set(model.attributes);
		} else {
			this.push(model);
		}

		return this;
	}

	public setFilter<FilterName extends ModelFilterName<T>>(
		name: FilterName,
		value: ModelFilters<T>[FilterName],
	): this {
		this._requestCollection.setFilter(name, value);

		return this;
	}

	public setFilters(filters: ModelFilters<T>): this {
		this._requestCollection.setFilters(filters);

		return this;
	}

	public setItemsPerPage(itemsPerPage: number) {
		this._itemsPerPage = itemsPerPage;

		return this;
	}

	public setSort(field: ModelSortName<T>, way: SortWaySet = true) {
		this._requestCollection.setSort(field, way);

		return this;
	}

	public setSorts(sorts: Record<string, SortWaySet>) {
		this._requestCollection.setSorts(sorts);

		return this;
	}
}
