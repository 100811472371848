export default async <T>(
	array: T[],
	predicate: (t: T) => Promise<boolean>,
): Promise<T | undefined> => {
	for (const t of array) {
		if (await predicate(t)) {
			return t;
		}
	}
	return undefined;
};