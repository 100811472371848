import { urn }                from 'helpers/ModelDictionary';
import { Blamable }           from 'helpers/traits';
import { Timestampable }      from 'helpers/traits';
import { computed }           from 'mobx';
import InvoicePrivateApiModel from '../../abstracts/InvoicePrivateApiModel';

export type InvoicingGroupEmailTypeReference = 'issuance' | 'reminder';

@urn('$', 'invoice', 'invoicing_group_email')
export default class InvoicingGroupEmailTypeModel extends Blamable(Timestampable(InvoicePrivateApiModel)) {
	static get path(): string {
		return '/invoicing_group_email_types/{?id}';
	}

	public _filters: ModelFiltersExtended<{
		'reference': string;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'label': string;
		'position': string;
	}> = {};

	public static cacheDuration = 3600;

	@computed
	public get reference(): InvoicingGroupEmailTypeReference {
		return this.get('reference');
	}

	public get label(): string {
		return this.get('label', '');
	}

	public get position(): number {
		return this.get('position', 0);
	}
}
