import InvoiceModel           from 'Models/invoice/InvoiceModel';
import InvoiceTagModel        from 'Models/invoice/InvoiceTagModel';
import doc                    from 'decorators/doc';
import model                  from 'decorators/model';
import { Blamable }           from 'helpers/traits';
import { Timestampable }      from 'helpers/traits';
import InvoicePrivateApiModel from '../../abstracts/InvoicePrivateApiModel';

@model.staticLabel('InvoiceInvoiceTag')
@model.urn('$', 'invoice', 'invoice_invoice_tag')
@doc.path('/invoice_invoice_tags/{?id}')
export default class InvoiceInvoiceTagModel extends Blamable(Timestampable(InvoicePrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'invoice': id;
		'invoiceTag': id;
		'invoiceTag.reference': InvoiceTagReference;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
	}> = {};

	@doc.model(InvoiceModel) declare invoice: InvoiceModel;
	@doc.model(InvoiceTagModel) declare invoiceTag: InvoiceTagModel;
}