import OperationModel              from 'Models/intervention/OperationModel';
import doc                         from 'decorators/doc';
import model                       from 'decorators/model';
import { Timestampable }           from 'helpers/traits';
import { Blamable }                from 'helpers/traits';
import InterventionPrivateApiModel from 'modelx/models/abstracts/InterventionPrivateApiModel';

@model.staticLabel('OperationType')
@model.urnResource('operation_type')
@doc.path('/operation_types/{?id}')
export default class OperationTypeModel extends Blamable(Timestampable(InterventionPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'archived': '0' | '1';
		'createdAt[after]': string;
		'createdAt[before]': string;
		'createdAt[strictly_after]': string;
		'createdAt[strictly_before]': string;
		'operations.operationCategory': id;
		'operations.operationCategory.partitionUrn': Urn;
		'updatedAt[after]': string | Date;
		'updatedAt[before]': string | Date;
		'updatedAt[strictly_after]': string | Date;
		'updatedAt[strictly_before]': string | Date;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'label': string;
		'updatedAt': string;
	}> = {};

	@doc.boolean declare archived: boolean;
	@doc.string declare label: string;

	@model.reverseResolvable({ urn: '$:intervention:operation' })
	public declare operation: OperationModel;
}
