import ContractIterationModel from 'Models/sales/ContractIterationModel';
import ItemDisplayModeModel   from 'Models/sales/ItemDisplayModeModel';
import doc                    from 'decorators/doc';
import model                  from 'decorators/model';
import { Timestampable }      from 'helpers/traits';
import { Blamable }           from 'helpers/traits';
import SalesPrivateApiModel   from 'modelx/models/abstracts/SalesPrivateApiModel';

@model.staticLabel('Prestation', false)
@model.urnResource('contract_iteration_item')
@doc.path('/contract_iteration_items/{?id}')
export default class ContractIterationItemModel extends Blamable(Timestampable(SalesPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'contractIteration': id;
	}> = {};

	public _sorts: ModelSortsExtended<{
		position: boolean;
	}> = {};

	@doc.model(ContractIterationModel) declare contractIteration: ContractIterationModel;
	@doc.string declare description: string;
	@doc.number declare discount: number;
	@doc.model(ItemDisplayModeModel) declare itemDisplayMode: ItemDisplayModeModel;
	@doc.string declare priceDetails: string;
	@doc.string declare publicComment: string;
	@doc.number declare quantity: number;
	@doc.string declare title: string;
	@doc.number declare position: number;

	public get contractIterationId(): id {
		return this.get('contractIteration.id', '');
	}
}