import { resolvable }              from '@mathquis/modelx-resolvables';
import ContactModel                from 'Models/directory/ContactModel';
import { ContactTypeReference }    from 'Models/intervention/ContactTypeModel';
import ContactTypeModel            from 'Models/intervention/ContactTypeModel';
import OperationCategoryModel      from 'Models/intervention/OperationCategoryModel';
import TaskZoneModel               from 'Models/intervention/TaskZoneModel';
import { urn }                     from 'helpers/ModelDictionary';
import { Blamable }                from 'helpers/traits';
import { Timestampable }           from 'helpers/traits';
import InterventionPrivateApiModel from '../../abstracts/InterventionPrivateApiModel';

@urn('$', 'intervention', 'zone_contact')
export default class ZoneContactModel extends Blamable(Timestampable(InterventionPrivateApiModel)) {

	static get path(): string {
		return '/zone_contacts/{?id}';
	}

	public _filters: ModelFiltersExtended<{
		'contactType': id;
		'contactType.reference': ContactTypeReference;
		'contactUrn': string;
		'createdAt[after]': string;
		'createdAt[before]': string;
		'createdAt[strictly_after]': string;
		'createdAt[strictly_before]': string;
		'operationCategory': id;
		'taskZone': id;
		'updatedAt[after]': string;
		'updatedAt[before]': string;
		'updatedAt[strictly_after]': string;
		'updatedAt[strictly_before]': string;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'position': string;
		'updatedAt': string;
	}> = {};

	public get position(): number {
		return this.get('position', 0);
	}

	public get contactTypeId(): id {
		return this.get('contactType.id', 0);
	}

	public get taskZoneId(): id {
		return this.get('taskZone.id', 0);
	}

	public get contactId(): id {
		return this.get('contact.id', 0) || this.contact.id;
	}

	public get contactUrn(): string {
		return this.get('contactUrn');
	}

	public get operationCategoryId(): id {
		return this.get('operationCategory.id', 0);
	}

	@resolvable(ContactModel, { attributeName: 'contactUrn' })
	declare contact: ContactModel;

	@resolvable(ContactTypeModel, { attributeName: 'contactType', cache: true })
	declare contactType: ContactTypeModel;

	@resolvable(OperationCategoryModel, { attributeName: 'operationCategory', cache: true })
	declare operationCategory: OperationCategoryModel;

	@resolvable(TaskZoneModel, { attributeName: 'taskZone' })
	declare taskZone: TaskZoneModel;
}
