import { ModelClass }            from '@mathquis/modelx/lib/types/collection';
import { ICollectionOptions }    from '@mathquis/modelx/lib/types/collection';
import { ConnectorResults }      from '@mathquis/modelx/';
import { AbstractApiCollection } from 'Collections/AbstractApiCollection';
import { computed }              from 'mobx';
import { action }                from 'mobx';
import { observable }            from 'mobx';
import { makeObservable }        from 'mobx';
import { override }              from 'mobx';
import AbstractApiModel          from '../models/abstracts/AbstractApiModel';

export class PagedCollection<T extends AbstractApiModel> extends AbstractApiCollection<T> {
	@observable public total = 0;

	@observable protected _page = 1;

	@observable private _itemsPerPage = 5;

	public constructor(model: ModelClass<T>, models?: T[], options?: ICollectionOptions) {
		super(model, models, options);

		makeObservable(this);
	}

	public clear() {
		this._page = 1;

		return super.clear();
	}

	public reload(options?) {
		this._page = 1;

		return this.list(options);
	}

	@action
	public setItemsPerPage(itemsPerPage: number) {
		this._itemsPerPage = itemsPerPage;

		return this;
	}

	@action
	public setPage(page: number) {
		this._page = page;

		return this;
	}

	@action
	public setTotal(value: number) {
		this.total = value;

		return this;
	}

	@computed
	public get itemsPerPage(): number {
		return this._itemsPerPage;
	}

	@computed
	public get page() {
		return this._page;
	}

	@computed
	public get hasPreviousPage() {
		return this._page > 1;
	}

	@computed
	public get hasNextPage() {
		return this.total > this._page * this._itemsPerPage;
	}

	@computed
	public get hasSinglePage() {
		return this.total <= this._itemsPerPage;
	}

	@override
	protected onListSuccess(results: ConnectorResults, options) {
		this.total = results.res.data['hydra:totalItems'];

		super.onListSuccess(results, options);
	}

	// eslint-disable-next-line @typescript-eslint/ban-types
	protected prepareListOptions(options): object {
		return super.prepareListOptions({
			...options,
			params: {
				itemsPerPage: this._itemsPerPage,
				page: this._page,
				...options.params,
			},
		});
	}
}

export default PagedCollection;
