export const BILLABLE_STATUS_IN_CREATION = {
	color: '#e67e22',
	label: 'En cours de création',
	reference: 'in_creation' as BillableStatusReference,
};

export const BILLABLE_STATUS_TO_VALIDATE = {
	color: '#E62260',
	label: 'A valider',
	reference: 'to_validate' as BillableStatusReference,
};

export const BILLABLE_STATUS_VALIDATED = {
	color: '#484AEC',
	label: 'Validé',
	reference: 'validated' as BillableStatusReference,
};

export const BILLABLE_STATUS_CREATED = {
	color: '#3498db',
	label: 'Créé',
	reference: 'created' as BillableStatusReference,
};

export const BILLABLE_STATUS_TO_BILL = {
	color: '#9b59b6',
	label: 'A facturer',
	reference: 'to_bill' as BillableStatusReference,
};

export const BILLABLE_STATUS_BILLED = {
	color: '#2ecc71',
	label: 'Facturé',
	reference: 'billed' as BillableStatusReference,
};

const billableStatuses = [
	BILLABLE_STATUS_BILLED,
	BILLABLE_STATUS_CREATED,
	BILLABLE_STATUS_IN_CREATION,
	BILLABLE_STATUS_TO_BILL,
	BILLABLE_STATUS_TO_VALIDATE,
	BILLABLE_STATUS_VALIDATED,
];

export const getColorByReference = reference => {
	const status = billableStatuses.find(status => status.reference === reference);

	if (!status) {
		return BILLABLE_STATUS_IN_CREATION.color;
	}

	return status.color;
};
