export const CONTRACT_ITERATION_ITEM_EVENT_STATUS_TO_DO = {
	label: 'À planifier',
	reference: 'to_do',
};

export const CONTRACT_ITERATION_ITEM_EVENT_STATUS_DONE = {
	label: 'Effectuée',
	reference: 'done',
};

export const CONTRACT_ITERATION_ITEM_EVENT_STATUS_DONE_PENDING = {
	label: 'Effectuée (en attente facturation)',
	reference: 'done_pending',
};

export const CONTRACT_ITERATION_ITEM_EVENT_STATUS_DONE_NOT_COMPLETE = {
	label: 'Effectuée (non complète)',
	reference: 'done_not_complete',
};

export const CONTRACT_ITERATION_ITEM_EVENT_STATUS_TO_PAY = {
	label: 'En attente de paiement',
	reference: 'to_pay',
};

export const CONTRACT_ITERATION_ITEM_EVENT_STATUS_PAID = {
	label: 'Paiement effectué',
	reference: 'paid',
};

export const CONTRACT_ITERATION_ITEM_EVENT_STATUS_LONG_DELAY_IN_PAYMENT = {
	label: 'En long retard de paiement',
	reference: 'long_delay_in_payment',
};
