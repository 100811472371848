import { action }         from 'mobx';
import { computed }       from 'mobx';
import { observable }     from 'mobx';
import { makeObservable } from 'mobx';
import { clear }          from 'tools/modelxTools';

export default abstract class AbstractModelXStore {

	@observable
	protected _isLoaded = false;

	@observable
	protected _isLoading = false;

	public constructor() {
		makeObservable(this);
	}

	public clear(): this {
		clear(this);

		return this;
	}

	@action
	public setIsLoaded(value: boolean): this {
		this._isLoaded = value;

		return this;
	}

	@action
	public setIsLoading(value: boolean): this {
		if (this._isLoading && !value) {
			this.setIsLoaded(true);
		}

		this._isLoading = value;

		return this;
	}

	@computed
	public get isLoading(): boolean {
		return this._isLoading;
	}

	@computed
	public get isLoaded(): boolean {
		return this._isLoaded;
	}
}