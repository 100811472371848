import notFoundRoutes from './notFoundRoutes';

let routes: IRoute[] = [...notFoundRoutes];
const modules = import.meta.glob(['./*', './*/*'], { eager: true });

Object.keys(modules).forEach(key => {
	if (key.endsWith('Routes.ts') && key !== './notFoundRoutes.ts' && modules[key]) {
		routes = [...(modules[key] as never)['default'], ...routes];
	}
});

export default routes;