import CompanyModel           from 'Models/directory/CompanyModel';
import doc from 'decorators/doc';
import model from 'decorators/model';
import { Timestampable }      from 'helpers/traits';
import { Blamable }           from 'helpers/traits';
import VehiclePrivateApiModel from '../../abstracts/VehiclePrivateApiModel';

@model.staticLabel(`Contrat d'assurance`)
@model.urnResource('insurance_contract')
@doc.path('/insurance_contracts/{?id}')
export default class InsuranceContractModel extends Blamable(Timestampable(VehiclePrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'endDate[after]': string | Date;
		'endDate[before]': string | Date;
		'endDate[strictly_after]': string | Date;
		'endDate[strictly_before]': string | Date;
		'insuranceContractVehicles.vehicle': id;
		'startDate[after]': string | Date;
		'startDate[before]': string | Date;
		'startDate[strictly_after]': string | Date;
		'startDate[strictly_before]': string | Date;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'companyUrn': string;
		'endDate': string;
		'id': string;
		'startDate': string;
	}> = {};

	@doc.modelUrn(CompanyModel) declare company: CompanyModel;
	@doc.string declare contractReference: string;
	@doc.date declare endDate: Moment;
	@doc.date declare startDate: Moment;
}