import { Model }    from '@mathquis/modelx';
import { computed } from 'mobx';

export default () => computed({
	equals: (a: Model[], b: Model[]) => {
		const aIds = a.map(m => m.id);
		const bIds = b.map(m => m.id);
		const aIsLoaded = a.map(m => m.isLoaded);
		const bIsLoaded = b.map(m => m.isLoaded);

		return (
			aIds.toString() === bIds.toString()
			&& aIsLoaded.toString() === bIsLoaded.toString()
		);
	},
});