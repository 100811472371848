import doc                  from 'decorators/doc';
import model                from 'decorators/model';
import { Blamable }         from 'helpers/traits';
import { Timestampable }    from 'helpers/traits';
import SalesPrivateApiModel from 'modelx/models/abstracts/SalesPrivateApiModel';

@model.cacheDuration()
@model.staticLabel('Statut')
@model.urnResource('quotation_request_status')
@doc.path('/quotation_request_statuses/{?id}')
export default class QuotationRequestStatusModel extends Blamable(Timestampable(SalesPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'reference': SalesQuotationRequestStatusReference;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'label': string;
		'position': string;
		'reference': string;
	}> = {};

	@doc.string declare label: string;
	@doc.number declare position: number;
	@doc.string declare reference: SalesQuotationRequestStatusReference;

	public get color() {
		switch (this.reference) {
			case 'created':
				return 'black';
			case 'sent_to_planning':
				return 'blue';
			case 'task_done':
				return 'lightgreen';
			case 'task_done_not_complete':
				return 'yellow';
			case 'quotation_in_creation':
				return 'grey';
			case 'quotation_created':
				return 'darkgreen';
			case 'cancelled':
				return 'purple';
			default:
				return 'black';
		}
	}
}