import { computed }                from 'mobx';
import InterventionPrivateApiModel from '../../abstracts/InterventionPrivateApiModel';

export type TaskTagReference = 'not_billable' | 'long_delay_in_payment';

export const taskTagColors: Record<TaskTagReference, string> = {
	long_delay_in_payment: 'orange',
	not_billable: 'red',
};

export default class TaskTagModel extends InterventionPrivateApiModel {

	static get path(): string {
		return '/task_tags/{?id}';
	}

	@computed
	public get label(): string {
		return this.get('label', '');
	}

	@computed
	public get reference(): TaskTagReference {
		return this.get('reference', '');
	}

	@computed
	public get color(): string {
		return taskTagColors[this.reference];
	}

	public _sorts: ModelSortsExtended<{
		'id': string;
		'label': string;
		'reference': string;
	}> = {};
}
