import { Timestampable }           from 'helpers/traits';
import { computed }                from 'mobx';
import InterventionPrivateApiModel from '../../abstracts/InterventionPrivateApiModel';

export default class InterventionAckModel extends Timestampable(InterventionPrivateApiModel) {
	public _filters: ModelFiltersExtended<{ intervention: id }> = {};

	static get path(): string {
		return '/intervention_acks/{?id}';
	}

	@computed
	public get interventionId(): id {
		return this.get('intervention.id', 0);
	}

	@computed
	public get contactName(): string {
		return this.get('contactName', '');
	}

	@computed
	public get rating(): number {
		return this.get('rating', 0);
	}

	@computed
	public get note(): string {
		return this.get('note', '');
	}

	public _sorts: ModelSortsExtended<{
		'contactName': string;
		'id': string;
		'updatedAt': string;
	}> = {};
}
