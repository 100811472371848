import lazyWithRetry from 'tools/lazyWithRetry';

const BillingGroupListPage = lazyWithRetry(() => import(
	/* webpackChunkName: "homePage" */
	'../../pages/BillingGroup/BillingGroupListPage/BillingGroupListPage'));

export default [
	{
		component: BillingGroupListPage,
		exact: true,
		path: '/billing_groups/billing_periods/:billingPeriodId',
	},
];
