import BlockRequestStatusModel from 'Models/sales/BlockRequestStatusModel';
import BlockRequestTypeModel   from 'Models/sales/BlockRequestTypeModel';
import OwnerSubPartitionModel  from 'Models/sales/OwnerSubPartitionModel';
import doc from 'decorators/doc';
import model from 'decorators/model';
import { Blamable }            from 'helpers/traits';
import { Timestampable }       from 'helpers/traits';
import SalesPrivateApiModel    from '../../abstracts/SalesPrivateApiModel';

@model.staticLabel('Blocage')
@model.urnResource('block_request')
@doc.path('/block_requests/{?id}')
export default class BlockRequestModel extends Blamable(Timestampable(SalesPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'blockRequestStatus': id;
		'blockRequestStatus.reference': BlockRequestStatusReference;
		'blockRequestTargets.ownerBlockRequestTarget.targetUrn': string;
		'blockRequestType': id;
		'blockRequestType.reference': BlockRequestTypeReference;
		'createdAt': string;
		'createdAt[after]': string;
		'createdAt[before]': string;
		'createdAt[strictly_after]': string;
		'createdAt[strictly_before]': string;
		'ownerSubPartition': id;
		'ownerSubPartition.partitionUrn': string;
		'ownerSubPartition.subPartitionUrn': string;
		'sourceUrn': string;
		'updatedAt': string;
		'updatedAt[after]': string;
		'updatedAt[before]': string;
		'updatedAt[strictly_after]': string;
		'updatedAt[strictly_before]': string;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'updatedAt': string;
	}> = {};

	@doc.model(BlockRequestStatusModel) declare blockRequestStatus: BlockRequestStatusModel;
	@doc.model(BlockRequestTypeModel) declare blockRequestType: BlockRequestTypeModel;
	@doc.string declare note:string;
	@doc.model(OwnerSubPartitionModel) declare ownerSubPartition: OwnerSubPartitionModel;

	public get name() {
		return `N°${this.id}`;
	}
}