import { urn }                from 'helpers/ModelDictionary';
import InvoicePrivateApiModel from '../../abstracts/InvoicePrivateApiModel';

export type DebitMandateStatusReference = 'unsigned' | 'signed' | 'actif' | 'inactif';

@urn('$', 'invoice', 'debit_mandate_status')
export default class DebitMandateStatusModel extends InvoicePrivateApiModel {

	static get path(): string {
		return '/debit_mandate_statuses/{?id}';
	}

	public _filters: ModelFiltersExtended<{
		'reference': string;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'reference': string;
	}> = {};

	public get label(): string {
		return this.get('label', '');
	}

	public get reference(): DebitMandateStatusReference {
		return this.get('reference', '');
	}
}