import lazyWithRetry from 'tools/lazyWithRetry';

const TaskGroupDashboardPage = lazyWithRetry(() => import(
	/* webpackChunkName: "taskGroupDashboardPage" */
	'../../pages/TaskGroup/TaskGroupDashboardPage/TaskGroupDashboardPage'));

export default [
	{
		component: TaskGroupDashboardPage,
		exact: true,
		path: '/task_groups/:taskGroupId',
	},
];
