import doc                         from 'decorators/doc';
import model                       from 'decorators/model';
import { Timestampable }           from 'helpers/traits';
import InterventionPrivateApiModel from 'modelx/models/abstracts/InterventionPrivateApiModel';

@model.cacheDuration()
@model.staticLabel('OperationStatus')
@model.urnResource('operation_status')
@doc.path('/operation_statuses/{?id}')
export default class OperationStatusModel extends Timestampable(InterventionPrivateApiModel) {
	public _filters: ModelFiltersExtended<unknown> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'label': string;
	}> = {};

	@doc.string declare label: string;
	@doc.string declare reference: InterventionOperationStatusReference;
}