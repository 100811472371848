import RiskAssessmentModel  from 'Models/intervention/RiskAssessmentModel';
import TaskZoneModel        from 'Models/intervention/TaskZoneModel';
import VatModel             from 'Models/invoice/VatModel';
import ActivityModel        from 'Models/sales/ActivityModel';
import QuotationItemModel   from 'Models/sales/QuotationItemModel';
import doc                  from 'decorators/doc';
import model                from 'decorators/model';
import { Timestampable }    from 'helpers/traits';
import { Blamable }         from 'helpers/traits';
import SalesPrivateApiModel from 'modelx/models/abstracts/SalesPrivateApiModel';

@model.staticLabel('QuotationItemActivity')
@model.urnResource('quotation_item_activity')
@doc.path('/quotation_item_activities/{?id}')
export default class QuotationItemActivityModel extends Blamable(Timestampable(SalesPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'locationUrn': Urn;
		'quotationItem': id;
		'quotationItem.quotation': id;
		'tags[serviceUrn][tagReference]': id;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'position': boolean;
		'quotationItem.position': boolean;
	}> = {};

	@doc.model(ActivityModel) declare activity: ActivityModel;
	@doc.string declare label: string;
	@doc.string declare materialQuantity: string;
	@doc.string declare note: string;
	@doc.number declare position: number;
	@doc.number declare priceExclTax: number;
	@doc.model(QuotationItemModel) declare quotationItem: QuotationItemModel;
	@doc.modelUrn(RiskAssessmentModel) declare riskAssessment: RiskAssessmentModel;
	@doc.number declare totalPriceExclTax: number;
	@doc.number declare totalPriceInclTax: number;
	@doc.string declare vatRate: string;
	@doc.modelUrn(VatModel) declare vat: VatModel;

	@model.resolvable(TaskZoneModel, { attributeName: 'locationUrn' })
	declare location: TaskZoneModel;
}
