import model                            from 'decorators/model';
import { computed }                     from 'mobx';
import { IInterventionStatusNameProps } from 'modelNames/intervention/InterventionStatusName';
import InterventionPrivateApiModel      from '../../abstracts/InterventionPrivateApiModel';

export const interventionStatusColors: Record<InterventionStatusReference, string> = {
	cancelled: 'mediumpurple',
	done: '#3473E2',
	in_creation: 'black',
	in_progress: 'rgba(52, 115, 226, .15)',
	pointed: '#28a745',
	pre_validated: '#F59A22',
	scheduled: '',
	validated: '#90EE90',
	waiting_pre_validation: '#ff4d4f',
};

export type InterventionStatusReference =
	'cancelled'
	| 'done'
	| 'in_progress'
	| 'in_creation'
	| 'pointed'
	| 'pre_validated'
	| 'scheduled'
	| 'validated'
	| 'waiting_pre_validation';

@model.urnResource('intervention_status')
export default class InterventionStatusModel extends InterventionPrivateApiModel {
	public _filters: ModelFiltersExtended<{
		reference: InterventionStatusReference;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'label': string;
		'position': string;
		'reference': string;
	}> = {};

	static get path(): string {
		return '/intervention_statuses/{?id}';
	}

	public static cacheDuration = 3600;

	@computed
	public get reference(): InterventionStatusReference {
		return this.get('reference', '');
	}

	@computed
	public get label(): string {
		return this.get('label', '');
	}

	@computed
	public get color(): string {
		return interventionStatusColors[this.reference] || '';
	}

	@computed get isDone(): boolean {
		return this.reference === 'done';
	}

	@computed
	public get isPointed(): boolean {
		return this.reference === 'pointed';
	}

	@computed
	public get isCancelled(): boolean {
		return this.reference === 'cancelled';
	}

	@computed
	public get isScheduled(): boolean {
		return this.reference === 'scheduled';
	}

	@computed
	public get isWaitingPreValidation(): boolean {
		return this.reference === 'waiting_pre_validation';
	}

	@computed
	public get isPreValidated(): boolean {
		return this.reference === 'pre_validated';
	}

	public renderName(props?: Omit<IInterventionStatusNameProps, 'model'>) {
		return super.renderName(props);
	}
}
