import doc                      from 'decorators/doc';
import model                    from 'decorators/model';
import { computed }             from 'mobx';
import { IPartitionNameProps }  from 'modelNames/partition/PartitionName';
import PartitionPrivateApiModel from 'modelx/models/abstracts/PartitionPrivateApiModel';

const backoffices: { icon: string; url: string; urn: string; }[] = [];

@model.cacheDuration()
@model.staticLabel('Société', false)
@model.urnResource('partition')
@doc.path('/partitions/{?id}')
export default class PartitionModel extends PartitionPrivateApiModel {
	public _filters: ModelFiltersExtended<{
		'name': string;
		'siren': id;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'name': string;
		'siren': string;
	}> = {};

	@doc.string declare name: string;
	@doc.string declare siren: string;
	@doc.string declare timezone: string;

	@computed
	public get backoffice() {
		return backoffices.find(b => b.urn === this.urn);
	}

	@computed
	public get icon() {
		return this.backoffice?.icon || '';
	}

	@computed
	public get url() {
		return this.backoffice?.url || '';
	}

	public renderName(props?: Omit<IPartitionNameProps, 'model'>) {
		return super.renderName(props);
	}
}