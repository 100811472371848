import { computed }         from 'mobx';
import SalesPrivateApiModel from '../../abstracts/SalesPrivateApiModel';

export default class EnterpriseConfigModel extends SalesPrivateApiModel {
	public _filters: ModelFiltersExtended<{
		enterpriseUrn: string;
	}> = {};

	static get path(): string {
		return '/enterprise_configs/{?id}';
	}

	@computed
	public get onPurchaseOrder(): boolean {
		return this.get('onPurchaseOrder', false);
	}

	@computed
	public get quotationsOnPurchaseOrder(): boolean {
		return this.get('quotationsOnPurchaseOrder', false);
	}
}
