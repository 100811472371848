import { resolvable }          from '@mathquis/modelx-resolvables';
import ContactModel            from 'Models/directory/ContactModel';
import StaffMemberModel        from 'Models/rh/StaffMemberModel';
import ContractActorGroupModel from 'Models/sales/ContractActorGroupModel';
import ContractModel           from 'Models/sales/ContractModel';
import doc                     from 'decorators/doc';
import model                   from 'decorators/model';
import { BlamableEmptyModel }  from 'helpers/traits';
import { Timestampable }       from 'helpers/traits';
import { Blamable }            from 'helpers/traits';
import { computed }            from 'mobx';
import SalesPrivateApiModel    from 'modelx/models/abstracts/SalesPrivateApiModel';
import { getIdFromUrn }        from 'tools/UrnTools';

@model.staticLabel('Acteur')
@model.urnResource('contract_actor')
@doc.path('/contract_actors/{?id}')
export default class ContractActorModel extends Blamable(Timestampable(SalesPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'contract': id;
		'contract.owner.clientUrn': Urn;
		'contract.owner.companyUrn': Urn;
		'contract.owner.enterpriseUrn': Urn;
		'contract.owner.ownerEnterprisePartition.enterprisePartitionUrn': Urn;
		'contract.owner.ownerSubPartition.ownerPartition.partitionUrn': Urn;
		'contract.owner.ownerSubPartition.subPartitionUrn': Urn;
		'contractActorGroup.reference': SalesContractActorGroupReference;
	}> = {};

	public _sorts: ModelSortsExtended<unknown> = {};

	@doc.model(ContractModel) declare contract: ContractModel;
	@doc.model(ContractActorGroupModel) declare contractActorGroup: ContractActorGroupModel;
	@doc.number declare position: number;

	@resolvable(ContactModel, {
		allow: [ContactModel, StaffMemberModel, BlamableEmptyModel],
		attributeName: 'actorUrn',
		unresolved: BlamableEmptyModel,
	})
	declare actor: BlamableEmptyModel;

	@computed
	public get actorId(): id {
		return parseInt(getIdFromUrn(this.get('actorUrn')));
	}

	@computed
	public get actorUrn(): string {
		return this.get('actorUrn');
	}
}
