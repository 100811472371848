import doc                    from 'decorators/doc';
import model                  from 'decorators/model';
import { Timestampable }      from 'helpers/traits';
import { Blamable }           from 'helpers/traits';
import CommentPrivateApiModel from 'modelx/models/abstracts/CommentPrivateApiModel';

@model.staticLabel('Source')
@model.urnResource('source')
@doc.path('/stonecode_comment_sources/{?id}')
export default class SourceModel extends Blamable(Timestampable(CommentPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'entityUrn': string;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'updatedAt': string;
	}> = {};

	public get entityUrn(): Urn {
		return this.get('entityUrn', '');
	}
}
// TODO DOC - Ajouter la propriété "entity"
