import lazyWithRetry from 'tools/lazyWithRetry';

const CollectableListPage = lazyWithRetry(() => import(
	/* webpackChunkName: "collectableListPage" */
	'../../pages/Collectable/CollectableListPage/CollectableListPage'));

const CollectableDashboardPage = lazyWithRetry(() => import(
	/* webpackChunkName: "collectableDashboardPage" */
	'../../pages/Collectable/CollectableDashboardPage/CollectableDashboardPage'));

const CollectableAddPage = lazyWithRetry(() => import(
	/* webpackChunkName: "collectableAddPage" */
	'../../pages/Collectable/CollectableAddPage/CollectableAddPage'));

export default [
	{
		component: CollectableListPage,
		exact: true,
		path: '/collectables',
	},
	{
		component: CollectableAddPage,
		exact: true,
		path: '/collectables/add',
	},
	{
		component: CollectableDashboardPage,
		exact: true,
		path: '/collectables/:collectableId',
	},
];