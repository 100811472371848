import lazyWithRetry from 'tools/lazyWithRetry';

const TaskOperationToScheduleListPage = lazyWithRetry(() => import(
	/* webpackChunkName: "taskOperationToScheduleListPage" */
	'../../pages/TaskOperation/TaskOperationToScheduleListPage'));

export default [
	{
		component: TaskOperationToScheduleListPage,
		exact: true,
		path: '/sub_partitions/:subPartitionId/to_schedule_task_operations',
	},
];