import doc                  from 'decorators/doc';
import model                from 'decorators/model';
import { Timestampable }    from 'helpers/traits';
import SalesPrivateApiModel from 'modelx/models/abstracts/SalesPrivateApiModel';

@model.cacheDuration()
@model.staticLabel('FrameworkAgreementStatus')
@model.urnResource('framework_agreement_status')
@doc.path('/framework_agreement_statuses/{?id}')
export default class FrameworkAgreementStatusModel extends Timestampable(SalesPrivateApiModel) {
	public _filters: ModelFiltersExtended<{
		'frameworkAgreementStatusHistories': id;
		'frameworkAgreements': id;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
	}> = {};

	@doc.string declare reference: SalesFrameworkAgreementStatusReference;
}