import { urn }                from 'helpers/ModelDictionary';
import { Blamable }           from 'helpers/traits';
import { Timestampable }      from 'helpers/traits';
import InvoicePrivateApiModel from '../../abstracts/InvoicePrivateApiModel';

@urn('$', 'invoice', 'invoice_notification_status')
export default class InvoiceNotificationStatusModel extends Blamable(Timestampable(InvoicePrivateApiModel)) {

	public _filters: ModelFiltersExtended<{
		'reference': string;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'label': string;
	}> = {};

	public static cacheDuration = 3600;

	static get path(): string {
		return '/invoice_notification_statuses/{?id}';
	}

	public get label(): string {
		return this.get('label', '');
	}

	public get reference(): string {
		return this.get('reference', '');
	}
}