import { urn }                from 'helpers/ModelDictionary';
import { Blamable }           from 'helpers/traits';
import { Timestampable }      from 'helpers/traits';
import { computed }           from 'mobx';
import InvoicePrivateApiModel from '../../abstracts/InvoicePrivateApiModel';

export type BillingPeriodStatusReference = 'open' | 'closed';

@urn('$', 'invoice', 'billing_period_status')
export default class BillingPeriodStatusModel extends Blamable(Timestampable(InvoicePrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'reference': BillingPeriodStatusReference;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'label': string;
	}> = {};

	static get path(): string {
		return '/billing_period_statuses/{?id}';
	}

	public static cacheDuration = 3600;

	@computed
	public get label(): string {
		return this.get('label', '');
	}

	@computed
	public get reference(): BillingPeriodStatusReference {
		return this.get('reference', '');
	}
}
