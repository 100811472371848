import React         from 'react';
import LoginRequired from './projectComponents/LoginRequired/LoginRequired';
import privateRoutes from './routes/privates';
import publicRoutes  from './routes/public';

const routes: IRoute[] = [
	...publicRoutes,

	...privateRoutes.map(route => ({
		...route,
		component: props => React.createElement(LoginRequired, props),
	})),
];

export default routes;
