import lazyWithRetry from 'tools/lazyWithRetry';

const BillableDashboardPage = lazyWithRetry(() => import(
	/* webpackChunkName: "billableDashboardPage" */
	'../../pages/Billable/BillableDashboardPage/BillableDashboardPage'));

export default [
	{
		component: BillableDashboardPage,
		path: '/sub_partitions/:subPartitionId/billables/:billableId',
	}
];