import { ApiConnector } from 'Connectors/ApiConnector';
import ConfigProxy      from 'tools/ConfigProxy';
import AbstractApiModel from './AbstractApiModel';

export const interventionConnector = new ApiConnector({
	baseURL: ConfigProxy.getServiceConfig('intervention', 'api_endpoint'),
});

export default class InterventionApiModel extends AbstractApiModel {
	public static get connector() {
		return interventionConnector;
	}

	public static serviceName: ServiceName = 'intervention';
}
