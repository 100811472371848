import { resolvable }              from '@mathquis/modelx-resolvables';
import SubPartitionModel           from 'Models/partition/SubPartitionModel';
import { urn }                     from 'helpers/ModelDictionary';
import jwtDecode                   from 'jwt-decode';
import { computed }                from 'mobx';
import moment                      from 'moment';
import InterventionPrivateApiModel from '../../abstracts/InterventionPrivateApiModel';

@urn('$', 'intervention', 'intervention_event_token')
export default class InterventionEventTokenModel extends InterventionPrivateApiModel {
	static get path(): string {
		return '/intervention_event_tokens/{?id}';
	}

	@computed
	public get partitionUrn(): string {
		return this.get('partitionUrn', '');
	}

	@computed
	public get jwt(): string {
		return this.get('token', '');
	}

	@computed
	public get data(): Record<string, unknown> {
		return jwtDecode(this.jwt);
	}

	@computed
	public get exp(): number {
		return this.data?.exp as number * 1000 || 0;
	}

	@computed
	public get expMoment(): Moment {
		return moment(this.exp);
	}

	@computed
	public get isExpired(): boolean {
		return (this.exp || 0) < Math.ceil(new Date().valueOf() / 1000);
	}

	public get token(): string {
		return this.get('token', '');
	}

	@resolvable(SubPartitionModel, { attributeName: 'subPartitionUrn', cache: true })
	declare subPartition: SubPartitionModel;
}
