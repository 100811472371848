import { GlobalCache }                  from '@mathquis/modelx-resolvables';
import { Identifier }                   from '@mathquis/modelx-resolvables';
import { AbstractResolvableCollection } from '@mathquis/modelx-resolvables';
import { ICollectionOptions }           from '@mathquis/modelx/lib/types/collection';
import { ModelClass }                   from '@mathquis/modelx/lib/types/collection';
import ConnectorResults                 from '@mathquis/modelx/lib/types/connectorResults';
import { cacheEnabled }                 from 'helpers/ModelCache';
import _get                             from 'lodash/get';
import _groupBy                         from 'lodash/groupBy';
import _orderBy                         from 'lodash/orderBy';
import _uniqBy                          from 'lodash/uniqBy';
import _uniq                            from 'lodash/uniq';
import { when }                         from 'mobx';
import { computed }                     from 'mobx';
import { action }                       from 'mobx';
import { observable }                   from 'mobx';
import { makeObservable }               from 'mobx';
import { whenAsync }                    from 'tools/modelxTools';
import AbstractApiModel                 from '../models/abstracts/AbstractApiModel';

export type CollectionSorts = Record<string, 'asc' | 'desc'>;

export type SortWaySet = boolean | null | 'asc' | 'desc';

export const collections: AbstractApiCollection<AbstractApiModel>[] = [];

export type comparatorFn<T> = (model: T, index?: number) => string | number;
export type comparator<T> = string | comparatorFn<T>;
declare type iteratee<T, V> = (model: T, index?: number) => V;
declare type order = boolean | 'asc' | 'desc';

type OptionRetry<T extends AbstractApiModel> = {
	interval?: number;
	timeout?: number;
	until?: (coll: AbstractApiCollection<T>) => boolean;
}

export abstract class AbstractApiCollection<T extends AbstractApiModel> extends AbstractResolvableCollection<T> {
	protected _filters: Partial<ModelFilters<T>> = {};
	protected _requiredFilters: string[] = [];

	protected _sorts: CollectionSorts = {};

	@observable
	private _hasEverBeenLoading = false;

	@observable
	private _isFailed = false;

	public constructor(model: ModelClass<T>, models?: T[], options?: ICollectionOptions) {
		super(model, models, options);

		makeObservable(this);
	}

	public clear() {
		this.clearFilters().clearSorts();

		return super.clear();
	}

	public clearFilters() {
		this._filters = {};

		return this;
	}

	public clearModels() {
		return super.clear();
	}

	public clearSorts() {
		this._sorts = {};

		return this;
	}

	public get hasEverBeenLoading() {
		if (this.isVirtualCollection) {
			return true;
		}

		return this._hasEverBeenLoading;
	}

	public get isFailed() {
		return this._isFailed;
	}

	public get isEmpty() {
		return this.length === 0 && this.isLoaded;
	}

	public distinctBy<V>(iteratee: iteratee<T,V>): T[] {
		return _uniqBy(this.models, iteratee);
	}

	public distinctDefinedKey(attribute: keyof T) {
		return this.distinctKey(attribute).filter(v => typeof v !== 'undefined');
	}

	public distinctIds(propName: Extract<keyof T, string>) {
		return [...new Set(this.map(m => m.getId(propName)))];
	}

	public distinctIris(propName: Extract<keyof T, string>) {
		return [...new Set(this.map(m => m.getIri(propName)))];
	}

	public distinctKey(attribute: keyof T) {
		return [...new Set(this.pluckKey(attribute))];
	}

	public fillGlobalCache(options?: ApiConnectorOptions<T>) {
		// tslint:disable-next-line:no-string-literal
		if (cacheEnabled && options?.cache || this.model['cacheDuration']) {
			this.forEach(m => GlobalCache.set(m));
		}
	}

	public filterBy<KeyName extends keyof T>(key: KeyName, value: T[KeyName] | T[KeyName][]) {
		return this.filter(model => Array.isArray(value) ? value.includes(model[key]) : model[key] === value);
	}

	public findBy<KeyName extends keyof T>(key: KeyName, value: T[KeyName] | T[KeyName][]) {
		return this.find(model => Array.isArray(value) ? value.includes(model[key]) : model[key] === value);
	}

	public getByIds(ids: id[]) {
		const strIds = ids.filter(id => !!id).map(id => id.toString());

		return this.filter(m => strIds.includes(m.id.toString()));
	}

	public getFilterValue<FilterName extends ModelFilterName<T>>(name: FilterName) {
		return this._filters[name];
	}

	public getFilters() {
		return this._filters;
	}

	public getSorts() {
		return this._sorts;
	}

	public get hasFilters() {
		return !!Object.keys(this._filters).length;
	}

	public groupBy<KeyName extends keyof T>(
		propertyName: KeyName | KeyName[],
		filterIterator?: (model: T) => boolean,
		orderByIterator?: (model: T) => unknown,
	) {
		let models = filterIterator ? this.filter(filterIterator) : this.models;

		if (orderByIterator) {
			models = _orderBy(models, orderByIterator);
		}

		if (Array.isArray(propertyName)) {
			return _groupBy(models, model => propertyName.map(name => model[name]).join());
		}

		return _groupBy(models, model => model[propertyName]);
	}

	public hasFilter(name: ModelFilterName<T>) {
		const f = this.getFilters()[name];
		return (
			typeof f !== 'undefined'
			&& f !== ''
			&& (!Array.isArray(f) || !!f.length)
		);
	}

	public async list(options?: ApiConnectorOptions<T>) {
		if (!this._hasEverBeenLoading) {
			when(() => this.isLoading, () => this.setHasEverBeenLoading(true));
		}

		this.checkRequiredFilters();
		await super.list(options);

		this.fillGlobalCache(options);

		return this;
	}

	public async listBy<FilterName extends ModelFilterName<T>>(
		vals: ModelFilters<T>[FilterName][],
		filterName: FilterName = 'id' as FilterName,
		options: ApiConnectorOptions<T> = {},
		withFilters?: ModelFilters<T>,
	) {
		const values = Array.isArray(vals) ? vals : [vals];

		if (withFilters) {
			console.warn(`ApiCollection - listBy - withFilters param is used => deprecated - ${this.path}`);

			this.setFilters(withFilters);
		}

		let cleanValues = [...new Set(values as never)].filter(v => typeof v !== 'undefined' && v !== '');

		if (filterName === 'id') {
			cleanValues = cleanValues.filter(id => id); // Empêche de filter sur l'id "0"
		}

		this.setFilter(filterName, cleanValues as unknown as ModelFilters<T>[FilterName]);

		if (cleanValues.length) {
			await this.list({ ...options, filterByIdProperty: filterName as string });
		} else {
			this.clearModels();
		}

		return this;
	}

	public async listByFromCollection<M extends AbstractApiModel>(
		collection: AbstractApiCollection<M> | M[],
		attribute: keyof M,
		filterName: ModelFilterName<T> = 'id',
		options: ApiConnectorOptions<T> = {},
		withFilters?: ModelFilters<T>,
	) {
		const values = collection.map(model => _get(model, attribute));

		return this.listBy(values, filterName, options, withFilters);
	}

	// For resolvables
	public async listById(identifiers: Identifier[]): Promise<this> {
		await this.listBy(identifiers, 'id', {
			params: {
				itemsPerPage: identifiers.length,
			},
		});

		// Si un id n'a pas été trouvé dans la requête list
		if (__DEV__) {
			const notFoundIdentifiers = identifiers.filter(id => !this.ids.includes(id));

			if (notFoundIdentifiers.length) {
				// On affiche un message avec la liste des entités et identifiants non trouvés
				const notFoundMessage = `Resolvable - listById - ${this.model.name} not found (ID : ${notFoundIdentifiers.join(', ')})`;
				console.error(notFoundMessage);
			}
		}

		return this;
	}

	public async listRetry(retryOptions?: OptionRetry<T>, options?: ApiConnectorOptions<T>) {
		const retry: OptionRetry<T> = { interval: 2000, timeout: 30000, ...retryOptions };

		const isEnded = async (m: this) => retry.until ? retry.until(m) : false;

		await this.list(options);

		if (await isEnded(this)) {
			return this;
		}

		return new Promise<this>(resolve => {
			const interval = setInterval(async () => {
				if (!this.isLoading) {
					await this.list(options);

					if (await isEnded(this)) {
						clearInterval(interval);
						resolve(this);
					}
				}
			}, retry.interval);

			setTimeout(() => clearInterval(interval), retry.timeout);
		});
	}

	public map<V>(iteratee: iteratee<T, V>): V[] {
		return super.map(iteratee);
	}

	public pluckKey<KeyName extends keyof T>(attribute: KeyName, defaultValue = undefined): T[KeyName][] {
		return this.map((model: unknown) => _get(model, attribute, defaultValue));
	}

	public removeFilter(field: string): this {
		this._filters = Object.keys(this._filters)
			.filter(key => key !== field)
			.reduce((newFilters, key) => {
				newFilters[key] = this._filters[key];
				return newFilters;
			}, {});

		return this;
	}

	public removeFilters(fields: (ModelFilterName<T>)[]): this {
		this._filters = Object.keys(this._filters)
			.filter((key) => !fields.includes(key as ModelFilterName<T>))
			.reduce((newFilters, key) => {
				newFilters[key] = this._filters[key];
				return newFilters;
			}, {});

		return this;
	}

	public replace(model: T): this {
		const found = this.getById(model.id);

		if (found) {
			found.reset(model.attributes);
		} else {
			this.push(model);
		}

		return this;
	}

	public replaceSorts(sorts: CollectionSorts): this {
		this._sorts = sorts;
		return this;
	}

	public setFilter<FilterName extends ModelFilterName<T>>(name: FilterName, value: ModelFilters<T>[FilterName]) {
		this._filters[name] = Array.isArray(value) ? _uniq(value) as never : value;
		return this;
	}

	public setFilters(filters: ModelFilters<T>) {
		this._filters = {};
		Object.keys(filters || {}).forEach(name => this.setFilter(name as never, filters[name]));
		return this;
	}

	@action
	public setHasEverBeenLoading(value: boolean) {
		this._hasEverBeenLoading = value;
	}

	@action
	public setIsFailed(value: boolean) {
		this._isFailed = value;
	}

	@action
	public setIsLoaded(value = true) {
		this.isLoaded = value;
	}

	public setRequiredFilter<FilterName extends ModelFilterName<T>>(name: FilterName, value: ModelFilters<T>[FilterName]) {
		this._requiredFilters.push(name as string);
		return this.setFilter(name, value);
	}

	public setSort(field: ModelSortName<T>, way: SortWaySet = true): this {
		if (field) {
			switch (typeof way) {
				case 'boolean':
					this._sorts[`order[${field as string}]`] = way ? 'asc' : 'desc';
					break;
				case 'object':
					delete this._sorts[`order[${field as string}]`];
					break;
				default:
					this._sorts[`order[${field as string}]`] = way;
			}
		}

		return this;
	}

	@computed
	public get ids(): id[] {
		return this.map(m => m.id);
	}

	@computed
	public get urns(): string[] {
		return this.map(m => m.urn);
	}

	public setSorts(sorts: Record<string, SortWaySet>): this {
		this._sorts = {};

		Object.keys(sorts).forEach(key => this.setSort(key as ModelSortName<T>, sorts[key]));

		return this;
	}

	public sortBy(comparator: comparator<T>, order?: order) {
		return super.sortBy(comparator as never, order);
	}

	public whenIsLoaded = (iter: (m: T) => AbstractApiModel = m => m) => whenAsync(() => {
		return this.isLoaded && this.every(m => iter(m).isLoaded);
	});

	protected checkRequiredFilter(value: unknown) {
		return value !== undefined && value !== null && value !== '';
	}

	protected checkRequiredFilters() {
		for (const filter of this._requiredFilters) {
			if (
				!this.checkRequiredFilter(this._filters[filter])
				|| (
					typeof this._filters[filter] === 'object' 
					&& !Object.values(this._filters[filter] || {}).some(this.checkRequiredFilter)
				)
			) {
				throw new Error(`Filer ${filter.toString()} empty`);
			}
		}
	}

	protected initialize(options: ICollectionOptions) {
		super.initialize(options);

		collections.push(this as never);
	}

	protected onListError(err: Error) {
		this.setIsFailed(true);

		super.onListError(err);
	}

	protected onListSuccess(results: ConnectorResults, options) {
		this.setIsFailed(false);

		return super.onListSuccess(results, options);
	}

	protected prepareListOptions(options: ApiConnectorOptions<T>) {
		const superOptions = { ...options, params: { ...this._filters, ...this._sorts, ...options.params } };

		// Lorsqu'on filtre sur l'id
		if (superOptions.params.id) {
			const ids = Array.isArray(superOptions.params.id) ? superOptions.params.id : [superOptions.params.id];
			const itemsPerPage = superOptions.params.itemsPerPage || -1;

			if (ids.length < itemsPerPage) { // Seulement s'il y a moins d'IDs en paramètre que la pagination en cours
				// On supprime la pagination
				superOptions.params.itemsPerPage = ids.length;
				superOptions.params.page = undefined;
				superOptions.params.partial = true; // Évite une requête COUNT du total sur l'API
			}
		}

		return super.prepareListOptions(superOptions);
	}
}
