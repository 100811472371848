/* eslint-disable @typescript-eslint/no-explicit-any */
const locationTools = {
	// eslint-disable-next-line @typescript-eslint/ban-types
	getUrlParams: (url: string): object => {
		const urlParams = {};
		const parts = url.split('?');
		if (parts.length > 1) {
			const subParts = parts[1].split('&');
			subParts.forEach(subPart => {
				const [key, value] = subPart.split('=');
				if (key !== '' && key !== undefined && value !== '' && value !== undefined) {
					urlParams[key] = value;
				}
			});
		}
		return urlParams;
	},
	getUrlWithoutParams: (url: string): string => {
		const parts = url.split('?');
		return parts[0];
	},
	getValue: (url: string, parameterName: string): string => {
		const urlParams = locationTools.getUrlParams(url);
		return urlParams[parameterName] ? urlParams[parameterName] : undefined;
	},
	removeInterrogationPoint: (url: string): string => {
		if (url.indexOf('?') === (url.length - 1)) {
			return url.slice(0, -1);
		}
		return url;
	},
	removeKey: (url: string, parameterName: string): string => {
		const urlWithoutParams = locationTools.getUrlWithoutParams(url);
		const urlParams = locationTools.getUrlParams(url) as any;
		if (urlParams[parameterName]) {
			delete urlParams[parameterName];
		}

		// const params = new URLSearchParams(urlParams);
		const paramsArray = Object.keys(urlParams).map(urlParamsKey => {
			return `${urlParamsKey}=${urlParams[urlParamsKey]}`;
		});
		const params = paramsArray.join('&');

		return locationTools.removeInterrogationPoint(`${urlWithoutParams}?${params.toString()}`);
	},
	removeKeys: (url: string, parameters: string[]): string => {
		parameters.forEach(parameterName => {
			url = locationTools.removeKey(url, parameterName);
		});
		return url;
	},
	setValue: (url: string, key: string, value: string): string => {
		url = locationTools.removeInterrogationPoint(url);
		const escapedKey = key.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
		const re = new RegExp('([?&])' + escapedKey + '=.*?(&|$)', 'i');
		const separator = url.indexOf('?') !== -1 ? '&' : '?';
		if (url.match(re)) {
			return url.replace(re, '$1' + key + '=' + value + '$2');
		} else {
			return url + separator + key + '=' + value;
		}
	},
	setValues: (url: string, keysAndValues: { [key: string]: string }): string => {
		url = locationTools.removeInterrogationPoint(url);
		Object.keys(keysAndValues).forEach(key => {
			if (typeof key === 'undefined' || typeof keysAndValues[key] === 'undefined' || keysAndValues[key] === '') {
				url = locationTools.removeKey(url, key);
			} else {
				url = locationTools.setValue(url, key, keysAndValues[key]);
			}
		});
		return url;
	},
};

export default locationTools;
