import PartitionModel             from 'Models/partition/PartitionModel';
import SubPartitionStatusModel    from 'Models/partition/SubPartitionStatusModel';
import doc                        from 'decorators/doc';
import model                      from 'decorators/model';
import { Blamable }               from 'helpers/traits';
import { Timestampable }          from 'helpers/traits';
import { ISubPartitionNameProps } from 'modelNames/partition/SubPartitionName';
import PartitionPrivateApiModel   from 'modelx/models/abstracts/PartitionPrivateApiModel';

@model.cacheDuration()
@model.staticLabel('Agence', false)
@model.urnResource('sub_partition')
@doc.path('/sub_partitions/{?id}')
export default class SubPartitionModel extends Blamable(Timestampable(PartitionPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'partition': id;
		'subPartitionStatus': id;
		'subPartitionStatus.reference': PartitionSubPartitionStatusReference;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'name': string;
		'position': string;
	}> = {};

	@doc.string declare name: string;
	@doc.model(PartitionModel) declare partition: PartitionModel;
	@doc.number declare position: number;
	@doc.string declare shortName: string;
	@doc.string declare siret: string;
	@doc.model(SubPartitionStatusModel) declare subPartitionStatus: SubPartitionStatusModel;
	@doc.string declare timezone: string;

	public get title() {
		return this.shortName || this.name;
	}

	public renderName(props?: Omit<ISubPartitionNameProps, 'model'>) {
		return super.renderName(props);
	}
}