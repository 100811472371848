/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from "protobufjs/minimal";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

export const Service = $root.Service = (() => {

    /**
     * Namespace Service.
     * @exports Service
     * @namespace
     */
    const Service = {};

    Service.Intervention = (function() {

        /**
         * Namespace Intervention.
         * @memberof Service
         * @namespace
         */
        const Intervention = {};

        Intervention.PlanningInterventionCard = (function() {

            /**
             * Properties of a PlanningInterventionCard.
             * @memberof Service.Intervention
             * @interface IPlanningInterventionCard
             * @property {Service.Intervention.PlanningInterventionCard.IIntervention|null} [intervention] PlanningInterventionCard intervention
             * @property {Array.<Service.Intervention.PlanningInterventionCard.ITaskOperation>|null} [taskOperations] PlanningInterventionCard taskOperations
             * @property {Array.<Service.Intervention.PlanningInterventionCard.IInterventionResource>|null} [interventionResources] PlanningInterventionCard interventionResources
             */

            /**
             * Constructs a new PlanningInterventionCard.
             * @memberof Service.Intervention
             * @classdesc Represents a PlanningInterventionCard.
             * @implements IPlanningInterventionCard
             * @constructor
             * @param {Service.Intervention.IPlanningInterventionCard=} [properties] Properties to set
             */
            function PlanningInterventionCard(properties) {
                this.taskOperations = [];
                this.interventionResources = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * PlanningInterventionCard intervention.
             * @member {Service.Intervention.PlanningInterventionCard.IIntervention|null|undefined} intervention
             * @memberof Service.Intervention.PlanningInterventionCard
             * @instance
             */
            PlanningInterventionCard.prototype.intervention = null;

            /**
             * PlanningInterventionCard taskOperations.
             * @member {Array.<Service.Intervention.PlanningInterventionCard.ITaskOperation>} taskOperations
             * @memberof Service.Intervention.PlanningInterventionCard
             * @instance
             */
            PlanningInterventionCard.prototype.taskOperations = $util.emptyArray;

            /**
             * PlanningInterventionCard interventionResources.
             * @member {Array.<Service.Intervention.PlanningInterventionCard.IInterventionResource>} interventionResources
             * @memberof Service.Intervention.PlanningInterventionCard
             * @instance
             */
            PlanningInterventionCard.prototype.interventionResources = $util.emptyArray;

            /**
             * Creates a new PlanningInterventionCard instance using the specified properties.
             * @function create
             * @memberof Service.Intervention.PlanningInterventionCard
             * @static
             * @param {Service.Intervention.IPlanningInterventionCard=} [properties] Properties to set
             * @returns {Service.Intervention.PlanningInterventionCard} PlanningInterventionCard instance
             */
            PlanningInterventionCard.create = function create(properties) {
                return new PlanningInterventionCard(properties);
            };

            /**
             * Encodes the specified PlanningInterventionCard message. Does not implicitly {@link Service.Intervention.PlanningInterventionCard.verify|verify} messages.
             * @function encode
             * @memberof Service.Intervention.PlanningInterventionCard
             * @static
             * @param {Service.Intervention.IPlanningInterventionCard} message PlanningInterventionCard message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PlanningInterventionCard.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.intervention != null && Object.hasOwnProperty.call(message, "intervention"))
                    $root.Service.Intervention.PlanningInterventionCard.Intervention.encode(message.intervention, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.taskOperations != null && message.taskOperations.length)
                    for (let i = 0; i < message.taskOperations.length; ++i)
                        $root.Service.Intervention.PlanningInterventionCard.TaskOperation.encode(message.taskOperations[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.interventionResources != null && message.interventionResources.length)
                    for (let i = 0; i < message.interventionResources.length; ++i)
                        $root.Service.Intervention.PlanningInterventionCard.InterventionResource.encode(message.interventionResources[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified PlanningInterventionCard message, length delimited. Does not implicitly {@link Service.Intervention.PlanningInterventionCard.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Service.Intervention.PlanningInterventionCard
             * @static
             * @param {Service.Intervention.IPlanningInterventionCard} message PlanningInterventionCard message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PlanningInterventionCard.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a PlanningInterventionCard message from the specified reader or buffer.
             * @function decode
             * @memberof Service.Intervention.PlanningInterventionCard
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Service.Intervention.PlanningInterventionCard} PlanningInterventionCard
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PlanningInterventionCard.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Service.Intervention.PlanningInterventionCard();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.intervention = $root.Service.Intervention.PlanningInterventionCard.Intervention.decode(reader, reader.uint32());
                        break;
                    case 2:
                        if (!(message.taskOperations && message.taskOperations.length))
                            message.taskOperations = [];
                        message.taskOperations.push($root.Service.Intervention.PlanningInterventionCard.TaskOperation.decode(reader, reader.uint32()));
                        break;
                    case 3:
                        if (!(message.interventionResources && message.interventionResources.length))
                            message.interventionResources = [];
                        message.interventionResources.push($root.Service.Intervention.PlanningInterventionCard.InterventionResource.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a PlanningInterventionCard message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Service.Intervention.PlanningInterventionCard
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Service.Intervention.PlanningInterventionCard} PlanningInterventionCard
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PlanningInterventionCard.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a PlanningInterventionCard message.
             * @function verify
             * @memberof Service.Intervention.PlanningInterventionCard
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            PlanningInterventionCard.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.intervention != null && message.hasOwnProperty("intervention")) {
                    let error = $root.Service.Intervention.PlanningInterventionCard.Intervention.verify(message.intervention);
                    if (error)
                        return "intervention." + error;
                }
                if (message.taskOperations != null && message.hasOwnProperty("taskOperations")) {
                    if (!Array.isArray(message.taskOperations))
                        return "taskOperations: array expected";
                    for (let i = 0; i < message.taskOperations.length; ++i) {
                        let error = $root.Service.Intervention.PlanningInterventionCard.TaskOperation.verify(message.taskOperations[i]);
                        if (error)
                            return "taskOperations." + error;
                    }
                }
                if (message.interventionResources != null && message.hasOwnProperty("interventionResources")) {
                    if (!Array.isArray(message.interventionResources))
                        return "interventionResources: array expected";
                    for (let i = 0; i < message.interventionResources.length; ++i) {
                        let error = $root.Service.Intervention.PlanningInterventionCard.InterventionResource.verify(message.interventionResources[i]);
                        if (error)
                            return "interventionResources." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a PlanningInterventionCard message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Service.Intervention.PlanningInterventionCard
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Service.Intervention.PlanningInterventionCard} PlanningInterventionCard
             */
            PlanningInterventionCard.fromObject = function fromObject(object) {
                if (object instanceof $root.Service.Intervention.PlanningInterventionCard)
                    return object;
                let message = new $root.Service.Intervention.PlanningInterventionCard();
                if (object.intervention != null) {
                    if (typeof object.intervention !== "object")
                        throw TypeError(".Service.Intervention.PlanningInterventionCard.intervention: object expected");
                    message.intervention = $root.Service.Intervention.PlanningInterventionCard.Intervention.fromObject(object.intervention);
                }
                if (object.taskOperations) {
                    if (!Array.isArray(object.taskOperations))
                        throw TypeError(".Service.Intervention.PlanningInterventionCard.taskOperations: array expected");
                    message.taskOperations = [];
                    for (let i = 0; i < object.taskOperations.length; ++i) {
                        if (typeof object.taskOperations[i] !== "object")
                            throw TypeError(".Service.Intervention.PlanningInterventionCard.taskOperations: object expected");
                        message.taskOperations[i] = $root.Service.Intervention.PlanningInterventionCard.TaskOperation.fromObject(object.taskOperations[i]);
                    }
                }
                if (object.interventionResources) {
                    if (!Array.isArray(object.interventionResources))
                        throw TypeError(".Service.Intervention.PlanningInterventionCard.interventionResources: array expected");
                    message.interventionResources = [];
                    for (let i = 0; i < object.interventionResources.length; ++i) {
                        if (typeof object.interventionResources[i] !== "object")
                            throw TypeError(".Service.Intervention.PlanningInterventionCard.interventionResources: object expected");
                        message.interventionResources[i] = $root.Service.Intervention.PlanningInterventionCard.InterventionResource.fromObject(object.interventionResources[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a PlanningInterventionCard message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Service.Intervention.PlanningInterventionCard
             * @static
             * @param {Service.Intervention.PlanningInterventionCard} message PlanningInterventionCard
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            PlanningInterventionCard.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults) {
                    object.taskOperations = [];
                    object.interventionResources = [];
                }
                if (options.defaults)
                    object.intervention = null;
                if (message.intervention != null && message.hasOwnProperty("intervention"))
                    object.intervention = $root.Service.Intervention.PlanningInterventionCard.Intervention.toObject(message.intervention, options);
                if (message.taskOperations && message.taskOperations.length) {
                    object.taskOperations = [];
                    for (let j = 0; j < message.taskOperations.length; ++j)
                        object.taskOperations[j] = $root.Service.Intervention.PlanningInterventionCard.TaskOperation.toObject(message.taskOperations[j], options);
                }
                if (message.interventionResources && message.interventionResources.length) {
                    object.interventionResources = [];
                    for (let j = 0; j < message.interventionResources.length; ++j)
                        object.interventionResources[j] = $root.Service.Intervention.PlanningInterventionCard.InterventionResource.toObject(message.interventionResources[j], options);
                }
                return object;
            };

            /**
             * Converts this PlanningInterventionCard to JSON.
             * @function toJSON
             * @memberof Service.Intervention.PlanningInterventionCard
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            PlanningInterventionCard.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            PlanningInterventionCard.Intervention = (function() {

                /**
                 * Properties of an Intervention.
                 * @memberof Service.Intervention.PlanningInterventionCard
                 * @interface IIntervention
                 * @property {string|null} [urn] Intervention urn
                 * @property {google.protobuf.ITimestamp|null} [updatedAt] Intervention updatedAt
                 * @property {google.protobuf.ITimestamp|null} [scheduleStartDate] Intervention scheduleStartDate
                 * @property {google.protobuf.ITimestamp|null} [scheduleEndDate] Intervention scheduleEndDate
                 * @property {Service.Intervention.PlanningInterventionCard.Intervention.IInterventionStatus|null} [interventionStatus] Intervention interventionStatus
                 * @property {number|null} [number] Intervention number
                 * @property {number|null} [value] Intervention value
                 * @property {number|null} [anomalyLevel] Intervention anomalyLevel
                 * @property {number|null} [anomalyCount] Intervention anomalyCount
                 * @property {string|null} [allInterventionOperationsStatus] Intervention allInterventionOperationsStatus
                 */

                /**
                 * Constructs a new Intervention.
                 * @memberof Service.Intervention.PlanningInterventionCard
                 * @classdesc Represents an Intervention.
                 * @implements IIntervention
                 * @constructor
                 * @param {Service.Intervention.PlanningInterventionCard.IIntervention=} [properties] Properties to set
                 */
                function Intervention(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Intervention urn.
                 * @member {string} urn
                 * @memberof Service.Intervention.PlanningInterventionCard.Intervention
                 * @instance
                 */
                Intervention.prototype.urn = "";

                /**
                 * Intervention updatedAt.
                 * @member {google.protobuf.ITimestamp|null|undefined} updatedAt
                 * @memberof Service.Intervention.PlanningInterventionCard.Intervention
                 * @instance
                 */
                Intervention.prototype.updatedAt = null;

                /**
                 * Intervention scheduleStartDate.
                 * @member {google.protobuf.ITimestamp|null|undefined} scheduleStartDate
                 * @memberof Service.Intervention.PlanningInterventionCard.Intervention
                 * @instance
                 */
                Intervention.prototype.scheduleStartDate = null;

                /**
                 * Intervention scheduleEndDate.
                 * @member {google.protobuf.ITimestamp|null|undefined} scheduleEndDate
                 * @memberof Service.Intervention.PlanningInterventionCard.Intervention
                 * @instance
                 */
                Intervention.prototype.scheduleEndDate = null;

                /**
                 * Intervention interventionStatus.
                 * @member {Service.Intervention.PlanningInterventionCard.Intervention.IInterventionStatus|null|undefined} interventionStatus
                 * @memberof Service.Intervention.PlanningInterventionCard.Intervention
                 * @instance
                 */
                Intervention.prototype.interventionStatus = null;

                /**
                 * Intervention number.
                 * @member {number} number
                 * @memberof Service.Intervention.PlanningInterventionCard.Intervention
                 * @instance
                 */
                Intervention.prototype.number = 0;

                /**
                 * Intervention value.
                 * @member {number} value
                 * @memberof Service.Intervention.PlanningInterventionCard.Intervention
                 * @instance
                 */
                Intervention.prototype.value = 0;

                /**
                 * Intervention anomalyLevel.
                 * @member {number} anomalyLevel
                 * @memberof Service.Intervention.PlanningInterventionCard.Intervention
                 * @instance
                 */
                Intervention.prototype.anomalyLevel = 0;

                /**
                 * Intervention anomalyCount.
                 * @member {number} anomalyCount
                 * @memberof Service.Intervention.PlanningInterventionCard.Intervention
                 * @instance
                 */
                Intervention.prototype.anomalyCount = 0;

                /**
                 * Intervention allInterventionOperationsStatus.
                 * @member {string} allInterventionOperationsStatus
                 * @memberof Service.Intervention.PlanningInterventionCard.Intervention
                 * @instance
                 */
                Intervention.prototype.allInterventionOperationsStatus = "";

                /**
                 * Creates a new Intervention instance using the specified properties.
                 * @function create
                 * @memberof Service.Intervention.PlanningInterventionCard.Intervention
                 * @static
                 * @param {Service.Intervention.PlanningInterventionCard.IIntervention=} [properties] Properties to set
                 * @returns {Service.Intervention.PlanningInterventionCard.Intervention} Intervention instance
                 */
                Intervention.create = function create(properties) {
                    return new Intervention(properties);
                };

                /**
                 * Encodes the specified Intervention message. Does not implicitly {@link Service.Intervention.PlanningInterventionCard.Intervention.verify|verify} messages.
                 * @function encode
                 * @memberof Service.Intervention.PlanningInterventionCard.Intervention
                 * @static
                 * @param {Service.Intervention.PlanningInterventionCard.IIntervention} message Intervention message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Intervention.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.urn != null && Object.hasOwnProperty.call(message, "urn"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.urn);
                    if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                        $root.google.protobuf.Timestamp.encode(message.updatedAt, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.scheduleStartDate != null && Object.hasOwnProperty.call(message, "scheduleStartDate"))
                        $root.google.protobuf.Timestamp.encode(message.scheduleStartDate, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.scheduleEndDate != null && Object.hasOwnProperty.call(message, "scheduleEndDate"))
                        $root.google.protobuf.Timestamp.encode(message.scheduleEndDate, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    if (message.interventionStatus != null && Object.hasOwnProperty.call(message, "interventionStatus"))
                        $root.Service.Intervention.PlanningInterventionCard.Intervention.InterventionStatus.encode(message.interventionStatus, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    if (message.number != null && Object.hasOwnProperty.call(message, "number"))
                        writer.uint32(/* id 6, wireType 0 =*/48).int32(message.number);
                    if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                        writer.uint32(/* id 7, wireType 0 =*/56).int32(message.value);
                    if (message.anomalyLevel != null && Object.hasOwnProperty.call(message, "anomalyLevel"))
                        writer.uint32(/* id 8, wireType 0 =*/64).int32(message.anomalyLevel);
                    if (message.anomalyCount != null && Object.hasOwnProperty.call(message, "anomalyCount"))
                        writer.uint32(/* id 9, wireType 0 =*/72).int32(message.anomalyCount);
                    if (message.allInterventionOperationsStatus != null && Object.hasOwnProperty.call(message, "allInterventionOperationsStatus"))
                        writer.uint32(/* id 10, wireType 2 =*/82).string(message.allInterventionOperationsStatus);
                    return writer;
                };

                /**
                 * Encodes the specified Intervention message, length delimited. Does not implicitly {@link Service.Intervention.PlanningInterventionCard.Intervention.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof Service.Intervention.PlanningInterventionCard.Intervention
                 * @static
                 * @param {Service.Intervention.PlanningInterventionCard.IIntervention} message Intervention message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Intervention.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an Intervention message from the specified reader or buffer.
                 * @function decode
                 * @memberof Service.Intervention.PlanningInterventionCard.Intervention
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {Service.Intervention.PlanningInterventionCard.Intervention} Intervention
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Intervention.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Service.Intervention.PlanningInterventionCard.Intervention();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.urn = reader.string();
                            break;
                        case 2:
                            message.updatedAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        case 3:
                            message.scheduleStartDate = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        case 4:
                            message.scheduleEndDate = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        case 5:
                            message.interventionStatus = $root.Service.Intervention.PlanningInterventionCard.Intervention.InterventionStatus.decode(reader, reader.uint32());
                            break;
                        case 6:
                            message.number = reader.int32();
                            break;
                        case 7:
                            message.value = reader.int32();
                            break;
                        case 8:
                            message.anomalyLevel = reader.int32();
                            break;
                        case 9:
                            message.anomalyCount = reader.int32();
                            break;
                        case 10:
                            message.allInterventionOperationsStatus = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an Intervention message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof Service.Intervention.PlanningInterventionCard.Intervention
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {Service.Intervention.PlanningInterventionCard.Intervention} Intervention
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Intervention.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an Intervention message.
                 * @function verify
                 * @memberof Service.Intervention.PlanningInterventionCard.Intervention
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Intervention.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.urn != null && message.hasOwnProperty("urn"))
                        if (!$util.isString(message.urn))
                            return "urn: string expected";
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt")) {
                        let error = $root.google.protobuf.Timestamp.verify(message.updatedAt);
                        if (error)
                            return "updatedAt." + error;
                    }
                    if (message.scheduleStartDate != null && message.hasOwnProperty("scheduleStartDate")) {
                        let error = $root.google.protobuf.Timestamp.verify(message.scheduleStartDate);
                        if (error)
                            return "scheduleStartDate." + error;
                    }
                    if (message.scheduleEndDate != null && message.hasOwnProperty("scheduleEndDate")) {
                        let error = $root.google.protobuf.Timestamp.verify(message.scheduleEndDate);
                        if (error)
                            return "scheduleEndDate." + error;
                    }
                    if (message.interventionStatus != null && message.hasOwnProperty("interventionStatus")) {
                        let error = $root.Service.Intervention.PlanningInterventionCard.Intervention.InterventionStatus.verify(message.interventionStatus);
                        if (error)
                            return "interventionStatus." + error;
                    }
                    if (message.number != null && message.hasOwnProperty("number"))
                        if (!$util.isInteger(message.number))
                            return "number: integer expected";
                    if (message.value != null && message.hasOwnProperty("value"))
                        if (!$util.isInteger(message.value))
                            return "value: integer expected";
                    if (message.anomalyLevel != null && message.hasOwnProperty("anomalyLevel"))
                        if (!$util.isInteger(message.anomalyLevel))
                            return "anomalyLevel: integer expected";
                    if (message.anomalyCount != null && message.hasOwnProperty("anomalyCount"))
                        if (!$util.isInteger(message.anomalyCount))
                            return "anomalyCount: integer expected";
                    if (message.allInterventionOperationsStatus != null && message.hasOwnProperty("allInterventionOperationsStatus"))
                        if (!$util.isString(message.allInterventionOperationsStatus))
                            return "allInterventionOperationsStatus: string expected";
                    return null;
                };

                /**
                 * Creates an Intervention message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof Service.Intervention.PlanningInterventionCard.Intervention
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {Service.Intervention.PlanningInterventionCard.Intervention} Intervention
                 */
                Intervention.fromObject = function fromObject(object) {
                    if (object instanceof $root.Service.Intervention.PlanningInterventionCard.Intervention)
                        return object;
                    let message = new $root.Service.Intervention.PlanningInterventionCard.Intervention();
                    if (object.urn != null)
                        message.urn = String(object.urn);
                    if (object.updatedAt != null) {
                        if (typeof object.updatedAt !== "object")
                            throw TypeError(".Service.Intervention.PlanningInterventionCard.Intervention.updatedAt: object expected");
                        message.updatedAt = $root.google.protobuf.Timestamp.fromObject(object.updatedAt);
                    }
                    if (object.scheduleStartDate != null) {
                        if (typeof object.scheduleStartDate !== "object")
                            throw TypeError(".Service.Intervention.PlanningInterventionCard.Intervention.scheduleStartDate: object expected");
                        message.scheduleStartDate = $root.google.protobuf.Timestamp.fromObject(object.scheduleStartDate);
                    }
                    if (object.scheduleEndDate != null) {
                        if (typeof object.scheduleEndDate !== "object")
                            throw TypeError(".Service.Intervention.PlanningInterventionCard.Intervention.scheduleEndDate: object expected");
                        message.scheduleEndDate = $root.google.protobuf.Timestamp.fromObject(object.scheduleEndDate);
                    }
                    if (object.interventionStatus != null) {
                        if (typeof object.interventionStatus !== "object")
                            throw TypeError(".Service.Intervention.PlanningInterventionCard.Intervention.interventionStatus: object expected");
                        message.interventionStatus = $root.Service.Intervention.PlanningInterventionCard.Intervention.InterventionStatus.fromObject(object.interventionStatus);
                    }
                    if (object.number != null)
                        message.number = object.number | 0;
                    if (object.value != null)
                        message.value = object.value | 0;
                    if (object.anomalyLevel != null)
                        message.anomalyLevel = object.anomalyLevel | 0;
                    if (object.anomalyCount != null)
                        message.anomalyCount = object.anomalyCount | 0;
                    if (object.allInterventionOperationsStatus != null)
                        message.allInterventionOperationsStatus = String(object.allInterventionOperationsStatus);
                    return message;
                };

                /**
                 * Creates a plain object from an Intervention message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof Service.Intervention.PlanningInterventionCard.Intervention
                 * @static
                 * @param {Service.Intervention.PlanningInterventionCard.Intervention} message Intervention
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Intervention.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.urn = "";
                        object.updatedAt = null;
                        object.scheduleStartDate = null;
                        object.scheduleEndDate = null;
                        object.interventionStatus = null;
                        object.number = 0;
                        object.value = 0;
                        object.anomalyLevel = 0;
                        object.anomalyCount = 0;
                        object.allInterventionOperationsStatus = "";
                    }
                    if (message.urn != null && message.hasOwnProperty("urn"))
                        object.urn = message.urn;
                    if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
                        object.updatedAt = $root.google.protobuf.Timestamp.toObject(message.updatedAt, options);
                    if (message.scheduleStartDate != null && message.hasOwnProperty("scheduleStartDate"))
                        object.scheduleStartDate = $root.google.protobuf.Timestamp.toObject(message.scheduleStartDate, options);
                    if (message.scheduleEndDate != null && message.hasOwnProperty("scheduleEndDate"))
                        object.scheduleEndDate = $root.google.protobuf.Timestamp.toObject(message.scheduleEndDate, options);
                    if (message.interventionStatus != null && message.hasOwnProperty("interventionStatus"))
                        object.interventionStatus = $root.Service.Intervention.PlanningInterventionCard.Intervention.InterventionStatus.toObject(message.interventionStatus, options);
                    if (message.number != null && message.hasOwnProperty("number"))
                        object.number = message.number;
                    if (message.value != null && message.hasOwnProperty("value"))
                        object.value = message.value;
                    if (message.anomalyLevel != null && message.hasOwnProperty("anomalyLevel"))
                        object.anomalyLevel = message.anomalyLevel;
                    if (message.anomalyCount != null && message.hasOwnProperty("anomalyCount"))
                        object.anomalyCount = message.anomalyCount;
                    if (message.allInterventionOperationsStatus != null && message.hasOwnProperty("allInterventionOperationsStatus"))
                        object.allInterventionOperationsStatus = message.allInterventionOperationsStatus;
                    return object;
                };

                /**
                 * Converts this Intervention to JSON.
                 * @function toJSON
                 * @memberof Service.Intervention.PlanningInterventionCard.Intervention
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Intervention.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                Intervention.InterventionStatus = (function() {

                    /**
                     * Properties of an InterventionStatus.
                     * @memberof Service.Intervention.PlanningInterventionCard.Intervention
                     * @interface IInterventionStatus
                     * @property {string|null} [reference] InterventionStatus reference
                     */

                    /**
                     * Constructs a new InterventionStatus.
                     * @memberof Service.Intervention.PlanningInterventionCard.Intervention
                     * @classdesc Represents an InterventionStatus.
                     * @implements IInterventionStatus
                     * @constructor
                     * @param {Service.Intervention.PlanningInterventionCard.Intervention.IInterventionStatus=} [properties] Properties to set
                     */
                    function InterventionStatus(properties) {
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * InterventionStatus reference.
                     * @member {string} reference
                     * @memberof Service.Intervention.PlanningInterventionCard.Intervention.InterventionStatus
                     * @instance
                     */
                    InterventionStatus.prototype.reference = "";

                    /**
                     * Creates a new InterventionStatus instance using the specified properties.
                     * @function create
                     * @memberof Service.Intervention.PlanningInterventionCard.Intervention.InterventionStatus
                     * @static
                     * @param {Service.Intervention.PlanningInterventionCard.Intervention.IInterventionStatus=} [properties] Properties to set
                     * @returns {Service.Intervention.PlanningInterventionCard.Intervention.InterventionStatus} InterventionStatus instance
                     */
                    InterventionStatus.create = function create(properties) {
                        return new InterventionStatus(properties);
                    };

                    /**
                     * Encodes the specified InterventionStatus message. Does not implicitly {@link Service.Intervention.PlanningInterventionCard.Intervention.InterventionStatus.verify|verify} messages.
                     * @function encode
                     * @memberof Service.Intervention.PlanningInterventionCard.Intervention.InterventionStatus
                     * @static
                     * @param {Service.Intervention.PlanningInterventionCard.Intervention.IInterventionStatus} message InterventionStatus message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    InterventionStatus.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.reference != null && Object.hasOwnProperty.call(message, "reference"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.reference);
                        return writer;
                    };

                    /**
                     * Encodes the specified InterventionStatus message, length delimited. Does not implicitly {@link Service.Intervention.PlanningInterventionCard.Intervention.InterventionStatus.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof Service.Intervention.PlanningInterventionCard.Intervention.InterventionStatus
                     * @static
                     * @param {Service.Intervention.PlanningInterventionCard.Intervention.IInterventionStatus} message InterventionStatus message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    InterventionStatus.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an InterventionStatus message from the specified reader or buffer.
                     * @function decode
                     * @memberof Service.Intervention.PlanningInterventionCard.Intervention.InterventionStatus
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {Service.Intervention.PlanningInterventionCard.Intervention.InterventionStatus} InterventionStatus
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    InterventionStatus.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Service.Intervention.PlanningInterventionCard.Intervention.InterventionStatus();
                        while (reader.pos < end) {
                            let tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.reference = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an InterventionStatus message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof Service.Intervention.PlanningInterventionCard.Intervention.InterventionStatus
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {Service.Intervention.PlanningInterventionCard.Intervention.InterventionStatus} InterventionStatus
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    InterventionStatus.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an InterventionStatus message.
                     * @function verify
                     * @memberof Service.Intervention.PlanningInterventionCard.Intervention.InterventionStatus
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    InterventionStatus.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.reference != null && message.hasOwnProperty("reference"))
                            if (!$util.isString(message.reference))
                                return "reference: string expected";
                        return null;
                    };

                    /**
                     * Creates an InterventionStatus message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof Service.Intervention.PlanningInterventionCard.Intervention.InterventionStatus
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {Service.Intervention.PlanningInterventionCard.Intervention.InterventionStatus} InterventionStatus
                     */
                    InterventionStatus.fromObject = function fromObject(object) {
                        if (object instanceof $root.Service.Intervention.PlanningInterventionCard.Intervention.InterventionStatus)
                            return object;
                        let message = new $root.Service.Intervention.PlanningInterventionCard.Intervention.InterventionStatus();
                        if (object.reference != null)
                            message.reference = String(object.reference);
                        return message;
                    };

                    /**
                     * Creates a plain object from an InterventionStatus message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof Service.Intervention.PlanningInterventionCard.Intervention.InterventionStatus
                     * @static
                     * @param {Service.Intervention.PlanningInterventionCard.Intervention.InterventionStatus} message InterventionStatus
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    InterventionStatus.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        let object = {};
                        if (options.defaults)
                            object.reference = "";
                        if (message.reference != null && message.hasOwnProperty("reference"))
                            object.reference = message.reference;
                        return object;
                    };

                    /**
                     * Converts this InterventionStatus to JSON.
                     * @function toJSON
                     * @memberof Service.Intervention.PlanningInterventionCard.Intervention.InterventionStatus
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    InterventionStatus.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return InterventionStatus;
                })();

                return Intervention;
            })();

            PlanningInterventionCard.TaskOperation = (function() {

                /**
                 * Properties of a TaskOperation.
                 * @memberof Service.Intervention.PlanningInterventionCard
                 * @interface ITaskOperation
                 * @property {Service.Intervention.PlanningInterventionCard.TaskOperation.IOperation|null} [operation] TaskOperation operation
                 * @property {Service.Intervention.PlanningInterventionCard.TaskOperation.ITaskZone|null} [taskZone] TaskOperation taskZone
                 * @property {Service.Intervention.PlanningInterventionCard.TaskOperation.ITask|null} [task] TaskOperation task
                 * @property {number|null} [id] TaskOperation id
                 * @property {Service.Intervention.PlanningInterventionCard.TaskOperation.IRiskAssessment|null} [riskAssessment] TaskOperation riskAssessment
                 * @property {string|null} [label] TaskOperation label
                 * @property {Array.<Service.Intervention.PlanningInterventionCard.TaskOperation.ITaskOperationExtra>|null} [mainTaskOperationExtras] TaskOperation mainTaskOperationExtras
                 */

                /**
                 * Constructs a new TaskOperation.
                 * @memberof Service.Intervention.PlanningInterventionCard
                 * @classdesc Represents a TaskOperation.
                 * @implements ITaskOperation
                 * @constructor
                 * @param {Service.Intervention.PlanningInterventionCard.ITaskOperation=} [properties] Properties to set
                 */
                function TaskOperation(properties) {
                    this.mainTaskOperationExtras = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * TaskOperation operation.
                 * @member {Service.Intervention.PlanningInterventionCard.TaskOperation.IOperation|null|undefined} operation
                 * @memberof Service.Intervention.PlanningInterventionCard.TaskOperation
                 * @instance
                 */
                TaskOperation.prototype.operation = null;

                /**
                 * TaskOperation taskZone.
                 * @member {Service.Intervention.PlanningInterventionCard.TaskOperation.ITaskZone|null|undefined} taskZone
                 * @memberof Service.Intervention.PlanningInterventionCard.TaskOperation
                 * @instance
                 */
                TaskOperation.prototype.taskZone = null;

                /**
                 * TaskOperation task.
                 * @member {Service.Intervention.PlanningInterventionCard.TaskOperation.ITask|null|undefined} task
                 * @memberof Service.Intervention.PlanningInterventionCard.TaskOperation
                 * @instance
                 */
                TaskOperation.prototype.task = null;

                /**
                 * TaskOperation id.
                 * @member {number} id
                 * @memberof Service.Intervention.PlanningInterventionCard.TaskOperation
                 * @instance
                 */
                TaskOperation.prototype.id = 0;

                /**
                 * TaskOperation riskAssessment.
                 * @member {Service.Intervention.PlanningInterventionCard.TaskOperation.IRiskAssessment|null|undefined} riskAssessment
                 * @memberof Service.Intervention.PlanningInterventionCard.TaskOperation
                 * @instance
                 */
                TaskOperation.prototype.riskAssessment = null;

                /**
                 * TaskOperation label.
                 * @member {string} label
                 * @memberof Service.Intervention.PlanningInterventionCard.TaskOperation
                 * @instance
                 */
                TaskOperation.prototype.label = "";

                /**
                 * TaskOperation mainTaskOperationExtras.
                 * @member {Array.<Service.Intervention.PlanningInterventionCard.TaskOperation.ITaskOperationExtra>} mainTaskOperationExtras
                 * @memberof Service.Intervention.PlanningInterventionCard.TaskOperation
                 * @instance
                 */
                TaskOperation.prototype.mainTaskOperationExtras = $util.emptyArray;

                /**
                 * Creates a new TaskOperation instance using the specified properties.
                 * @function create
                 * @memberof Service.Intervention.PlanningInterventionCard.TaskOperation
                 * @static
                 * @param {Service.Intervention.PlanningInterventionCard.ITaskOperation=} [properties] Properties to set
                 * @returns {Service.Intervention.PlanningInterventionCard.TaskOperation} TaskOperation instance
                 */
                TaskOperation.create = function create(properties) {
                    return new TaskOperation(properties);
                };

                /**
                 * Encodes the specified TaskOperation message. Does not implicitly {@link Service.Intervention.PlanningInterventionCard.TaskOperation.verify|verify} messages.
                 * @function encode
                 * @memberof Service.Intervention.PlanningInterventionCard.TaskOperation
                 * @static
                 * @param {Service.Intervention.PlanningInterventionCard.ITaskOperation} message TaskOperation message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TaskOperation.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operation != null && Object.hasOwnProperty.call(message, "operation"))
                        $root.Service.Intervention.PlanningInterventionCard.TaskOperation.Operation.encode(message.operation, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.taskZone != null && Object.hasOwnProperty.call(message, "taskZone"))
                        $root.Service.Intervention.PlanningInterventionCard.TaskOperation.TaskZone.encode(message.taskZone, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.task != null && Object.hasOwnProperty.call(message, "task"))
                        $root.Service.Intervention.PlanningInterventionCard.TaskOperation.Task.encode(message.task, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                        writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.id);
                    if (message.riskAssessment != null && Object.hasOwnProperty.call(message, "riskAssessment"))
                        $root.Service.Intervention.PlanningInterventionCard.TaskOperation.RiskAssessment.encode(message.riskAssessment, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    if (message.label != null && Object.hasOwnProperty.call(message, "label"))
                        writer.uint32(/* id 6, wireType 2 =*/50).string(message.label);
                    if (message.mainTaskOperationExtras != null && message.mainTaskOperationExtras.length)
                        for (let i = 0; i < message.mainTaskOperationExtras.length; ++i)
                            $root.Service.Intervention.PlanningInterventionCard.TaskOperation.TaskOperationExtra.encode(message.mainTaskOperationExtras[i], writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified TaskOperation message, length delimited. Does not implicitly {@link Service.Intervention.PlanningInterventionCard.TaskOperation.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof Service.Intervention.PlanningInterventionCard.TaskOperation
                 * @static
                 * @param {Service.Intervention.PlanningInterventionCard.ITaskOperation} message TaskOperation message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TaskOperation.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a TaskOperation message from the specified reader or buffer.
                 * @function decode
                 * @memberof Service.Intervention.PlanningInterventionCard.TaskOperation
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {Service.Intervention.PlanningInterventionCard.TaskOperation} TaskOperation
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TaskOperation.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Service.Intervention.PlanningInterventionCard.TaskOperation();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.operation = $root.Service.Intervention.PlanningInterventionCard.TaskOperation.Operation.decode(reader, reader.uint32());
                            break;
                        case 2:
                            message.taskZone = $root.Service.Intervention.PlanningInterventionCard.TaskOperation.TaskZone.decode(reader, reader.uint32());
                            break;
                        case 3:
                            message.task = $root.Service.Intervention.PlanningInterventionCard.TaskOperation.Task.decode(reader, reader.uint32());
                            break;
                        case 4:
                            message.id = reader.uint32();
                            break;
                        case 5:
                            message.riskAssessment = $root.Service.Intervention.PlanningInterventionCard.TaskOperation.RiskAssessment.decode(reader, reader.uint32());
                            break;
                        case 6:
                            message.label = reader.string();
                            break;
                        case 7:
                            if (!(message.mainTaskOperationExtras && message.mainTaskOperationExtras.length))
                                message.mainTaskOperationExtras = [];
                            message.mainTaskOperationExtras.push($root.Service.Intervention.PlanningInterventionCard.TaskOperation.TaskOperationExtra.decode(reader, reader.uint32()));
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a TaskOperation message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof Service.Intervention.PlanningInterventionCard.TaskOperation
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {Service.Intervention.PlanningInterventionCard.TaskOperation} TaskOperation
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TaskOperation.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a TaskOperation message.
                 * @function verify
                 * @memberof Service.Intervention.PlanningInterventionCard.TaskOperation
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                TaskOperation.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operation != null && message.hasOwnProperty("operation")) {
                        let error = $root.Service.Intervention.PlanningInterventionCard.TaskOperation.Operation.verify(message.operation);
                        if (error)
                            return "operation." + error;
                    }
                    if (message.taskZone != null && message.hasOwnProperty("taskZone")) {
                        let error = $root.Service.Intervention.PlanningInterventionCard.TaskOperation.TaskZone.verify(message.taskZone);
                        if (error)
                            return "taskZone." + error;
                    }
                    if (message.task != null && message.hasOwnProperty("task")) {
                        let error = $root.Service.Intervention.PlanningInterventionCard.TaskOperation.Task.verify(message.task);
                        if (error)
                            return "task." + error;
                    }
                    if (message.id != null && message.hasOwnProperty("id"))
                        if (!$util.isInteger(message.id))
                            return "id: integer expected";
                    if (message.riskAssessment != null && message.hasOwnProperty("riskAssessment")) {
                        let error = $root.Service.Intervention.PlanningInterventionCard.TaskOperation.RiskAssessment.verify(message.riskAssessment);
                        if (error)
                            return "riskAssessment." + error;
                    }
                    if (message.label != null && message.hasOwnProperty("label"))
                        if (!$util.isString(message.label))
                            return "label: string expected";
                    if (message.mainTaskOperationExtras != null && message.hasOwnProperty("mainTaskOperationExtras")) {
                        if (!Array.isArray(message.mainTaskOperationExtras))
                            return "mainTaskOperationExtras: array expected";
                        for (let i = 0; i < message.mainTaskOperationExtras.length; ++i) {
                            let error = $root.Service.Intervention.PlanningInterventionCard.TaskOperation.TaskOperationExtra.verify(message.mainTaskOperationExtras[i]);
                            if (error)
                                return "mainTaskOperationExtras." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a TaskOperation message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof Service.Intervention.PlanningInterventionCard.TaskOperation
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {Service.Intervention.PlanningInterventionCard.TaskOperation} TaskOperation
                 */
                TaskOperation.fromObject = function fromObject(object) {
                    if (object instanceof $root.Service.Intervention.PlanningInterventionCard.TaskOperation)
                        return object;
                    let message = new $root.Service.Intervention.PlanningInterventionCard.TaskOperation();
                    if (object.operation != null) {
                        if (typeof object.operation !== "object")
                            throw TypeError(".Service.Intervention.PlanningInterventionCard.TaskOperation.operation: object expected");
                        message.operation = $root.Service.Intervention.PlanningInterventionCard.TaskOperation.Operation.fromObject(object.operation);
                    }
                    if (object.taskZone != null) {
                        if (typeof object.taskZone !== "object")
                            throw TypeError(".Service.Intervention.PlanningInterventionCard.TaskOperation.taskZone: object expected");
                        message.taskZone = $root.Service.Intervention.PlanningInterventionCard.TaskOperation.TaskZone.fromObject(object.taskZone);
                    }
                    if (object.task != null) {
                        if (typeof object.task !== "object")
                            throw TypeError(".Service.Intervention.PlanningInterventionCard.TaskOperation.task: object expected");
                        message.task = $root.Service.Intervention.PlanningInterventionCard.TaskOperation.Task.fromObject(object.task);
                    }
                    if (object.id != null)
                        message.id = object.id >>> 0;
                    if (object.riskAssessment != null) {
                        if (typeof object.riskAssessment !== "object")
                            throw TypeError(".Service.Intervention.PlanningInterventionCard.TaskOperation.riskAssessment: object expected");
                        message.riskAssessment = $root.Service.Intervention.PlanningInterventionCard.TaskOperation.RiskAssessment.fromObject(object.riskAssessment);
                    }
                    if (object.label != null)
                        message.label = String(object.label);
                    if (object.mainTaskOperationExtras) {
                        if (!Array.isArray(object.mainTaskOperationExtras))
                            throw TypeError(".Service.Intervention.PlanningInterventionCard.TaskOperation.mainTaskOperationExtras: array expected");
                        message.mainTaskOperationExtras = [];
                        for (let i = 0; i < object.mainTaskOperationExtras.length; ++i) {
                            if (typeof object.mainTaskOperationExtras[i] !== "object")
                                throw TypeError(".Service.Intervention.PlanningInterventionCard.TaskOperation.mainTaskOperationExtras: object expected");
                            message.mainTaskOperationExtras[i] = $root.Service.Intervention.PlanningInterventionCard.TaskOperation.TaskOperationExtra.fromObject(object.mainTaskOperationExtras[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a TaskOperation message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof Service.Intervention.PlanningInterventionCard.TaskOperation
                 * @static
                 * @param {Service.Intervention.PlanningInterventionCard.TaskOperation} message TaskOperation
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                TaskOperation.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.mainTaskOperationExtras = [];
                    if (options.defaults) {
                        object.operation = null;
                        object.taskZone = null;
                        object.task = null;
                        object.id = 0;
                        object.riskAssessment = null;
                        object.label = "";
                    }
                    if (message.operation != null && message.hasOwnProperty("operation"))
                        object.operation = $root.Service.Intervention.PlanningInterventionCard.TaskOperation.Operation.toObject(message.operation, options);
                    if (message.taskZone != null && message.hasOwnProperty("taskZone"))
                        object.taskZone = $root.Service.Intervention.PlanningInterventionCard.TaskOperation.TaskZone.toObject(message.taskZone, options);
                    if (message.task != null && message.hasOwnProperty("task"))
                        object.task = $root.Service.Intervention.PlanningInterventionCard.TaskOperation.Task.toObject(message.task, options);
                    if (message.id != null && message.hasOwnProperty("id"))
                        object.id = message.id;
                    if (message.riskAssessment != null && message.hasOwnProperty("riskAssessment"))
                        object.riskAssessment = $root.Service.Intervention.PlanningInterventionCard.TaskOperation.RiskAssessment.toObject(message.riskAssessment, options);
                    if (message.label != null && message.hasOwnProperty("label"))
                        object.label = message.label;
                    if (message.mainTaskOperationExtras && message.mainTaskOperationExtras.length) {
                        object.mainTaskOperationExtras = [];
                        for (let j = 0; j < message.mainTaskOperationExtras.length; ++j)
                            object.mainTaskOperationExtras[j] = $root.Service.Intervention.PlanningInterventionCard.TaskOperation.TaskOperationExtra.toObject(message.mainTaskOperationExtras[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this TaskOperation to JSON.
                 * @function toJSON
                 * @memberof Service.Intervention.PlanningInterventionCard.TaskOperation
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                TaskOperation.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                TaskOperation.Operation = (function() {

                    /**
                     * Properties of an Operation.
                     * @memberof Service.Intervention.PlanningInterventionCard.TaskOperation
                     * @interface IOperation
                     * @property {string|null} [internalLabel] Operation internalLabel
                     * @property {Service.Intervention.PlanningInterventionCard.TaskOperation.Operation.IOperationCategory|null} [operationCategory] Operation operationCategory
                     */

                    /**
                     * Constructs a new Operation.
                     * @memberof Service.Intervention.PlanningInterventionCard.TaskOperation
                     * @classdesc Represents an Operation.
                     * @implements IOperation
                     * @constructor
                     * @param {Service.Intervention.PlanningInterventionCard.TaskOperation.IOperation=} [properties] Properties to set
                     */
                    function Operation(properties) {
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Operation internalLabel.
                     * @member {string} internalLabel
                     * @memberof Service.Intervention.PlanningInterventionCard.TaskOperation.Operation
                     * @instance
                     */
                    Operation.prototype.internalLabel = "";

                    /**
                     * Operation operationCategory.
                     * @member {Service.Intervention.PlanningInterventionCard.TaskOperation.Operation.IOperationCategory|null|undefined} operationCategory
                     * @memberof Service.Intervention.PlanningInterventionCard.TaskOperation.Operation
                     * @instance
                     */
                    Operation.prototype.operationCategory = null;

                    /**
                     * Creates a new Operation instance using the specified properties.
                     * @function create
                     * @memberof Service.Intervention.PlanningInterventionCard.TaskOperation.Operation
                     * @static
                     * @param {Service.Intervention.PlanningInterventionCard.TaskOperation.IOperation=} [properties] Properties to set
                     * @returns {Service.Intervention.PlanningInterventionCard.TaskOperation.Operation} Operation instance
                     */
                    Operation.create = function create(properties) {
                        return new Operation(properties);
                    };

                    /**
                     * Encodes the specified Operation message. Does not implicitly {@link Service.Intervention.PlanningInterventionCard.TaskOperation.Operation.verify|verify} messages.
                     * @function encode
                     * @memberof Service.Intervention.PlanningInterventionCard.TaskOperation.Operation
                     * @static
                     * @param {Service.Intervention.PlanningInterventionCard.TaskOperation.IOperation} message Operation message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Operation.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.internalLabel != null && Object.hasOwnProperty.call(message, "internalLabel"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.internalLabel);
                        if (message.operationCategory != null && Object.hasOwnProperty.call(message, "operationCategory"))
                            $root.Service.Intervention.PlanningInterventionCard.TaskOperation.Operation.OperationCategory.encode(message.operationCategory, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified Operation message, length delimited. Does not implicitly {@link Service.Intervention.PlanningInterventionCard.TaskOperation.Operation.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof Service.Intervention.PlanningInterventionCard.TaskOperation.Operation
                     * @static
                     * @param {Service.Intervention.PlanningInterventionCard.TaskOperation.IOperation} message Operation message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Operation.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an Operation message from the specified reader or buffer.
                     * @function decode
                     * @memberof Service.Intervention.PlanningInterventionCard.TaskOperation.Operation
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {Service.Intervention.PlanningInterventionCard.TaskOperation.Operation} Operation
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Operation.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Service.Intervention.PlanningInterventionCard.TaskOperation.Operation();
                        while (reader.pos < end) {
                            let tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.internalLabel = reader.string();
                                break;
                            case 2:
                                message.operationCategory = $root.Service.Intervention.PlanningInterventionCard.TaskOperation.Operation.OperationCategory.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an Operation message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof Service.Intervention.PlanningInterventionCard.TaskOperation.Operation
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {Service.Intervention.PlanningInterventionCard.TaskOperation.Operation} Operation
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Operation.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an Operation message.
                     * @function verify
                     * @memberof Service.Intervention.PlanningInterventionCard.TaskOperation.Operation
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Operation.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.internalLabel != null && message.hasOwnProperty("internalLabel"))
                            if (!$util.isString(message.internalLabel))
                                return "internalLabel: string expected";
                        if (message.operationCategory != null && message.hasOwnProperty("operationCategory")) {
                            let error = $root.Service.Intervention.PlanningInterventionCard.TaskOperation.Operation.OperationCategory.verify(message.operationCategory);
                            if (error)
                                return "operationCategory." + error;
                        }
                        return null;
                    };

                    /**
                     * Creates an Operation message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof Service.Intervention.PlanningInterventionCard.TaskOperation.Operation
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {Service.Intervention.PlanningInterventionCard.TaskOperation.Operation} Operation
                     */
                    Operation.fromObject = function fromObject(object) {
                        if (object instanceof $root.Service.Intervention.PlanningInterventionCard.TaskOperation.Operation)
                            return object;
                        let message = new $root.Service.Intervention.PlanningInterventionCard.TaskOperation.Operation();
                        if (object.internalLabel != null)
                            message.internalLabel = String(object.internalLabel);
                        if (object.operationCategory != null) {
                            if (typeof object.operationCategory !== "object")
                                throw TypeError(".Service.Intervention.PlanningInterventionCard.TaskOperation.Operation.operationCategory: object expected");
                            message.operationCategory = $root.Service.Intervention.PlanningInterventionCard.TaskOperation.Operation.OperationCategory.fromObject(object.operationCategory);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from an Operation message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof Service.Intervention.PlanningInterventionCard.TaskOperation.Operation
                     * @static
                     * @param {Service.Intervention.PlanningInterventionCard.TaskOperation.Operation} message Operation
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Operation.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        let object = {};
                        if (options.defaults) {
                            object.internalLabel = "";
                            object.operationCategory = null;
                        }
                        if (message.internalLabel != null && message.hasOwnProperty("internalLabel"))
                            object.internalLabel = message.internalLabel;
                        if (message.operationCategory != null && message.hasOwnProperty("operationCategory"))
                            object.operationCategory = $root.Service.Intervention.PlanningInterventionCard.TaskOperation.Operation.OperationCategory.toObject(message.operationCategory, options);
                        return object;
                    };

                    /**
                     * Converts this Operation to JSON.
                     * @function toJSON
                     * @memberof Service.Intervention.PlanningInterventionCard.TaskOperation.Operation
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Operation.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    Operation.OperationCategory = (function() {

                        /**
                         * Properties of an OperationCategory.
                         * @memberof Service.Intervention.PlanningInterventionCard.TaskOperation.Operation
                         * @interface IOperationCategory
                         * @property {string|null} [urn] OperationCategory urn
                         */

                        /**
                         * Constructs a new OperationCategory.
                         * @memberof Service.Intervention.PlanningInterventionCard.TaskOperation.Operation
                         * @classdesc Represents an OperationCategory.
                         * @implements IOperationCategory
                         * @constructor
                         * @param {Service.Intervention.PlanningInterventionCard.TaskOperation.Operation.IOperationCategory=} [properties] Properties to set
                         */
                        function OperationCategory(properties) {
                            if (properties)
                                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * OperationCategory urn.
                         * @member {string} urn
                         * @memberof Service.Intervention.PlanningInterventionCard.TaskOperation.Operation.OperationCategory
                         * @instance
                         */
                        OperationCategory.prototype.urn = "";

                        /**
                         * Creates a new OperationCategory instance using the specified properties.
                         * @function create
                         * @memberof Service.Intervention.PlanningInterventionCard.TaskOperation.Operation.OperationCategory
                         * @static
                         * @param {Service.Intervention.PlanningInterventionCard.TaskOperation.Operation.IOperationCategory=} [properties] Properties to set
                         * @returns {Service.Intervention.PlanningInterventionCard.TaskOperation.Operation.OperationCategory} OperationCategory instance
                         */
                        OperationCategory.create = function create(properties) {
                            return new OperationCategory(properties);
                        };

                        /**
                         * Encodes the specified OperationCategory message. Does not implicitly {@link Service.Intervention.PlanningInterventionCard.TaskOperation.Operation.OperationCategory.verify|verify} messages.
                         * @function encode
                         * @memberof Service.Intervention.PlanningInterventionCard.TaskOperation.Operation.OperationCategory
                         * @static
                         * @param {Service.Intervention.PlanningInterventionCard.TaskOperation.Operation.IOperationCategory} message OperationCategory message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        OperationCategory.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.urn != null && Object.hasOwnProperty.call(message, "urn"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.urn);
                            return writer;
                        };

                        /**
                         * Encodes the specified OperationCategory message, length delimited. Does not implicitly {@link Service.Intervention.PlanningInterventionCard.TaskOperation.Operation.OperationCategory.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof Service.Intervention.PlanningInterventionCard.TaskOperation.Operation.OperationCategory
                         * @static
                         * @param {Service.Intervention.PlanningInterventionCard.TaskOperation.Operation.IOperationCategory} message OperationCategory message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        OperationCategory.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes an OperationCategory message from the specified reader or buffer.
                         * @function decode
                         * @memberof Service.Intervention.PlanningInterventionCard.TaskOperation.Operation.OperationCategory
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {Service.Intervention.PlanningInterventionCard.TaskOperation.Operation.OperationCategory} OperationCategory
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        OperationCategory.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Service.Intervention.PlanningInterventionCard.TaskOperation.Operation.OperationCategory();
                            while (reader.pos < end) {
                                let tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.urn = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes an OperationCategory message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof Service.Intervention.PlanningInterventionCard.TaskOperation.Operation.OperationCategory
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {Service.Intervention.PlanningInterventionCard.TaskOperation.Operation.OperationCategory} OperationCategory
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        OperationCategory.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies an OperationCategory message.
                         * @function verify
                         * @memberof Service.Intervention.PlanningInterventionCard.TaskOperation.Operation.OperationCategory
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        OperationCategory.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.urn != null && message.hasOwnProperty("urn"))
                                if (!$util.isString(message.urn))
                                    return "urn: string expected";
                            return null;
                        };

                        /**
                         * Creates an OperationCategory message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof Service.Intervention.PlanningInterventionCard.TaskOperation.Operation.OperationCategory
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {Service.Intervention.PlanningInterventionCard.TaskOperation.Operation.OperationCategory} OperationCategory
                         */
                        OperationCategory.fromObject = function fromObject(object) {
                            if (object instanceof $root.Service.Intervention.PlanningInterventionCard.TaskOperation.Operation.OperationCategory)
                                return object;
                            let message = new $root.Service.Intervention.PlanningInterventionCard.TaskOperation.Operation.OperationCategory();
                            if (object.urn != null)
                                message.urn = String(object.urn);
                            return message;
                        };

                        /**
                         * Creates a plain object from an OperationCategory message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof Service.Intervention.PlanningInterventionCard.TaskOperation.Operation.OperationCategory
                         * @static
                         * @param {Service.Intervention.PlanningInterventionCard.TaskOperation.Operation.OperationCategory} message OperationCategory
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        OperationCategory.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            let object = {};
                            if (options.defaults)
                                object.urn = "";
                            if (message.urn != null && message.hasOwnProperty("urn"))
                                object.urn = message.urn;
                            return object;
                        };

                        /**
                         * Converts this OperationCategory to JSON.
                         * @function toJSON
                         * @memberof Service.Intervention.PlanningInterventionCard.TaskOperation.Operation.OperationCategory
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        OperationCategory.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return OperationCategory;
                    })();

                    return Operation;
                })();

                TaskOperation.TaskOperationExtra = (function() {

                    /**
                     * Properties of a TaskOperationExtra.
                     * @memberof Service.Intervention.PlanningInterventionCard.TaskOperation
                     * @interface ITaskOperationExtra
                     * @property {number|null} [id] TaskOperationExtra id
                     * @property {Service.Intervention.PlanningInterventionCard.ITaskOperation|null} [mainTaskOperation] TaskOperationExtra mainTaskOperation
                     */

                    /**
                     * Constructs a new TaskOperationExtra.
                     * @memberof Service.Intervention.PlanningInterventionCard.TaskOperation
                     * @classdesc Represents a TaskOperationExtra.
                     * @implements ITaskOperationExtra
                     * @constructor
                     * @param {Service.Intervention.PlanningInterventionCard.TaskOperation.ITaskOperationExtra=} [properties] Properties to set
                     */
                    function TaskOperationExtra(properties) {
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * TaskOperationExtra id.
                     * @member {number} id
                     * @memberof Service.Intervention.PlanningInterventionCard.TaskOperation.TaskOperationExtra
                     * @instance
                     */
                    TaskOperationExtra.prototype.id = 0;

                    /**
                     * TaskOperationExtra mainTaskOperation.
                     * @member {Service.Intervention.PlanningInterventionCard.ITaskOperation|null|undefined} mainTaskOperation
                     * @memberof Service.Intervention.PlanningInterventionCard.TaskOperation.TaskOperationExtra
                     * @instance
                     */
                    TaskOperationExtra.prototype.mainTaskOperation = null;

                    /**
                     * Creates a new TaskOperationExtra instance using the specified properties.
                     * @function create
                     * @memberof Service.Intervention.PlanningInterventionCard.TaskOperation.TaskOperationExtra
                     * @static
                     * @param {Service.Intervention.PlanningInterventionCard.TaskOperation.ITaskOperationExtra=} [properties] Properties to set
                     * @returns {Service.Intervention.PlanningInterventionCard.TaskOperation.TaskOperationExtra} TaskOperationExtra instance
                     */
                    TaskOperationExtra.create = function create(properties) {
                        return new TaskOperationExtra(properties);
                    };

                    /**
                     * Encodes the specified TaskOperationExtra message. Does not implicitly {@link Service.Intervention.PlanningInterventionCard.TaskOperation.TaskOperationExtra.verify|verify} messages.
                     * @function encode
                     * @memberof Service.Intervention.PlanningInterventionCard.TaskOperation.TaskOperationExtra
                     * @static
                     * @param {Service.Intervention.PlanningInterventionCard.TaskOperation.ITaskOperationExtra} message TaskOperationExtra message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    TaskOperationExtra.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                            writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.id);
                        if (message.mainTaskOperation != null && Object.hasOwnProperty.call(message, "mainTaskOperation"))
                            $root.Service.Intervention.PlanningInterventionCard.TaskOperation.encode(message.mainTaskOperation, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified TaskOperationExtra message, length delimited. Does not implicitly {@link Service.Intervention.PlanningInterventionCard.TaskOperation.TaskOperationExtra.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof Service.Intervention.PlanningInterventionCard.TaskOperation.TaskOperationExtra
                     * @static
                     * @param {Service.Intervention.PlanningInterventionCard.TaskOperation.ITaskOperationExtra} message TaskOperationExtra message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    TaskOperationExtra.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a TaskOperationExtra message from the specified reader or buffer.
                     * @function decode
                     * @memberof Service.Intervention.PlanningInterventionCard.TaskOperation.TaskOperationExtra
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {Service.Intervention.PlanningInterventionCard.TaskOperation.TaskOperationExtra} TaskOperationExtra
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    TaskOperationExtra.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Service.Intervention.PlanningInterventionCard.TaskOperation.TaskOperationExtra();
                        while (reader.pos < end) {
                            let tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.uint32();
                                break;
                            case 2:
                                message.mainTaskOperation = $root.Service.Intervention.PlanningInterventionCard.TaskOperation.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a TaskOperationExtra message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof Service.Intervention.PlanningInterventionCard.TaskOperation.TaskOperationExtra
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {Service.Intervention.PlanningInterventionCard.TaskOperation.TaskOperationExtra} TaskOperationExtra
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    TaskOperationExtra.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a TaskOperationExtra message.
                     * @function verify
                     * @memberof Service.Intervention.PlanningInterventionCard.TaskOperation.TaskOperationExtra
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    TaskOperationExtra.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id))
                                return "id: integer expected";
                        if (message.mainTaskOperation != null && message.hasOwnProperty("mainTaskOperation")) {
                            let error = $root.Service.Intervention.PlanningInterventionCard.TaskOperation.verify(message.mainTaskOperation);
                            if (error)
                                return "mainTaskOperation." + error;
                        }
                        return null;
                    };

                    /**
                     * Creates a TaskOperationExtra message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof Service.Intervention.PlanningInterventionCard.TaskOperation.TaskOperationExtra
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {Service.Intervention.PlanningInterventionCard.TaskOperation.TaskOperationExtra} TaskOperationExtra
                     */
                    TaskOperationExtra.fromObject = function fromObject(object) {
                        if (object instanceof $root.Service.Intervention.PlanningInterventionCard.TaskOperation.TaskOperationExtra)
                            return object;
                        let message = new $root.Service.Intervention.PlanningInterventionCard.TaskOperation.TaskOperationExtra();
                        if (object.id != null)
                            message.id = object.id >>> 0;
                        if (object.mainTaskOperation != null) {
                            if (typeof object.mainTaskOperation !== "object")
                                throw TypeError(".Service.Intervention.PlanningInterventionCard.TaskOperation.TaskOperationExtra.mainTaskOperation: object expected");
                            message.mainTaskOperation = $root.Service.Intervention.PlanningInterventionCard.TaskOperation.fromObject(object.mainTaskOperation);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a TaskOperationExtra message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof Service.Intervention.PlanningInterventionCard.TaskOperation.TaskOperationExtra
                     * @static
                     * @param {Service.Intervention.PlanningInterventionCard.TaskOperation.TaskOperationExtra} message TaskOperationExtra
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    TaskOperationExtra.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        let object = {};
                        if (options.defaults) {
                            object.id = 0;
                            object.mainTaskOperation = null;
                        }
                        if (message.id != null && message.hasOwnProperty("id"))
                            object.id = message.id;
                        if (message.mainTaskOperation != null && message.hasOwnProperty("mainTaskOperation"))
                            object.mainTaskOperation = $root.Service.Intervention.PlanningInterventionCard.TaskOperation.toObject(message.mainTaskOperation, options);
                        return object;
                    };

                    /**
                     * Converts this TaskOperationExtra to JSON.
                     * @function toJSON
                     * @memberof Service.Intervention.PlanningInterventionCard.TaskOperation.TaskOperationExtra
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    TaskOperationExtra.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return TaskOperationExtra;
                })();

                TaskOperation.TaskZone = (function() {

                    /**
                     * Properties of a TaskZone.
                     * @memberof Service.Intervention.PlanningInterventionCard.TaskOperation
                     * @interface ITaskZone
                     * @property {string|null} [name] TaskZone name
                     */

                    /**
                     * Constructs a new TaskZone.
                     * @memberof Service.Intervention.PlanningInterventionCard.TaskOperation
                     * @classdesc Represents a TaskZone.
                     * @implements ITaskZone
                     * @constructor
                     * @param {Service.Intervention.PlanningInterventionCard.TaskOperation.ITaskZone=} [properties] Properties to set
                     */
                    function TaskZone(properties) {
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * TaskZone name.
                     * @member {string} name
                     * @memberof Service.Intervention.PlanningInterventionCard.TaskOperation.TaskZone
                     * @instance
                     */
                    TaskZone.prototype.name = "";

                    /**
                     * Creates a new TaskZone instance using the specified properties.
                     * @function create
                     * @memberof Service.Intervention.PlanningInterventionCard.TaskOperation.TaskZone
                     * @static
                     * @param {Service.Intervention.PlanningInterventionCard.TaskOperation.ITaskZone=} [properties] Properties to set
                     * @returns {Service.Intervention.PlanningInterventionCard.TaskOperation.TaskZone} TaskZone instance
                     */
                    TaskZone.create = function create(properties) {
                        return new TaskZone(properties);
                    };

                    /**
                     * Encodes the specified TaskZone message. Does not implicitly {@link Service.Intervention.PlanningInterventionCard.TaskOperation.TaskZone.verify|verify} messages.
                     * @function encode
                     * @memberof Service.Intervention.PlanningInterventionCard.TaskOperation.TaskZone
                     * @static
                     * @param {Service.Intervention.PlanningInterventionCard.TaskOperation.ITaskZone} message TaskZone message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    TaskZone.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                        return writer;
                    };

                    /**
                     * Encodes the specified TaskZone message, length delimited. Does not implicitly {@link Service.Intervention.PlanningInterventionCard.TaskOperation.TaskZone.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof Service.Intervention.PlanningInterventionCard.TaskOperation.TaskZone
                     * @static
                     * @param {Service.Intervention.PlanningInterventionCard.TaskOperation.ITaskZone} message TaskZone message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    TaskZone.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a TaskZone message from the specified reader or buffer.
                     * @function decode
                     * @memberof Service.Intervention.PlanningInterventionCard.TaskOperation.TaskZone
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {Service.Intervention.PlanningInterventionCard.TaskOperation.TaskZone} TaskZone
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    TaskZone.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Service.Intervention.PlanningInterventionCard.TaskOperation.TaskZone();
                        while (reader.pos < end) {
                            let tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.name = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a TaskZone message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof Service.Intervention.PlanningInterventionCard.TaskOperation.TaskZone
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {Service.Intervention.PlanningInterventionCard.TaskOperation.TaskZone} TaskZone
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    TaskZone.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a TaskZone message.
                     * @function verify
                     * @memberof Service.Intervention.PlanningInterventionCard.TaskOperation.TaskZone
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    TaskZone.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.name != null && message.hasOwnProperty("name"))
                            if (!$util.isString(message.name))
                                return "name: string expected";
                        return null;
                    };

                    /**
                     * Creates a TaskZone message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof Service.Intervention.PlanningInterventionCard.TaskOperation.TaskZone
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {Service.Intervention.PlanningInterventionCard.TaskOperation.TaskZone} TaskZone
                     */
                    TaskZone.fromObject = function fromObject(object) {
                        if (object instanceof $root.Service.Intervention.PlanningInterventionCard.TaskOperation.TaskZone)
                            return object;
                        let message = new $root.Service.Intervention.PlanningInterventionCard.TaskOperation.TaskZone();
                        if (object.name != null)
                            message.name = String(object.name);
                        return message;
                    };

                    /**
                     * Creates a plain object from a TaskZone message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof Service.Intervention.PlanningInterventionCard.TaskOperation.TaskZone
                     * @static
                     * @param {Service.Intervention.PlanningInterventionCard.TaskOperation.TaskZone} message TaskZone
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    TaskZone.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        let object = {};
                        if (options.defaults)
                            object.name = "";
                        if (message.name != null && message.hasOwnProperty("name"))
                            object.name = message.name;
                        return object;
                    };

                    /**
                     * Converts this TaskZone to JSON.
                     * @function toJSON
                     * @memberof Service.Intervention.PlanningInterventionCard.TaskOperation.TaskZone
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    TaskZone.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return TaskZone;
                })();

                TaskOperation.Task = (function() {

                    /**
                     * Properties of a Task.
                     * @memberof Service.Intervention.PlanningInterventionCard.TaskOperation
                     * @interface ITask
                     * @property {string|null} [urn] Task urn
                     */

                    /**
                     * Constructs a new Task.
                     * @memberof Service.Intervention.PlanningInterventionCard.TaskOperation
                     * @classdesc Represents a Task.
                     * @implements ITask
                     * @constructor
                     * @param {Service.Intervention.PlanningInterventionCard.TaskOperation.ITask=} [properties] Properties to set
                     */
                    function Task(properties) {
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Task urn.
                     * @member {string} urn
                     * @memberof Service.Intervention.PlanningInterventionCard.TaskOperation.Task
                     * @instance
                     */
                    Task.prototype.urn = "";

                    /**
                     * Creates a new Task instance using the specified properties.
                     * @function create
                     * @memberof Service.Intervention.PlanningInterventionCard.TaskOperation.Task
                     * @static
                     * @param {Service.Intervention.PlanningInterventionCard.TaskOperation.ITask=} [properties] Properties to set
                     * @returns {Service.Intervention.PlanningInterventionCard.TaskOperation.Task} Task instance
                     */
                    Task.create = function create(properties) {
                        return new Task(properties);
                    };

                    /**
                     * Encodes the specified Task message. Does not implicitly {@link Service.Intervention.PlanningInterventionCard.TaskOperation.Task.verify|verify} messages.
                     * @function encode
                     * @memberof Service.Intervention.PlanningInterventionCard.TaskOperation.Task
                     * @static
                     * @param {Service.Intervention.PlanningInterventionCard.TaskOperation.ITask} message Task message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Task.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.urn != null && Object.hasOwnProperty.call(message, "urn"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.urn);
                        return writer;
                    };

                    /**
                     * Encodes the specified Task message, length delimited. Does not implicitly {@link Service.Intervention.PlanningInterventionCard.TaskOperation.Task.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof Service.Intervention.PlanningInterventionCard.TaskOperation.Task
                     * @static
                     * @param {Service.Intervention.PlanningInterventionCard.TaskOperation.ITask} message Task message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Task.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a Task message from the specified reader or buffer.
                     * @function decode
                     * @memberof Service.Intervention.PlanningInterventionCard.TaskOperation.Task
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {Service.Intervention.PlanningInterventionCard.TaskOperation.Task} Task
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Task.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Service.Intervention.PlanningInterventionCard.TaskOperation.Task();
                        while (reader.pos < end) {
                            let tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.urn = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a Task message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof Service.Intervention.PlanningInterventionCard.TaskOperation.Task
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {Service.Intervention.PlanningInterventionCard.TaskOperation.Task} Task
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Task.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a Task message.
                     * @function verify
                     * @memberof Service.Intervention.PlanningInterventionCard.TaskOperation.Task
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Task.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.urn != null && message.hasOwnProperty("urn"))
                            if (!$util.isString(message.urn))
                                return "urn: string expected";
                        return null;
                    };

                    /**
                     * Creates a Task message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof Service.Intervention.PlanningInterventionCard.TaskOperation.Task
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {Service.Intervention.PlanningInterventionCard.TaskOperation.Task} Task
                     */
                    Task.fromObject = function fromObject(object) {
                        if (object instanceof $root.Service.Intervention.PlanningInterventionCard.TaskOperation.Task)
                            return object;
                        let message = new $root.Service.Intervention.PlanningInterventionCard.TaskOperation.Task();
                        if (object.urn != null)
                            message.urn = String(object.urn);
                        return message;
                    };

                    /**
                     * Creates a plain object from a Task message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof Service.Intervention.PlanningInterventionCard.TaskOperation.Task
                     * @static
                     * @param {Service.Intervention.PlanningInterventionCard.TaskOperation.Task} message Task
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Task.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        let object = {};
                        if (options.defaults)
                            object.urn = "";
                        if (message.urn != null && message.hasOwnProperty("urn"))
                            object.urn = message.urn;
                        return object;
                    };

                    /**
                     * Converts this Task to JSON.
                     * @function toJSON
                     * @memberof Service.Intervention.PlanningInterventionCard.TaskOperation.Task
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Task.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return Task;
                })();

                TaskOperation.RiskAssessment = (function() {

                    /**
                     * Properties of a RiskAssessment.
                     * @memberof Service.Intervention.PlanningInterventionCard.TaskOperation
                     * @interface IRiskAssessment
                     * @property {string|null} [urn] RiskAssessment urn
                     */

                    /**
                     * Constructs a new RiskAssessment.
                     * @memberof Service.Intervention.PlanningInterventionCard.TaskOperation
                     * @classdesc Represents a RiskAssessment.
                     * @implements IRiskAssessment
                     * @constructor
                     * @param {Service.Intervention.PlanningInterventionCard.TaskOperation.IRiskAssessment=} [properties] Properties to set
                     */
                    function RiskAssessment(properties) {
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * RiskAssessment urn.
                     * @member {string} urn
                     * @memberof Service.Intervention.PlanningInterventionCard.TaskOperation.RiskAssessment
                     * @instance
                     */
                    RiskAssessment.prototype.urn = "";

                    /**
                     * Creates a new RiskAssessment instance using the specified properties.
                     * @function create
                     * @memberof Service.Intervention.PlanningInterventionCard.TaskOperation.RiskAssessment
                     * @static
                     * @param {Service.Intervention.PlanningInterventionCard.TaskOperation.IRiskAssessment=} [properties] Properties to set
                     * @returns {Service.Intervention.PlanningInterventionCard.TaskOperation.RiskAssessment} RiskAssessment instance
                     */
                    RiskAssessment.create = function create(properties) {
                        return new RiskAssessment(properties);
                    };

                    /**
                     * Encodes the specified RiskAssessment message. Does not implicitly {@link Service.Intervention.PlanningInterventionCard.TaskOperation.RiskAssessment.verify|verify} messages.
                     * @function encode
                     * @memberof Service.Intervention.PlanningInterventionCard.TaskOperation.RiskAssessment
                     * @static
                     * @param {Service.Intervention.PlanningInterventionCard.TaskOperation.IRiskAssessment} message RiskAssessment message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    RiskAssessment.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.urn != null && Object.hasOwnProperty.call(message, "urn"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.urn);
                        return writer;
                    };

                    /**
                     * Encodes the specified RiskAssessment message, length delimited. Does not implicitly {@link Service.Intervention.PlanningInterventionCard.TaskOperation.RiskAssessment.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof Service.Intervention.PlanningInterventionCard.TaskOperation.RiskAssessment
                     * @static
                     * @param {Service.Intervention.PlanningInterventionCard.TaskOperation.IRiskAssessment} message RiskAssessment message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    RiskAssessment.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a RiskAssessment message from the specified reader or buffer.
                     * @function decode
                     * @memberof Service.Intervention.PlanningInterventionCard.TaskOperation.RiskAssessment
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {Service.Intervention.PlanningInterventionCard.TaskOperation.RiskAssessment} RiskAssessment
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    RiskAssessment.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Service.Intervention.PlanningInterventionCard.TaskOperation.RiskAssessment();
                        while (reader.pos < end) {
                            let tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.urn = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a RiskAssessment message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof Service.Intervention.PlanningInterventionCard.TaskOperation.RiskAssessment
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {Service.Intervention.PlanningInterventionCard.TaskOperation.RiskAssessment} RiskAssessment
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    RiskAssessment.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a RiskAssessment message.
                     * @function verify
                     * @memberof Service.Intervention.PlanningInterventionCard.TaskOperation.RiskAssessment
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    RiskAssessment.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.urn != null && message.hasOwnProperty("urn"))
                            if (!$util.isString(message.urn))
                                return "urn: string expected";
                        return null;
                    };

                    /**
                     * Creates a RiskAssessment message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof Service.Intervention.PlanningInterventionCard.TaskOperation.RiskAssessment
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {Service.Intervention.PlanningInterventionCard.TaskOperation.RiskAssessment} RiskAssessment
                     */
                    RiskAssessment.fromObject = function fromObject(object) {
                        if (object instanceof $root.Service.Intervention.PlanningInterventionCard.TaskOperation.RiskAssessment)
                            return object;
                        let message = new $root.Service.Intervention.PlanningInterventionCard.TaskOperation.RiskAssessment();
                        if (object.urn != null)
                            message.urn = String(object.urn);
                        return message;
                    };

                    /**
                     * Creates a plain object from a RiskAssessment message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof Service.Intervention.PlanningInterventionCard.TaskOperation.RiskAssessment
                     * @static
                     * @param {Service.Intervention.PlanningInterventionCard.TaskOperation.RiskAssessment} message RiskAssessment
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    RiskAssessment.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        let object = {};
                        if (options.defaults)
                            object.urn = "";
                        if (message.urn != null && message.hasOwnProperty("urn"))
                            object.urn = message.urn;
                        return object;
                    };

                    /**
                     * Converts this RiskAssessment to JSON.
                     * @function toJSON
                     * @memberof Service.Intervention.PlanningInterventionCard.TaskOperation.RiskAssessment
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    RiskAssessment.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return RiskAssessment;
                })();

                return TaskOperation;
            })();

            PlanningInterventionCard.InterventionResource = (function() {

                /**
                 * Properties of an InterventionResource.
                 * @memberof Service.Intervention.PlanningInterventionCard
                 * @interface IInterventionResource
                 * @property {string|null} [urn] InterventionResource urn
                 * @property {Service.Intervention.PlanningInterventionCard.InterventionResource.IResourceType|null} [resourceType] InterventionResource resourceType
                 * @property {string|null} [resourceUrn] InterventionResource resourceUrn
                 * @property {string|null} [entityUrn] InterventionResource entityUrn
                 */

                /**
                 * Constructs a new InterventionResource.
                 * @memberof Service.Intervention.PlanningInterventionCard
                 * @classdesc Represents an InterventionResource.
                 * @implements IInterventionResource
                 * @constructor
                 * @param {Service.Intervention.PlanningInterventionCard.IInterventionResource=} [properties] Properties to set
                 */
                function InterventionResource(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * InterventionResource urn.
                 * @member {string} urn
                 * @memberof Service.Intervention.PlanningInterventionCard.InterventionResource
                 * @instance
                 */
                InterventionResource.prototype.urn = "";

                /**
                 * InterventionResource resourceType.
                 * @member {Service.Intervention.PlanningInterventionCard.InterventionResource.IResourceType|null|undefined} resourceType
                 * @memberof Service.Intervention.PlanningInterventionCard.InterventionResource
                 * @instance
                 */
                InterventionResource.prototype.resourceType = null;

                /**
                 * InterventionResource resourceUrn.
                 * @member {string} resourceUrn
                 * @memberof Service.Intervention.PlanningInterventionCard.InterventionResource
                 * @instance
                 */
                InterventionResource.prototype.resourceUrn = "";

                /**
                 * InterventionResource entityUrn.
                 * @member {string} entityUrn
                 * @memberof Service.Intervention.PlanningInterventionCard.InterventionResource
                 * @instance
                 */
                InterventionResource.prototype.entityUrn = "";

                /**
                 * Creates a new InterventionResource instance using the specified properties.
                 * @function create
                 * @memberof Service.Intervention.PlanningInterventionCard.InterventionResource
                 * @static
                 * @param {Service.Intervention.PlanningInterventionCard.IInterventionResource=} [properties] Properties to set
                 * @returns {Service.Intervention.PlanningInterventionCard.InterventionResource} InterventionResource instance
                 */
                InterventionResource.create = function create(properties) {
                    return new InterventionResource(properties);
                };

                /**
                 * Encodes the specified InterventionResource message. Does not implicitly {@link Service.Intervention.PlanningInterventionCard.InterventionResource.verify|verify} messages.
                 * @function encode
                 * @memberof Service.Intervention.PlanningInterventionCard.InterventionResource
                 * @static
                 * @param {Service.Intervention.PlanningInterventionCard.IInterventionResource} message InterventionResource message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                InterventionResource.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.urn != null && Object.hasOwnProperty.call(message, "urn"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.urn);
                    if (message.resourceType != null && Object.hasOwnProperty.call(message, "resourceType"))
                        $root.Service.Intervention.PlanningInterventionCard.InterventionResource.ResourceType.encode(message.resourceType, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.resourceUrn != null && Object.hasOwnProperty.call(message, "resourceUrn"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.resourceUrn);
                    if (message.entityUrn != null && Object.hasOwnProperty.call(message, "entityUrn"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.entityUrn);
                    return writer;
                };

                /**
                 * Encodes the specified InterventionResource message, length delimited. Does not implicitly {@link Service.Intervention.PlanningInterventionCard.InterventionResource.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof Service.Intervention.PlanningInterventionCard.InterventionResource
                 * @static
                 * @param {Service.Intervention.PlanningInterventionCard.IInterventionResource} message InterventionResource message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                InterventionResource.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an InterventionResource message from the specified reader or buffer.
                 * @function decode
                 * @memberof Service.Intervention.PlanningInterventionCard.InterventionResource
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {Service.Intervention.PlanningInterventionCard.InterventionResource} InterventionResource
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                InterventionResource.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Service.Intervention.PlanningInterventionCard.InterventionResource();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.urn = reader.string();
                            break;
                        case 2:
                            message.resourceType = $root.Service.Intervention.PlanningInterventionCard.InterventionResource.ResourceType.decode(reader, reader.uint32());
                            break;
                        case 3:
                            message.resourceUrn = reader.string();
                            break;
                        case 4:
                            message.entityUrn = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an InterventionResource message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof Service.Intervention.PlanningInterventionCard.InterventionResource
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {Service.Intervention.PlanningInterventionCard.InterventionResource} InterventionResource
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                InterventionResource.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an InterventionResource message.
                 * @function verify
                 * @memberof Service.Intervention.PlanningInterventionCard.InterventionResource
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                InterventionResource.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.urn != null && message.hasOwnProperty("urn"))
                        if (!$util.isString(message.urn))
                            return "urn: string expected";
                    if (message.resourceType != null && message.hasOwnProperty("resourceType")) {
                        let error = $root.Service.Intervention.PlanningInterventionCard.InterventionResource.ResourceType.verify(message.resourceType);
                        if (error)
                            return "resourceType." + error;
                    }
                    if (message.resourceUrn != null && message.hasOwnProperty("resourceUrn"))
                        if (!$util.isString(message.resourceUrn))
                            return "resourceUrn: string expected";
                    if (message.entityUrn != null && message.hasOwnProperty("entityUrn"))
                        if (!$util.isString(message.entityUrn))
                            return "entityUrn: string expected";
                    return null;
                };

                /**
                 * Creates an InterventionResource message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof Service.Intervention.PlanningInterventionCard.InterventionResource
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {Service.Intervention.PlanningInterventionCard.InterventionResource} InterventionResource
                 */
                InterventionResource.fromObject = function fromObject(object) {
                    if (object instanceof $root.Service.Intervention.PlanningInterventionCard.InterventionResource)
                        return object;
                    let message = new $root.Service.Intervention.PlanningInterventionCard.InterventionResource();
                    if (object.urn != null)
                        message.urn = String(object.urn);
                    if (object.resourceType != null) {
                        if (typeof object.resourceType !== "object")
                            throw TypeError(".Service.Intervention.PlanningInterventionCard.InterventionResource.resourceType: object expected");
                        message.resourceType = $root.Service.Intervention.PlanningInterventionCard.InterventionResource.ResourceType.fromObject(object.resourceType);
                    }
                    if (object.resourceUrn != null)
                        message.resourceUrn = String(object.resourceUrn);
                    if (object.entityUrn != null)
                        message.entityUrn = String(object.entityUrn);
                    return message;
                };

                /**
                 * Creates a plain object from an InterventionResource message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof Service.Intervention.PlanningInterventionCard.InterventionResource
                 * @static
                 * @param {Service.Intervention.PlanningInterventionCard.InterventionResource} message InterventionResource
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                InterventionResource.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.urn = "";
                        object.resourceType = null;
                        object.resourceUrn = "";
                        object.entityUrn = "";
                    }
                    if (message.urn != null && message.hasOwnProperty("urn"))
                        object.urn = message.urn;
                    if (message.resourceType != null && message.hasOwnProperty("resourceType"))
                        object.resourceType = $root.Service.Intervention.PlanningInterventionCard.InterventionResource.ResourceType.toObject(message.resourceType, options);
                    if (message.resourceUrn != null && message.hasOwnProperty("resourceUrn"))
                        object.resourceUrn = message.resourceUrn;
                    if (message.entityUrn != null && message.hasOwnProperty("entityUrn"))
                        object.entityUrn = message.entityUrn;
                    return object;
                };

                /**
                 * Converts this InterventionResource to JSON.
                 * @function toJSON
                 * @memberof Service.Intervention.PlanningInterventionCard.InterventionResource
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                InterventionResource.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                InterventionResource.ResourceType = (function() {

                    /**
                     * Properties of a ResourceType.
                     * @memberof Service.Intervention.PlanningInterventionCard.InterventionResource
                     * @interface IResourceType
                     * @property {string|null} [reference] ResourceType reference
                     */

                    /**
                     * Constructs a new ResourceType.
                     * @memberof Service.Intervention.PlanningInterventionCard.InterventionResource
                     * @classdesc Represents a ResourceType.
                     * @implements IResourceType
                     * @constructor
                     * @param {Service.Intervention.PlanningInterventionCard.InterventionResource.IResourceType=} [properties] Properties to set
                     */
                    function ResourceType(properties) {
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * ResourceType reference.
                     * @member {string} reference
                     * @memberof Service.Intervention.PlanningInterventionCard.InterventionResource.ResourceType
                     * @instance
                     */
                    ResourceType.prototype.reference = "";

                    /**
                     * Creates a new ResourceType instance using the specified properties.
                     * @function create
                     * @memberof Service.Intervention.PlanningInterventionCard.InterventionResource.ResourceType
                     * @static
                     * @param {Service.Intervention.PlanningInterventionCard.InterventionResource.IResourceType=} [properties] Properties to set
                     * @returns {Service.Intervention.PlanningInterventionCard.InterventionResource.ResourceType} ResourceType instance
                     */
                    ResourceType.create = function create(properties) {
                        return new ResourceType(properties);
                    };

                    /**
                     * Encodes the specified ResourceType message. Does not implicitly {@link Service.Intervention.PlanningInterventionCard.InterventionResource.ResourceType.verify|verify} messages.
                     * @function encode
                     * @memberof Service.Intervention.PlanningInterventionCard.InterventionResource.ResourceType
                     * @static
                     * @param {Service.Intervention.PlanningInterventionCard.InterventionResource.IResourceType} message ResourceType message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ResourceType.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.reference != null && Object.hasOwnProperty.call(message, "reference"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.reference);
                        return writer;
                    };

                    /**
                     * Encodes the specified ResourceType message, length delimited. Does not implicitly {@link Service.Intervention.PlanningInterventionCard.InterventionResource.ResourceType.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof Service.Intervention.PlanningInterventionCard.InterventionResource.ResourceType
                     * @static
                     * @param {Service.Intervention.PlanningInterventionCard.InterventionResource.IResourceType} message ResourceType message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ResourceType.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a ResourceType message from the specified reader or buffer.
                     * @function decode
                     * @memberof Service.Intervention.PlanningInterventionCard.InterventionResource.ResourceType
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {Service.Intervention.PlanningInterventionCard.InterventionResource.ResourceType} ResourceType
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ResourceType.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Service.Intervention.PlanningInterventionCard.InterventionResource.ResourceType();
                        while (reader.pos < end) {
                            let tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.reference = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a ResourceType message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof Service.Intervention.PlanningInterventionCard.InterventionResource.ResourceType
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {Service.Intervention.PlanningInterventionCard.InterventionResource.ResourceType} ResourceType
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ResourceType.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a ResourceType message.
                     * @function verify
                     * @memberof Service.Intervention.PlanningInterventionCard.InterventionResource.ResourceType
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ResourceType.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.reference != null && message.hasOwnProperty("reference"))
                            if (!$util.isString(message.reference))
                                return "reference: string expected";
                        return null;
                    };

                    /**
                     * Creates a ResourceType message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof Service.Intervention.PlanningInterventionCard.InterventionResource.ResourceType
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {Service.Intervention.PlanningInterventionCard.InterventionResource.ResourceType} ResourceType
                     */
                    ResourceType.fromObject = function fromObject(object) {
                        if (object instanceof $root.Service.Intervention.PlanningInterventionCard.InterventionResource.ResourceType)
                            return object;
                        let message = new $root.Service.Intervention.PlanningInterventionCard.InterventionResource.ResourceType();
                        if (object.reference != null)
                            message.reference = String(object.reference);
                        return message;
                    };

                    /**
                     * Creates a plain object from a ResourceType message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof Service.Intervention.PlanningInterventionCard.InterventionResource.ResourceType
                     * @static
                     * @param {Service.Intervention.PlanningInterventionCard.InterventionResource.ResourceType} message ResourceType
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ResourceType.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        let object = {};
                        if (options.defaults)
                            object.reference = "";
                        if (message.reference != null && message.hasOwnProperty("reference"))
                            object.reference = message.reference;
                        return object;
                    };

                    /**
                     * Converts this ResourceType to JSON.
                     * @function toJSON
                     * @memberof Service.Intervention.PlanningInterventionCard.InterventionResource.ResourceType
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ResourceType.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return ResourceType;
                })();

                return InterventionResource;
            })();

            return PlanningInterventionCard;
        })();

        return Intervention;
    })();

    Service.Common = (function() {

        /**
         * Namespace Common.
         * @memberof Service
         * @namespace
         */
        const Common = {};

        Common.RealTimeEvent = (function() {

            /**
             * Properties of a RealTimeEvent.
             * @memberof Service.Common
             * @interface IRealTimeEvent
             * @property {string|null} [uuid] RealTimeEvent uuid
             * @property {number|Long|null} [timestamp] RealTimeEvent timestamp
             * @property {string|null} [type] RealTimeEvent type
             * @property {string|null} [kind] RealTimeEvent kind
             * @property {string|null} [proto] RealTimeEvent proto
             * @property {string|null} [contentType] RealTimeEvent contentType
             * @property {Uint8Array|null} [data] RealTimeEvent data
             */

            /**
             * Constructs a new RealTimeEvent.
             * @memberof Service.Common
             * @classdesc Represents a RealTimeEvent.
             * @implements IRealTimeEvent
             * @constructor
             * @param {Service.Common.IRealTimeEvent=} [properties] Properties to set
             */
            function RealTimeEvent(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * RealTimeEvent uuid.
             * @member {string} uuid
             * @memberof Service.Common.RealTimeEvent
             * @instance
             */
            RealTimeEvent.prototype.uuid = "";

            /**
             * RealTimeEvent timestamp.
             * @member {number|Long} timestamp
             * @memberof Service.Common.RealTimeEvent
             * @instance
             */
            RealTimeEvent.prototype.timestamp = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * RealTimeEvent type.
             * @member {string} type
             * @memberof Service.Common.RealTimeEvent
             * @instance
             */
            RealTimeEvent.prototype.type = "";

            /**
             * RealTimeEvent kind.
             * @member {string} kind
             * @memberof Service.Common.RealTimeEvent
             * @instance
             */
            RealTimeEvent.prototype.kind = "";

            /**
             * RealTimeEvent proto.
             * @member {string} proto
             * @memberof Service.Common.RealTimeEvent
             * @instance
             */
            RealTimeEvent.prototype.proto = "";

            /**
             * RealTimeEvent contentType.
             * @member {string} contentType
             * @memberof Service.Common.RealTimeEvent
             * @instance
             */
            RealTimeEvent.prototype.contentType = "";

            /**
             * RealTimeEvent data.
             * @member {Uint8Array} data
             * @memberof Service.Common.RealTimeEvent
             * @instance
             */
            RealTimeEvent.prototype.data = $util.newBuffer([]);

            /**
             * Creates a new RealTimeEvent instance using the specified properties.
             * @function create
             * @memberof Service.Common.RealTimeEvent
             * @static
             * @param {Service.Common.IRealTimeEvent=} [properties] Properties to set
             * @returns {Service.Common.RealTimeEvent} RealTimeEvent instance
             */
            RealTimeEvent.create = function create(properties) {
                return new RealTimeEvent(properties);
            };

            /**
             * Encodes the specified RealTimeEvent message. Does not implicitly {@link Service.Common.RealTimeEvent.verify|verify} messages.
             * @function encode
             * @memberof Service.Common.RealTimeEvent
             * @static
             * @param {Service.Common.IRealTimeEvent} message RealTimeEvent message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RealTimeEvent.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.uuid != null && Object.hasOwnProperty.call(message, "uuid"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.uuid);
                if (message.timestamp != null && Object.hasOwnProperty.call(message, "timestamp"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.timestamp);
                if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.type);
                if (message.kind != null && Object.hasOwnProperty.call(message, "kind"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.kind);
                if (message.proto != null && Object.hasOwnProperty.call(message, "proto"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.proto);
                if (message.contentType != null && Object.hasOwnProperty.call(message, "contentType"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.contentType);
                if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                    writer.uint32(/* id 7, wireType 2 =*/58).bytes(message.data);
                return writer;
            };

            /**
             * Encodes the specified RealTimeEvent message, length delimited. Does not implicitly {@link Service.Common.RealTimeEvent.verify|verify} messages.
             * @function encodeDelimited
             * @memberof Service.Common.RealTimeEvent
             * @static
             * @param {Service.Common.IRealTimeEvent} message RealTimeEvent message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RealTimeEvent.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a RealTimeEvent message from the specified reader or buffer.
             * @function decode
             * @memberof Service.Common.RealTimeEvent
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {Service.Common.RealTimeEvent} RealTimeEvent
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RealTimeEvent.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Service.Common.RealTimeEvent();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.uuid = reader.string();
                        break;
                    case 2:
                        message.timestamp = reader.uint64();
                        break;
                    case 3:
                        message.type = reader.string();
                        break;
                    case 4:
                        message.kind = reader.string();
                        break;
                    case 5:
                        message.proto = reader.string();
                        break;
                    case 6:
                        message.contentType = reader.string();
                        break;
                    case 7:
                        message.data = reader.bytes();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a RealTimeEvent message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof Service.Common.RealTimeEvent
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {Service.Common.RealTimeEvent} RealTimeEvent
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RealTimeEvent.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a RealTimeEvent message.
             * @function verify
             * @memberof Service.Common.RealTimeEvent
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            RealTimeEvent.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.uuid != null && message.hasOwnProperty("uuid"))
                    if (!$util.isString(message.uuid))
                        return "uuid: string expected";
                if (message.timestamp != null && message.hasOwnProperty("timestamp"))
                    if (!$util.isInteger(message.timestamp) && !(message.timestamp && $util.isInteger(message.timestamp.low) && $util.isInteger(message.timestamp.high)))
                        return "timestamp: integer|Long expected";
                if (message.type != null && message.hasOwnProperty("type"))
                    if (!$util.isString(message.type))
                        return "type: string expected";
                if (message.kind != null && message.hasOwnProperty("kind"))
                    if (!$util.isString(message.kind))
                        return "kind: string expected";
                if (message.proto != null && message.hasOwnProperty("proto"))
                    if (!$util.isString(message.proto))
                        return "proto: string expected";
                if (message.contentType != null && message.hasOwnProperty("contentType"))
                    if (!$util.isString(message.contentType))
                        return "contentType: string expected";
                if (message.data != null && message.hasOwnProperty("data"))
                    if (!(message.data && typeof message.data.length === "number" || $util.isString(message.data)))
                        return "data: buffer expected";
                return null;
            };

            /**
             * Creates a RealTimeEvent message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof Service.Common.RealTimeEvent
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {Service.Common.RealTimeEvent} RealTimeEvent
             */
            RealTimeEvent.fromObject = function fromObject(object) {
                if (object instanceof $root.Service.Common.RealTimeEvent)
                    return object;
                let message = new $root.Service.Common.RealTimeEvent();
                if (object.uuid != null)
                    message.uuid = String(object.uuid);
                if (object.timestamp != null)
                    if ($util.Long)
                        (message.timestamp = $util.Long.fromValue(object.timestamp)).unsigned = true;
                    else if (typeof object.timestamp === "string")
                        message.timestamp = parseInt(object.timestamp, 10);
                    else if (typeof object.timestamp === "number")
                        message.timestamp = object.timestamp;
                    else if (typeof object.timestamp === "object")
                        message.timestamp = new $util.LongBits(object.timestamp.low >>> 0, object.timestamp.high >>> 0).toNumber(true);
                if (object.type != null)
                    message.type = String(object.type);
                if (object.kind != null)
                    message.kind = String(object.kind);
                if (object.proto != null)
                    message.proto = String(object.proto);
                if (object.contentType != null)
                    message.contentType = String(object.contentType);
                if (object.data != null)
                    if (typeof object.data === "string")
                        $util.base64.decode(object.data, message.data = $util.newBuffer($util.base64.length(object.data)), 0);
                    else if (object.data.length)
                        message.data = object.data;
                return message;
            };

            /**
             * Creates a plain object from a RealTimeEvent message. Also converts values to other types if specified.
             * @function toObject
             * @memberof Service.Common.RealTimeEvent
             * @static
             * @param {Service.Common.RealTimeEvent} message RealTimeEvent
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            RealTimeEvent.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.uuid = "";
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.timestamp = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.timestamp = options.longs === String ? "0" : 0;
                    object.type = "";
                    object.kind = "";
                    object.proto = "";
                    object.contentType = "";
                    if (options.bytes === String)
                        object.data = "";
                    else {
                        object.data = [];
                        if (options.bytes !== Array)
                            object.data = $util.newBuffer(object.data);
                    }
                }
                if (message.uuid != null && message.hasOwnProperty("uuid"))
                    object.uuid = message.uuid;
                if (message.timestamp != null && message.hasOwnProperty("timestamp"))
                    if (typeof message.timestamp === "number")
                        object.timestamp = options.longs === String ? String(message.timestamp) : message.timestamp;
                    else
                        object.timestamp = options.longs === String ? $util.Long.prototype.toString.call(message.timestamp) : options.longs === Number ? new $util.LongBits(message.timestamp.low >>> 0, message.timestamp.high >>> 0).toNumber(true) : message.timestamp;
                if (message.type != null && message.hasOwnProperty("type"))
                    object.type = message.type;
                if (message.kind != null && message.hasOwnProperty("kind"))
                    object.kind = message.kind;
                if (message.proto != null && message.hasOwnProperty("proto"))
                    object.proto = message.proto;
                if (message.contentType != null && message.hasOwnProperty("contentType"))
                    object.contentType = message.contentType;
                if (message.data != null && message.hasOwnProperty("data"))
                    object.data = options.bytes === String ? $util.base64.encode(message.data, 0, message.data.length) : options.bytes === Array ? Array.prototype.slice.call(message.data) : message.data;
                return object;
            };

            /**
             * Converts this RealTimeEvent to JSON.
             * @function toJSON
             * @memberof Service.Common.RealTimeEvent
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            RealTimeEvent.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return RealTimeEvent;
        })();

        return Common;
    })();

    return Service;
})();

export const google = $root.google = (() => {

    /**
     * Namespace google.
     * @exports google
     * @namespace
     */
    const google = {};

    google.protobuf = (function() {

        /**
         * Namespace protobuf.
         * @memberof google
         * @namespace
         */
        const protobuf = {};

        protobuf.Timestamp = (function() {

            /**
             * Properties of a Timestamp.
             * @memberof google.protobuf
             * @interface ITimestamp
             * @property {number|Long|null} [seconds] Timestamp seconds
             * @property {number|null} [nanos] Timestamp nanos
             */

            /**
             * Constructs a new Timestamp.
             * @memberof google.protobuf
             * @classdesc Represents a Timestamp.
             * @implements ITimestamp
             * @constructor
             * @param {google.protobuf.ITimestamp=} [properties] Properties to set
             */
            function Timestamp(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Timestamp seconds.
             * @member {number|Long} seconds
             * @memberof google.protobuf.Timestamp
             * @instance
             */
            Timestamp.prototype.seconds = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Timestamp nanos.
             * @member {number} nanos
             * @memberof google.protobuf.Timestamp
             * @instance
             */
            Timestamp.prototype.nanos = 0;

            /**
             * Creates a new Timestamp instance using the specified properties.
             * @function create
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {google.protobuf.ITimestamp=} [properties] Properties to set
             * @returns {google.protobuf.Timestamp} Timestamp instance
             */
            Timestamp.create = function create(properties) {
                return new Timestamp(properties);
            };

            /**
             * Encodes the specified Timestamp message. Does not implicitly {@link google.protobuf.Timestamp.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {google.protobuf.ITimestamp} message Timestamp message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Timestamp.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.seconds != null && Object.hasOwnProperty.call(message, "seconds"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.seconds);
                if (message.nanos != null && Object.hasOwnProperty.call(message, "nanos"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.nanos);
                return writer;
            };

            /**
             * Encodes the specified Timestamp message, length delimited. Does not implicitly {@link google.protobuf.Timestamp.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {google.protobuf.ITimestamp} message Timestamp message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Timestamp.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Timestamp message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.Timestamp} Timestamp
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Timestamp.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.Timestamp();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.seconds = reader.int64();
                        break;
                    case 2:
                        message.nanos = reader.int32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Timestamp message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.Timestamp} Timestamp
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Timestamp.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Timestamp message.
             * @function verify
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Timestamp.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.seconds != null && message.hasOwnProperty("seconds"))
                    if (!$util.isInteger(message.seconds) && !(message.seconds && $util.isInteger(message.seconds.low) && $util.isInteger(message.seconds.high)))
                        return "seconds: integer|Long expected";
                if (message.nanos != null && message.hasOwnProperty("nanos"))
                    if (!$util.isInteger(message.nanos))
                        return "nanos: integer expected";
                return null;
            };

            /**
             * Creates a Timestamp message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.Timestamp} Timestamp
             */
            Timestamp.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.Timestamp)
                    return object;
                let message = new $root.google.protobuf.Timestamp();
                if (object.seconds != null)
                    if ($util.Long)
                        (message.seconds = $util.Long.fromValue(object.seconds)).unsigned = false;
                    else if (typeof object.seconds === "string")
                        message.seconds = parseInt(object.seconds, 10);
                    else if (typeof object.seconds === "number")
                        message.seconds = object.seconds;
                    else if (typeof object.seconds === "object")
                        message.seconds = new $util.LongBits(object.seconds.low >>> 0, object.seconds.high >>> 0).toNumber();
                if (object.nanos != null)
                    message.nanos = object.nanos | 0;
                return message;
            };

            /**
             * Creates a plain object from a Timestamp message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {google.protobuf.Timestamp} message Timestamp
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Timestamp.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.seconds = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.seconds = options.longs === String ? "0" : 0;
                    object.nanos = 0;
                }
                if (message.seconds != null && message.hasOwnProperty("seconds"))
                    if (typeof message.seconds === "number")
                        object.seconds = options.longs === String ? String(message.seconds) : message.seconds;
                    else
                        object.seconds = options.longs === String ? $util.Long.prototype.toString.call(message.seconds) : options.longs === Number ? new $util.LongBits(message.seconds.low >>> 0, message.seconds.high >>> 0).toNumber() : message.seconds;
                if (message.nanos != null && message.hasOwnProperty("nanos"))
                    object.nanos = message.nanos;
                return object;
            };

            /**
             * Converts this Timestamp to JSON.
             * @function toJSON
             * @memberof google.protobuf.Timestamp
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Timestamp.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Timestamp;
        })();

        return protobuf;
    })();

    return google;
})();

export { $root as default };
