import StaffMemberModel           from 'Models/rh/StaffMemberModel';
import OwnerSubPartitionModel     from 'Models/vehicle/OwnerSubPartitionModel';
import VehicleModel               from 'Models/vehicle/VehicleModel';
import ViolationModel             from 'Models/vehicle/ViolationModel';
import ViolationTicketStatusModel from 'Models/vehicle/ViolationTicketStatusModel';
import doc                        from 'decorators/doc';
import model                      from 'decorators/model';
import nestedResolvable           from 'decorators/nestedResolvable';
import { EmptyModel }             from 'decorators/resolvableUrn';
import resolvableUrn              from 'decorators/resolvableUrn';
import { Timestampable }          from 'helpers/traits';
import { Blamable }               from 'helpers/traits';
import { computed }               from 'mobx';
import moment                     from 'moment';
import VehiclePrivateApiModel     from '../../abstracts/VehiclePrivateApiModel';

@model.staticLabel('Contravention', false)
@model.urnResource('violation_ticket')
@doc.path('/violation_tickets/{?id}')
export default class ViolationTicketModel extends Blamable(Timestampable(VehiclePrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'amountExpirationDate[after]': string | Date;
		'amountExpirationDate[before]': string | Date;
		'amountExpirationDate[strictly_after]': string | Date;
		'amountExpirationDate[strictly_before]': string | Date;
		'createdAt[after]': string;
		'createdAt[before]': string;
		'createdAt[strictly_after]': string;
		'createdAt[strictly_before]': string;
		'driver': id;
		'reducedAmountExpirationDate[after]': string | Date;
		'reducedAmountExpirationDate[before]': string | Date;
		'reducedAmountExpirationDate[strictly_after]': string | Date;
		'reducedAmountExpirationDate[strictly_before]': string | Date;
		'ticketDate[after]': string | Date;
		'ticketDate[before]': string | Date;
		'ticketDate[strictly_after]': string | Date;
		'ticketDate[strictly_before]': string | Date;
		'updatedAt[after]': string | Date;
		'updatedAt[before]': string | Date;
		'updatedAt[strictly_after]': string | Date;
		'updatedAt[strictly_before]': string | Date;
		'vehicle': id;
		'vehicle.vehicleSubPartitions.ownerSubPartition.subPartitionUrn': string;
		'violation': id;
		'violation.reference': VehicleViolationReference;
		'violation.violationType': id;
		'violation.violationType.reference': VehicleViolationTypeReference;
		'violationDate[after]': string | Date;
		'violationDate[before]': string | Date;
		'violationDate[strictly_after]': string | Date;
		'violationDate[strictly_before]': string | Date;
		'violationTicketStatus': id;
		'violationTicketStatus.reference': VehicleViolationTicketStatusReference;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'amountExpirationDate': string;
		'reducedAmountExpirationDate': string;
		'ticketDate': string;
		'updatedAt': string;
		'violationDate': string;
		'violationTicketStatus.label': string;
	}> = {};

	@doc.number declare amount: number;
	@doc.date declare amountExpirationDate: Moment;
	@doc.string declare driver: string;
	@doc.number declare increasedAmount: number;
	@doc.string declare location: string;
	@doc.model(OwnerSubPartitionModel) declare ownerSubPartition: OwnerSubPartitionModel;
	@doc.number declare pointsWithdrawn: number;
	@doc.number declare reducedAmount: number;
	@doc.date declare reducedAmountExpirationDate?: Moment;
	@doc.string declare reference: VehicleViolationTicketReference;
	@doc.date declare ticketDate: Moment;
	@doc.model(VehicleModel) declare vehicle: VehicleModel;
	@doc.model(ViolationModel) declare violation: ViolationModel;
	@doc.date declare violationDate: Moment;
	@doc.model(ViolationTicketStatusModel) declare violationTicketStatus: ViolationTicketStatusModel;

	@resolvableUrn({ attributeName: 'driver' })
	declare staffMember: StaffMemberModel | EmptyModel;

	@nestedResolvable()
	public get subPartition() {
		return this.ownerSubPartition.subPartition;
	}

	@nestedResolvable()
	public get violationType() {
		return this.violation.violationType;
	}

	@computed
	public get remainingNbDaysBeforeExpiration() {
		return Math.ceil(this.amountExpirationDate.diff(moment(), 'days', true));
	}

	public get isExpiredSoon() {
		return this.remainingNbDaysBeforeExpiration < 7 && this.remainingNbDaysBeforeExpiration >= 0;
	}
}
