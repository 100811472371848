import { computed }                from 'mobx';
import InterventionPrivateApiModel from '../../abstracts/InterventionPrivateApiModel';

export type CollectableTypeReference = 'volume' | 'int' | 'bool';

export default class CollectableTypeModel extends InterventionPrivateApiModel {
	public _filters: ModelFiltersExtended<{
		reference: string;
	}> = {};

	static get path(): string {
		return '/collectable_types/{?id}';
	}

	@computed
	public get label(): string {
		return this.get('label', '');
	}

	@computed
	public get reference(): CollectableTypeReference {
		return this.get('reference', '');
	}

	@computed
	public get unitLabel(): string {
		return this.get('unitLabel', '');
	}

	public _sorts: ModelSortsExtended<{
		'id': string;
		'label': string;
		'unitLabel': string;
	}> = {};
}
