import 'moment/dist/locale/fr';

import ConfigProvider               from 'antd/lib/config-provider';
import Modal                        from 'antd/lib/modal';
import Spin                         from 'antd/lib/spin';
import locale                       from 'antd/locale/fr_FR';
import { setContextModal }          from 'components/AppForm';
import { Provider as MobxProvider } from 'mobx-react';
import { observer }                 from 'mobx-react';
import moment                       from 'moment';
import { Switch }                   from 'react-router-dom';
import { Route }                    from 'react-router-dom';
import { Router }                   from 'react-router-dom';
import React                        from 'react';
import routes                       from 'routes';
import mobxStore                    from 'stores';
import { appStore }                 from 'stores';
import browserHistory               from 'tools/browserHistory';
import initThemePartition           from './initThemePartition';

moment.locale('fr');

export default observer(() => {
	const [isThemeReady, setIsThemeReady] = React.useState(false);
	const [modal, contextHolder] = Modal.useModal();

	React.useEffect(() => {
		setContextModal(modal);
		initThemePartition().then(() => setIsThemeReady(true));
	}, []);

	if (!isThemeReady) {
		return null;
	}

	const primaryColor = appStore.primaryColor;

	return (
		<React.Suspense fallback={<Spin className="app-centered-spin" size="large" />}>
			<ConfigProvider 
				getPopupContainer={() => {
					return document.getElementById('main') || document.body;
				}}
				locale={locale} 
				theme={{
					components: {
						Breadcrumb: {
							colorText: '#0006',
							colorTextDescription: primaryColor,
						},
						Empty: {
							colorText: '#00000040',
						},
						Table: {
							colorFillSecondary: '#FFF',
						},
					},
					hashed: false,
					token: {
						colorLink: primaryColor,
						colorLinkActive: primaryColor,
						colorLinkHover: primaryColor + '90',
						colorPrimary: primaryColor,
					},
				}}
			>
				<MobxProvider {...mobxStore}>
					<Router history={browserHistory}>
						<Switch>
							{routes.map((route, i) => <RouteWithSubRoutes key={i} {...route} />)}
						</Switch>

						{contextHolder}
					</Router>
				</MobxProvider>
			</ConfigProvider>
		</React.Suspense>
	);
});

const RouteWithSubRoutes = (route: IRoute) => {
	return <Route path={route.path} render={props => <route.component {...props} route={route} />} />;
};
