import Popconfirm    from 'antd/lib/popconfirm';
import View          from 'components/View';
import { LinkProps } from 'react-router-dom';
import React         from 'react';

export default (props: LinkProps) => {
	const { children, to } = props;

	return (
		<Popconfirm
			cancelText="Non"
			icon={null}
			okText="Oui"
			onConfirm={() => window.open(to.toString(), '_blank')}
			title={<TitleComp />}
		>
			<a href={to.toString()} onClick={e => e.preventDefault()} style={{ textDecoration: 'underline' }}>
				{children}
			</a>
		</Popconfirm>
	);
};

const TitleComp = () => {
	const minWidth = 300;

	const content = <span>{`Vous allez être dirigé vers un autre site, voulez vous continuer ?`}</span>;

	return (
		<View centerH gap={10} marginL={-22} minWidth={minWidth}>
			{content}
		</View>
	);
};