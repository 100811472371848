import EnterprisePartitionModel from 'Models/directory/EnterprisePartitionModel';
import FunctionFamilyModel      from 'Models/directory/FunctionFamilyModel';
import GlobalContactModel       from 'Models/directory/GlobalContactModel';
import doc                      from 'decorators/doc';
import model                    from 'decorators/model';
import { Timestampable }        from 'helpers/traits';
import { Blamable }             from 'helpers/traits';
import { computed }             from 'mobx';
import DirectoryPrivateApiModel from 'modelx/models/abstracts/DirectoryPrivateApiModel';
import moment                   from 'moment';

@model.staticLabel('Contact')
@model.urnResource('contact')
@doc.path('/contacts/{?id}')
export default class ContactModel extends Blamable(Timestampable(DirectoryPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'archived': '0' | '1';
		'contactContactGroups': id;
		'createdAt[after]': string;
		'createdAt[before]': string;
		'createdAt[strictly_after]': string;
		'createdAt[strictly_before]': string;
		'dateOut[after]': string | Date;
		'dateOut[before]': string | Date;
		'dateOut[strictly_after]': string | Date;
		'dateOut[strictly_before]': string | Date;
		'email': string;
		'enabled': '0' | '1';
		'enabledForConnection': '0' | '1';
		'enterprisePartition': id;
		'enterprisePartition.clientPartitions': id;
		'enterprisePartition.clientPartitions.clients': id;
		'enterprisePartition.clientPartitions.clients.subPartitionUrn': Urn;
		'enterprisePartition.clientPartitions.company': id;
		'enterprisePartition.enterprise': id;
		'enterprisePartition.partitionUrn': Urn;
		'firstName': string;
		'functionFamily': id;
		'functionFamily.reference': DirectoryFunctionFamilyReference;
		'globalContact': id;
		'lastName': string;
		'phoneNumber': string;
		'updatedAt[after]': string | Date;
		'updatedAt[before]': string | Date;
		'updatedAt[strictly_after]': string | Date;
		'updatedAt[strictly_before]': string | Date;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'email': string;
		'firstName': string;
		'function': string;
		'gender': string;
		'id': string;
		'lastName': string;
		'phoneNumberBis': string;
		'phoneNumber': string;
	}> = {};

	@doc.boolean declare archived: boolean;
	@doc.date declare dateOut?: Moment;
	@doc.boolean declare enabled: boolean;
	@doc.boolean declare enabledForConnection: boolean;
	@doc.model(EnterprisePartitionModel) declare enterprisePartition: EnterprisePartitionModel;
	@doc.string declare function: string;
	@doc.model(FunctionFamilyModel) declare functionFamily: FunctionFamilyModel;
	@doc.model(GlobalContactModel) declare globalContact: GlobalContactModel;
	@doc.string declare note: string;
	@doc.string declare plainPassword: string;

	/**
	 * @deprecated
	 */
	public get photo() {
		return this.get('photo');
	}


	@computed
	public get contactFunction(): string {
		return this.get('function', '');
	}

	@computed
	public get isOut(): boolean {
		return !!this.dateOut && moment(this.dateOut).isBefore(moment());
	}

	@computed
	public get hasInterfaceAccess() {
		return (
			this.globalContact.globalContactConnectionStatus.reference === 'enabled'
			&& this.globalContact.globalContactStatus.reference === 'enabled'
			&& !this.isOut
		);
	}

	@computed
	public get civilityLabel() {
		return this.globalContact.civilityLabel;
	}

	@computed
	public get email() {
		return this.globalContact.email;
	}

	@computed
	public get faxNumber() {
		return this.globalContact.faxNumber;
	}

	@computed
	public get firstName() {
		return this.globalContact.firstName;
	}

	@computed
	public get fullName() {
		return this.globalContact.fullName;
	}

	@computed
	public get gender() {
		return this.globalContact.gender;
	}

	@computed
	public get genderName() {
		return this.globalContact.genderName;
	}

	@computed
	public get lastName() {
		return this.globalContact.lastName;
	}

	@computed
	public get phoneNumber() {
		return this.globalContact.phoneNumber;
	}

	@computed
	public get phoneNumberBis() {
		return this.globalContact.phoneNumberBis;
	}
}