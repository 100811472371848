export function traverseAndFlatten(currentNode, target, flattenedKey?) {
	for (const key in currentNode) {
		// eslint-disable-next-line no-prototype-builtins
		if (currentNode.hasOwnProperty(key)) {
			let newKey;

			if (flattenedKey === undefined) {
				newKey = key;
			} else {
				newKey = flattenedKey + '.' + key;
			}

			const value = currentNode[key];

			if (typeof value === 'object') {
				traverseAndFlatten(value, target, newKey);
			} else {
				target[newKey] = value;
			}
		}
	}
}

export function flatten(obj) {
	const flattenedObject = {};

	traverseAndFlatten(obj, flattenedObject);

	return flattenedObject;
}
