import { resolvable }                   from '@mathquis/modelx-resolvables';
import FileModel                        from 'Models/file/FileModel';
import InterventionModel                from 'Models/intervention/InterventionModel';
import InterventionOperationStatusModel from 'Models/intervention/InterventionOperationStatusModel';
import TaskOperationModel               from 'Models/intervention/TaskOperationModel';
import model                            from 'decorators/model';
import nestedResolvable                 from 'decorators/nestedResolvable';
import { urn }                          from 'helpers/ModelDictionary';
import { Blamable }                     from 'helpers/traits';
import { Timestampable }                from 'helpers/traits';
import { computed }                     from 'mobx';
import InterventionPrivateApiModel      from '../../abstracts/InterventionPrivateApiModel';

@model.staticLabel('Prestation', false)
@urn('$', 'intervention', 'intervention_operation')
export default class InterventionOperationModel extends Blamable(Timestampable(InterventionPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'createdAt[after]': string;
		'createdAt[before]': string;
		'createdAt[strictly_after]': string;
		'createdAt[strictly_before]': string;
		'intervention': id;
		'intervention.taskZone': id;
		'intervention.taskZone.owner': id;
		'intervention.taskZone.owner.clientUrn': string;
		'intervention.taskZone.owner.companyUrn': string;
		'intervention.taskZone.owner.enterprisePartitionUrn': string;
		'intervention.taskZone.owner.enterpriseUrn': string;
		'intervention.taskZone.owner.partitionUrn': string;
		'interventionOperationStatus': id;
		'taskOperation': id;
		'updatedAt[after]': string;
		'updatedAt[before]': string;
		'updatedAt[strictly_after]': string;
		'updatedAt[strictly_before]': string;
	}> = {};

	static get path(): string {
		return '/intervention_operations/{?id}';
	}

	@computed
	public get interventionId(): id {
		return this.get('intervention.id', 0);
	}

	@computed
	public get taskOperationId(): id {
		return this.get('taskOperation.id', 0);
	}

	@computed
	public get interventionOperationStatusId(): id {
		return this.get('interventionOperationStatus.id', 0);
	}

	@computed
	public get interventionOperationValue(): number {
		return this.get('value', 0);
	}

	public _sorts: ModelSortsExtended<{
		'id': string;
		'updatedAt': string;
	}> = {};

	@resolvable(InterventionModel, { attributeName: 'intervention' })
	declare intervention: InterventionModel;

	@resolvable(InterventionOperationStatusModel, { attributeName: 'interventionOperationStatus' })
	declare interventionOperationStatus: InterventionOperationStatusModel;

	public get note(): string {
		return this.get('note', '');
	}

	@resolvable(TaskOperationModel, { attributeName: 'taskOperation' })
	declare taskOperation: TaskOperationModel;

	@model.fileResolvable('intervention_operation_photo')
	public declare photos: FileModel[];

	public get value(): number {
		return this.get('value', 0);
	}

	@nestedResolvable()
	public get operationType() {
		return this.taskOperation.operation.operationType;
	}

	@nestedResolvable()
	public get task() {
		return this.taskOperation.task;
	}

	@nestedResolvable()
	public get operation() {
		return this.taskOperation.operation;
	}

	@nestedResolvable()
	public get operationCategory() {
		return this.taskOperation.operation.operationCategory;
	}
}
