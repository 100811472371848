import { resolvable }                   from '@mathquis/modelx-resolvables';
import ContactModel                     from 'Models/directory/ContactModel';
import StaffMemberModel                 from 'Models/rh/StaffMemberModel';
import QuotationActorGroupModel         from 'Models/sales/QuotationActorGroupModel';
import { QuotationActorGroupReference } from 'Models/sales/QuotationActorGroupModel';
import QuotationModel                   from 'Models/sales/QuotationModel';
import doc                              from 'decorators/doc';
import model                            from 'decorators/model';
import { BlamableEmptyModel }           from 'helpers/traits';
import { Timestampable }                from 'helpers/traits';
import { Blamable }                     from 'helpers/traits';
import { computed }                     from 'mobx';
import SalesPrivateApiModel             from 'modelx/models/abstracts/SalesPrivateApiModel';
import { getIdFromUrn }                 from 'tools/UrnTools';

@model.staticLabel('QuotationActor')
@model.urnResource('quotation_actor')
@doc.path('/quotation_actors/{?id}')
export default class QuotationActorModel extends Blamable(Timestampable(SalesPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'quotation': id;
		'quotation.owner.clientUrn': Urn;
		'quotation.owner.companyUrn': Urn;
		'quotation.owner.enterpriseUrn': Urn;
		'quotation.owner.ownerEnterprisePartition.enterprisePartitionUrn': Urn;
		'quotation.owner.ownerSubPartition.ownerPartition.partitionUrn': Urn;
		'quotation.owner.ownerSubPartition.subPartitionUrn': Urn;
		'quotationActorGroup.reference': QuotationActorGroupReference;
	}> = {};

	public _sorts: ModelSortsExtended<unknown> = {};

	@computed
	public get actorId(): id {
		return parseInt(getIdFromUrn(this.get('actorUrn')));
	}

	@computed
	public get actorUrn(): string {
		return this.get('actorUrn');
	}

	@resolvable(ContactModel, {
		allow: [ContactModel, StaffMemberModel],
		attributeName: 'actorUrn',
		unresolved: BlamableEmptyModel,
	})
	declare actor: BlamableEmptyModel;

	@doc.number declare position: number;
	@doc.model(QuotationModel) declare quotation: QuotationModel;
	@doc.model(QuotationActorGroupModel) declare quotationActorGroup: QuotationActorGroupModel;

}