import lazyWithRetry from 'tools/lazyWithRetry';

const InstallationDashboardPage = lazyWithRetry(() => import(
	/* webpackChunkName: "InstallationDashboardPage" */
	'../../../pages/Installation/InstallationDashboardPage'));

export default [
	{
		component: InstallationDashboardPage,
		exact: true,
		path: '/installations/:installationId',
	},
];
