import { computed }         from 'mobx';
import SalesPrivateApiModel from '../../abstracts/SalesPrivateApiModel';

export default class ContractIterationItemScheduleConstraintModel extends SalesPrivateApiModel {
	public _filters: ModelFiltersExtended<{
		'contractIterationItem': id;
		'contractIterationItem.contractIteration': id;
	}> = {};

	static get path(): string {
		return '/contract_iteration_item_schedule_constraints/{?id}';
	}

	@computed
	public get contractIterationItemId(): id {
		return this.get('contractIterationItem.id');
	}

	@computed
	public get endTime(): string {
		return this.get('endTime');
	}

	@computed
	public get startTime(): string {
		return this.get('startTime');
	}

	@computed
	public get weekday(): number {
		return this.get('weekday');
	}
}
