import { computed }         from 'mobx';
import SalesPrivateApiModel from '../../abstracts/SalesPrivateApiModel';

export default class ContractIterationItemActivityChargeableModel extends SalesPrivateApiModel {
	public _filters: ModelFiltersExtended<{
		'contractIterationItemActivity.contractIterationItem': id;
		'contractIterationItemActivity.contractIterationItem.contractIteration': id;
	}> = {};

	static get path(): string {
		return '/contract_iteration_item_activity_chargeables/{?id}';
	}

	@computed
	public get budgetQuantity(): number {
		return this.get('budgetQuantity');
	}

	@computed
	public get budgetPrice(): number {
		return this.get('budgetPrice');
	}

	@computed
	public get chargeableId(): id {
		return this.get('chargeable.id');
	}

	@computed
	public get chargeableIri(): string {
		return this.get('chargeable.@id');
	}

	@computed
	public get chargeableModeId(): id {
		return this.get('chargeableMode.id');
	}

	@computed
	public get chargeableModeIri(): string {
		return this.get('chargeableMode.@id');
	}

	@computed
	public get contractIterationItemActivityId(): id {
		return this.get('contractIterationItemActivity.id');
	}

	@computed
	public get required(): boolean {
		return this.get('required');
	}

	@computed
	public get vatUrn(): string {
		return this.get('vatUrn');
	}
}
