import SalesPrivateApiModel from '../../abstracts/SalesPrivateApiModel';

export default class ContractIterationItemEventStatusHistory extends SalesPrivateApiModel {
	public _filters: ModelFiltersExtended<{
		'contractIterationItemEvent.contractIterationItem.contractIteration': id;
	}> = {};

	static get path(): string {
		return '/contract_iteration_item_event_status_histories/{?id}';
	}
}
