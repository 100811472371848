import CompanyModel             from 'Models/directory/CompanyModel';
import EnterprisePartitionModel from 'Models/directory/EnterprisePartitionModel';
import doc                      from 'decorators/doc';
import model                    from 'decorators/model';
import { Timestampable }        from 'helpers/traits';
import { Blamable }             from 'helpers/traits';
import DirectoryPrivateApiModel from 'modelx/models/abstracts/DirectoryPrivateApiModel';

@model.staticLabel('ClientPartition')
@model.urnResource('client_partition')
@doc.path('/client_partitions/{?id}')
export default class ClientPartitionModel extends Blamable(Timestampable(DirectoryPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'company': id;
		'company.name': string;
		'enterprisePartition': id;
		'enterprisePartition.contacts': id;
		'enterprisePartition.partitionUrn': Urn;
		'number': number;
		'reference': DirectoryClientPartitionReference;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'company.name': string;
		'enterprisePartition.enterprise.name': string;
		'id': string;
	}> = {};

	@doc.model(CompanyModel) declare company: CompanyModel;
	@doc.model(EnterprisePartitionModel) declare enterprisePartition: EnterprisePartitionModel;
	@doc.number declare number: number;
	@doc.string declare reference: DirectoryClientPartitionReference;
}