import PaymentDeadlineTypeModel     from 'Models/supplier/PaymentDeadlineTypeModel';
import PaymentModeModel             from 'Models/supplier/PaymentModeModel';
import doc                          from 'decorators/doc';
import model                        from 'decorators/model';
import { Timestampable }            from 'helpers/traits';
import { Blamable }                 from 'helpers/traits';
import SupplierPrivateApiModel      from 'modelx/models/abstracts/SupplierPrivateApiModel';

@model.staticLabel('Délai de paiement')
@model.urnResource('purchase_condition')
@doc.path('/purchase_conditions/{?id}')
export default class PurchaseConditionModel extends Blamable(Timestampable(SupplierPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'createdAt[after]': string;
		'createdAt[before]': string;
		'createdAt[strictly_after]': string;
		'createdAt[strictly_before]': string;
		'supplierEnterprisePurchaseConditions.supplierEnterprise': id;
		'supplierEnterprisePurchaseConditions.supplierEnterprise.enterpriseUrn': string;
		'supplierEnterprisePurchaseConditions.supplierEnterprise.supplierCompanies': id;
		'supplierEnterprisePurchaseConditions.supplierEnterprise.supplierCompanies.companyUrn': string;
		'supplierEnterprisePurchaseConditions.supplierEnterprisePurchaseConditionStatus': id;
		'supplierEnterprisePurchaseConditions.supplierEnterprisePurchaseConditionStatus.reference': SupplierSupplierEnterprisePurchaseConditionStatusReference;
		'updatedAt[after]': string | Date;
		'updatedAt[before]': string | Date;
		'updatedAt[strictly_after]': string | Date;
		'updatedAt[strictly_before]': string | Date;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'updatedAt': string;
	}> = {};

	@doc.number declare delay: number;
	@doc.string declare label: string;
	@doc.model(PaymentDeadlineTypeModel) declare paymentDeadlineType: PaymentDeadlineTypeModel;
	@doc.model(PaymentModeModel) declare paymentMode: PaymentModeModel;

	public get formattedDelay() {
		return this.delay + ' jour' + (this.delay > 1 ? 's' : '');
	}
}
