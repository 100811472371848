import FileModel                                      from 'Models/file/FileModel';
import PaymentModeTypeModel                           from 'Models/invoice/PaymentModeTypeModel';
import PurchaseConditionModel                         from 'Models/supplier/PurchaseConditionModel';
import SupplierEnterpriseModel                        from 'Models/supplier/SupplierEnterpriseModel';
import SupplierEnterprisePurchaseConditionStatusModel from 'Models/supplier/SupplierEnterprisePurchaseConditionStatusModel';
import doc                                            from 'decorators/doc';
import model                                          from 'decorators/model';
import { Timestampable }                              from 'helpers/traits';
import { Blamable }                                   from 'helpers/traits';
import SupplierPrivateApiModel                        from 'modelx/models/abstracts/SupplierPrivateApiModel';
import TagModel                                       from './TagModel';

@model.staticLabel('Modalité de paiement', false)
@model.urnResource('supplier_enterprise_purchase_condition')
@doc.path('/supplier_enterprise_purchase_conditions/{?id}')
export default class SupplierEnterprisePurchaseConditionModel extends Blamable(Timestampable(SupplierPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'createdAt[after]': string;
		'createdAt[before]': string;
		'createdAt[strictly_after]': string;
		'createdAt[strictly_before]': string;
		'purchaseCondition': id;
		'supplierEnterprise': id;
		'supplierEnterprise.enterpriseUrn': Urn;
		'supplierEnterprise.supplierCompanies': id;
		'supplierEnterprise.supplierCompanies.companyUrn': Urn;
		'supplierEnterprise.supplierCompanies.supplierSubPartitions.ownerSubPartition.subPartitionUrn': Urn;
		'supplierEnterprise.supplierEnterpriseStatus.reference': SupplierSupplierEnterpriseStatusReference;
		'supplierEnterprisePurchaseConditionStatus': id;
		'supplierEnterprisePurchaseConditionStatus.reference': SupplierSupplierEnterprisePurchaseConditionStatusReference;
		'updatedAt[after]': string | Date;
		'updatedAt[before]': string | Date;
		'updatedAt[strictly_after]': string | Date;
		'updatedAt[strictly_before]': string | Date;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'updatedAt': string;
	}> = {};

	@doc.number declare deposit: number;
	@doc.modelUrn(PaymentModeTypeModel) declare paymentModeType: PaymentModeTypeModel;
	@doc.model(PurchaseConditionModel) declare purchaseCondition: PurchaseConditionModel;
	@doc.model(SupplierEnterpriseModel) declare supplierEnterprise: SupplierEnterpriseModel;
	@doc.model(SupplierEnterprisePurchaseConditionStatusModel) declare supplierEnterprisePurchaseConditionStatus: SupplierEnterprisePurchaseConditionStatusModel;

	@model.fileResolvable('supplier_enterprise_purchase_condition_levy_mandate')
	public declare mandateFile: FileModel;

	@model.fileResolvable('supplier_enterprise_purchase_condition_bank_statement')
	public declare bankStatementFile: FileModel;

	@model.reverseResolvable<TagModel>({
		filters: (models) => ({
			'tag.tagReference.reference': 'bic',
			'targetUrn': models.map(m => m.urn),
		}),
		mapping: (m1, m2) => m1.urn === m2.targetUrn,
		urn: '$:supplier:tag',
	})
	public declare tagBic: TagModel;

	@model.reverseResolvable<TagModel>({
		filters: (models) => ({
			'tag.tagReference.reference': 'iban',
			'targetUrn': models.map(m => m.urn),
		}),
		mapping: (m1, m2) => m1.urn === m2.targetUrn,
		urn: '$:supplier:tag',
	})
	public declare tagIban: TagModel;
}
