import PartitionModel       from 'Models/partition/PartitionModel';
import doc                  from 'decorators/doc';
import model                from 'decorators/model';
import { Timestampable }    from 'helpers/traits';
import { Blamable }         from 'helpers/traits';
import SalesPrivateApiModel from 'modelx/models/abstracts/SalesPrivateApiModel';

@model.staticLabel('OwnerPartition')
@model.urnResource('owner_partition')
@doc.path('/owner_partitions/{?id}')
export default class OwnerPartitionModel extends Blamable(Timestampable(SalesPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'frameworkAgreements': id;
		'ownerSubPartitions': id;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
	}> = {};

	@doc.modelUrn(PartitionModel) declare partition: PartitionModel;
}