import lazyWithRetry from 'tools/lazyWithRetry';

const ActivityListPage = lazyWithRetry(() => import(
	/* webpackChunkName: "activityListPage" */
	'../../pages/Activity/ActivityListPage'));

export default [
	{
		component: ActivityListPage,
		exact: true,
		path: '/activities',
	},
];
