import model                from 'decorators/model';
import { computed }         from 'mobx';
import SalesPrivateApiModel from '../../abstracts/SalesPrivateApiModel';

export type ContractIterationItemEventStatusReferenceModel = 'to_do' | 'done' |
'done_not_complete' | 'done_pending' | 'to_pay' |
'paid' | 'long_delay_in_payment' | 'suspended' |
'billable' | 'billable_cancelled' | 'created' | 'to_bill' | 'not_to_bill';

@model.urnResource('quotation_item_event_status')
export default class QuotationItemEventStatusModel extends SalesPrivateApiModel {
	public _filters: ModelFiltersExtended<{
		'reference': ContractIterationItemEventStatusReferenceModel;
	}> = {};

	static get path(): string {
		return '/quotation_item_event_statuses/{?id}';
	}

	@computed
	public get label(): string {
		return this.get('label');
	}

	@computed
	public get reference(): ContractIterationItemEventStatusReferenceModel {
		return this.get('reference');
	}

	public get color() {
		switch (this.reference) {
			case 'done_not_complete':
				return 'yellow';
			case 'done':
				return 'green';
			default:
				return 'blue';
		}
	}
}
