import doc                 from 'decorators/doc';
import model               from 'decorators/model';
import { Timestampable }   from 'helpers/traits';
import FilePrivateApiModel from '../../abstracts/FilePrivateApiModel';

@model.cacheDuration()
@model.staticLabel('FileStatus')
@model.urnResource('file_status')
@doc.path('/stonecode_file_file_statuses/{?id}')
export default class FileStatusModel extends Timestampable(FilePrivateApiModel) {
	public _filters: ModelFiltersExtended<unknown> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
	}> = {};

	@doc.string declare label: string;
	@doc.string declare reference: FileFileStatusReference;
	
	public get fullName() {
		switch (this.reference) {
			case 'available':
				return 'Prêt';
			case 'generable':
				return 'En préparation';
			default :
				return this.label;
		}
	}

	public get color() {
		switch (this.reference) {
			case 'available':
				return 'green';
			case 'generable':
				return 'blue';
			case 'to_delete':
				return 'red';
		}
	}
}
