import lazyWithRetry from 'tools/lazyWithRetry';

const ContractListPage = lazyWithRetry(() => import(
	/* webpackChunkName: "contractIterationListPage" */
	'../../pages/Contract/ContractListPage'),
	// '../../pages/ContractIteration/ContractIterationListPage'),
);

const ContractDashboardPage = lazyWithRetry(() => import(
	/* webpackChunkName: "contractIterationDashboardPage" */
	'../../pages/Contract/ContractDashboardPage'),
	// '../../pages/ContractIteration/ContractIterationDashboardPage'),
);

export default [
	{
		component: ContractDashboardPage,
		exact: true,
		path: '/contracts/:contractId',
	},
	{
		component: ContractListPage,
		exact: true,
		path: '/sub_partitions/:subPartitionId/contracts',
	},
];
