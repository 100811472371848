import CompanyModel             from 'Models/directory/CompanyModel';
import doc                      from 'decorators/doc';
import model                    from 'decorators/model';
import { Timestampable }        from 'helpers/traits';
import { Blamable }             from 'helpers/traits';
import DirectoryPrivateApiModel from '../../abstracts/DirectoryPrivateApiModel';

@model.staticLabel('CompanyContact')
@model.urnResource('company_contact')
@doc.path('/company_contacts/{?id}')
export default class CompanyContactModel extends Blamable(Timestampable(DirectoryPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'company': id;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
	}> = {};

	@doc.model(CompanyModel) declare company: CompanyModel;
	@doc.string declare email: string;
	@doc.string declare faxNumber: string;
	@doc.string declare phoneNumber: string;
}