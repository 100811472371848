import { LoggedApiConnector } from 'modelx/connectors/LoggedApiConnector';
import ConfigProxy            from 'tools/ConfigProxy';
import InterventionApiModel   from './InterventionApiModel';

export const interventionLoggedApiConnector = new LoggedApiConnector({
	baseURL: ConfigProxy.getServiceConfig('intervention', 'api_endpoint'),
});

export default class InterventionPrivateApiModel extends InterventionApiModel {
	public static get connector() {
		return interventionLoggedApiConnector;
	}
}
