import { SearchOutlined }    from '@ant-design/icons';
import { CloseCircleFilled } from '@ant-design/icons';
import Button                from 'antd/lib/button';
import Select                from 'antd/lib/select';
import Form                  from 'components/Form';
import Input                 from 'components/Input';
import { ISidebarFilter }    from 'components/ListCollection';
import PageList              from 'components/PagesList/PageList';
import { PageListData }      from 'components/PagesList/PageList';
import View                  from 'components/View';
import _sortBy               from 'lodash/sortBy';
import _uniqueId             from 'lodash/uniqueId';
import AAM                   from 'modelx/models/abstracts/AbstractApiModel';
import React                 from 'react';
import './InputFilters.scss';

export default (props: {
	filters: ISidebarFilter<AAM>[];
	form: Form | null;
	onChange: () => void;
	onClear: () => void;
	pageList: PageList;
	pageListData: PageListData;
}) => {
	const { filters, form, pageListData } = props;
	const [menuOpen, setMenuOpen] = React.useState(false);
	const [selectKey, setSelectKey] = React.useState(_uniqueId());
	const [searchValue, setSearchValue] = React.useState('');
	const [isUpToDate, setIsUpToDate] = React.useState(true);
	const searchValueRef = React.useRef('');
	const filtersAndSearch = _sortBy([...filters, searchFilter], f => f.label);
	const [displayedNames, setDisplayedNames] = React.useState<string[]>(
		filtersAndSearch.filter(f => Object.keys(pageListData.params).includes(f.name)).map(f => f.name),
	);
	const displayedNamesRef = React.useRef(displayedNames);

	let menu;

	React.useEffect(() => setSelectKey(menuOpen ? selectKey : _uniqueId()), [menuOpen]);
	React.useEffect(() => {
		if (!displayedNames.includes('search')) {
			searchValueRef.current = searchValue;
		}
	}, [searchValue]);

	React.useEffect(() => {
		if (props.pageList) {
			const oldFetch = props.pageList.fetch;

			props.pageList.fetch = () => {
				setIsUpToDate(true);
				return oldFetch();
			};

			props.pageList.onFormValuesChange = (v) => {
				const dNames = displayedNamesRef.current;
				const toRemoveNames = Object.keys(v).filter(k => !v[k] || !v[k].length);
				const toAddNames = Object.keys(v).filter(k => !toRemoveNames.includes(k) && !dNames.includes(k));

				if (toRemoveNames.length || toAddNames.length) {
					setDisplayedNames([...dNames.filter(n => !toRemoveNames.includes(n)), ...toAddNames]);
				}

				if (Object.keys(v).includes('search') && !v['search']) {
					props.onChange();
				} else {
					setIsUpToDate(false);
				}
			};
		}
	}, [props.pageList]);

	React.useEffect(() => {
		displayedNamesRef.current = displayedNames;

		setMenuOpen(false);
	}, [displayedNames]);

	const onClear = () => {
		props.onClear();
		setDisplayedNames([]);
	};

	return (
		<View bg="white" centerV className="InputFilters" gap={4} minWidth={400} paddingH={6} rel row>
			<View absL={0} heightF onClick={() => menu.focus()} widthF />

			<View centerV paddingL={6}>
				<SearchOutlined />
			</View>

			{!!displayedNames.length && (
				<View row wrap>
					{displayedNames.map((name) => {
						const filter = filtersAndSearch.find(f => f.name === name);

						if (!filter || !filter.render) {
							return null;
						}

						return (
							<FilterItem
								autoFocus={menuOpen}
								filter={filter}
								form={form}
								key={name}
								onBlur={() => {
									const value = form?.getFieldValue(name);
									if (typeof value === 'undefined' || value === '') {
										setDisplayedNames(displayedNames.filter(n => n !== name));
									}
								}}
								pageListData={pageListData}
								searchValueRef={searchValueRef}
							/>
						);
					})}
				</View>
			)}

			<View flex minWidth={200}>
				<Select
					className={'main-search'}
					filterOption={(s, o) => {
						const search = removeAccents(s).toLowerCase();
						const oSearch = removeAccents((o?.children as unknown as string || '')).toLowerCase();
						return oSearch.includes(search) || o?.key === 'search';
					}}
					key={selectKey}
					onBlur={() => setMenuOpen(false)}
					onFocus={() => setMenuOpen(displayedNames.length <= filters.length)}
					onSearch={setSearchValue}
					onSelect={name => {
						setDisplayedNames([...displayedNames, name]);
						setIsUpToDate(false);

						if (name === 'search') {
							setTimeout(props.onChange);
						}
					}}
					open={menuOpen}
					optionFilterProp="children"
					placeholder="Rechercher"
					popupMatchSelectWidth={false}
					ref={ref => menu = ref}
					searchValue={searchValue}
					showArrow={false}
					showSearch
					style={{ width: '100%' }}
				>
					{filtersAndSearch.filter(f => !displayedNames.includes(f.name)).map(f => (
						<Select.Option key={f.name} value={f.name}>
							{f.label}
						</Select.Option>
					))}
				</Select>
			</View>

			{!isUpToDate && <Button onClick={props.onChange} size="small" type="primary">Appliquer</Button>}
			{!!displayedNames.length && (
				<View paddingH={4}>
					<CloseCircleFilled onClick={onClear} style={{ color: 'rgba(0,0,0,0.6)', zIndex: 1 }} />
				</View>
			)}
		</View>
	);
};

const FilterItem = (props: {
	autoFocus: boolean;
	filter: ISidebarFilter<AAM>;
	form: Form | null;
	onBlur: () => void;
	pageListData: PageListData;
	searchValueRef;
}) => {
	const { autoFocus, filter, form, onBlur, pageListData, searchValueRef } = props;

	React.useEffect(() => {
		if (filter.name === 'search') {
			form?.setFieldsValue({ search: searchValueRef.current });
		}
	}, []);

	const Comp = React.cloneElement(((filter.render || (() => null))(pageListData)) as never, {
		allowClear: true,
		autoFocus,
		defaultOpen: autoFocus,
		form: null,
		onBlur,
		popupMatchSelectWidth: false,
		size: 'small',
	});

	return (
		<View centerV className={`filter-search-item`} gap={4} key={filter.name} paddingH={4} row style={{ zIndex: 1 }}>
			<View bold noWrap size={10}>{filter.label} : </View>
			<View flex>
				<Form.Item className={filter.name} key={filter.name} name={filter.name}>
					{Comp}
				</Form.Item>
			</View>
		</View>
	);
};

const removeAccents = str => str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

const searchFilter = {
	label: 'Texte',
	name: 'search',
	render: () => <Input placeholder="Recherche" />,
	transform: 'search',
} as ISidebarFilter<AAM>;
