import PolicyModel            from 'Models/comment/PolicyModel';
import ServiceModel           from 'Models/comment/ServiceModel';
import doc                    from 'decorators/doc';
import model                  from 'decorators/model';
import { Timestampable }      from 'helpers/traits';
import { Blamable }           from 'helpers/traits';
import CommentPrivateApiModel from '../../abstracts/CommentPrivateApiModel';

@model.cacheDuration()
@model.staticLabel('Type')
@model.urnResource('type')
@doc.path('/stonecode_comment_types/{?id}')
export default class TypeModel extends Blamable(Timestampable(CommentPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'deprecated': '0' | '1';
		'policy': id;
		'policy.reference': CommentPolicyReference;
		'reference': CommentTypeReference;
		'service': id;
		'service.serviceUrn': string;
		'system': '0' | '1';
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'label': string;
		'updatedAt': string;
	}> = {};

	@doc.boolean declare defaultLock: boolean;
	@doc.boolean declare deprecated: boolean;
	@doc.string declare label: string;
	@doc.number declare maxLength: number;
	@doc.number declare maxPerSource: number;
	@doc.model(PolicyModel) declare policy: PolicyModel;
	@doc.string declare reference: CommentTypeReference;
	@doc.model(ServiceModel) declare service: ServiceModel;
	@doc.boolean declare system: boolean;
	@doc.string declare urnMask: string;
}
