import SourceModel            from 'Models/comment/SourceModel';
import TypeModel              from 'Models/comment/TypeModel';
import TypeSourceModel        from 'Models/comment/TypeSourceModel';
import doc                    from 'decorators/doc';
import model                  from 'decorators/model';
import { Timestampable }      from 'helpers/traits';
import { Blamable }           from 'helpers/traits';
import { computed }           from 'mobx';
import CommentPrivateApiModel from '../../abstracts/CommentPrivateApiModel';

@model.staticLabel('Commentaire')
@model.urnResource('comment')
@doc.path('/stonecode_comment_comments/{?id}')
export default class CommentModel extends Blamable(Timestampable(CommentPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'typeSource': id;
		'typeSource.source': id;
		'typeSource.source.entityUrn': string;
		'typeSource.type': id;
		'typeSource.type.reference': CommentTypeReference;
		'typeSource.type.service': id;
		'typeSource.type.service.serviceUrn': string;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'updatedAt': string;
	}> = {};

	@doc.date declare date: Moment;
	@doc.modelUrn(SourceModel) declare source: SourceModel;
	@doc.string declare text: string;
	@doc.model(TypeModel) declare type: TypeModel;
	@doc.model(TypeSourceModel) declare typeSource: TypeSourceModel;
	
	@computed
	public get interventionID():string {
		return this.text.split('INTER###')[1];
	}

	@computed
	public get interventionIDTag():string {
		return `${CommentModel.interventionIDSeparator}${this.interventionID}`;
	}

	@computed
	public get textWithoutInterventionID():string {
		return this.text.split('INTER###')[0];
	}

	@computed
	public get isInternalComment():boolean {
		return [
			'task_zone_exploitation_comment',
			'contract_iteration_exploitation_comment',
			'quotation_exploitation_comment',
			'intervention_pointing_comment',
		].includes(this.typeSource.type.reference);
	}

	public static interventionIDSeparator = 'INTER###';
}
