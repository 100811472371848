import doc from 'decorators/doc';
import model from 'decorators/model';
import { Blamable } from 'helpers/traits';
import { Timestampable } from 'helpers/traits';
import InvoicePrivateApiModel from '../../abstracts/InvoicePrivateApiModel';

@model.staticLabel('Source')
@model.urnResource('source')
@doc.path('/sources/{?id}')
export default class SourceModel extends Blamable(Timestampable(InvoicePrivateApiModel)) {
	@doc.string declare entryPoint:string;
	@doc.string declare host:string;
	@doc.string declare name:string;
	@doc.string declare reference:SourceReference;
	@doc.string declare type:string;
}