import { computed }         from 'mobx';
import SalesPrivateApiModel from '../../abstracts/SalesPrivateApiModel';

export default class QuotationItemScheduleConstraintModel extends SalesPrivateApiModel {
	public _filters: ModelFiltersExtended<{
		quotationItem: id;
		'quotationItem.quotation': id;
	}> = {};

	static get path(): string {
		return '/quotation_item_schedule_constraints/{?id}';
	}

	@computed
	public get quotationItemId(): id {
		return this.get('quotationItem.id');
	}

	@computed
	public get endTime(): string {
		return this.get('endTime');
	}

	@computed
	public get startTime(): string {
		return this.get('startTime');
	}

	@computed
	public get weekday(): number {
		return this.get('weekday');
	}
}
