import InterventionGroupOwnerModel     from 'Models/intervention/InterventionGroupOwnerModel';
import InterventionGroupStatusModel    from 'Models/intervention/InterventionGroupStatusModel';
import doc                             from 'decorators/doc';
import model                           from 'decorators/model';
import { Timestampable }               from 'helpers/traits';
import { Blamable }                    from 'helpers/traits';
import { IInterventionGroupNameProps } from 'modelNames/intervention/InterventionGroupName';
import InterventionPrivateApiModel     from 'modelx/models/abstracts/InterventionPrivateApiModel';

@model.staticLabel('Groupe d\'interventions')
@model.urnResource('intervention_group')
@doc.path('/intervention_groups/{?id}')
export default class InterventionGroupModel extends Blamable(Timestampable(InterventionPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'createdAt[after]': string;
		'createdAt[before]': string;
		'createdAt[strictly_after]': string;
		'createdAt[strictly_before]': string;
		'createdBy': id;
		'interventionGroupOwner.ownerUrn': Urn;
		'interventionGroupStatus': id;
		'interventionGroupStatus.reference': InterventionInterventionGroupStatusReference;
		'interventionGroupTaskZones': id;
		'interventionGroupTaskZones.taskZone': id;
		'interventionGroupTaskZones.taskZone.owner.clientUrn': Urn;
		'interventionGroupTaskZones.taskZone.owner.ownerSubPartition.subPartitionUrn': Urn;
		'updatedAt[after]': string | Date;
		'updatedAt[before]': string | Date;
		'updatedAt[strictly_after]': string | Date;
		'updatedAt[strictly_before]': string | Date;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'updatedAt': string;
	}> = {};

	@doc.model(InterventionGroupOwnerModel) declare interventionGroupOwner: InterventionGroupOwnerModel;
	@doc.model(InterventionGroupStatusModel) declare interventionGroupStatus: InterventionGroupStatusModel;
	@doc.number declare number: number;

	public renderName(props?: Omit<IInterventionGroupNameProps, 'model'>) {
		return super.renderName(props);
	}
}
