import ContractIterationModel from 'Models/sales/ContractIterationModel';
import doc                    from 'decorators/doc';
import model                  from 'decorators/model';
import { Timestampable }      from 'helpers/traits';
import { Blamable }           from 'helpers/traits';
import SalesPrivateApiModel   from 'modelx/models/abstracts/SalesPrivateApiModel';

@model.staticLabel('Clôture de contrat')
@model.urnResource('contract_iteration_closing')
@doc.path('/contract_iteration_closings/{?id}')
export default class ContractIterationClosingModel extends Blamable(Timestampable(SalesPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'contractIteration': id;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
	}> = {};

	@doc.modelUrn(ContractIterationModel) declare contractIteration: ContractIterationModel;
	@doc.date declare closingDate: Moment;
}
