import { computed }         from 'mobx';
import { getIdFromUrn }     from 'tools/UrnTools';
import SalesPrivateApiModel from '../../abstracts/SalesPrivateApiModel';

export default class QuotationStatusHistoryModel extends SalesPrivateApiModel {
	public _filters: ModelFiltersExtended<{ quotation: id }> = {};

	static get path(): string {
		return '/quotation_status_histories/{?id}';
	}

	@computed
	public get comment(): string {
		return this.get('comment');
	}

	@computed
	public get createdAt(): string {
		return this.get('createdAt');
	}

	@computed
	public get createdBy(): string {
		return this.get('createdBy');
	}

	public get createdById(): id {
		return parseInt(getIdFromUrn(this.get('createdBy')));
	}

	@computed
	public get quotationStatusId(): id {
		return this.get('quotationStatus.id');
	}

	@computed
	public get rejectionReason(): string {
		return this.get('rejectionReason', '');
	}

	@computed
	public get messageToCommercialContact(): string {
		return this.get('messageToCommercialContact', '');
	}
}
