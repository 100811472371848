import { resolvable }           from '@mathquis/modelx-resolvables';
import DirectoryContactModel    from 'Models/directory/ContactModel';
import { ContactTypeReference } from 'Models/sales/ContactTypeModel';
import ContactTypeModel         from 'Models/sales/ContactTypeModel';
import OwnerModel               from 'Models/sales/OwnerModel';
import { urn }                  from 'helpers/ModelDictionary';
import { Blamable }             from 'helpers/traits';
import { Timestampable }        from 'helpers/traits';
import { computed }             from 'mobx';
import { getIdFromUrn }         from 'tools/UrnTools';
import SalesPrivateApiModel     from '../../abstracts/SalesPrivateApiModel';

@urn('$', 'sales', 'contact')
export default class ContactModel extends Blamable(Timestampable(SalesPrivateApiModel)) {

	static get path(): string {
		return '/contacts/{?id}';
	}

	public _filters: ModelFiltersExtended<{
		'contactType.reference': ContactTypeReference;
		'contactUrn': string;
		'createdAt[after]': string;
		'createdAt[before]': string;
		'createdAt[strictly_after]': string;
		'createdAt[strictly_before]': string;
		'owner': id;
		'owner.clientUrn': string;
		'owner.companyUrn': string;
		'owner.ownerEnterprisePartition.enterprisePartitionUrn': string;
		'owner.enterpriseUrn': string;
		'owner.ownerSubPartition.ownerPartition.partitionUrn': string;
		'owner.ownerSubPartition.subPartitionUrn': string;
		'updatedAt[after]': string;
		'updatedAt[before]': string;
		'updatedAt[strictly_after]': string;
		'updatedAt[strictly_before]': string;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'position': string;
		'updatedAt': string;
	}> = {};

	@resolvable(DirectoryContactModel, { attributeName: 'contactUrn' })
	declare contact: DirectoryContactModel;

	public get contactUrn(): string {
		return this.get('contactUrn');
	}

	public get contactTypeId(): id {
		return this.get('contactType.id', 0);
	}

	public get ownerId(): id {
		return this.get('owner.id', 0);
	}

	@computed
	public get contactId(): number {
		return parseInt(getIdFromUrn(this.get('contactUrn')));
	}

	@resolvable(ContactTypeModel, { attributeName: 'contactType' })
	declare contactType: ContactTypeModel;

	@resolvable(OwnerModel, { attributeName: 'owner' })
	declare owner: OwnerModel;

	public get position(): number {
		return this.get('position', 0);
	}
}
