import lazyWithRetry from 'tools/lazyWithRetry';

const SkillListPage = lazyWithRetry(() => import(
	/* webpackChunkName: "skillListPage" */
	'../../pages/Skill/SkillListPage/SkillListPage'));

const SkillDashboardPage = lazyWithRetry(() => import(
	/* webpackChunkName: "skillDashboardPage" */
	'../../pages/Skill/SkillDashboardPage/SkillDashboardPage'));

const SkillAddPage = lazyWithRetry(() => import(
	/* webpackChunkName: "skillAddPage" */
	'../../pages/Skill/SkillAddPage/SkillAddPage'));

export default [
	{
		component: SkillListPage,
		exact: true,
		path: '/skills',
	},
	{
		component: SkillAddPage,
		exact: true,
		path: '/skills/add',
	},
	{
		component: SkillDashboardPage,
		exact: true,
		path: '/skills/:skillId',
	},
];
