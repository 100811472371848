export default function getTextFromElement(elem: React.ReactNode | string): string {
	if (!elem) {
		return '';
	}
	if (
		typeof elem === 'string' 
		|| typeof elem === 'number' 
		|| typeof elem === 'boolean' 
	) {
		return '' + elem;
	}
	if (!('props' in elem)) {
		return '';
	}
	const children = elem.props && elem.props.children;
	if (children instanceof Array) {
		return children.map(getTextFromElement).join('');
	}
	return getTextFromElement(children);
}
