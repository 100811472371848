import { LoggedApiConnector } from 'Connectors/LoggedApiConnector';
import ConfigProxy            from 'tools/ConfigProxy';
import FileApiModel           from './FileApiModel';

export const fileLoggedApiConnector = new LoggedApiConnector({
	baseURL: ConfigProxy.getServiceConfig('file', 'api_endpoint'),
});

export default class FilePrivateApiModel extends FileApiModel {
	public static get connector() {
		return fileLoggedApiConnector;
	}
}
