import { computed }           from 'mobx';
import InvoicePrivateApiModel from '../../abstracts/InvoicePrivateApiModel';

export default class InvoiceMetaModel extends InvoicePrivateApiModel {
	public _filters: ModelFiltersExtended<{
		invoice: id;
	}> = {};

	static get path(): string {
		return '/invoice_metas/{?id}';
	}

	@computed
	public get reference(): string {
		return this.get('reference');
	}

	@computed
	public get value(): number {
		return this.get('value');
	}

	public _sorts: ModelSortsExtended<{
		'id': string;
		'reference': string;
		'value': string;
	}> = {};
}
