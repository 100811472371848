import doc                  from 'decorators/doc';
import model                from 'decorators/model';
import SalesPrivateApiModel from '../../abstracts/SalesPrivateApiModel';

@model.staticLabel('ItemDisplayMode')
@model.urnResource('item_display_mode')
@doc.path('/item_display_modes/{?id}')
export default class ItemDisplayModeModel extends SalesPrivateApiModel {
	public _filters: ModelFiltersExtended<unknown> = {};

	public _sorts: ModelSortsExtended<unknown> = {};

	@doc.string declare label: string;
	@doc.string declare reference: SalesItemDisplayModeReference;
}