import doc                    from 'decorators/doc';
import model                  from 'decorators/model';
import { Timestampable }      from 'helpers/traits';
import { Blamable }           from 'helpers/traits';
import InvoicePrivateApiModel from 'modelx/models/abstracts/InvoicePrivateApiModel';

@model.cacheDuration()
@model.staticLabel('BillableStatus')
@model.urnResource('billable_status')
@doc.path('/billable_statuses/{?id}')
export default class BillableStatusModel extends Blamable(Timestampable(InvoicePrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'label': string;
		'reference': InvoiceBillableStatusReference;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'label': string;
		'reference': string;
	}> = {};

	@doc.string declare label: string;
	@doc.number declare position: number;
	@doc.string declare reference: InvoiceBillableStatusReference;
}