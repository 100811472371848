import EnterpriseModel          from 'Models/directory/EnterpriseModel';
import PartitionModel           from 'Models/partition/PartitionModel';
import doc                      from 'decorators/doc';
import model                    from 'decorators/model';
import { Timestampable }        from 'helpers/traits';
import { Blamable }             from 'helpers/traits';
import DirectoryPrivateApiModel from '../../abstracts/DirectoryPrivateApiModel';

@model.staticLabel('Entreprise', false)
@model.urnResource('enterprise_partition')
@doc.path('/enterprise_partitions/{?id}')
export default class EnterprisePartitionModel extends Blamable(Timestampable(DirectoryPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'clientPartitions.clients.subPartitionUrn': string;
		'contacts': id;
		'enterprise': id;
		'enterprise.enterpriseGroup': id;
		'enterprise.enterpriseType': id;
		'partitionUrn': string;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'enterprise.commercialName': string;
		'enterprise.name': string;
		'enterprise.siren': string;
		'id': string;
	}> = {};

	@doc.model(EnterpriseModel) declare enterprise: EnterpriseModel;
	@doc.modelUrn(PartitionModel) declare partition: PartitionModel;

	public get enterpriseId() {
		return this.get('enterprise.id');
	}
}
