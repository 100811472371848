import lazyWithRetry from 'tools/lazyWithRetry';

const InvoiceListPage = lazyWithRetry(() => import(
	/* webpackChunkName: "invoiceListPage" */
	'../../pages/Invoice/InvoiceListPage/InvoiceListPage'));

const ContractIterationListPage = lazyWithRetry(() => import(
	/* webpackChunkName: "contractIterationListPage" */
	'../../pages/ContractIteration/ContractIterationListPage'),
);

const ContractIterationDashboardPage = lazyWithRetry(() => import(
	/* webpackChunkName: "contractIterationDashboardPage" */
	'../../pages/ContractIteration/ContractIterationDashboardPage'),
);

const ContractIterationStatisticsPage = lazyWithRetry(() => import(
	/* webpackChunkName: "contractIterationStatisticsPage" */
	'../../pages/ContractIteration/ContractIterationStatisticsPage/ContractIterationStatisticsPage'),
);

const ContractualCalendarPage = lazyWithRetry(() => import(
	/* webpackChunkName: "contractualCalendarPage" */
	'../../pages/ContractIteration/ContractualCalendarPage'),
);

export default [
	{
		component: InvoiceListPage,
		exact: true,
		path: '/contract_iterations/:contractIterationId/invoices',
	},
	{
		component: ContractualCalendarPage,
		exact: true,
		path: '/contract_iterations/:contractIterationId/contractual-calendar',
	},
	{
		component: ContractIterationDashboardPage,
		exact: true,
		path: '/contract_iterations/:contractIterationId',
	},
	{
		component: ContractIterationListPage,
		exact: true,
		path: '/sub_partitions/:subPartitionId/contract_iterations',
	},
	{
		component: ContractIterationStatisticsPage,
		exact: true,
		path: '/sub_partitions/:subPartitionId/contract_iteration_statistics',
	}
];
