import doc                    from 'decorators/doc';
import model                  from 'decorators/model';
import { Timestampable }      from 'helpers/traits';
import { Blamable }           from 'helpers/traits';
import InvoicePrivateApiModel from 'modelx/models/abstracts/InvoicePrivateApiModel';

@model.cacheDuration()
@model.staticLabel('Mode de facturation')
@model.urnResource('billing_frequency')
@doc.path('/billing_frequencies/{?id}')
export default class BillingFrequencyModel extends Blamable(Timestampable(InvoicePrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'archived': '0' | '1';
		'reference': InvoiceBillingFrequencyReference;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'label': string;
	}> = {};

	@doc.boolean declare archived: boolean;
	@doc.string declare description: string;
	@doc.string declare label: string;
	@doc.string declare reference: InvoiceBillingFrequencyReference;
}
