import lazyWithRetry from 'tools/lazyWithRetry';

const BillingPeriodListPage = lazyWithRetry(() => import(
	/* webpackChunkName: "billingPeriodListPage" */
	'../../pages/BillingPeriod/BillingPeriodListPage'));

const BillingPeriodDashboardPage = lazyWithRetry(() => import(
	/* webpackChunkName: "billingPeriodDashboardPage" */
	'../../pages/BillingPeriod/BillingPeriodDashboardPage/BillingPeriodDashboardPage'));

export default [
	{
		component: BillingPeriodListPage,
		exact: true,
		path: '/billing_periods',
	},
	{
		component: BillingPeriodDashboardPage,
		exact: true,
		path: '/billing_periods/:billingPeriodId',
	},
];
