import StaffMemberModel       from 'Models/rh/StaffMemberModel';
import SupplierCompanyModel   from 'Models/supplier/SupplierCompanyModel';
import DamageStatusModel      from 'Models/vehicle/DamageStatusModel';
import DamageTypeModel        from 'Models/vehicle/DamageTypeModel';
import InsuranceContractModel from 'Models/vehicle/InsuranceContractModel';
import VehicleModel           from 'Models/vehicle/VehicleModel';
import doc                    from 'decorators/doc';
import model                  from 'decorators/model';
import { EmptyModel }         from 'decorators/resolvableUrn';
import { Timestampable }      from 'helpers/traits';
import { Blamable }           from 'helpers/traits';
import VehiclePrivateApiModel from 'modelx/models/abstracts/VehiclePrivateApiModel';

@model.staticLabel('Sinistre')
@model.urnResource('damage')
@doc.path('/damages/{?id}')
export default class DamageModel extends Blamable(Timestampable(VehiclePrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'amount': number;
		'damageStatus': id;
		'damageStatus.reference': VehicleDamageStatusReference;
		'damageType': id;
		'damageType.reference': VehicleDamageTypeReference;
		'datetime[after]': string | Date;
		'datetime[before]': string | Date;
		'datetime[strictly_after]': string | Date;
		'datetime[strictly_before]': string | Date;
		'insuranceContract': id;
		'insuranceContract.contractReference': id;
		'vehicle': id;
		'vehicle.licensePlate': id;
		'vehicle.vehicleSubPartitions.ownerSubPartition.subPartitionUrn': string;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'amount': string;
		'datetime': string;
		'deductibleAmount': string;
		'driverUrn': string;
		'id': string;
		'location': string;
		'supplierUrn': string;
		'vatAmount': string;
	}> = {};

	@doc.number declare amount: number;
	@doc.string declare conditions: string;
	@doc.model(DamageStatusModel) declare damageStatus: DamageStatusModel;
	@doc.model(DamageTypeModel) declare damageType: DamageTypeModel;
	@doc.date declare datetime: Moment;
	@doc.number declare deductibleAmount: number;
	@doc.model(InsuranceContractModel) declare insuranceContract: InsuranceContractModel;
	@doc.string declare location: string;
	@doc.string declare shockPoints: string;
	@doc.number declare vatAmount: number;
	@doc.model(VehicleModel) declare vehicle: VehicleModel;

	@model.resolvableUrn({ attributeName: 'driverUrn' })
	public declare driver: StaffMemberModel | EmptyModel;

	@model.resolvableUrn({ attributeName: 'supplierUrn' })
	public declare supplier: SupplierCompanyModel | EmptyModel;
}