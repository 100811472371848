import doc                      from 'decorators/doc';
import model                    from 'decorators/model';
import { Timestampable }        from 'helpers/traits';
import DirectoryPrivateApiModel from 'modelx/models/abstracts/DirectoryPrivateApiModel';

@model.cacheDuration()
@model.staticLabel('GlobalContactConnectionStatus')
@model.urnResource('global_contact_connection_status')
@doc.path('/global_contact_connection_statuses/{?id}')
export default class GlobalContactConnectionStatusModel extends Timestampable(DirectoryPrivateApiModel) {
	public _filters: ModelFiltersExtended<{
		'reference': DirectoryGlobalContactConnectionStatusReference;
	}> = {};

	public _sorts: ModelSortsExtended<unknown> = {};

	@doc.string declare label: string;
	@doc.string declare reference: DirectoryGlobalContactConnectionStatusReference;
}