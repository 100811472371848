import OperationModel              from 'Models/intervention/OperationModel';
import RiskAssessmentModel         from 'Models/intervention/RiskAssessmentModel';
import TaskModel                   from 'Models/intervention/TaskModel';
import TaskOperationStatusModel    from 'Models/intervention/TaskOperationStatusModel';
import TaskZoneModel               from 'Models/intervention/TaskZoneModel';
import SourceModel                 from 'Models/invoice/SourceModel';
import doc                         from 'decorators/doc';
import model                       from 'decorators/model';
import nestedResolvable            from 'decorators/nestedResolvable';
import { Timestampable }           from 'helpers/traits';
import { Blamable }                from 'helpers/traits';
import { computed }                from 'mobx';
import InterventionPrivateApiModel from 'modelx/models/abstracts/InterventionPrivateApiModel';
import moment                      from 'moment';

type InterventionTaskMetaReference = TaskMetaReference;
type InterventionTaskOperationStatusReference = TaskOperationStatusReference;

@model.staticLabel('Prestation', false)
@model.urnResource('task_operation')
@doc.path('/task_operations/{?id}')
export default class TaskOperationModel extends Blamable(Timestampable(InterventionPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'createdAt[after]': string;
		'createdAt[before]': string;
		'createdAt[strictly_after]': string;
		'createdAt[strictly_before]': string;
		'exists[mainTaskOperationExtras]': '0' | '1';
		'exists[taskOperationExtras]': '0' | '1';
		'mainTaskOperationExtras': id;
		'mainTaskOperationExtras.mainTaskOperation': id;
		'mainTaskOperationExtras.mainTaskOperation.task.taskMetas': id;
		'mainTaskOperationExtras.mainTaskOperation.task.taskMetas.reference': InterventionTaskMetaReference;
		'mainTaskOperationExtras.mainTaskOperation.task.taskMetas.value': id;
		'operation': id;
		'operation.constraintGroups.constraintGroupProperties.property': id;
		'operation.constraintGroups.constraintGroupProperties.property.propertyType': id;
		'operation.operationCategory': id;
		'operation.operationType': id;
		'scheduleEndDate[after]': string | Date;
		'scheduleEndDate[before]': string | Date;
		'scheduleEndDate[strictly_after]': string | Date;
		'scheduleEndDate[strictly_before]': string | Date;
		'scheduleStartDate[after]': string | Date;
		'scheduleStartDate[before]': string | Date;
		'scheduleStartDate[strictly_after]': string | Date;
		'scheduleStartDate[strictly_before]': string | Date;
		'sourceUrn': Urn;
		'tags[serviceUrn][tagReference]': id;
		'task': id;
		'task.owner.clientUrn': Urn;
		'task.owner.enterprisePartitionUrn': Urn;
		'task.owner.enterpriseUrn': Urn;
		'task.owner.ownerSubPartition': id;
		'task.owner.ownerSubPartition.partitionUrn': Urn;
		'task.owner.ownerSubPartition.subPartitionUrn': Urn;
		'task.taskGroup': id;
		'task.taskMetas': id;
		'task.taskMetas.reference': InterventionTaskMetaReference;
		'task.taskMetas.value': id;
		'taskOperationExtras': id;
		'taskOperationExtras.extraTaskOperation': id;
		'taskOperationStatus': id;
		'taskOperationStatus.reference': InterventionTaskOperationStatusReference;
		'taskZone': id;
		'taskZone.city': string;
		'taskZone.tags[serviceUrn][tagReference]': id;
		'taskZone.zipCode': string;
		'updatedAt[after]': string | Date;
		'updatedAt[before]': string | Date;
		'updatedAt[strictly_after]': string | Date;
		'updatedAt[strictly_before]': string | Date;
		'value[between]': string;
		'value[gt]': string;
		'value[gte]': string;
		'value[lt]': string;
		'value[lte]': string;
		'wantProperty': string;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'scheduleEndDate': string;
		'scheduleStartDate': string;
		'task.title': string;
		'taskZone.city': string;
		'taskZone.name': string;
		'taskZone.zipCode': string;
		'updatedAt': string;
	}> = {};

	@doc.string declare label: string;
	@doc.string declare note: string;
	@doc.model(OperationModel) declare operation: OperationModel;
	@doc.model(RiskAssessmentModel) declare riskAssessment: RiskAssessmentModel;
	@doc.date declare scheduleEndDate: Moment;
	@doc.date declare scheduleStartDate: Moment;
	@doc.modelUrn(SourceModel) declare source: SourceModel;
	@doc.model(TaskModel) declare task: TaskModel;
	@doc.model(TaskOperationStatusModel) declare taskOperationStatus: TaskOperationStatusModel;
	@doc.model(TaskZoneModel) declare taskZone: TaskZoneModel;
	@doc.number declare value: number;

	public get operationId(): id {
		return this.get('operation.id', 0);
	}

	public get taskZoneId(): id {
		return this.get('taskZone.id', 0);
	}

	public get taskId(): id {
		return this.get('task.id', 0);
	}

	public get taskOperationStatusId(): id {
		return this.get('taskOperationStatus.id', 0);
	}

	@computed
	public get isLate() {
		return moment(this.scheduleStartDate).isBefore();
	}

	@nestedResolvable()
	public get operationType() {
		return this.operation.operationType;
	}

	@nestedResolvable()
	public get operationCategory() {
		return this.operation.operationCategory;
	}

	@nestedResolvable()
	public get company() {
		return this.taskZone.company;
	}

	@nestedResolvable()
	public get owner() {
		return this.taskZone.owner;
	}

	/**
	 * Permet de savoir s'il est possible de planifier une intervention pour cette TaskOperation
	 */
	public get isDisabledAddIntervention() {
		const statusForDisabled: TaskOperationStatusReference[] = ['cancelled'];
		return statusForDisabled.includes(this.taskOperationStatus.reference);
	}
}