import axios                   from 'axios';
import { authenticationStore } from 'stores';
import downloadFile            from 'tools/downloadFile';
import { notificationError }   from 'tools/notification';

export default async (url: string, name?: string) => {
	try {
		const token = authenticationStore.session.token;
		const response = await axios.get(
			url,
			{ headers: { 'X-Platform-Authorization': `Bearer ${token}` }, responseType: 'blob' },
		);

		const fileName = name || 'file';

		downloadFile(response.data, fileName);
	} catch (error) {
		notificationError({ message: `Une erreur s'est produite` });
	}
};