export const importAllModels = async () => {
	const modules = import.meta.glob([
		// '../modelx/models/private/*',
		// '../modelx/models/private/*/*',
		// '../modelx/models/public/*',
		// '../modelx/models/public/*/*',

		// Fix pour les reverseResolvables
		'../modelx/models/private/sales/QuotationFrameworkAgreementIterationQuotationOptionModel.ts',
		'../modelx/models/private/sales/QuotationRequestFrameworkAgreementIterationQuotationOptionModel.ts',
		'../modelx/models/private/supplier/ValidationThresholdModel.ts',
		'../modelx/models/private/vehicle/VehicleSubPartitionAssignmentModel.ts',
		'../modelx/models/private/vehicle/FundingVehicleModel.ts',
		'../modelx/models/private/vehicle/VehicleMileageModel.ts',
	]);

	await Promise.all(Object.values(modules).map(module => module()));
};
