import { resolvable }         from '@mathquis/modelx-resolvables';
import InvoiceModel           from 'Models/invoice/InvoiceModel';
import ItemDisplayModeModel   from 'Models/invoice/ItemDisplayModeModel';
import { urn }                from 'helpers/ModelDictionary';
import { Blamable }           from 'helpers/traits';
import { Timestampable }      from 'helpers/traits';
import { computed }           from 'mobx';
import InvoicePrivateApiModel from '../../abstracts/InvoicePrivateApiModel';

@urn('$', 'invoice', 'invoice_item_group')
export default class InvoiceItemGroupModel extends Blamable(Timestampable(InvoicePrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'invoice': id;
	}> = {};

	static get path(): string {
		return '/invoice_item_groups/{?id}';
	}

	@computed
	public get invoiceId(): id {
		return this.get('invoice.id');
	}

	public get description(): string {
		return this.get('description', '');
	}

	public get label(): string {
		return this.get('label', '');
	}

	@resolvable(InvoiceModel, { attributeName: 'invoice' })
	declare invoice: InvoiceModel;

	@resolvable(ItemDisplayModeModel, { attributeName: 'itemDisplayMode' })
	declare itemDisplayMode: ItemDisplayModeModel;
}
