import { computed }                    from 'mobx';
import moment                          from 'moment';
import InterventionPrivateApiModel     from '../../abstracts/InterventionPrivateApiModel';
import TaskOperationModel              from './TaskOperationModel';

export interface IConstraintCheck {
	constraintUrn: string;
	error: string | null;
	isOk: boolean;
	required: boolean;
}

export interface IResourceConstraintCheck extends IConstraintCheck {
	resourceUrn: string;
}

export interface IOperationConstraintCheck extends IConstraintCheck {
	taskOperationUrn: string;
}

class ConstraintCheckerModel extends InterventionPrivateApiModel {
	static get path(): string {
		return '/constraint_checkers/{?id}';
	}

	@computed
	public get availabilityConstraintUrns(): string[] {
		return this.availabilityConstraintChecks.map(c => c.resourceUrn || '');
	}

	@computed
	public get assignmentConstraintUrns(): string[] {
		return this.assignmentConstraintChecks.map(c => c.resourceUrn || '');
	}

	public getConstraintChecksForConstraintUrn(constraintUrn: string): IConstraintCheck[] {
		return this.allConstraintChecks.filter(c => c.constraintUrn === constraintUrn);
	}

	public getConstraintChecksForConstraintUrns(constraintUrns: string[]): IConstraintCheck[] {
		return this.allConstraintChecks.filter(c => constraintUrns.includes(c.constraintUrn));
	}

	public getConstraintChecksForResourceUrn(resourceUrn: string): IResourceConstraintCheck[] {
		return this.resourceConstraintChecks.filter(c => c.resourceUrn === resourceUrn);
	}

	@computed
	public get availabilityConstraintChecks(): IResourceConstraintCheck[] {
		return this.get('constraintChecks.availabilityConstraints', []);
	}

	@computed
	public get assignmentConstraintChecks(): IResourceConstraintCheck[] {
		return this.get('constraintChecks.assignmentConstraints', []);
	}

	@computed
	public get scheduleConstraintChecks(): IResourceConstraintCheck[] {
		return this.get('constraintChecks.scheduleConstraints', []);
	}

	@computed
	public get operationConstraintChecks(): IOperationConstraintCheck[] {
		return this.get('constraintChecks.operationConstraints', []);
	}

	@computed
	public get resourceConstraintChecks(): IResourceConstraintCheck[] {
		return [...this.availabilityConstraintChecks, ...this.assignmentConstraintChecks];
	}

	@computed
	public get allConstraintChecks(): IConstraintCheck[] {
		return [
			...this.resourceConstraintChecks,
			...this.operationConstraintChecks,
			...this.scheduleConstraintChecks
		];
	}

	@computed
	public get scheduleStartDate(): Moment {
		return this.get('scheduleStartDate', moment());
	}

	@computed
	public get scheduleEndDate(): Moment {
		return this.get('scheduleEndDate', moment());
	}

	@computed
	public get taskOperations(): TaskOperationModel[] {
		return this.get('taskOperations', []).map(object => {
			return new TaskOperationModel(object);
		});
	}

}

export default ConstraintCheckerModel;
