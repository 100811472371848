import doc                      from 'decorators/doc';
import model                    from 'decorators/model';
import { Timestampable }        from 'helpers/traits';
import PartitionPrivateApiModel from 'modelx/models/abstracts/PartitionPrivateApiModel';

@model.cacheDuration()
@model.staticLabel('SubPartitionStatus')
@model.urnResource('sub_partition_status')
@doc.path('/sub_partition_statuses/{?id}')
export default class SubPartitionStatusModel extends Timestampable(PartitionPrivateApiModel) {
	public _filters: ModelFiltersExtended<unknown> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
	}> = {};

	@doc.string declare label: string;
	@doc.string declare reference: PartitionSubPartitionStatusReference;
}