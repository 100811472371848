import FileModel               from 'Models/file/FileModel';
import StaffMemberModel        from 'Models/rh/StaffMemberModel';
import ContractIterationModel  from 'Models/sales/ContractIterationModel';
import QuotationModel          from 'Models/sales/QuotationModel';
import AllocationRequestModel  from 'Models/supplier/AllocationRequestModel';
import OrderGroupModel         from 'Models/supplier/OrderGroupModel';
import OrderPartStatusModel    from 'Models/supplier/OrderPartStatusModel';
import MaintenanceModel        from 'Models/vehicle/MaintenanceModel';
import doc                     from 'decorators/doc';
import model                   from 'decorators/model';
import { EmptyModel }          from 'decorators/resolvableUrn';
import { Timestampable }       from 'helpers/traits';
import { Blamable }            from 'helpers/traits';
import SupplierPrivateApiModel from '../../abstracts/SupplierPrivateApiModel';

@model.staticLabel('Commande', false)
@model.urnResource('order_part')
@doc.path('/order_parts/{?id}')
export default class OrderPartModel extends Blamable(Timestampable(SupplierPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'createdAt[after]': string;
		'createdAt[before]': string;
		'createdAt[strictly_after]': string;
		'createdAt[strictly_before]': string;
		'createdBy': id;
		'orderGroup': id;
		'orderGroup.hasInvoice': '0' | '1';
		'orderGroup.number': number;
		'orderGroup.orderGroupStatus': id;
		'orderGroup.orderGroupStatus.reference': SupplierOrderGroupStatusReference;
		'orderGroup.orderType': id;
		'orderGroup.orderType.reference': SupplierOrderTypeReference;
		'orderGroup.reference': SupplierOrderGroupReference;
		'orderGroup.sourceUrn': string;
		'orderGroup.supplierSubPartition': id;
		'orderGroup.supplierSubPartition.ownerSubPartition.subPartitionUrn': string;
		'orderGroup.supplierSubPartition.supplierCompany': id;
		'orderGroup.supplierSubPartition.supplierCompany.companyUrn': string;
		'orderPartStatus': id;
		'orderPartStatus.reference': SupplierOrderPartStatusReference;
		'partNumber': number;
		'periods': id;
		'periods.period': id;
		'reference': SupplierOrderPartReference;
		'sourceUrn': string;
		'totalExclTax[between]': string;
		'totalExclTax[gt]': string;
		'totalExclTax[gte]': string;
		'totalExclTax[lt]': string;
		'totalExclTax[lte]': string;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'partNumber': string;
		'reference': string;
		'updatedAt': string;
	}> = {};

	@doc.model(OrderGroupModel) declare orderGroup: OrderGroupModel;
	@doc.model(OrderPartStatusModel) declare orderPartStatus: OrderPartStatusModel;
	@doc.number declare partNumber: number;
	@doc.string declare reference: SupplierOrderPartReference;
	@doc.number declare temporaryTotalExclTax: number;
	@doc.number declare totalExclTax: number;

	@model.reverseResolvable<AllocationRequestModel>({
		singleRequest: false,
		sortWay: 'desc',
		urn: '$:supplier:allocation_request',
	})
	public declare lastAllocationRequest: AllocationRequestModel;

	@model.nestedResolvable()
	public get lastAllocationRequestStatus() {
		return this.lastAllocationRequest.allocationRequestStatus;
	}

	@model.resolvableUrn({ attributeName: 'sourceUrn' })
	public declare source: MaintenanceModel | QuotationModel | ContractIterationModel;

	@model.resolvableUrn({ attributeName: 'validatorUrn' })
	public declare validator: StaffMemberModel | EmptyModel;

	@model.fileResolvable('order_form_pdf')
	public declare file: FileModel;
}
