import { computed }                from 'mobx';
import moment                      from 'moment';
import InterventionPrivateApiModel from '../../abstracts/InterventionPrivateApiModel';

export default class TaskScheduleConstraintModel extends InterventionPrivateApiModel {

	public _filters: ModelFiltersExtended<{
		task: id,
		'task.taskOperations': id,
	}> = {};

	static get path(): string {
		return '/task_schedule_constraints/{?id}';
	}

	@computed
	public get startTime(): string {
		return this.get('startTime', '');
	}

	@computed
	public get dayName(): string {
		return moment().day(this.weekDay).format('dddd');
	}

	@computed
	public get startTimeFormatted(): string {
		return moment(this.startTime, 'hh:mm:ss').format('LT');
	}

	@computed
	public get endTime(): string {
		return this.get('endTime', '');
	}

	@computed
	public get endTimeFormatted(): string {
		return moment(this.endTime, 'hh:mm:ss').format('LT');
	}

	@computed
	public get weekDay(): number {
		return this.get('weekday', 0);
	}

	@computed
	public get momentWeekDay(): number {
		if (this.weekDay === 0) {
			return 6;
		}

		return this.weekDay - 1;
	}

	@computed
	public get fullDateFormatted(): string {
		return `le ${this.dayName} de ${this.startTimeFormatted} à ${this.endTimeFormatted}`;
	}

	@computed
	public get taskId(): id {
		return this.get('task.id', 0);
	}

	public _sorts: ModelSortsExtended<{
		'id': string;
		'updatedAt': string;
	}> = {};
}
