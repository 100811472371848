import lazyWithRetry from 'tools/lazyWithRetry';

const QuotationItemAddPage = lazyWithRetry(() => import(
	/* webpackChunkName: "quotationItemAddPage" */
	'../../pages/QuotationItem/QuotationItemAddPage/QuotationItemAddPage'),
);

export default [
	{
		component: QuotationItemAddPage,
		exact: true,
		path: '/quotation_items/add/:quotationId',
	},
];
