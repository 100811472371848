import lazyWithRetry from 'tools/lazyWithRetry';

const ExportPage = lazyWithRetry(
	() =>
		import(
			/* webpackChunkName: "exportPage" */
			'../../pages/Export/ExportPage/ExportPage'),
);

export default [
	{
		component: ExportPage,
		exact: true,
		path: '/exports/:exportId',
	}
];
