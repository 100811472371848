import TaskZoneModel                      from 'Models/intervention/TaskZoneModel';
import DivisionalClassModel               from 'Models/invoice/DivisionalClassModel';
import InvoiceItemGroupModel              from 'Models/invoice/InvoiceItemGroupModel';
import VatModel                           from 'Models/invoice/VatModel';
import ContractIterationItemActivityModel from 'Models/sales/ContractIterationItemActivityModel';
import QuotationItemActivityModel         from 'Models/sales/QuotationItemActivityModel';
import doc                                from 'decorators/doc';
import model                              from 'decorators/model';
import { Timestampable }                  from 'helpers/traits';
import { Blamable }                       from 'helpers/traits';
import { computed }                       from 'mobx';
import InvoicePrivateApiModel             from 'modelx/models/abstracts/InvoicePrivateApiModel';

@model.staticLabel('InvoiceItem')
@model.urnResource('invoice_item')
@doc.path('/invoice_items/{?id}')
export default class InvoiceItemModel extends Blamable(Timestampable(InvoicePrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'accountingAnalyticCodeEntity': id;
		'invoiceItemGroup.invoice': id;
		'invoiceItemGroup.invoice.billingGroup.billingOwner.ownerSubPartition': id;
		'invoiceItemGroup.invoice.billingGroup.billingOwner.ownerSubPartition.subPartitionUrn': Urn;
		'invoiceItemGroup.invoice.billingPeriod': id;
		'invoiceItemGroup.invoice.billingPeriod.partitionUrn': Urn;
		'invoiceItemGroup.invoice.date': string | Date;
		'invoiceItemGroup.invoice.date[after]': string | Date;
		'invoiceItemGroup.invoice.date[before]': string | Date;
		'invoiceItemGroup.invoice.date[strictly_after]': string | Date;
		'invoiceItemGroup.invoice.date[strictly_before]': string | Date;
		'invoiceItemGroup.invoiceItems.invoiceItemMetas': id;
		'invoiceItemGroup.invoiceItems.invoiceItemMetas.reference': InvoiceInvoiceItemMetaReference;
		'invoiceItemGroup.invoiceItems.invoiceItemMetas.value': id;
		'invoiceItemGroup.invoiceItems.locationUrn': Urn;
		'sourceUrn': Urn;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'label': string;
		'priceExclTax': string;
		'quantity': string;
	}> = {};

	@doc.string declare accountingCode: string;
	@doc.string declare accountingVatCode: string;
	@doc.string declare description: string;
	@doc.model(DivisionalClassModel) declare divisionalClass: DivisionalClassModel;
	@doc.model(InvoiceItemGroupModel) declare invoiceItemGroup: InvoiceItemGroupModel;
	@doc.string declare label: string;
	@doc.string declare note: string;
	@doc.number declare priceExclTax: number;
	@doc.number declare priceInclTax: number;
	@doc.number declare priceVat: number;
	@doc.string declare quantity: string;
	@doc.number declare totalPriceExclTax: number;
	@doc.number declare totalPriceInclTax: number;
	@doc.number declare totalPriceVat: number;
	@doc.string declare ttcVatCode: string;
	@doc.model(VatModel) declare vat: VatModel;
	@doc.string declare vatRate: string;

	@model.resolvableUrn({ attributeName: 'sourceUrn' })
	public declare source: ContractIterationItemActivityModel | QuotationItemActivityModel | null;

	@model.resolvable(TaskZoneModel, { attributeName: 'locationUrn' })
	declare location: TaskZoneModel;

	@computed
	public get accountingCodeIri(): string {
		return this.get('accountingCodeEntity.@id');
	}

	@computed
	public get accountingAnalyticCodeIri(): string {
		return this.get('accountingAnalyticCodeEntity.@id');
	}
}
