import Button from 'antd/lib/button';
import Col    from 'antd/lib/grid/col';
import Row    from 'antd/lib/grid/row';
import React  from 'react';

import SidebarWrapper, { ISidebarWrapperProps } from './SidebarWrapper';

export interface ISidebarFilterWrapperProps extends ISidebarWrapperProps {
	loading?: boolean;
	onReset: () => void;
	onSubmit?: () => void;
}

export default class SidebarFilterWrapper extends SidebarWrapper<React.PropsWithChildren<ISidebarFilterWrapperProps>> {

	static defaultProps = {
		renderTitle: () => 'Filtres',
	};

	protected _renderHead = () => {
		const { loading, onReset, onSubmit } = this.props;

		return (
			<Row gutter={[16, 16]}>
				<Col span={12}>
					<Button
						block
						disabled={loading}
						htmlType="submit"
						loading={loading}
						onClick={onSubmit}
						type="primary"
					>
						Appliquer
					</Button>
				</Col>

				<Col span={12}>
					<Button block disabled={loading} loading={loading} onClick={onReset}>
						Réinitialiser
					</Button>
				</Col>
			</Row>
		);
	};
}