import { resolvable }              from '@mathquis/modelx-resolvables';
import ClientModel                 from 'Models/directory/ClientModel';
import ClientPartitionModel        from 'Models/directory/ClientPartitionModel';
import CompanyModel                from 'Models/directory/CompanyModel';
import EnterpriseModel             from 'Models/directory/EnterpriseModel';
import EnterprisePartitionModel    from 'Models/directory/EnterprisePartitionModel';
import OwnerSubPartitionModel      from 'Models/intervention/OwnerSubPartitionModel';
import PartitionModel              from 'Models/partition/PartitionModel';
import SubPartitionModel           from 'Models/partition/SubPartitionModel';
import { urn }                     from 'helpers/ModelDictionary';
import { Timestampable }           from 'helpers/traits';
import { Blamable }                from 'helpers/traits';
import InterventionPrivateApiModel from '../../abstracts/InterventionPrivateApiModel';

@urn('$', 'intervention', 'owner')
export default class OwnerModel extends Blamable(Timestampable(InterventionPrivateApiModel)) {

	static get path(): string {
		return '/owners/{?id}';
	}

	public _filters: ModelFiltersExtended<{
		'clientUrn': string;
		'companyUrn': string;
		'enterprisePartitionUrn': string;
		'enterpriseUrn': string;
	}> = {};

	public get clientId() {
		return this.client.id;
	}

	public get companyId() {
		return this.company.id;
	}

	public get clientUrn() {
		return this.client.urn;
	}

	@resolvable(ClientModel, { attributeName: 'clientUrn' })
	declare client: ClientModel;

	@resolvable(ClientPartitionModel, { attributeName: 'clientPartitionUrn' })
	declare clientPartition: ClientPartitionModel;

	@resolvable(CompanyModel, { attributeName: 'companyUrn' })
	declare company: CompanyModel;

	@resolvable(EnterpriseModel, { attributeName: 'enterpriseUrn' })
	declare enterprise: EnterpriseModel;

	@resolvable(EnterprisePartitionModel, { attributeName: 'enterprisePartitionUrn' })
	declare enterprisePartition: EnterprisePartitionModel;

	@resolvable(OwnerSubPartitionModel, { attributeName: 'ownerSubPartition' })
	declare ownerSubPartition: OwnerSubPartitionModel;

	@resolvable(PartitionModel, { attributeName: 'partitionUrn', cache: true })
	declare partition: PartitionModel;

	@resolvable(SubPartitionModel, { attributeName: 'subPartitionUrn', cache: true })
	declare subPartition: SubPartitionModel;
}