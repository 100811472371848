import { resolvable }               from '@mathquis/modelx-resolvables';
import InvoicingGroupEmailTypeModel from 'Models/invoice/InvoicingGroupEmailTypeModel';
import InvoicingGroupModel          from 'Models/invoice/InvoicingGroupModel';
import { urn }                      from 'helpers/ModelDictionary';
import { Blamable }                 from 'helpers/traits';
import { Timestampable }            from 'helpers/traits';
import { computed }                 from 'mobx';
import InvoicePrivateApiModel       from '../../abstracts/InvoicePrivateApiModel';

@urn('$', 'invoice', 'invoicing_group_email')
export default class InvoicingGroupEmailModel extends Blamable(Timestampable(InvoicePrivateApiModel)) {
	static get path(): string {
		return '/invoicing_group_emails/{?id}';
	}

	public _filters: ModelFiltersExtended<{
		'invoicingGroup': id;
		'invoicingGroup.invoicingOwner': id;
		'invoicingGroup.invoicingOwner.enterpriseUrn': string;
		'invoicingGroupEmailType': id;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'position': string;
	}> = {};

	@computed
	public get email(): string {
		return this.get('email', '');
	}

	@computed
	public get groupId(): id {
		return this.get('invoicingGroup.id', '');
	}

	@computed
	public get typeId(): id {
		return this.get('invoicingGroupEmailType.id', '');
	}

	@resolvable(InvoicingGroupModel, { attributeName: 'invoicingGroup' })
	declare invoicingGroup: InvoicingGroupModel;

	@resolvable(InvoicingGroupEmailTypeModel, { attributeName: 'invoicingGroupEmailType', cache: true })
	declare invoicingGroupEmailType: InvoicingGroupEmailTypeModel;

	public get position(): number {
		return this.get('position', 0);
	}
}
