import doc                         from 'decorators/doc';
import model                       from 'decorators/model';
import { Timestampable }           from 'helpers/traits';
import { Blamable }                from 'helpers/traits';
import InterventionPrivateApiModel from 'modelx/models/abstracts/InterventionPrivateApiModel';
import TaskOperationModel          from './TaskOperationModel';

const customDoc = doc;

@model.staticLabel('Retour')
@model.urnResource('task_operation_extra')
@doc.path('/task_operation_extras/{?id}')
export default class TaskOperationExtraModel extends Blamable(Timestampable(InterventionPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'extraTaskOperation': id;
		'extraTaskOperation.task': id;
		'extraTaskOperation.taskZone': id;
		'mainTaskOperation': id;
		'mainTaskOperation.interventionOperations': id;
		'mainTaskOperation.interventionOperations.intervention': id;
		'mainTaskOperation.task': id;
		'mainTaskOperation.task.owner': id;
		'mainTaskOperation.task.owner.clientUrn': Urn;
		'mainTaskOperation.task.owner.enterprisePartitionUrn': Urn;
		'mainTaskOperation.task.owner.enterpriseUrn': Urn;
		'mainTaskOperation.task.owner.ownerSubPartition': id;
		'mainTaskOperation.task.owner.ownerSubPartition.partitionUrn': Urn;
		'mainTaskOperation.task.owner.ownerSubPartition.subPartitionUrn': Urn;
		'mainTaskOperation.task.taskGroup': id;
		'mainTaskOperation.task.taskMetas': id;
		'mainTaskOperation.task.taskMetas.reference': TaskMetaReference;
		'mainTaskOperation.task.taskMetas.value': id;
		'mainTaskOperation.taskZone': id;
	}> = {};

	public _sorts: ModelSortsExtended<unknown> = {};

	@doc.string declare note: string;

	@customDoc.model(TaskOperationModel) declare mainTaskOperation: TaskOperationModel;
	@customDoc.model(TaskOperationModel) declare extraTaskOperation: TaskOperationModel;
}