import InterventionModel       from 'Models/intervention/InterventionModel';
import axios                   from 'axios';
import Proto                   from 'proto/proto';
import Protobuf                from 'protobufjs/minimal';
import { authenticationStore } from 'stores';
import ConfigProxy             from 'tools/ConfigProxy';

const { PlanningInterventionCard } = Proto.Service.Intervention;

export default async function fetchPlanningCards(params: ModelFilters<InterventionModel>) {
	const st = (new Date()).getTime();

	const instance = axios.create({
		baseURL: ConfigProxy.getServiceConfig('intervention', 'api_endpoint'),
		responseType: 'arraybuffer',
	});

	instance.interceptors.request.use(async (config) => {
		if (authenticationStore.session.isExpired) {
			await authenticationStore.refreshTokenAsync();
		}

		config.headers['X-Platform-Authorization'] = 'Bearer ' + authenticationStore.session.token;

		return config;
	});

	const { data } = await instance.get('/interventions/_planning_cards', { params });

	const stp = (new Date()).getTime();
	const reader = Protobuf.Reader.create(new Uint8Array(data));

	const interventionCards: Proto.Service.Intervention.PlanningInterventionCard[] = [];

	while (reader.pos < reader.len) {
		interventionCards.push(PlanningInterventionCard.decodeDelimited(reader));
	}

	const et = (new Date()).getTime();
	const totalTime = (et - st);
	const parseTime = (et - stp);

	console.log('Loaded %d protos in %fms (parsing: %fms)', interventionCards.length, totalTime, parseTime);

	return interventionCards;
}