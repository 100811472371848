import AccountingAnalyticCategoryModel    from 'Models/invoice/AccountingAnalyticCategoryModel';
import AccountingAnalyticSubCategoryModel from 'Models/invoice/AccountingAnalyticSubCategoryModel';
import doc                                from 'decorators/doc';
import model                              from 'decorators/model';
import { Timestampable }                  from 'helpers/traits';
import { Blamable }                       from 'helpers/traits';
import InvoicePrivateApiModel             from 'modelx/models/abstracts/InvoicePrivateApiModel';

@model.staticLabel('Axe analytique')
@model.urnResource('accounting_analytic_code')
@doc.path('/accounting_analytic_codes/{?id}')
export default class AccountingAnalyticCodeModel extends Blamable(Timestampable(InvoicePrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'accountingAnalyticCategory': id;
		'accountingAnalyticCategory.code': string;
		'accountingAnalyticSubCategory': id;
		'accountingAnalyticSubCategory.code': string;
		'createdAt[after]': string;
		'createdAt[before]': string;
		'createdAt[strictly_after]': string;
		'createdAt[strictly_before]': string;
		'enabled': '0' | '1';
		'updatedAt[after]': string | Date;
		'updatedAt[before]': string | Date;
		'updatedAt[strictly_after]': string | Date;
		'updatedAt[strictly_before]': string | Date;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'accountingAnalyticCategory.code': string;
		'accountingAnalyticSubCategory.code': string;
		'id': string;
		'updatedAt': string;
	}> = {};

	@doc.model(AccountingAnalyticCategoryModel) declare accountingAnalyticCategory: AccountingAnalyticCategoryModel;
	@doc.model(AccountingAnalyticSubCategoryModel) declare accountingAnalyticSubCategory: AccountingAnalyticSubCategoryModel;
	@doc.boolean declare enabled: boolean;
}