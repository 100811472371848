import InvoiceModel           from 'Models/invoice/InvoiceModel';
import doc from 'decorators/doc';
import model from 'decorators/model';
import { Blamable }           from 'helpers/traits';
import { Timestampable }      from 'helpers/traits';
import { computed }           from 'mobx';
import InvoicePrivateApiModel from '../../abstracts/InvoicePrivateApiModel';

@model.staticLabel('InvoiceRecovery')
@model.urnResource('invoice_recovery')
@doc.path('/invoice_recoveries/{?id}')
export default class InvoiceRecoveryModel extends Blamable(Timestampable(InvoicePrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'forwardedToLawyer': boolean;
		'invoice': id;
		'invoice.invoiceStatus': id;
		'invoice.invoiceStatus.reference': InvoiceStatusReference;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'updatedAt': string;
	}> = {};

	@doc.boolean declare forwardedToLawyer:boolean;
	@doc.model(InvoiceModel) declare invoice: InvoiceModel;

	@computed
	public get lastLogId(): id {
		return this.get('lastLog.id');
	}
}