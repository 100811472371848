import Select          from 'antd/lib/select';
import { SelectProps } from 'antd/lib/select';
import Spin            from 'antd/lib/spin';
import _debounce       from 'lodash/debounce';
import _omit           from 'lodash/omit';
import { observer }    from 'mobx-react';
import React           from 'react';

export interface ISelectLoadMoreProps<VT> extends SelectProps<VT> {
	isFailed?: boolean;
	isLoading: boolean;
	maxCount?: number;
	onLoadMore: (search: string) => void;
	searchOnFirstOpen?: boolean;
	searchOnMount?: boolean;
}

@observer
export default class SelectLoadMore extends React.Component<ISelectLoadMoreProps<never>> {
	private _hasBeenOpened = false;
	private _lastSearch = '';

	private _onSearch = _debounce(s => {
		if (this.props.onSearch) {
			return this.props.onSearch(s);
		}
	}, 500);

	private _target;

	public async componentDidMount() {
		if (this.props.searchOnMount || this.props.defaultOpen) {
			await this._fetchAsync('');
		}
	}

	public render() {
		const { isLoading, showSearch } = this.props;

		return (
			<Select
				notFoundContent={isLoading ? <Spin size="small" /> : null}

				{..._omit(this.props, [
					'searchOnFirstOpen',
					'searchOnMount',
					'isFailed',
					'isLoading',
					'onLoadMore',
					'useUrn',
				])}
				onDropdownVisibleChange={this._onDropdownVisibleChange}
				onPopupScroll={this._onScroll}
				onSearch={(typeof showSearch === 'undefined' || showSearch) ? this._onSearch : undefined}
			>
				{this._renderOptions()}
			</Select>
		);
	}

	private _fetchAsync = (search: string) => {
		if (this.props.onLoadMore) {
			this._lastSearch = search;

			return this.props.onLoadMore(search);
		}
	};

	private _onDropdownVisibleChange = async (open: boolean) => {
		const { onDropdownVisibleChange, searchOnFirstOpen } = this.props;

		if (onDropdownVisibleChange) {
			onDropdownVisibleChange(open);
		}

		if (open && !this._hasBeenOpened) {
			this._hasBeenOpened = true;

			if (searchOnFirstOpen) {
				await this._fetchAsync('');
			}
		}
	};

	private _onScroll = (event) => {
		const { isLoading, onLoadMore } = this.props;

		this._target = event.target;

		const scrollPosition = Math.ceil(this._target.scrollTop + this._target.offsetHeight);

		if (!isLoading && scrollPosition >= this._target.scrollHeight) {
			onLoadMore(this._lastSearch);
		}
	};

	private _renderOptions = () => {
		const { children, isFailed, isLoading } = this.props;

		if (isFailed) {
			return [
				<Select.Option
					disabled={true}
					key="_error"
					style={{ color: 'red' }}
					value=""
				>
					Erreur lors de la récupération des informations
				</Select.Option>,
			];
		}

		const childrenArr = React.Children.toArray(children).filter(c => c['key'] !== '.$_empty');

		if (isLoading) {
			return [
				...childrenArr,

				<Select.Option disabled={true} key="loading" value="">
					<Spin size="small" style={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }} />
				</Select.Option>,
			];
		}

		return childrenArr;
	};
}