import doc                    from 'decorators/doc';
import model                  from 'decorators/model';
import { Blamable }           from 'helpers/traits';
import { Timestampable }      from 'helpers/traits';
import InvoicePrivateApiModel from '../../abstracts/InvoicePrivateApiModel';

@model.staticLabel('InvoiceTag')
@model.urn('$', 'invoice', 'invoice_tag')
@doc.path('/invoice_tags/{?id}')
export default class InvoiceTagModel extends Blamable(Timestampable(InvoicePrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'label': string;
		'reference': InvoiceTagReference;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'label': string;
		'reference': string;
	}> = {};

	@doc.string declare label: string;
	@doc.string declare reference: InvoiceTagReference;
}