import PartitionModel              from 'Models/partition/PartitionModel';
import doc                         from 'decorators/doc';
import model                       from 'decorators/model';
import { Timestampable }           from 'helpers/traits';
import { Blamable }                from 'helpers/traits';
import broomSvg                    from 'icons/broom.svg';
import sinkSvg                     from 'icons/sink.svg';
import spraySvg                    from 'icons/spray.svg';
import { computed }                from 'mobx';
import React                       from 'react';
import InterventionPrivateApiModel from '../../abstracts/InterventionPrivateApiModel';

@model.cacheDuration()
@model.staticLabel('Service')
@model.urnResource('operation_category')
@doc.path('/operation_categories/{?id}')
export default class OperationCategoryModel extends Blamable(Timestampable(InterventionPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'createdAt[after]': string;
		'createdAt[before]': string;
		'createdAt[strictly_after]': string;
		'createdAt[strictly_before]': string;
		'partitionUrn': string;
		'updatedAt[after]': string | Date;
		'updatedAt[before]': string | Date;
		'updatedAt[strictly_after]': string | Date;
		'updatedAt[strictly_before]': string | Date;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'label': string;
		'position': string;
		'updatedAt': string;
	}> = {};

	@doc.string declare label: string;
	@doc.modelUrn(PartitionModel) declare partition: PartitionModel;
	@doc.number declare position: number;

	@computed
	public get icon(): React.ReactNode {
		switch (this.label) {
			case 'Assainissement':
				return sinkSvg;
			case 'Dégraissage':
				return spraySvg;
			case 'Ramonage':
				return broomSvg;
		}

		return null;
	}
}