import { ApiCollection }            from 'Collections/ApiCollection';
import ClientModel                  from 'Models/directory/ClientModel';
import CompanyModel                 from 'Models/directory/CompanyModel';
import AccountingAnalyticCodeModel  from 'Models/invoice/AccountingAnalyticCodeModel';
import AccountingCodeModel          from 'Models/invoice/AccountingCodeModel';
import BillableItemMetaModel        from 'Models/invoice/BillableItemMetaModel';
import BillableItemModel            from 'Models/invoice/BillableItemModel';
import BillableMetaModel            from 'Models/invoice/BillableMetaModel';
import BillableModel                from 'Models/invoice/BillableModel';
import BillableStatusHistoryModel   from 'Models/invoice/BillableStatusHistoryModel';
import BillableStatusModel          from 'Models/invoice/BillableStatusModel';
import BillingGroupMetaModel        from 'Models/invoice/BillingGroupMetaModel';
import BillingGroupModel            from 'Models/invoice/BillingGroupModel';
import BillingOwnerModel            from 'Models/invoice/BillingOwnerModel';
import BillingPeriodModel           from 'Models/invoice/BillingPeriodModel';
import BillingPeriodStatusModel     from 'Models/invoice/BillingPeriodStatusModel';
import InvoiceItemModel             from 'Models/invoice/InvoiceItemModel';
import InvoiceModel                 from 'Models/invoice/InvoiceModel';
import InvoiceStatusModel           from 'Models/invoice/InvoiceStatusModel';
import InvoiceTypeModel             from 'Models/invoice/InvoiceTypeModel';
import InvoicingGroupModel          from 'Models/invoice/InvoicingGroupModel';
import VatModel                     from 'Models/invoice/VatModel';
import ContractIterationModel       from 'Models/sales/ContractIterationModel';
import ContractModel                from 'Models/sales/ContractModel';
import QuotationModel               from 'Models/sales/QuotationModel';
import { computed }                 from 'mobx';
import AbstractModelXStore          from 'stores/AbstractModelXStore';
import { getUniqueListForProperty } from 'tools/CollectionHelper';
import ConfigProxy                  from 'tools/ConfigProxy';
import { getIdFromUrn }             from 'tools/UrnTools';

export default class BillableDashboardStore extends AbstractModelXStore {
	public accountingAnalyticCodeCollection = new ApiCollection(AccountingAnalyticCodeModel);
	public accountingCodeCollection = new ApiCollection(AccountingCodeModel);
	public billable = new BillableModel();
	public billableItemCollection = new ApiCollection(BillableItemModel);
	public billableItemMetaCollection = new ApiCollection(BillableItemMetaModel);
	public billableMetaCollection = new ApiCollection(BillableMetaModel);
	public billableStatusCollection = new ApiCollection(BillableStatusModel);
	public billableStatusHistoryCollection = new ApiCollection(BillableStatusHistoryModel);
	public billingGroup = new BillingGroupModel();
	public billingGroupMetaCollection = new ApiCollection(BillingGroupMetaModel);
	public billingOwner = new BillingOwnerModel();
	public billingPeriodCollection = new ApiCollection(BillingPeriodModel);
	public billingPeriodStatusCollection = new ApiCollection(BillingPeriodStatusModel);
	public client = new ClientModel();
	public company = new CompanyModel();
	public contract = new ContractModel();
	public contractIteration = new ContractIterationModel();
	public invoiceCollection = new ApiCollection(InvoiceModel);

	public invoiceInvoiceCollection = this.invoiceCollection.createVirtualCollection(
		invoice => !!this.invoiceTypeInvoice && invoice.getId('invoiceType') === this.invoiceTypeInvoice.id
	);

	public invoiceRefundCollection = this.invoiceCollection.createVirtualCollection(
		invoice => !!this.invoiceTypeRefund && invoice.getId('invoiceType') === this.invoiceTypeRefund.id
	);

	public invoiceStatusCollection = new ApiCollection(InvoiceStatusModel);
	public invoiceTypeCollection = new ApiCollection(InvoiceTypeModel);
	public invoicingGroup = new InvoicingGroupModel();
	public quotation = new QuotationModel();
	public refundedInvoiceCollection = new ApiCollection(InvoiceModel);
	public refundedInvoiceItemCollection = new ApiCollection(InvoiceItemModel);
	public vatCollection = new ApiCollection(VatModel);

	public fetchBillableData = (billableId) => {
		this.billable.setId(billableId).fetch().then(() => {
			return Promise.all([
				this.billableItemCollection
					.setFilter('billable', billableId)
					.list(),

				this.billableItemMetaCollection
					.setFilter('billableItem.billable', billableId)
					.list(),

				this.billableMetaCollection
					.setFilter('billable', billableId)
					.list(),

				this.billableStatusHistoryCollection
					.setFilter('billable', billableId)
					.list(),

				this.billingGroup
					.setId(this.billable.getId('billingGroup'))
					.fetch()
					.then(() => {
						return Promise.all([
							this.billingOwner
								.set({ id: this.billingGroup.getId('billingOwner') })
								.fetch()
								.then(async () => {
									await Promise.all([
										this.client.setId(this.billingOwner.clientId).fetch(),
										this.company.setId(this.billingOwner.companyId).fetch(),
									]);
								}),

							this.invoicingGroup
								.setId(this.billingGroup.getId('invoicingGroup'))
								.fetch(),
						]);
					}),

				this.billingGroupMetaCollection
					.setFilters({
						billingGroup: this.billable.getId('billingGroup'),
						reference: ['contract_iteration_urn', 'quotation_urn'],
					})
					.list()
					.then(() => {
						const promises: Promise<unknown>[] = [];

						const firstBillingGroupMeta = this.billingGroupMetaCollection.first();
						if (firstBillingGroupMeta) {
							if (firstBillingGroupMeta.reference === 'contract_iteration_urn') {
								const contractIterationId = getIdFromUrn(firstBillingGroupMeta.value);
								promises.push(
									this.contractIteration
										.setId(contractIterationId)
										.fetch()
										.then(async () => {
											await this.contract
												.setId(this.contractIteration.contractId)
												.fetch();
										}),
								);

							} else {
								const quotationId = getIdFromUrn(firstBillingGroupMeta.value);
								promises.push(
									this.quotation
										.setId(quotationId)
										.fetch(),
								);
							}
						}

						promises.push(
							this.invoiceCollection
								.listByFromCollection(this.billingGroupMetaCollection, 'value', 'billingGroup.billingGroupMetas.value'),
						);

						return promises;
					}),

				this.refundedInvoiceCollection
					.setFilter('refundedByBillables.billable', billableId)
					.list()
					.then(() => {
						const invoicesIds = getUniqueListForProperty(this.refundedInvoiceCollection, 'id', value => value);

						if (invoicesIds.length < 1) {
							return;
						}

						return this.refundedInvoiceItemCollection
							.setFilter('invoiceItemGroup.invoice', invoicesIds)
							.list();
					}),
			]);
		});
	};

	public fetchConstantCollections = () => {
		this.accountingAnalyticCodeCollection.list();
		this.accountingCodeCollection.list();
		this.billableStatusCollection.list();
		this.billingPeriodCollection
			.setFilter('partitionUrn', ConfigProxy.get('PARTITION_URN'))
			.list();
		this.billingPeriodStatusCollection.list({ cache: 3600 });
		this.invoiceTypeCollection.list({ cache: 3600 });
		this.vatCollection.list();
	};

	@computed
	public get invoiceTypeInvoice() {
		return this.invoiceTypeCollection.findBy('reference', 'invoice');
	}

	@computed
	public get invoiceTypeRefund() {
		return this.invoiceTypeCollection.findBy('reference', 'refund');
	}

	@computed
	public get currentBillingPeriod() {
		const billingPeriodStatusOpen = this.billingPeriodStatusCollection
			.find(billingPeriodStatus => billingPeriodStatus.reference === 'open');

		if (!billingPeriodStatusOpen) {
			return new BillingPeriodModel();
		}

		const currentBillingPeriod = this.billingPeriodCollection
			.find(billingPeriod => billingPeriod.getIri('billingPeriodStatus') === billingPeriodStatusOpen.iri);

		if (!currentBillingPeriod) {
			return new BillingPeriodModel();
		}

		return currentBillingPeriod;
	}

	@computed
	public get invoiceType() {
		const invoiceType = this.invoiceTypeCollection.find(invoiceType => invoiceType.id === this.billable.getId('invoiceType'));

		if (!invoiceType) {
			return new InvoiceTypeModel();
		}

		return invoiceType;
	}

	@computed
	public get billableStatus() {
		const billableStatus = this.billableStatusCollection.find(bs => bs.id === this.billable.getId('billableStatus'));

		if (!billableStatus) {
			return new BillableStatusModel();
		}

		return billableStatus;
	}
}
