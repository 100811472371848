import doc from 'decorators/doc';
import model from 'decorators/model';
import { Timestampable } from 'helpers/traits';
import { Blamable } from 'helpers/traits';
import VehiclePrivateApiModel from '../../abstracts/VehiclePrivateApiModel';

@model.cacheDuration()
@model.staticLabel('Marque', false)
@model.urnResource('vehicle_brand')
@doc.path('/vehicle_brands/{?id}')
export default class VehicleBrandModel extends Blamable(Timestampable(VehiclePrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'reference': VehicleVehicleBrandReference;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'label': string;
	}> = {};

	@doc.string declare label: string;
	@doc.string declare reference: VehicleVehicleBrandReference;
}