import { LoggedApiConnector } from 'Connectors/LoggedApiConnector';
import ConfigProxy            from 'tools/ConfigProxy';
import VehicleApiModel        from './VehicleApiModel';

export const vehicleLoggedApiConnector = new LoggedApiConnector({
	baseURL: ConfigProxy.getServiceConfig('vehicle', 'api_endpoint'),
});

export default class VehiclePrivateApiModel extends VehicleApiModel {
	public static get connector() {
		return vehicleLoggedApiConnector;
	}
}
