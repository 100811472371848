import {
	Media,
}                             from 'helpers/traits';
import InvoicePrivateApiModel from '../../abstracts/InvoicePrivateApiModel';

export default class DebitMandatePreviewModel extends Media(InvoicePrivateApiModel) {

	static get path(): string {
		return '/debit_mandates/{id}/preview';
	}
}