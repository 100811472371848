import { ApiCollection }                      from 'Collections/ApiCollection';
import ContactModel                           from 'Models/directory/ContactModel';
import EnterpriseModel                        from 'Models/directory/EnterpriseModel';
import EnterprisePartitionModel               from 'Models/directory/EnterprisePartitionModel';
import FileModel                              from 'Models/file/FileModel';
import SourceModel                            from 'Models/file/SourceModel';
import TypeSourceModel                        from 'Models/file/TypeSourceModel';
import BillingGroupModel                      from 'Models/invoice/BillingGroupModel';
import ChannelNotificationModel               from 'Models/invoice/ChannelNotificationModel';
import ContactTypeModel                       from 'Models/invoice/ContactTypeModel';
import DebitMandateModel                      from 'Models/invoice/DebitMandateModel';
import DebitMandateStatusHistoryModel         from 'Models/invoice/DebitMandateStatusHistoryModel';
import DebitMandateStatusModel                from 'Models/invoice/DebitMandateStatusModel';
import InvoicingContactModel                  from 'Models/invoice/InvoicingContactModel';
import InvoicingGroupChannelNotificationModel from 'Models/invoice/InvoicingGroupChannelNotificationModel';
import InvoicingGroupEmailModel               from 'Models/invoice/InvoicingGroupEmailModel';
import InvoicingGroupEmailTypeModel           from 'Models/invoice/InvoicingGroupEmailTypeModel';
import InvoicingGroupModel                    from 'Models/invoice/InvoicingGroupModel';
import InvoicingOwnerModel                    from 'Models/invoice/InvoicingOwnerModel';
import EnterpriseConfigModel                  from 'Models/sales/EnterpriseConfigModel';
import AbstractModelXStore                    from 'stores/AbstractModelXStore';
import { enterpriseHierarchyStore }           from 'stores';

export default class EnterprisePartitionDashboardStore extends AbstractModelXStore {

	public billingGroupCollection = new ApiCollection(BillingGroupModel);

	public channelNotificationCollection = new ApiCollection(ChannelNotificationModel);

	public debitMandateCollection = new ApiCollection(DebitMandateModel);

	public debitMandateMandateFileCollection = new ApiCollection(FileModel);
	public debitMandateRibFileCollection = new ApiCollection(FileModel);
	public debitMandateSourceCollection = new ApiCollection(SourceModel);
	public debitMandateStatusCollection = new ApiCollection(DebitMandateStatusModel);
	public debitMandateStatusHistoryCollection = new ApiCollection(DebitMandateStatusHistoryModel);
	public debitMandateTypeSourceCollection = new ApiCollection(TypeSourceModel);

	public enterprise = new EnterpriseModel();
	public enterpriseConfig = new EnterpriseConfigModel();
	public invoicingContactCollection = new ApiCollection(InvoicingContactModel);
	public invoicingContactContactCollection = new ApiCollection(ContactModel);
	public invoicingContactTypeCollection = new ApiCollection(ContactTypeModel);
	public invoicingGroup = new InvoicingGroupModel();
	public invoicingGroupChannelNotificationCollection = new ApiCollection(InvoicingGroupChannelNotificationModel);
	public invoicingGroupCollection = new ApiCollection(InvoicingGroupModel);
	public invoicingGroupEmailCollection = new ApiCollection(InvoicingGroupEmailModel);
	public invoicingGroupEmailTypeCollection = new ApiCollection(InvoicingGroupEmailTypeModel);
	public invoicingOwner = new InvoicingOwnerModel();

	public fetchContactsCollection = async (enterprisePartition: EnterprisePartitionModel) => {
		await Promise.all([
			this.invoicingContactCollection
				.setSort('position')
				.setFilter('enterprisePartitionUrn', enterprisePartition.urn)
				.list()
				.then(async () => {
					await this.invoicingContactContactCollection
						.listByFromCollection(this.invoicingContactCollection, 'contactId', 'id');
				}),
		]);
	};

	public fetchDebitMandatesAsync = async () => {
		await this.debitMandateCollection
			// eslint-disable-next-line sort-keys
			.setSorts({ 'partitionUrn': true, 'invoicingGroup.id': true, 'debitMandateStatus.position': true, })
			.listBy([this.enterprise.urn], 'invoicingGroup.invoicingOwner.enterpriseUrn');

		await Promise.all([
			this.debitMandateMandateFileCollection
				.setFilters({
					'typeSource.type.reference': 'debit_mandate',
				})
				.listBy(this.debitMandateCollection.urns, 'typeSource.source.entityUrn'),

			this.debitMandateRibFileCollection
				.setFilters({
					'typeSource.type.reference': 'debit_mandate_rib',
				})
				.listBy(this.debitMandateCollection.urns, 'typeSource.source.entityUrn'),

			this.debitMandateTypeSourceCollection
				.listBy(this.debitMandateCollection.urns, 'source.entityUrn'),

			this.debitMandateSourceCollection
				.listBy(this.debitMandateCollection.urns, 'entityUrn'),

			this.debitMandateStatusCollection
				.list({ cache: 3600, }),

			this.debitMandateStatusHistoryCollection
				.listByFromCollection(this.debitMandateCollection, 'id', 'debitMandate'),
		]);
	};

	public fetchInvoicingGroupAsync = async () => {
		await this.invoicingGroupCollection
			.setFilter('invoicingOwner.enterpriseUrn', this.enterprise.urn)
			.setSort('position')
			.list();

		await Promise.all([
			this.billingGroupCollection
				.listByFromCollection(this.invoicingGroupCollection, 'id', 'invoicingGroup'),

			this.invoicingGroupEmailCollection
				.listByFromCollection(this.invoicingGroupCollection, 'id', 'invoicingGroup'),
		]);
	};

	public getDebitMandateMandateFiles = (debitMandate: DebitMandateModel) => {
		const sources = this.debitMandateSourceCollection.filterBy('entityUrn', debitMandate.urn);
		const typeSources = this.debitMandateTypeSourceCollection.filterBy('sourceId', sources.map(s => s.id));
		return this.debitMandateMandateFileCollection.filter(m => typeSources.map(ts => ts.urn).includes(m.getUrn('typeSource')));
	};

	public getDebitMandateRibFiles = (debitMandate: DebitMandateModel) => {
		const sources = this.debitMandateSourceCollection.filterBy('entityUrn', debitMandate.urn);
		const typeSources = this.debitMandateTypeSourceCollection.filterBy('sourceId', sources.map(s => s.id));
		return this.debitMandateRibFileCollection.filter(m => typeSources.map(ts => ts.urn).includes(m.getUrn('typeSource')));
	};

	public async initAsync(enterprisePartition: EnterprisePartitionModel, enterprise: EnterpriseModel) {
		await Promise.all([
			new ApiCollection(EnterpriseConfigModel)
				.setFilters({ enterpriseUrn: enterprise.urn })
				.list()
				.then(coll => this.enterpriseConfig.set(coll.first()?.attributes)),

			this.channelNotificationCollection.list({ cache: 3600 }),

			this.enterprise
				.setId(enterprisePartition.getId('enterprise'))
				.fetch()
				.then(() => {
					this.fetchDebitMandatesAsync();
					this.fetchInvoicingGroupAsync();
				}),

			this.invoicingGroupEmailTypeCollection.list({ cache: 3600 }),

			this.invoicingContactTypeCollection.list({ cache: 3600 }),

			this.fetchContactsCollection(enterprisePartition),

			this.invoicingGroupChannelNotificationCollection
				.setFilter('invoicingGroup.invoicingOwner.enterpriseUrn', enterprisePartition.getUrn('enterprise'))
				.list(),
		]);
	}

	public get accountingCode() {
		return this.invoicingOwner.accountingCode || enterpriseHierarchyStore.enterprise.reference;
	}
}
