import { computed }                from 'mobx';
import InterventionPrivateApiModel from '../../abstracts/InterventionPrivateApiModel';

export default class TaskTaskTagModel extends InterventionPrivateApiModel {
	public _filters: ModelFiltersExtended<{
		'task': id;
	}> = {};

	static get path(): string {
		return '/task_task_tags/{?id}';
	}

	@computed
	public get taskId(): id {
		return this.get('task.id', 0);
	}

	@computed
	public get taskTagId(): id {
		return this.get('taskTag.id', 0);
	}

	public _sorts: ModelSortsExtended<{
		'id': string;
	}> = {};
}
