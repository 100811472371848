import { ApiConnector } from 'Connectors/ApiConnector';
import ConfigProxy      from 'tools/ConfigProxy';
import AbstractApiModel from './AbstractApiModel';

export const supplierConnector = new ApiConnector({
	baseURL: ConfigProxy.getServiceConfig('supplier', 'api_endpoint'),
});

export default class SupplierApiModel extends AbstractApiModel {
	static get connector() {
		return supplierConnector;
	}

	public static serviceName: ServiceName = 'supplier';
}
