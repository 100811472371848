import { urn }                from 'helpers/ModelDictionary';
import { Blamable }           from 'helpers/traits';
import { Timestampable }      from 'helpers/traits';
import { computed }           from 'mobx';
import InvoicePrivateApiModel from '../../abstracts/InvoicePrivateApiModel';

export type NotificationTypeReference = 'reminder' | 'sending' | 'issuance';

@urn('$', 'invoice', 'notification_type')
export default class NotificationTypeModel extends Blamable(Timestampable(InvoicePrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'reference': NotificationTypeReference;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'label': string;
	}> = {};

	static get path(): string {
		return '/notification_types/{?id}';
	}

	public static cacheDuration = 3600;

	@computed
	public get label(): string {
		return this.get('label');
	}

	@computed
	public get reference(): NotificationTypeReference {
		return this.get('reference');
	}
}
