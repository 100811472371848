import lazyWithRetry from 'tools/lazyWithRetry';

const InvoiceListPage = lazyWithRetry(() => import(
	/* webpackChunkName: "invoiceListPage" */
	'../../pages/Invoice/InvoiceListPage/InvoiceListPage'));

const QuotationDashboardPage = lazyWithRetry(() => import(
	/* webpackChunkName: "quotationDashboardPage" */
	'../../pages/Quotation/QuotationDashboardPage'),
);

const QuotationListPage = lazyWithRetry(() => import(
	/* webpackChunkName: "contractListPage" */
	'../../pages/Quotation/QuotationListPage'),
);

const QuotationContractualCalendarPage = lazyWithRetry(() => import(
	/* webpackChunkName: "quotationContractualCalendarPage" */
	'../../pages/Quotation/QuotationContractualCalendarPage'),
);

const QuotationStatisticsPage = lazyWithRetry(() => import(
	/* webpackChunkName: "quotationStatisticsPage" */
	'../../pages/Quotation/QuotationStatisticsPage/QuotationStatisticsPage'),
);

export default [
	{
		component: InvoiceListPage,
		exact: true,
		path: '/quotations/:quotationId/invoices',
	},
	{
		component: QuotationContractualCalendarPage,
		exact: true,
		path: '/quotations/:quotationId/contractual-calendar',
	},
	{
		component: QuotationDashboardPage,
		exact: true,
		path: '/quotations/:quotationId',
	},
	{
		component: QuotationListPage,
		exact: true,
		path: '/sub_partitions/:subPartitionId/quotations',
	},
	{
		component: QuotationStatisticsPage,
		exact: true,
		path: '/sub_partitions/:subPartitionId/quotation_statistics',
	}
];
