import GridInfoEmptyValue  from 'components/GridInfo/GridInfoEmptyValue';
import { IModelNameProps } from 'components/ModelName';
import SingleRowSkeleton   from 'components/SingleRowSkeleton';
import _camelCase          from 'lodash/camelCase';
import { observer }        from 'mobx-react';
import AbstractApiModel    from 'modelx/models/abstracts/AbstractApiModel';
import React               from 'react';
import { ucfirst }         from 'tools/jsTools';
import View                from './View';

export default observer(<T extends AbstractApiModel>(props: IModelNameProps<T>) => {
	const { model, underline } = props;
	const [module, setModule] = React.useState(null);

	React.useEffect(() => {
		(async () => {
			if (model.urnData) {
				const { resource, service } = model.urnData;
				const fileName = ucfirst(_camelCase(resource));

				const module = await import(`../modelNames/${service}/${fileName}Name.tsx`)
					.catch(() => import(`../components/ModelName`));

				setModule(module);
			}
		})();
	}, [model.urnData]);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const Component = module ? module['default'] as any : null;

	if (!model.urnData) {
		return <GridInfoEmptyValue />;
	}

	return (module && model.isLoaded) ? <View className={underline ? 'model-name-underline' : ''} inline><Component {...props} /></View> : <SingleRowSkeleton />;
});
