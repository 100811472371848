import doc                  from 'decorators/doc';
import model                from 'decorators/model';
import { Timestampable }    from 'helpers/traits';
import { Blamable }         from 'helpers/traits';
import SalesPrivateApiModel from 'modelx/models/abstracts/SalesPrivateApiModel';

@model.cacheDuration()
@model.staticLabel('Activité', false)
@model.urnResource('activity_category')
@doc.path('/activity_categories/{?id}')
export default class ActivityCategoryModel extends Blamable(Timestampable(SalesPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'code': id;
		'createdAt[after]': string;
		'createdAt[before]': string;
		'createdAt[strictly_after]': string;
		'createdAt[strictly_before]': string;
		'enabled': '0' | '1';
		'label': string;
		'reference': SalesActivityCategoryReference;
		'updatedAt[after]': string | Date;
		'updatedAt[before]': string | Date;
		'updatedAt[strictly_after]': string | Date;
		'updatedAt[strictly_before]': string | Date;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'code': string;
		'id': string;
		'label': string;
		'position': string;
		'reference': string;
		'updatedAt': string;
	}> = {};

	@doc.string declare code: string;
	@doc.boolean declare enabled: boolean;
	@doc.string declare label: string;
	@doc.number declare position: number;
	@doc.string declare reference: SalesActivityCategoryReference;
}
