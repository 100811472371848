import { LoggedApiConnector } from 'Connectors/LoggedApiConnector';
import ConfigProxy            from 'tools/ConfigProxy';
import CommentApiModel        from './CommentApiModel';

export const commentLoggedApiConnector = new LoggedApiConnector({
	baseURL: ConfigProxy.getServiceConfig('comment', 'api_endpoint'),
});

export default class CommentPrivateApiModel extends CommentApiModel {
	public static get connector() {
		return commentLoggedApiConnector;
	}
}
