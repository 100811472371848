import QuotationItemEventModel       from 'Models/sales/QuotationItemEventModel';
import QuotationItemEventStatusModel from 'Models/sales/QuotationItemEventStatusModel';
import doc                           from 'decorators/doc';
import model                         from 'decorators/model';
import { Timestampable }             from 'helpers/traits';
import { Blamable }                  from 'helpers/traits';
import SalesPrivateApiModel          from 'modelx/models/abstracts/SalesPrivateApiModel';

@model.staticLabel('QuotationItemEventStatusHistory')
@model.urnResource('quotation_item_event_status_history')
@doc.path('/quotation_item_event_status_histories/{?id}')
export default class QuotationItemEventStatusHistoryModel extends Blamable(Timestampable(SalesPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'quotationItemEvent.quotationItem': id;
		'quotationItemEvent.quotationItem.quotation': id;
	}> = {};

	public _sorts: ModelSortsExtended<unknown> = {};

	@doc.string declare comment: string;
	@doc.model(QuotationItemEventModel) declare quotationItemEvent: QuotationItemEventModel;
	@doc.model(QuotationItemEventStatusModel) declare quotationItemEventStatus: QuotationItemEventStatusModel;
}