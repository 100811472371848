import DebitMandateStatusModel  from 'Models/invoice/DebitMandateStatusModel';
import InvoicingGroupModel      from 'Models/invoice/InvoicingGroupModel';
import PartitionModel           from 'Models/partition/PartitionModel';
import doc                      from 'decorators/doc';
import model                    from 'decorators/model';
import { Timestampable }        from 'helpers/traits';
import { Blamable }             from 'helpers/traits';
import InvoicePrivateApiModel   from 'modelx/models/abstracts/InvoicePrivateApiModel';
import { displayBlob }          from 'tools/blobTools';
import notificationApiError     from 'tools/notificationApiError';
import DebitMandatePreviewModel from './DebitMandatePreviewModel';

@model.staticLabel('Mandat de prélèvement')
@model.urnResource('debit_mandate')
@doc.path('/debit_mandates/{?id}')
export default class DebitMandateModel extends Blamable(Timestampable(InvoicePrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'debitMandateStatus.reference': InvoiceDebitMandateStatusReference;
		'invoicingGroup': id;
		'invoicingGroup.billingGroups.billingOwner.clientUrn': Urn;
		'invoicingGroup.billingGroups.billingOwner.invoicingOwner.enterpriseUrn': Urn;
		'invoicingGroup.billingGroups.billingOwner.ownerSubPartition.partitionUrn': Urn;
		'invoicingGroup.invoicingOwner.enterpriseUrn': Urn;
		'partitionUrn': Urn;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'debitMandateStatus.position': string;
		'id': string;
		'invoicingGroup.id': string;
		'partitionUrn': string;
	}> = {};

	@doc.string declare bankName: string;
	@doc.string declare bic: string;
	@doc.string declare city: string;
	@doc.model(DebitMandateStatusModel) declare debitMandateStatus: DebitMandateStatusModel;
	@doc.boolean declare document: boolean;
	@doc.string declare iban: string;
	@doc.model(InvoicingGroupModel) declare invoicingGroup: InvoicingGroupModel;
	@doc.modelUrn(PartitionModel) declare partition: PartitionModel;
	@doc.string declare rum: string;

	public async downloadPdfAsync() {
		try {
			const debitMandatePreviewModel = new DebitMandatePreviewModel({ id: this.id });

			await debitMandatePreviewModel.fetch();

			displayBlob(
				debitMandatePreviewModel.blob,
				`Mandat - ${this.rum}.pdf`,
			);
		} catch (err) {
			notificationApiError(err);
		}
	}
}