import doc                 from 'decorators/doc';
import model               from 'decorators/model';
import { Timestampable }   from 'helpers/traits';
import FilePrivateApiModel from '../../abstracts/FilePrivateApiModel';

@model.cacheDuration()
@model.staticLabel('MimeType')
@model.urnResource('mime_type')
@doc.path('/stonecode_file_mime_types/{?id}')
export default class MimeTypeModel extends Timestampable(FilePrivateApiModel) {
	public _filters: ModelFiltersExtended<{
		'reference': FileMimeTypeReference;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'reference': string;
		'updatedAt': string;
	}> = {};

	@doc.string declare reference: FileMimeTypeReference;
}
