import lazyWithRetry from 'tools/lazyWithRetry';

const ContactListPage = lazyWithRetry(() => import('../../../pages/Contact/ContactListPage'));

const ContactDashboardPage = lazyWithRetry(() => import('../../../pages/Contact/ContactDashboardPage'));

export default [
	{
		component: ContactListPage,
		exact: true,
		path: '/contacts',
	},
	{
		component: ContactDashboardPage,
		exact: true,
		path: '/contacts/:contactId',
	},
];
