import OutsideLink   from 'components/OutsideLink';
import { LinkProps } from 'react-router-dom';
import { Link }      from 'react-router-dom';
import React         from 'react';

export default (props: LinkProps) => {
	const { to } = props;

	if (to.toString().startsWith('/')) {
		return <Link {...props} />;
	} else {
		return <OutsideLink {...props} />;
	}
};