import { MenuFoldOutlined }         from '@ant-design/icons';
import AntdLayout                   from 'antd/lib/layout';
import Spin                         from 'antd/lib/spin';
import { observer }                 from 'mobx-react';
import { renderRoutes }             from 'react-router-config';
import { Link }                     from 'react-router-dom';
import React                        from 'react';
import privateRoutes                from 'routes/privates';
import SelectSubPartition           from 'selects/partition/SelectSubPartition';
import { appStore }                 from 'stores';
import { enterpriseHierarchyStore } from 'stores';
import ConfigProxy                  from 'tools/ConfigProxy';
import navigate                     from 'tools/navigate';
import Breadcrumb                   from './Breadcrumb';
import AppUserMenu                  from './components/AppUserMenu';
import LayoutSidebar                from './components/LayoutSidebar';
import Navigation                   from './components/Navigation';
import './css/AppName.scss';

@observer
export default class Layout extends React.Component<INavigationProps> {

	public state = { mainMenuCollapsed: true };

	componentDidMount(): void {
		const urlParams = new URLSearchParams(window.location.search);
		const id = urlParams.get('toSP');		
		if (id) {
			appStore.setSubPartitionId(id);
			enterpriseHierarchyStore.clear();
			window.location.replace('/');
		}
	}

	public render() {
		const { Content, Header, Sider } = AntdLayout;
		const { mainMenuCollapsed } = this.state;

		const { location } = this.props;

		return (
			<div className={`app-container ${ConfigProxy.get('PARTITION_NAME')}`}>
				<AntdLayout className="app-main">
					<Sider className="app-side-menu" collapsed={mainMenuCollapsed} collapsedWidth={70} width={300}>
						{!mainMenuCollapsed && <div className="side-menu-focus" onClick={this._toggleMainMenu} />}

						<div className="menu-toggle-trigger" onClick={this._toggleMainMenu}>
							<div className="app-name">AURA {appStore.partition.name}</div>

							<MenuFoldOutlined className="Layout__MenuIcon" />
						</div>

						<Navigation location={location} />
					</Sider>

					<div className="app-body">
						<div className="app-header">
							<AntdLayout className="app-header-layout">
								<Header className="app-header-div">
									<Link to={navigate.toHome()}>
										<div className="app-name">
											<div id="app-name-text">
												AURA {appStore.partition.name}
											</div>
										</div>
									</Link>

									{appStore.subPartition.id ? (
										<SelectSubPartition
											allowClear={false}
											filters={{ id: appStore.subPartitionIds }}
											onChange={id => {
												appStore.setSubPartitionId(id);
												enterpriseHierarchyStore.clear();

												window.location.replace('/');
											}}
											showSearch={true}
											style={{ minWidth: 200 }}
											value={appStore.subPartition?.id?.toString()}
										/>
									) : null}

									<div className="app-right">
										<AppUserMenu />
									</div>
								</Header>
							</AntdLayout>
						</div>
						<AntdLayout className="page-content-wrapper">
							<LayoutSidebar />

							<Content className="page-content">
								<Breadcrumb {...this.props} />

								{appStore.subPartitionUrn ? (
									<React.Suspense fallback={<Spin className="app-centered-spin" size="large" />}>
										{renderRoutes(privateRoutes)}
									</React.Suspense>
								) : (
									<Spin className="app-centered-spin" size="large" />
								)}
							</Content>
						</AntdLayout>
					</div>
				</AntdLayout>
			</div>
		);
	}

	private _toggleMainMenu = () => this.setState({ mainMenuCollapsed: !this.state.mainMenuCollapsed });
}