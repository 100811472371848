import EnterprisePartitionModel from 'Models/directory/EnterprisePartitionModel';
import doc                      from 'decorators/doc';
import model                    from 'decorators/model';
import resolvable               from 'decorators/resolvable';
import { Timestampable }        from 'helpers/traits';
import { Blamable }             from 'helpers/traits';
import SalesPrivateApiModel     from 'modelx/models/abstracts/SalesPrivateApiModel';

@model.staticLabel('OwnerEnterprisePartition')
@model.urnResource('owner_enterprise_partition')
@doc.path('/owner_enterprise_partitions/{?id}')
export default class OwnerEnterprisePartitionModel extends Blamable(Timestampable(SalesPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'frameworkAgreementEnterprisePartitions': id;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
	}> = {};

	@resolvable(EnterprisePartitionModel, { attributeName: 'enterprisePartitionUrn' })
	declare enterprisePartition: EnterprisePartitionModel;
}
