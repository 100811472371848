import { LoggedApiConnector } from 'modelx/connectors/LoggedApiConnector';
import ConfigProxy            from 'tools/ConfigProxy';
import AbstractApiModel       from './AbstractApiModel';

export const auditerLoggedApiConnector = new LoggedApiConnector({
	baseURL: ConfigProxy.getServiceConfig('auditer', 'api_endpoint'),
});

export default class AuditerPrivateApiModel extends AbstractApiModel {
	public static get connector() {
		return auditerLoggedApiConnector;
	}
}
