import doc                     from 'decorators/doc';
import model                   from 'decorators/model';
import SupplierPrivateApiModel from '../../abstracts/SupplierPrivateApiModel';

@model.cacheDuration()
@model.staticLabel('Statut')
@model.urnResource('period_status')
@doc.path('/period_statuses/{?id}')
export default class PeriodStatusModel extends SupplierPrivateApiModel {
	public _filters: ModelFiltersExtended<{
		'reference': SupplierPeriodStatusReference;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
	}> = {};

	@doc.string declare label: string;
	@doc.string declare reference: SupplierPeriodStatusReference;

	public get color() {
		switch (this.reference) {
			case 'open':
				return 'lightblue';
			case 'locked':
				return 'red';
			case 'closed':
				return 'black';
		}
	}
}