import doc                            from 'decorators/doc';
import model                          from 'decorators/model';
import { computed }                   from 'mobx';
import InterventionPrivateApiModel    from '../../abstracts/InterventionPrivateApiModel';
import ConstraintCheckerModel         from './ConstraintCheckerModel';

@model.staticLabel('ConstraintCheckerBatchModel')
@doc.path('/constraint_checkers/{?id}')
export default class ConstraintCheckerBatchModel extends InterventionPrivateApiModel {
	@computed
	public get constraintCheckers(): ConstraintCheckerModel[] {
		return this.get('constraintCheckers.hydra:member', []).map(object => {
			return new ConstraintCheckerModel(object);
		});
	}
}
