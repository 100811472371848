import lazyWithRetry from 'tools/lazyWithRetry';

const EnterpriseListPage = lazyWithRetry(() => import(
	/* webpackChunkName: "enterpriseListPage" */
	'../../pages/Enterprise/EnterpriseListPage'));

const EnterpriseDashboardPage = lazyWithRetry(() => import(
	/* webpackChunkName: "enterpriseDashboardPage" */
	'../../pages/Enterprise/EnterpriseDashboardPage'));

export default [
	{
		component: EnterpriseListPage,
		exact: true,
		path: '/enterprises',
	},
	{
		component: EnterpriseDashboardPage,
		exact: true,
		path: '/enterprises/:enterpriseId',
	},
];
