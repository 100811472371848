import lazyWithRetry from 'tools/lazyWithRetry';

const TaskZoneListPage = lazyWithRetry(() => import(
	/* webpackChunkName: "taskZoneListPage" */
	'../../pages/TaskZone/TaskZoneListPage'));

const TaskZoneDashboardPage = lazyWithRetry(() => import(
	/* webpackChunkName: "taskZoneDashboardPage" */
	'../../pages/TaskZone/TaskZoneDashboardPage'));

export default [
	{
		component: TaskZoneListPage,
		exact: true,
		path: '/sub_partitions/:subPartitionId/task_zones',
	},
	{
		component: TaskZoneDashboardPage,
		exact: true,
		path: '/sub_partitions/:subPartitionId/task_zones/:taskZoneId',
	},
];
