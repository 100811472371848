import { resolvable }       from '@mathquis/modelx-resolvables';
import PartitionModel       from 'Models/partition/PartitionModel';
import SubPartitionModel    from 'Models/partition/SubPartitionModel';
import { urn }              from 'helpers/ModelDictionary';
import { Blamable }         from 'helpers/traits';
import { Timestampable }    from 'helpers/traits';
import SalesPrivateApiModel from '../../abstracts/SalesPrivateApiModel';

@urn('$', 'sales', 'owner_sub_partition')
export default class OwnerSubPartitionModel extends Blamable(Timestampable(SalesPrivateApiModel)) {

	static get path(): string {
		return '/owner_sub_partitions/{?id}';
	}

	public _filters: ModelFiltersExtended<{
		'partitionUrn': string;
		'subPartitionUrn': string;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'updatedAt': string;
	}> = {};

	@resolvable(PartitionModel, { attributeName: 'partitionUrn', cache: true })
	declare partition: PartitionModel;

	@resolvable(SubPartitionModel, { attributeName: 'subPartitionUrn', cache: true })
	declare subPartition: SubPartitionModel;
}