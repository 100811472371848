import Card                    from 'antd/lib/card';
import { observer }            from 'mobx-react';
import LoginHeader             from 'pages/LoginPage/components/LoginHeader';
import React                   from 'react';
import SelectSubPartition      from 'selects/partition/SelectSubPartition';
import { appStore }            from 'stores';
import { authenticationStore } from 'stores';
import { notificationError }   from 'tools/notification';
import './SelectSubPartitionPage.scss';

export default observer(() => {
	React.useEffect(() => {
		if (appStore.storedSubPartitionId) {
			notificationError(`Cette ${appStore.subPartition.getStaticLabel()} (id: ${appStore.storedSubPartitionId}) 
			n'existe pas sur ${appStore.partition.name}`);
		}
	}, [appStore.storedSubPartitionId]);

	return (
		<div className="SelectSubPartitionPage">
			<Card>
				<LoginHeader />

				<p style={{ marginTop: 10 }}>
					<strong>
						{`Bonjour ${appStore.staffMember.fullName}`}.
					</strong>
					<br />
					<strong>
						{`Sur quelle agence souhaitez-vous vous connecter ?`}
					</strong>
				</p>

				<SelectSubPartition
					allowClear={false}
					filters={{ id: appStore.subPartitionIds }}
					onChange={v => appStore.setSubPartitionId(v)}
					showSearch={true}
					style={{ width: '100%' }}
				/>

				<a onClick={() => authenticationStore.logout()} style={{ marginTop: 58 }}>Déconnexion</a>
			</Card>
		</div>
	);
});
