import { resolvable }           from '@mathquis/modelx-resolvables';
import ContactModel             from 'Models/directory/ContactModel';
import EnterprisePartitionModel from 'Models/directory/EnterprisePartitionModel';
import ContactTypeModel         from 'Models/invoice/ContactTypeModel';
import { ContactTypeReference } from 'Models/invoice/ContactTypeModel';
import InvoicingOwnerModel      from 'Models/invoice/InvoicingOwnerModel';
import PartitionModel           from 'Models/partition/PartitionModel';
import { urn }                  from 'helpers/ModelDictionary';
import { Timestampable }        from 'helpers/traits';
import { Blamable }             from 'helpers/traits';
import { computed }             from 'mobx';
import { getIdFromUrn }         from 'tools/UrnTools';
import InvoicePrivateApiModel   from '../../abstracts/InvoicePrivateApiModel';

@urn('$', 'invoice', 'invoicing_contact')
export default class InvoicingContactModel extends Blamable(Timestampable(InvoicePrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'contactType.reference': ContactTypeReference;
		'contactUrn': string;
		'createdAt[after]': string;
		'createdAt[before]': string;
		'createdAt[strictly_after]': string;
		'createdAt[strictly_before]': string;
		'enterprisePartitionUrn': string;
		'updatedAt[after]': string;
		'updatedAt[before]': string;
		'updatedAt[strictly_after]': string;
		'updatedAt[strictly_before]': string;
	}> = {};

	static get path(): string {
		return '/invoicing_contacts/{?id}';
	}

	@computed
	public get contactTypeId(): id {
		return this.get('contactType.id');
	}

	@computed
	public get position(): number {
		return this.get('position');
	}

	@computed
	public get ownerId(): id {
		return this.get('owner.id');
	}

	@computed
	public get contactId(): string {
		return getIdFromUrn(this.get('contactUrn'));
	}

	@computed
	public get contactUrn(): string {
		return this.get('contactUrn');
	}

	@computed
	public get enterprisePartitionUrn(): string {
		return this.get('enterprisePartitionUrn');
	}

	@computed
	public get enterprisePartitionId(): id {
		return getIdFromUrn(this.enterprisePartitionUrn);
	}

	public _sorts: ModelSortsExtended<{
		'id': string;
		'position': string;
		'updatedAt': string;
	}> = {};


	@resolvable(ContactModel, { attributeName: 'contactUrn' })
	declare contact: ContactModel;

	@resolvable(ContactTypeModel, { attributeName: 'contactType', cache: true })
	declare contactType: ContactTypeModel;

	@resolvable(EnterprisePartitionModel, { attributeName: 'enterprisePartitionUrn' })
	declare enterprisePartition: EnterprisePartitionModel;

	@resolvable(InvoicingOwnerModel, { attributeName: 'invoicingOwner' })
	declare invoicingOwner: InvoicingOwnerModel;

	@resolvable(PartitionModel, { attributeName: 'partitionUrn', cache: true })
	declare partition: PartitionModel;
}
