import FileModel                                     from 'Models/file/FileModel';
import BillingFrequencyModel                         from 'Models/invoice/BillingFrequencyModel';
import InvoicingGroupModel                           from 'Models/invoice/InvoicingGroupModel';
import PaymentModeModel                              from 'Models/invoice/PaymentModeModel';
import FrameworkAgreementIterationStatusModel        from 'Models/sales/FrameworkAgreementIterationStatusModel';
import FrameworkAgreementModel                       from 'Models/sales/FrameworkAgreementModel';
import doc                                           from 'decorators/doc';
import model                                         from 'decorators/model';
import { Timestampable }                             from 'helpers/traits';
import { Blamable }                                  from 'helpers/traits';
import SalesPrivateApiModel                          from 'modelx/models/abstracts/SalesPrivateApiModel';
import PurchaseOrderModel                            from './PurchaseOrderModel';

@model.staticLabel(`Accord-cadre`)
@model.urnResource('framework_agreement_iteration')
@doc.path('/framework_agreement_iterations/{?id}')
export default class FrameworkAgreementIterationModel extends Blamable(Timestampable(SalesPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'endDate[after]': string | Date;
		'endDate[before]': string | Date;
		'endDate[strictly_after]': string | Date;
		'endDate[strictly_before]': string | Date;
		'frameworkAgreement': id;
		'frameworkAgreement.frameworkAgreementEnterprisePartitions.ownerEnterprisePartition.enterprisePartitionUrn': Urn;
		'frameworkAgreement.frameworkAgreementSubPartitions.frameworkAgreementSubPartitionStatus.reference': SalesFrameworkAgreementSubPartitionStatusReference;
		'frameworkAgreement.frameworkAgreementSubPartitions.ownerSubPartition': id;
		'frameworkAgreement.frameworkAgreementSubPartitions.ownerSubPartition.subPartitionUrn': Urn;
		'frameworkAgreementIterationActivities': id;
		'frameworkAgreementIterationQuotationOptions.quotationOption': id;
		'frameworkAgreementIterationQuotationOptions.quotationOption.quotationOptionEntity': id;
		'frameworkAgreementIterationQuotationOptions.quotationOption.reference': SalesQuotationOptionReference;
		'frameworkAgreementIterationStatus': id;
		'frameworkAgreementIterationStatus.reference': SalesFrameworkAgreementIterationStatusReference;
		'frameworkAgreementIterationStatusHistories': id;
		'startDate[after]': string | Date;
		'startDate[before]': string | Date;
		'startDate[strictly_after]': string | Date;
		'startDate[strictly_before]': string | Date;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'number': string;
		'reference': string;
	}> = {};

	@doc.date declare endDate: Moment;
	@doc.model(FrameworkAgreementModel) declare frameworkAgreement: FrameworkAgreementModel;
	@doc.model(FrameworkAgreementIterationStatusModel) declare frameworkAgreementIterationStatus: FrameworkAgreementIterationStatusModel;
	@doc.number declare number: number;
	@doc.string declare reference: SalesFrameworkAgreementIterationReference;
	@doc.date declare startDate: Moment;

	@model.resolvableUrn({ attributeName: 'defaultBillingFrequencyUrn' })
	declare defaultBillingFrequency: BillingFrequencyModel;

	@model.resolvableUrn({ attributeName: 'defaultInvoicingGroupUrn' })
	declare defaultInvoicingGroup: InvoicingGroupModel;

	@model.resolvableUrn({ attributeName: 'defaultPaymentModeUrn' })
	declare defaultPaymentMode: PaymentModeModel;

	@model.reverseResolvable<FileModel, FrameworkAgreementIterationModel>({
		filters: models => ({
			'fileStatus.reference': 'available',
			'typeSource.source.entityUrn': models.map(m => m.urn),
			'typeSource.type.reference': 'framework_agreement_iteration_document_pdf',
		}),
		multipleRequests: true,
		urn: '$:file:file',
	})
	public declare document: FileModel;

	@model.reverseResolvable<PurchaseOrderModel, FrameworkAgreementIterationModel>({
		filters: models => ({
			'purchaseOrderTargets.targetUrn': models.map(m => m.urn),
		}),
		multipleRequests: true,
		urn: '$:sales:purchase_order',
	})
	public declare purchaseOrder: PurchaseOrderModel;
}
