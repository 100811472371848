import _uniqueId          from 'lodash/uniqueId';
import { action }         from 'mobx';
import { computed }       from 'mobx';
import { observable }     from 'mobx';
import { makeObservable } from 'mobx';
import React              from 'react';

export default class LayoutSidebarStore {
	@observable
	private _content: React.ReactNode | null;

	@observable
	private _key = _uniqueId();

	public constructor() {
		makeObservable(this);
	}

	public clear() {
		this.setContent(null);
	}

	@action
	public remount() {
		this._key = _uniqueId();
	}

	@action
	public setContent(content: React.ReactNode) {
		this._content = content;
	}

	public get key() {
		return this._key;
	}

	@computed
	public get content() {
		return this._content;
	}

	@computed
	public get isOpen() {
		return !!this._content;
	}
}