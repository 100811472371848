/* eslint-disable @typescript-eslint/no-explicit-any */
import { ModelClass }     from '@mathquis/modelx/lib/types/collection';
import { observable }     from 'mobx';
import { makeObservable } from 'mobx';
import { computed }       from 'mobx';
import { action }         from 'mobx';
import AbstractApiModel   from 'modelx/models/abstracts/AbstractApiModel';

class ModelDictionary {
	private _dict = new Map<string, any>();

	@observable
	private _size = 0;

	public constructor() {
		makeObservable(this);
	}

	public get<T extends AbstractApiModel>(urn: string): ModelClass<T> {
		const [partition, service, resource] = urn.split(':');
		return this._dict.get(ModelDictionary.getKey(partition, service, resource));
	}

	@computed
	public get models() {
		return [...this._dict.values()];
	}

	public get size() {
		return this._size;
	}

	public set<T extends AbstractApiModel>(partition: string, service: string, resource: string, model: T): void {
		this._dict.set(ModelDictionary.getKey(partition, service, resource), model);
		this.setSize(this._dict.size);
	}

	@action
	public setSize(value: number) {
		return this._size = value;
	}

	private static getKey(partition: string, service: string, resource: string): string {
		return `/${partition}:${service}:${resource}/`;
	}
}

const modelDictionary = new ModelDictionary();

export function urn(partition: string, service: string, resource: string) {
	return function (target) {
		console.debug(
			'Registering model %c%s%c [urn: %s:%s:%s:*]',
			'color: blue; font-weight: bold',
			target.name,
			'color: inherit; font-weight: normal',
			partition, service, resource,
		);
		target.serviceUrn = `${partition}:${service}:${resource}`;
		modelDictionary.set(partition, service, resource, target);
		return target;
	};
}

export default modelDictionary;