import type { ArgsProps } from 'antd/lib/notification/interface';
import { notification }   from 'antd';

export const notificationSuccess = (options: Partial<ArgsProps> | string) => {
	notification['success']({
		className: 'project-notification success',
		message: `Success`,
		placement: 'bottomRight',
		...(typeof options === 'string' ? { message: options } : options),
	});
};

export const notificationError = (options: Partial<ArgsProps> | string) => {
	notification['error']({
		className: 'project-notification error',
		message: `Error`,
		placement: 'bottomRight',
		...(typeof options === 'string' ? { message: options } : options),
	});
};

export const notificationDev = (options: Partial<ArgsProps> | string) => {
	if (__DEV__) {
		notification['error']({
			duration: 7,
			message: `Message de development`,
			placement: 'bottomLeft',
			...(typeof options === 'string' ? { description: options } : options),
		});
	}
};
