import ContractIterationModel                   from 'Models/sales/ContractIterationModel';
import QuotationModel                           from 'Models/sales/QuotationModel';
import QuotationRequestModel                    from 'Models/sales/QuotationRequestModel';
import OrderBillingAddressModel                 from 'Models/supplier/OrderBillingAddressModel';
import OrderGroupStatusModel                    from 'Models/supplier/OrderGroupStatusModel';
import OrderPartModel                           from 'Models/supplier/OrderPartModel';
import OrderShippingAddressModel                from 'Models/supplier/OrderShippingAddressModel';
import OrderTypeModel                           from 'Models/supplier/OrderTypeModel';
import PaymentDeadlineTypeModel                 from 'Models/supplier/PaymentDeadlineTypeModel';
import PaymentModeModel                         from 'Models/supplier/PaymentModeModel';
import SupplierEnterprisePurchaseConditionModel from 'Models/supplier/SupplierEnterprisePurchaseConditionModel';
import SupplierSubPartitionModel                from 'Models/supplier/SupplierSubPartitionModel';
import MaintenanceModel                         from 'Models/vehicle/MaintenanceModel';
import doc                                      from 'decorators/doc';
import model                                    from 'decorators/model';
import { Timestampable }                        from 'helpers/traits';
import { Blamable }                             from 'helpers/traits';
import SupplierPrivateApiModel                  from 'modelx/models/abstracts/SupplierPrivateApiModel';

@model.staticLabel('Groupe de commande')
@model.urnResource('order_group')
@doc.path('/order_groups/{?id}')
export default class OrderGroupModel extends Blamable(Timestampable(SupplierPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'createdAt[after]': string;
		'createdAt[before]': string;
		'createdAt[strictly_after]': string;
		'createdAt[strictly_before]': string;
		'createdBy': id;
		'hasInvoice': '0' | '1';
		'number': number;
		'orderGroupStatus': id;
		'orderGroupStatus.reference': SupplierOrderGroupStatusReference;
		'orderParts.periods': id;
		'orderParts.periods.period': id;
		'orderType': id;
		'orderType.reference': SupplierOrderTypeReference;
		'reference': SupplierOrderGroupReference;
		'sourceUrn': Urn;
		'supplierSubPartition': id;
		'supplierSubPartition.ownerSubPartition.ownerPartition.partitionUrn': Urn;
		'supplierSubPartition.ownerSubPartition.subPartitionUrn': Urn;
		'supplierSubPartition.supplierCompany': id;
		'supplierSubPartition.supplierCompany.companyUrn': Urn;
		'totalExclTax[between]': string;
		'totalExclTax[gt]': string;
		'totalExclTax[gte]': string;
		'totalExclTax[lt]': string;
		'totalExclTax[lte]': string;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'label': string;
		'number': string;
		'orderType.label': string;
		'reference': string;
		'updatedAt': string;
	}> = {};

	@doc.string declare comment: string;
	@doc.number declare delay: number;
	@doc.number declare deposit: number;
	@doc.boolean declare hasInvoice: boolean;
	@doc.string declare label: string;
	@doc.number declare number: number;
	@doc.model(OrderBillingAddressModel) declare orderBillingAddress: OrderBillingAddressModel;
	@doc.model(OrderGroupStatusModel) declare orderGroupStatus: OrderGroupStatusModel;
	@doc.model(OrderShippingAddressModel) declare orderShippingAddress: OrderShippingAddressModel;
	@doc.model(OrderTypeModel) declare orderType: OrderTypeModel;
	@doc.number declare partNumber: number;
	@doc.model(PaymentDeadlineTypeModel) declare paymentDeadlineType: PaymentDeadlineTypeModel;
	@doc.model(PaymentModeModel) declare paymentMode: PaymentModeModel;
	@doc.string declare reference: SupplierOrderGroupReference;
	@doc.model(SupplierEnterprisePurchaseConditionModel) declare supplierEnterprisePurchaseCondition: SupplierEnterprisePurchaseConditionModel;
	@doc.model(SupplierSubPartitionModel) declare supplierSubPartition: SupplierSubPartitionModel;
	@doc.number declare totalExclTax: number;

	@model.nestedResolvable()
	public get supplierCompany() {
		return this.supplierSubPartition.supplierCompany;
	}

	@model.nestedResolvable()
	public get supplierEnterprise() {
		return this.supplierSubPartition.supplierCompany.supplierEnterprise;
	}

	@model.resolvableUrn({ attributeName: 'sourceUrn' })
	public declare source: MaintenanceModel | QuotationModel | ContractIterationModel | QuotationRequestModel;

	@model.nestedResolvable()
	public get subPartition() {
		return this.supplierSubPartition.ownerSubPartition.subPartition;
	}

	@model.reverseResolvable<OrderPartModel>({ multipleRequests: true, urn: '$:supplier:order_part' })
	public declare firstOrderPart: OrderPartModel;
}
