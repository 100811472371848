import doc                    from 'decorators/doc';
import model                  from 'decorators/model';
import { Timestampable }      from 'helpers/traits';
import { Blamable }           from 'helpers/traits';
import VehiclePrivateApiModel from '../../abstracts/VehiclePrivateApiModel';

@model.cacheDuration()
@model.staticLabel(`Type d'entretien`)
@model.urnResource('maintenance_type')
@doc.path('/maintenance_types/{?id}')
export default class MaintenanceTypeModel extends Blamable(Timestampable(VehiclePrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'recurrent': '0' | '1';
		'reference': VehicleMaintenanceTypeReference;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'label': string;
	}> = {};

	@doc.string declare label: string;
	@doc.boolean declare recurrent: boolean;
	@doc.string declare reference: VehicleMaintenanceTypeReference;
}