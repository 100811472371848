import OperationModel              from 'Models/intervention/OperationModel';
import ResourceTypeModel           from 'Models/intervention/ResourceTypeModel';
import doc                         from 'decorators/doc';
import model                       from 'decorators/model';
import { Timestampable }           from 'helpers/traits';
import { Blamable }                from 'helpers/traits';
import InterventionPrivateApiModel from 'modelx/models/abstracts/InterventionPrivateApiModel';

@model.staticLabel('ConstraintGroup')
@model.urnResource('constraint_group')
@doc.path('/constraint_groups/{?id}')
export default class ConstraintGroupModel extends Blamable(Timestampable(InterventionPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'createdAt[after]': string;
		'createdAt[before]': string;
		'createdAt[strictly_after]': string;
		'createdAt[strictly_before]': string;
		'operation': id;
		'operation.taskOperations': id;
		'resourceType': id;
		'resourceType.reference': InterventionResourceTypeReference;
		'updatedAt[after]': string | Date;
		'updatedAt[before]': string | Date;
		'updatedAt[strictly_after]': string | Date;
		'updatedAt[strictly_before]': string | Date;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'updatedAt': string;
		'value': string;
	}> = {};

	@doc.string declare comparator: string;
	@doc.string declare error: string;
	@doc.string declare label: string;
	@doc.model(OperationModel) declare operation: OperationModel;
	@doc.model(ResourceTypeModel) declare resourceType: ResourceTypeModel;
	@doc.string declare type: string;
	@doc.number declare value: number;
}