import { contextModal }     from 'components/AppForm';
import { IFormModalProps }  from 'components/FormModal/FormModal';
import Modal                from 'components/Modal/Modal';
import { closeAllTooltips } from 'components/Tooltip';
import React                from 'react';

class ModalStore {
	public open(
		renderContent: () => React.ReactNode,
		modalProps?: Omit<IFormModalProps, 'children'>,
	) {
		closeAllTooltips();

		return contextModal.confirm({
			...Modal.defaultProps,
			closable: true,
			content: renderContent(),
			icon: null,
			wrapClassName: 'app-modal',
			...modalProps,
		});
	}
}

const modalStore = new ModalStore();
export default modalStore;
