import BillableStatusModel    from 'Models/invoice/BillableStatusModel';
import BillingGroupModel      from 'Models/invoice/BillingGroupModel';
import InvoiceTypeModel       from 'Models/invoice/InvoiceTypeModel';
import ItemDisplayModeModel   from 'Models/invoice/ItemDisplayModeModel';
import SourceModel            from 'Models/invoice/SourceModel';
import doc                    from 'decorators/doc';
import model                  from 'decorators/model';
import { Timestampable }      from 'helpers/traits';
import { Blamable }           from 'helpers/traits';
import { computed }           from 'mobx';
import InvoicePrivateApiModel from '../../abstracts/InvoicePrivateApiModel';

@model.staticLabel('Facture', false)
@model.urnResource('billable')
@doc.path('/billables/{?id}')
export default class BillableModel extends Blamable(Timestampable(InvoicePrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'billableItems.billableItemMetas.reference': InvoiceBillableItemMetaReference;
		'billableItems.billableItemMetas.value': id;
		'billableItems.locationUrn': string;
		'billableMetas': id;
		'billableMetas.reference': InvoiceBillableMetaReference;
		'billableMetas.value': id;
		'billableStatus': id;
		'billableStatus.reference': InvoiceBillableStatusReference;
		'billingGroup': id;
		'billingGroup.billingOwner.clientUrn': string;
		'billingGroup.billingOwner.companyUrn': string;
		'billingGroup.billingOwner.invoicingOwner.enterpriseUrn': string;
		'billingGroup.billingOwner.ownerSubPartition.subPartitionUrn': string;
		'billingGroup.invoicingGroup.invoicingOwner.enterpriseUrn': string;
		'createdAt[after]': string;
		'createdAt[before]': string;
		'createdAt[strictly_after]': string;
		'createdAt[strictly_before]': string;
		'invoiceableAt[after]': string;
		'invoiceableAt[before]': string;
		'invoiceableAt[strictly_after]': string;
		'invoiceableAt[strictly_before]': string;
		'refundedInvoices.invoiceRefunded': id;
		'sourceUrn': string;
		'updatedAt[after]': string | Date;
		'updatedAt[before]': string | Date;
		'updatedAt[strictly_after]': string | Date;
		'updatedAt[strictly_before]': string | Date;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'billableStatus.position': string;
		'id': string;
		'updatedAt': string;
	}> = {};

	@doc.number declare amountExclTax: number;
	@doc.model(BillableStatusModel) declare billableStatus: BillableStatusModel;
	@doc.model(BillingGroupModel) declare billingGroup: BillingGroupModel;
	@doc.date declare date: Moment;
	@doc.string declare description: string;
	@doc.model(InvoiceTypeModel) declare invoiceType: InvoiceTypeModel;
	@doc.date declare invoiceableAt: Moment;
	@doc.model(ItemDisplayModeModel) declare itemDisplayMode: ItemDisplayModeModel;
	@doc.string declare label: string;
	@doc.boolean declare rebilling: boolean;
	@doc.modelUrn(SourceModel) declare source: SourceModel;

	@computed
	public get billingGroupId(): id {
		return this.get('billingGroup.id');
	}

	@model.nestedResolvable()
	public get ownerSubPartition() {
		return this.billingGroup.billingOwner.ownerSubPartition;
	}

	@model.nestedResolvable()
	public get subPartition() {
		return this.billingGroup.billingOwner.ownerSubPartition.subPartition;
	}
}