import doc                    from 'decorators/doc';
import model                  from 'decorators/model';
import { Timestampable }      from 'helpers/traits';
import CommentPrivateApiModel from '../../abstracts/CommentPrivateApiModel';

@model.staticLabel('Service')
@model.urnResource('service')
@doc.path('/stonecode_comment_services/{?id}')
export default class ServiceModel extends Timestampable(CommentPrivateApiModel) {
	public _filters: ModelFiltersExtended<{
		'serviceUrn': string;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'updatedAt': string;
	}> = {};

	@doc.modelUrn(ServiceModel) declare service: ServiceModel;
}
