import { resolvable }                        from '@mathquis/modelx-resolvables';
import TaskModel                             from 'Models/intervention/TaskModel';
import ContractIterationItemEventStatusModel from 'Models/sales/ContractIterationItemEventStatusModel';
import ContractIterationItemModel            from 'Models/sales/ContractIterationItemModel';
import model                                 from 'decorators/model';
import nestedResolvable                      from 'decorators/nestedResolvable';
import { urn }                               from 'helpers/ModelDictionary';
import { Timestampable }                     from 'helpers/traits';
import { Blamable }                          from 'helpers/traits';
import { computed }                          from 'mobx';
import moment                                from 'moment';
import SalesPrivateApiModel                  from '../../abstracts/SalesPrivateApiModel';

@model.staticLabel('Contrat')
@urn('$', 'sales', 'contract_iteration_item_event')
export default class ContractIterationItemEventModel extends Blamable(Timestampable(SalesPrivateApiModel)) {

	static get path(): string {
		return '/contract_iteration_item_events/{?id}';
	}

	public _filters: ModelFiltersExtended<{
		'contractIterationItem': id;
		'contractIterationItem.contractIteration': id;
		'contractIterationItemEventStatus.reference': string;
	}> = {};


	@resolvable(ContractIterationItemModel, { attributeName: 'contractIterationItem' })
	declare contractIterationItem: ContractIterationItemModel;

	@resolvable(ContractIterationItemEventStatusModel, { attributeName: 'contractIterationItemEventStatus' })
	declare contractIterationItemEventStatus: ContractIterationItemEventStatusModel;

	@computed
	public get scheduleEndDate(): Moment {
		return moment(this.get('scheduleEndDate', moment()));
	}

	@computed
	public get scheduleStartDate(): Moment {
		return moment(this.get('scheduleStartDate', moment()));
	}

	@resolvable(TaskModel, { attributeName: 'taskUrn' })
	declare task: TaskModel;

	@nestedResolvable()
	public get contract() {
		return this.contractIterationItem.contractIteration.contract;
	}

	@nestedResolvable()
	public get contractIteration() {
		return this.contractIterationItem.contractIteration;
	}
}