import { computed }           from 'mobx';
import InvoicePrivateApiModel from '../../abstracts/InvoicePrivateApiModel';

export default class BillableAggregatedDataModel extends InvoicePrivateApiModel {
	public _filters: ModelFiltersExtended<{
		'billableStatus.reference': string;
		billingGroup: id;
	}> = {};

	static get path(): string {
		return '/billables/_aggregated_data';
	}

	@computed
	public get amountExclTax(): number {
		return this.get('amountExclTax');
	}

	@computed
	public get amountVat(): number {
		return this.get('amountVat');
	}

	@computed
	public get amountInclTax(): number {
		return this.get('amountInclTax');
	}

	@computed
	public get count(): number {
		return this.get('count', 0);
	}
}
