import { PagedCollection }   from 'Collections/PagedCollection';
import OwnerResourceModel    from 'Models/intervention/OwnerResourceModel';
import PropertyModel         from 'Models/intervention/PropertyModel';
import PropertyTypeModel     from 'Models/intervention/PropertyTypeModel';
import LeaveTypeModel        from 'Models/rh/LeaveTypeModel';
import StaffAddressModel     from 'Models/rh/StaffAddressModel';
import StaffMemberLeaveModel from 'Models/rh/StaffMemberLeaveModel';
import StaffMemberModel      from 'Models/rh/StaffMemberModel';
import { computed }          from 'mobx';
import { ApiCollection }     from 'modelx/collections/ApiCollection';
import ConfigProxy           from 'tools/ConfigProxy';
import AbstractModelXStore   from './AbstractModelXStore';

export default class StaffMemberDashboardStore extends AbstractModelXStore {
	public leaveTypeCollection = new ApiCollection(LeaveTypeModel);
	public ownerResourceCollection = new ApiCollection(OwnerResourceModel);
	public propertyCollection = new ApiCollection(PropertyModel);
	public propertyTypeCollection = new ApiCollection(PropertyTypeModel);
	public staffAddressCollection = new ApiCollection(StaffAddressModel);
	public staffMember = new StaffMemberModel();
	public staffMemberLeavePagedCollection = new PagedCollection(StaffMemberLeaveModel);

	public async initAsync(id: id) {
		this.clear();

		await Promise.all([
			this.staffMember
				.set({ id })
				.fetch()
				.then(async () => {
					await Promise.all([
						this.staffAddressCollection
							.setFilter('staff', this.staffMember.getId('staff'))
							.list(),

						this.ownerResourceCollection
							.setFilter('entityUrn', this.staffMember.urn)
							.list(),
					]);
				}),

			this.propertyTypeCollection
				.setFilters({
					'properties.partitionUrn': ConfigProxy.get('PARTITION_URN'),
					'resourceType.reference': 'technician',
				})
				.list()
				.then(() => {
					return this.propertyCollection
						.setSort('label')
						.listBy(this.propertyTypeCollection.ids, 'propertyType');
				}),

			this.staffMemberLeavePagedCollection.setFilter('staffMember', id).list(),

			this.leaveTypeCollection.list(),
		]);
	}

	@computed
	public get staffAddress() {
		return this.staffAddressCollection.length > 0 ?
			this.staffAddressCollection.models[0] : new StaffAddressModel();
	}

	@computed
	public get ownerResource() {
		return this.ownerResourceCollection.length > 0 ?
			this.ownerResourceCollection.models[0] : new OwnerResourceModel();
	}
}
