import { Model } from '@mathquis/modelx';

const defaultUrnRegex = /\$:([a-z]+):([a-z_]+):(\d+)/;

export const splitUrn = (urn: string): string[] => {
	// even with typing, sometimes urn is not a string!!!
	if (!urn || typeof urn !== 'string') {
		return [];
	}

	const regex = new RegExp(defaultUrnRegex);
	const matches = urn.match(regex);

	if (!matches || matches.length !== 4) {
		console.warn('Urn "%s" does not match defaultUrnRegex', urn);

		return [];
	}

	return matches;
};

export const getMaskFromUrn = (urn: string): string => {
	const urnParts = splitUrn(urn);
	return `$:${urnParts[1]}:${urnParts[2]}:*`;
};

export const getIdFromUrn = (urn: string): string => splitUrn(urn)[3] || '';

export const getEntityFromUrn = (urn: string): string => splitUrn(urn)[2] || '';

export const getIdFromIri = (iri: string): string => {
	if (!iri) {
		return '';
	}

	const index = iri.lastIndexOf('/');

	if (index < 0) {
		return '';
	}

	return iri.substr(index + 1);
};

export const getUrnFromModel = (model: Model) => {
	switch (model.constructor.name) {
		case 'ContractIterationModel': {
			return `$:sales:contract_iteration:${model.id}`;
		}
		case 'ContractIterationItemModel': {
			return `$:sales:contract_iteration_item:${model.id}`;
		}
		case 'QuotationItemModel': {
			return `$:sales:quotation_item:${model.id}`;
		}
		default: {
			console.warn('Impossible to get urn from model');
			return '';
		}
	}
};
