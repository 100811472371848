import { ClockCircleOutlined }       from '@ant-design/icons';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { CalendarOutlined }          from '@ant-design/icons';
import { CheckCircleOutlined }       from '@ant-design/icons';
import doc                           from 'decorators/doc';
import model                         from 'decorators/model';
import { computed }                  from 'mobx';
import React                         from 'react';
import InterventionPrivateApiModel   from '../../abstracts/InterventionPrivateApiModel';

export const taskOperationStatusColors: Record<TaskOperationStatusReference, string> = {
	cancelled: '',
	done: '#118E55',
	scheduled: '#3473E2',
	suspended: '',
	to_reschedule: '#f00',
	to_schedule: '#F59A22',
};

export const taskOperationStatusIcons: Record<TaskOperationStatusReference, React.FC | null> = {
	cancelled: null,
	done: CheckCircleOutlined,
	scheduled: CalendarOutlined,
	suspended: null,
	to_reschedule: ExclamationCircleOutlined,
	to_schedule: ClockCircleOutlined,
};

@model.cacheDuration()
@model.staticLabel('Statut')
@model.urnResource('task_operation_status')
@doc.path('/task_operation_statuses/{?id}')
export default class TaskOperationStatusModel extends InterventionPrivateApiModel {
	public _filters: ModelFiltersExtended<{
		'reference': TaskOperationStatusReference;
	}> = {};

	@doc.string declare label: string;
	@doc.string declare reference: TaskOperationStatusReference;

	@computed
	public get color(): string {
		return taskOperationStatusColors[this.reference] || '';
	}

	@computed
	public get icon(): React.FC {
		return taskOperationStatusIcons[this.reference] || (() => null);
	}

	public hasReference(references: TaskOperationStatusReference[]) {
		return references.includes(this.reference);
	}
}