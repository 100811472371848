import doc from 'decorators/doc';
import model from 'decorators/model';
import { Timestampable }        from 'helpers/traits';
import { Blamable }             from 'helpers/traits';
import DirectoryPrivateApiModel from '../../abstracts/DirectoryPrivateApiModel';

@model.cacheDuration()
@model.staticLabel(`Type d'entreprise`)
@model.urnResource('enterprise_type')
@doc.path('/enterprise_types/{?id}')
export default class EnterpriseTypeModel extends Blamable(Timestampable(DirectoryPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'hasSiren': '0' | '1';
		'reference': DirectoryEnterpriseTypeReference;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'hasSiren': string;
		'id': string;
		'label': string;
	}> = {};

	@doc.boolean declare hasSiren: boolean;
	@doc.string declare label: string;
	@doc.string declare reference: DirectoryEnterpriseTypeReference;
}