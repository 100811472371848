import lazyWithRetry from 'tools/lazyWithRetry';

const EnterprisePartitionListPage = lazyWithRetry(() => import(
	/* webpackChunkName: "enterprisePartitionListPage" */
	'../../pages/EnterprisePartition/EnterprisePartitionListPage'));

const EnterprisePartitionDashboardPage = lazyWithRetry(() => import(
	/* webpackChunkName: "enterprisePartitionDashboardPage" */
	'../../pages/EnterprisePartition/EnterprisePartitionDashboardPage'));

export default [
	{
		component: EnterprisePartitionListPage,
		exact: true,
		path: '/enterprise_partitions',
	},
	{
		component: EnterprisePartitionDashboardPage,
		exact: true,
		path: '/sub_partitions/:subPartitionId/enterprise_partitions/:enterprisePartitionId',
	},
];
