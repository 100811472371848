import lazyWithRetry from 'tools/lazyWithRetry';

const ContractIterationItemAddPage = lazyWithRetry(
	() =>
		import(
			/* webpackChunkName: "contractIterationItemAddPage" */
			'../../pages/ContractIterationItem/ContractIterationItemAddPage/ContractIterationItemAddPage'),
);

export default [
	{
		component: ContractIterationItemAddPage,
		exact: true,
		path: '/contract_iteration_items/add/:contractIterationId',
	},
];
