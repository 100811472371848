import doc                 from 'decorators/doc';
import model               from 'decorators/model';
import { Timestampable }   from 'helpers/traits';
import FilePrivateApiModel from '../../abstracts/FilePrivateApiModel';

@model.staticLabel('Policy')
@model.urnResource('policy')
@doc.path('/stonecode_file_policies/{?id}')
export default class PolicyModel extends Timestampable(FilePrivateApiModel) {
	public _filters: ModelFiltersExtended<{
		'reference': FilePolicyReference;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'reference': string;
		'updatedAt': string;
	}> = {};

	@doc.string declare reference: FilePolicyReference;
}
