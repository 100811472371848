import { ApiConnector } from 'Connectors/ApiConnector';
import ConfigProxy      from 'tools/ConfigProxy';
import AbstractApiModel from './AbstractApiModel';

export const fileConnector = new ApiConnector({
	baseURL: ConfigProxy.getServiceConfig('file', 'api_endpoint'),
});

export default class FileApiModel extends AbstractApiModel {
	static get connector() {
		return fileConnector;
	}

	public static serviceName: ServiceName = 'file';
}
