import OwnerPartitionModel            from 'Models/supplier/OwnerPartitionModel';
import PeriodAggregatedDataModelModel from 'Models/supplier/PeriodAggregatedDataModel';
import PeriodStatusModel              from 'Models/supplier/PeriodStatusModel';
import doc                            from 'decorators/doc';
import model                          from 'decorators/model';
import { Timestampable }              from 'helpers/traits';
import { Blamable }                   from 'helpers/traits';
import SupplierPrivateApiModel        from '../../abstracts/SupplierPrivateApiModel';

@model.staticLabel(`Période d'achat`, false)
@model.urnResource('period')
@doc.path('/periods/{?id}')
export default class PeriodModel extends Blamable(Timestampable(SupplierPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'endDate[after]': string | Date;
		'endDate[before]': string | Date;
		'endDate[strictly_after]': string | Date;
		'endDate[strictly_before]': string | Date;
		'ownerPartition.partitionUrn': string;
		'periodStatus': id;
		'periodStatus.reference': SupplierPeriodStatusReference;
		'startDate[after]': string | Date;
		'startDate[before]': string | Date;
		'startDate[strictly_after]': string | Date;
		'startDate[strictly_before]': string | Date;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'endDate': string;
		'id': string;
		'startDate': string;
		'updatedAt': string;
	}> = {};

	@doc.date declare endDate: Moment;
	@doc.model(OwnerPartitionModel) declare ownerPartition: OwnerPartitionModel;
	@doc.model(PeriodStatusModel) declare periodStatus: PeriodStatusModel;
	@doc.date declare startDate: Moment;

	public aggregatedData = new PeriodAggregatedDataModelModel({ id: this.id });
}