import { urn } from 'helpers/ModelDictionary';

export default (partition: PartitionName, service: ServiceName, resource: string) => {
	return target => {
		Object.defineProperty(target, 'urnData', {
			configurable: false,
			enumerable: false,
			value: { partition, resource, service },
			writable: false,
		});

		return urn(partition, service, resource)(target);
	};
};