import BillableModel          from 'Models/invoice/BillableModel';
import BillableStatusModel    from 'Models/invoice/BillableStatusModel';
import doc from 'decorators/doc';
import model from 'decorators/model';
import { Blamable }           from 'helpers/traits';
import { Timestampable }      from 'helpers/traits';
import InvoicePrivateApiModel from '../../abstracts/InvoicePrivateApiModel';

@model.staticLabel('BillableStatusHistory')
@model.urnResource('billable_status_history')
@doc.path('/billable_status_histories/{?id}')
export default class BillableStatusHistoryModel extends Blamable(Timestampable(InvoicePrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'billable': id;
		'createdAt[after]': string;
		'createdAt[before]': string;
		'createdAt[strictly_after]': string;
		'createdAt[strictly_before]': string;
		'updatedAt[after]': string;
		'updatedAt[before]': string;
		'updatedAt[strictly_after]': string;
		'updatedAt[strictly_before]': string;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'updatedAt': string;
	}> = {};

	@doc.model(BillableModel) declare billable: BillableModel;
	@doc.model(BillableStatusModel) declare billableStatus: BillableStatusModel;
	@doc.string declare note:string;
}