import { computed }           from 'mobx';
import { getIdFromUrn }       from 'tools/UrnTools';
import InvoicePrivateApiModel from '../../abstracts/InvoicePrivateApiModel';

export type BillingGroupMetaReference = 'contract_urn' | 'contract_iteration_urn' | 'quotation_urn' | 'purchase_order_urn' | 'dc4' | 'framework_agreement_iteration_urn';

export default class BillingGroupMetaModel extends InvoicePrivateApiModel {
	public _filters: ModelFiltersExtended<{
		billingGroup: id;
		reference: BillingGroupMetaReference;
		value: string;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'reference': string;
		'updatedAt': string;
		'value': string;
	}> = {};

	static get path(): string {
		return '/billing_group_metas/{?id}';
	}

	@computed
	public get billingGroupId(): id {
		return this.get('billingGroup.id');
	}

	@computed
	public get reference(): BillingGroupMetaReference {
		return this.get('reference');
	}

	@computed
	public get value(): string {
		return this.get('value');
	}

	@computed
	public get valueId(): id {
		return getIdFromUrn(this.value);
	}
}
