export const blobToBase64 = (
	blob: Blob,
	onLoad: (base64: string | ArrayBuffer | null) => void,
	onError?: () => void,
) => {
	const reader = new FileReader();

	reader.readAsDataURL(blob);
	reader.onload = () => onLoad(reader.result);

	if (onError) {
		reader.onerror = onError;
	}
};

export const blobToBase64Async = (blob: Blob): Promise<string | ArrayBuffer | null> => {
	return new Promise((resolve, reject) => blobToBase64(blob, resolve, reject));
};

export const showBlob = (blob: Blob) => window.open(URL.createObjectURL(blob));

export const downloadBlob = (blob: Blob, name = 'file.txt'): void => {
	// Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
	const blobUrl = URL.createObjectURL(blob);

	// Create a link element
	const link = document.createElement('a');

	// Set link's href to point to the Blob URL
	link.href = blobUrl;
	link.download = name;

	// Append link to the body
	document.body.appendChild(link);

	// Dispatch click event on the link
	// This is necessary as link.click() does not work on the latest firefox
	link.dispatchEvent(
		new MouseEvent('click', {
			bubbles: true,
			cancelable: true,
			view: window,
		}),
	);

	// Remove link from body
	document.body.removeChild(link);
};

export const displayBlob = (blob: Blob, title: string): void => {
	if (blob.type !== 'application/pdf') {
		downloadBlob(blob, title);
		return;
	}
	const fileURL = URL.createObjectURL(blob);
	const newWindow = window.open('');
	if (newWindow) {
		const iframe = document.createElement('iframe');
		iframe.src = fileURL;
		iframe.style.width = '100%';
		iframe.style.height = '100vh';
		iframe.style.border = '0';
		const button = document.createElement('button');
		button.innerHTML = '&DownArrowBar; Télécharger le pdf';
		button.addEventListener('click', () => {
			downloadBlob(blob, title);
		});
		newWindow.document.body.append(iframe);
		newWindow.document.body.append(button);
		newWindow.document.head.insertAdjacentHTML('beforeend', `
		<style>
			* {
				margin: 0;
				padding; 0;
			}

			button {
				position: absolute;
				left: 50%;
				bottom: 2rem;
				transform: translateX(-50%);
				background: white;
				border: 1px solid #aaa;
				border-radius: 3px;
				color: black;
				padding: 1rem;
				box-shadow: 0 8px 13px -3px rgba(0,0,0,.3);
				cursor: pointer;
				font-family: sans-serif;
			}

			button:hover, button:focus {
				border-color: black;
				background-color: #f5f5f5;
				box-shadow: 0 8px 13px -8px rgba(0,0,0,.3);
			}
		</style>
		`);
		setTimeout(() => {
			newWindow.document.title = title;
		}, 50);
	}
	else {
		downloadBlob(blob, title);
	}
};