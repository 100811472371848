import PropertyTypeModel           from 'Models/intervention/PropertyTypeModel';
import PartitionModel              from 'Models/partition/PartitionModel';
import doc from 'decorators/doc';
import model from 'decorators/model';
import { Timestampable }           from 'helpers/traits';
import { Blamable }                from 'helpers/traits';
import InterventionPrivateApiModel from '../../abstracts/InterventionPrivateApiModel';

@model.staticLabel('Propriété', false)
@model.urnResource('property')
@doc.path('/properties/{?id}')
export default class PropertyModel extends Blamable(Timestampable(InterventionPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'createdAt[after]': string;
		'createdAt[before]': string;
		'createdAt[strictly_after]': string;
		'createdAt[strictly_before]': string;
		'partitionUrn': string;
		'propertyType': id;
		'propertyType.resourceType.reference': InterventionResourceTypeReference;
		'resourceProperties': id;
		'updatedAt[after]': string | Date;
		'updatedAt[before]': string | Date;
		'updatedAt[strictly_after]': string | Date;
		'updatedAt[strictly_before]': string | Date;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'label': string;
		'updatedAt': string;
	}> = {};

	@doc.string declare description: string;
	@doc.string declare label: string;
	@doc.modelUrn(PartitionModel) declare partition: PartitionModel;
	@doc.model(PropertyTypeModel) declare propertyType: PropertyTypeModel;
}