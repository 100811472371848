import doc                         from 'decorators/doc';
import model                       from 'decorators/model';
import { urn }                     from 'helpers/ModelDictionary';
import InterventionPrivateApiModel from '../../abstracts/InterventionPrivateApiModel';

export type ContactTypeReference = 'buying' | 'pre_intervention_reader' | 'pre_intervention_validator' | 'responsible' | 'signatory';

@doc.path('/contact_types/{?id}')
@model.cacheDuration()
@urn('$', 'intervention', 'contact_type')
export default class ContactTypeModel extends InterventionPrivateApiModel {
	public _filters: ModelFiltersExtended<{
		'reference': ContactTypeReference;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'label': string;
		'position': string;
	}> = {};

	@doc.string declare description: string;
	@doc.string declare label: string;
	@doc.string declare position: number;
	@doc.string declare reference: ContactTypeReference;
}
