import doc                    from 'decorators/doc';
import model                  from 'decorators/model';
import { Timestampable }      from 'helpers/traits';
import CommentPrivateApiModel from '../../abstracts/CommentPrivateApiModel';

@model.staticLabel('Policy')
@model.urnResource('policy')
@doc.path('/stonecode_comment_policies/{?id}')
export default class PolicyModel extends Timestampable(CommentPrivateApiModel) {
	public _filters: ModelFiltersExtended<{
		'reference': CommentPolicyReference;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'reference': string;
		'updatedAt': string;
	}> = {};

	@doc.string declare reference: CommentPolicyReference;
}
