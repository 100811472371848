import ContractIterationModel      from 'Models/sales/ContractIterationModel';
import QuotationModel              from 'Models/sales/QuotationModel';
import doc                         from 'decorators/doc';
import model                       from 'decorators/model';
import { Timestampable }           from 'helpers/traits';
import { Blamable }                from 'helpers/traits';
import InterventionPrivateApiModel from 'modelx/models/abstracts/InterventionPrivateApiModel';

@model.staticLabel('InterventionGroupOwner')
@model.urnResource('intervention_group_owner')
@doc.path('/intervention_group_owners/{?id}')
export default class InterventionGroupOwnerModel extends Blamable(Timestampable(InterventionPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'ownerUrn': Urn;
	}> = {};

	public _sorts: ModelSortsExtended<unknown> = {};

	@model.resolvableUrn({ attributeName: 'ownerUrn' })
	declare owner: QuotationModel | ContractIterationModel;
}