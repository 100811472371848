import Tooltip from 'antd/lib/tooltip';
import React   from 'react';

type TooltipRef = { close: () => void; };
const refs: React.MutableRefObject<TooltipRef | undefined>[] = [];

export default (props: React.ComponentProps<typeof Tooltip>) => {
	const [open, setOpen] = React.useState(false);
	const ref = React.useRef<TooltipRef>();

	React.useEffect(() => {
		refs.push(ref);

		return () => {
			refs.splice(refs.indexOf(ref), 1);
		};
	}, []);

	React.useImperativeHandle(ref, () => ({
		close: () => setOpen(false),
	}));

	return <Tooltip onOpenChange={v => setOpen(v)} open={open} overlayClassName="custom-tooltip" {...props} />;
};

export const closeAllTooltips = () => refs.forEach(ref => ref.current?.close());