import { LoggedApiConnector } from 'Connectors/LoggedApiConnector';
import ConfigProxy            from 'tools/ConfigProxy';
import InvoiceApiModel        from './InvoiceApiModel';

export const invoiceLoggedApiConnector = new LoggedApiConnector({
	baseURL: ConfigProxy.getServiceConfig('invoice', 'api_endpoint'),
});

export default class InvoicePrivateApiModel extends InvoiceApiModel {
	public static get connector() {
		return invoiceLoggedApiConnector;
	}
}
