import { Collection }                           from '@mathquis/modelx';
import { ApiCollection }                        from 'Collections/ApiCollection';
import InterventionGroupInterventionModel       from 'Models/intervention/InterventionGroupInterventionModel';
import InterventionModel                        from 'Models/intervention/InterventionModel';
import { IInterventionPlanningEventAttributes } from 'Models/intervention/InterventionPlanningEventModel';
import InterventionPlanningEventModel           from 'Models/intervention/InterventionPlanningEventModel';
import { collectionStaffMember }                from 'Models/intervention/InterventionPlanningEventModel';
import InterventionResourceModel                from 'Models/intervention/InterventionResourceModel';
import InterventionStatusModel                  from 'Models/intervention/InterventionStatusModel';
import PlanningModel                            from 'Models/intervention/PlanningModel';
import ResourceModel                            from 'Models/intervention/ResourceModel';
import { TaskMetaReference }                    from 'Models/intervention/TaskMetaModel';
import TaskOperationStatusModel                 from 'Models/intervention/TaskOperationStatusModel';
import StaffMemberModel                         from 'Models/rh/StaffMemberModel';
import VehicleModel                             from 'Models/vehicle/VehicleModel';
import { Buffer }                               from 'buffer';
import computedModels                           from 'decorators/computedModels';
import _flatten                                 from 'lodash/flatten';
import _uniq                                    from 'lodash/uniq';
import { makeObservable }                       from 'mobx';
import { observable }                           from 'mobx';
import { computed }                             from 'mobx';
import { action }                               from 'mobx';
import moment                                   from 'moment';
import Proto                                    from 'proto/proto';
import MercureInterventionPartitionListener     from 'services/MercureInterventionPartitionListener';
import { appStore }                             from 'stores';
import { getIdFromUrn }                         from 'tools/UrnTools';
import fetchPlanningCards                       from 'tools/fetchPlanningCards';
import { whenAsync }                            from 'tools/modelxTools';
import notificationApiError                     from 'tools/notificationApiError';
import sortBy                                   from 'tools/sortBy';
import AbstractModelXStore                      from './AbstractModelXStore';

const { PlanningInterventionCard } = Proto.Service.Intervention;

export default class InterventionPlanningStore extends AbstractModelXStore {

	public collectionAssignedVehicle = new ApiCollection(VehicleModel);
	public collectionResourceTechnician = new ApiCollection(ResourceModel);
	public interventionGroupInterventionCollection = new ApiCollection(InterventionGroupInterventionModel);
	public interventionStatusCollection = new ApiCollection(InterventionStatusModel);

	public mercure = new MercureInterventionPartitionListener(eventDataJson => {
		const eventData = typeof eventDataJson === 'string' ? JSON.parse(eventDataJson) : eventDataJson;

		const card = PlanningInterventionCard.decode(Buffer.from(eventData.data, 'base64'));

		// Si on est sur un planning technicien ou si la card fait partie des operationCategories du planning
		if (
			this.planningId === 0
			|| card.taskOperations.some(to => this.operationCategoryUrns.includes(to.operation?.operationCategory?.urn as Urn))
		) {
			// On ajoute l'objet "card" au planning
			this.addPlanningInterventionCard(card);

			this.setUpdatedAt(moment());
		}
	});

	public planningEventCollection = new Collection(InterventionPlanningEventModel);
	public planningId?: id;
	public taskOperationStatusCollection = new ApiCollection(TaskOperationStatusModel);

	@observable
	private _isSubmitting = false;

	private _lastFilterFrom: Date = new Date();
	private _lastFilterInterventionStatusId?: number = undefined;

	@observable
	private _updatedAt: string | undefined;

	public constructor() {
		super();
		makeObservable(this);
	}

	public get technicianUrns() {
		return _uniq(_flatten(this.planningEventCollection.map(m => m.technicianUrns)));
	}

	public addPlanningInterventionCard(card: Proto.Service.Intervention.PlanningInterventionCard) {
		// Création d'un nouvel objet planningEvent
		const planningEvent = new InterventionPlanningEventModel();
		planningEvent.setAttributesFromInterventionCard(card, true);

		// On cherche si l'objet planningEvent existe déjà
		const oldPlanningEvent = this.planningEventCollection.find(e => e.id === planningEvent.id);

		// S'il existe déjà
		if (oldPlanningEvent) {
			// On remplace ses data par celles du nouveau PlanningEvent
			oldPlanningEvent.set(planningEvent.attributes);
		} else {
			// On ajoute le nouveau PlanningEvent au tableau
			this.planningEventCollection.push(planningEvent);
		}

		this._refreshCollectionTechnician().then();
	}

	public clear() {
		super.clear();

		this.planningId = undefined;

		return this;
	}

	public async fetchInterventionCards(
		from: Date,
		interventionStatusId?: number,
		contractUrns?: string[],
		quotationUrns?: string[],
		taskZoneIds?: string[],
		staffMembers: string[] = [],
		vehicles: string[] = [],
	) {
		this._lastFilterFrom = from;
		this._lastFilterInterventionStatusId = interventionStatusId;

		try {
			this.setIsLoading(true);

			const params: ModelFilters<InterventionModel> = {
				'interventionResources.resource.ownerResource.entityUrn': [...staffMembers, ...vehicles],
				'interventionStatus': interventionStatusId,
				'scheduleStartDate[after]': from,
				'scheduleStartDate[before]': moment(from).add(7, 'days').subtract(1, 'minutes').toDate(),
				'taskZone': taskZoneIds,
				'taskZone.owner.ownerSubPartition.subPartitionUrn': appStore.subPartitionUrn,
			};

			if (this.operationCategoryUrns.length) {
				params['interventionOperations.taskOperation.operation.operationCategory'] = this.operationCategoryUrns;
			}

			if (contractUrns || quotationUrns) {
				let taskMetaValues: string[] = [];
				const taskMetaReferences: TaskMetaReference[] = [];

				if (contractUrns && contractUrns.length) {
					taskMetaValues = taskMetaValues.concat(contractUrns);
					taskMetaReferences.push('contract_urn');
				}

				if (quotationUrns && quotationUrns.length) {
					taskMetaValues = taskMetaValues.concat(quotationUrns);
					taskMetaReferences.push('quotation_urn');
				}

				params['interventionOperations.taskOperation.task.taskMetas.value'] = taskMetaValues;
				params['interventionOperations.taskOperation.task.taskMetas.reference'] = taskMetaReferences;
			}

			const interventionCards = await fetchPlanningCards(params);

			this.planningEventCollection.set(
				interventionCards.map(ic => new InterventionPlanningEventModel().setAttributesFromInterventionCard(ic)),
			);

			this._refreshCollectionTechnician().then();
			this.interventionGroupInterventionCollection
				.listBy(this.planningEventCollection.map(pe => pe.id), 'intervention')
				.then();

		} catch (err) {
			notificationApiError(err);

			throw err;
		} finally {
			this.setIsLoading(false);
		}
	}

	public get planning() {
		return appStore.collectionPlanning.getById(this.planningId || '') || new PlanningModel();
	}

	public get operationCategoryUrns() {
		return this.planning.collPlanningOperationCategory.map(m => m.getUrn('operationCategory'));
	}

	public async fetchResources() {

		await Promise.all([
			whenAsync(() => this.planning.collPlanningOperationCategory.isLoaded).then(async () => {
				await this.collectionResourceTechnician
					.setFilter('ownerSubPartition.subPartitionUrn', appStore.subPartitionUrn)
					.setFilter('resourceOperationCategories.operationCategory', this.operationCategoryUrns)
					.setFilter('resourceType.reference', 'technician')
					.list();
			}),

			this.planning.collPlanningResourceVehicle.whenIsLoaded(m => m.resource.ownerResource),
		]);

		await this.fetchVehicles();
	}

	public async fetchVehicles() {
		const allVehicleIds = this.planning.collPlanningResourceVehicle
			.map(m => m.resource.getId('entity'));

		const from = this._lastFilterFrom;
		const to = moment(from).add(7, 'days').toDate();

		await this.collectionAssignedVehicle
			.setFilter('vehicleSubPartitions.ownerSubPartition.subPartitionUrn', appStore.subPartitionUrn)
			.setFilter('vehicleSubPartitions.vehicleSubPartitionAssignments.startDate[before]', to)
			.setFilter('vehicleSubPartitions.vehicleSubPartitionAssignments.endDate[after]', from)
			.setFilter('archived', '0')
			.listBy(allVehicleIds);
	}

	public async initAsync(planning: PlanningModel) {
		this.clear();
		this.setIsLoading(true);

		this.planningId = planning.id;

		await Promise.all([
			this.interventionStatusCollection.setSort('label').list(),
			this.taskOperationStatusCollection.list(),
			(planning.id && this.fetchResources()),
		]);

		this.setIsLoading(false);
	}

	public refreshInterventionCards() {
		return this.fetchInterventionCards(
			this._lastFilterFrom,
			this._lastFilterInterventionStatusId,
		);
	}

	public setPlanningEventFromIntervention(
		intervention: InterventionModel,
		options?: {
			addTechnicianUrn?: string,
			addVehicleInterventionResource?: InterventionResourceModel,
			removeTechnicianUrn?: string,
			removeVehicle?: boolean,
		},
	) {
		const planningEvent = this.planningEventCollection?.find(pe => pe.id === intervention.id)
			|| new InterventionPlanningEventModel();

		const data: IInterventionPlanningEventAttributes = {
			...planningEvent.attributes,

			animated: true,
			endDate: intervention.scheduleEndDate,
			id: intervention.id,
			startDate: intervention.scheduleStartDate,
		};

		if (options) {
			if (options.addVehicleInterventionResource) {
				data.vehicleId = options.addVehicleInterventionResource.getId('resource');
				data.vehicleInterventionResourceId = options.addVehicleInterventionResource.id;
			}

			if (options.removeVehicle) {
				data.vehicleId = 0;
				data.vehicleInterventionResourceId = 0;
			}

			if (options.addTechnicianUrn) {
				data.technicianUrns = [...(data.technicianUrns || []), options.addTechnicianUrn];
			}

			if (options.removeTechnicianUrn) {
				data.technicianUrns = data.technicianUrns.filter(v => v !== options.removeTechnicianUrn);
			}
		}

		const newPlanningEvent = planningEvent.set(data).clone();

		this.planningEventCollection?.remove(planningEvent).push(newPlanningEvent);
	}

	@action
	public setSubmitting(value: boolean) {
		this._isSubmitting = value;
	}

	@action
	public setUpdatedAt(date: Moment) {
		this._updatedAt = date.toString();
	}

	@computed
	public get isSubmitting() {
		return this._isSubmitting;
	}

	@computed
	public get updatedAt() {
		return this._updatedAt ? moment(new Date(this._updatedAt)) : null;
	}

	@computedModels()
	public get assignedVehicles() {
		if (!this.collectionAssignedVehicle.isLoaded) {
			return [];
		}

		return sortBy(this.collectionAssignedVehicle.models.slice(), v => v.fullName.trim());
	}

	@computedModels()
	public get technicians() {
		const arr = this.collectionResourceTechnician
			.filter(r => r.entity instanceof StaffMemberModel)
			.map(r => r.entity as StaffMemberModel)
			.filter(v => !v.archived && v.id);

		return sortBy(arr, m => m.fullName);
	}

	private async _refreshCollectionTechnician() {
		const technicianUrns = this.technicianUrns;

		if (!collectionStaffMember.isLoading) {
			if (technicianUrns.some(urn => !collectionStaffMember.urns.includes(urn))) {
				await collectionStaffMember.listBy(technicianUrns.map(urn => getIdFromUrn(urn)));
			}
		}
	}
}
