import { RightOutlined }       from '@ant-design/icons';
import { LockOutlined }        from '@ant-design/icons';
import { MailOutlined }        from '@ant-design/icons';
import Button                  from 'antd/lib/button';
import Card                    from 'antd/lib/card';
import Form                    from 'components/Form';
import Input                   from 'components/Input';
import { observer }            from 'mobx-react';
import { Link }                from 'react-router-dom';
import React                   from 'react';
import { authenticationStore } from 'stores/index';
import ConfigProxy             from 'tools/ConfigProxy';
import notificationApiError    from 'tools/notificationApiError';
import LoginHeader             from './components/LoginHeader';
import './LoginPage.scss';

@observer
export default class LoginPage extends React.Component {
	public state = { loading: false };

	private _formRef = React.createRef<Form>();

	public componentDidMount() {
		document.title = `AURA ${ConfigProxy.get('PARTITION_NAME')}`;
	}

	public render() {
		const { loading } = this.state;

		return (
			<div className="LoginScreen">
				<Card>
					<LoginHeader />

					<div className="LoginScreen__welcomeMessage">
						Connexion à AURA {ConfigProxy.get('PARTITION_NAME')}
					</div>

					<div className="LoginScreen__cardBody">
						<div className="LoginScreen__form">
							<Form
								layout="vertical"
								onFinish={this._handleOnFinish}
								ref={this._formRef}
							>
								<Form.Item
									name="username"
									required={true}
								>
									<Input
										className="login-input"
										placeholder="Votre adresse mail"
										prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
									/>
								</Form.Item>

								<Form.Item
									name="password"
									required={true}
								>
									<Input.Password
										className="login-input"
										placeholder="Votre mot de passe"
										prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
										type="password"
									/>
								</Form.Item>

								<Form.Item>
									<div className="LoginScreen__formFooter">
										<Button
											disabled={loading}
											htmlType="submit"
											loading={loading}
											size="large"
											type="primary"
										>
											Connectez-vous
											<RightOutlined />
										</Button>
									</div>
								</Form.Item>
							</Form>
						</div>

						<div className="LoginScreen__resetPassword">
							<Link to="/reset_password">
								{`Mot de passe oublié `}<RightOutlined />
							</Link>
						</div>
					</div>
				</Card>
			</div>
		);
	}

	private _handleOnFinish = values => {
		this.setState({ loading: true });

		authenticationStore
			.login(values.username, values.password)
			.catch(err => notificationApiError(err, { message: 'Erreur lors de la connexion' }))
			.finally(() => this.setState({ loading: false }));
	};
}
