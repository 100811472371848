import doc from 'decorators/doc';
import model from 'decorators/model';
import VehiclePrivateApiModel from '../../abstracts/VehiclePrivateApiModel';

@model.cacheDuration()
@model.staticLabel(`Type d'infraction`)
@model.urnResource('violation_type')
@doc.path('/violation_types/{?id}')
export default class ViolationTypeModel extends VehiclePrivateApiModel {
	public _filters: ModelFiltersExtended<{
		'reference': VehicleViolationTypeReference;
	}> = {};

	public _sorts: ModelSortsExtended<unknown> = {};

	@doc.string declare label: string;
	@doc.string declare reference: VehicleViolationTypeReference;
}