import ClientPartitionModel     from 'Models/directory/ClientPartitionModel';
import SubPartitionModel        from 'Models/partition/SubPartitionModel';
import doc                      from 'decorators/doc';
import model                    from 'decorators/model';
import { Timestampable }        from 'helpers/traits';
import { Blamable }             from 'helpers/traits';
import DirectoryPrivateApiModel from 'modelx/models/abstracts/DirectoryPrivateApiModel';

@model.staticLabel('Établissement')
@model.urnResource('client')
@doc.path('/clients/{?id}')
export default class ClientModel extends Blamable(Timestampable(DirectoryPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'clientPartition': id;
		'clientPartition.company': id;
		'clientPartition.company.name': string;
		'clientPartition.enterprisePartition': id;
		'clientPartition.enterprisePartition.contacts': id;
		'clientPartition.enterprisePartition.enterprise': id;
		'clientPartition.enterprisePartition.partitionUrn': Urn;
		'clientPartition.number': number;
		'clientPartition.reference': DirectoryClientPartitionReference;
		'enabled': '0' | '1';
		'subPartitionUrn': Urn;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'clientPartition.company.name': string;
		'clientPartition.enterprisePartition.enterprise.name': string;
		'id': string;
	}> = {};

	@doc.model(ClientPartitionModel) declare clientPartition: ClientPartitionModel;
	@doc.boolean declare enabled: boolean;
	@doc.modelUrn(SubPartitionModel) declare subPartition: SubPartitionModel;

	public get clientPartitionId() {
		return this.get('clientPartition.id');
	}
}