import doc                    from 'decorators/doc';
import model                  from 'decorators/model';
import { Timestampable }      from 'helpers/traits';
import { Blamable }           from 'helpers/traits';
import InvoicePrivateApiModel from 'modelx/models/abstracts/InvoicePrivateApiModel';

@model.cacheDuration()
@model.staticLabel('Méthode de paiement')
@model.urnResource('payment_mode_type')
@doc.path('/payment_mode_types/{?id}')
export default class PaymentModeTypeModel extends Blamable(Timestampable(InvoicePrivateApiModel)) {
	public _filters: ModelFiltersExtended<unknown> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'label': string;
		'shortLabel': string;
	}> = {};

	@doc.string declare label: string;
	@doc.string declare reference: InvoicePaymentModeTypeReference;
	@doc.string declare shortLabel: string;
}
