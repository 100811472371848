/* eslint-disable max-classes-per-file,@typescript-eslint/no-explicit-any,@typescript-eslint/ban-ts-comment */

import { IResolvableOptions } from '@mathquis/modelx-resolvables/lib/types/AbstractResolvableModel';
import { ModelClass }         from '@mathquis/modelx/lib/types/collection';
import StaffMemberModel       from 'Models/rh/StaffMemberModel';
import resolvableUrn          from 'decorators/resolvableUrn';
import { computed }           from 'mobx';
import AbstractApiModel       from 'modelx/models/abstracts/AbstractApiModel';
import moment                 from 'moment';

export interface IBlamedModel extends AbstractApiModel {
	email: string,
	fullName: string;
	urn: Urn
}

export class BlamableEmptyModel extends AbstractApiModel implements IBlamedModel {
	public constructor(props) {
		super(props);

		this.setIsLoaded(true);
	}

	public get email(): string {
		return 'Inconnu';
	}

	public get fullName(): string {
		return this.id ? 'Inconnu' : 'le système';
	}

	public get urn(): Urn {
		return '$:partition:partition:7';
	}
}

export function Blamable<T extends ModelClass<AbstractApiModel>>(Base: T, options?: IResolvableOptions) {
	const c = class Blamable extends Base {
		declare createdBy: IBlamedModel | StaffMemberModel;
		declare updatedBy: IBlamedModel | StaffMemberModel;

		constructor(...args: any[]) {
			super(...args);
		}
	};

	resolvableUrn({
		attributeName: '',
		cache: true,
		unresolved: BlamableEmptyModel,
		...options,
	})(c.prototype, 'createdBy');

	resolvableUrn({
		attributeName: '',
		cache: true,
		unresolved: BlamableEmptyModel,
		...options,
	})(c.prototype, 'updatedBy');

	return c;
}

export function Media<T extends ModelClass<AbstractApiModel>>(Base: T) {
	return class Media extends Base {
		private _blob;

		constructor(...args: any[]) {
			super(...args);
		}

		public fetch(options?: ApiConnectorOptions<this>): Promise<this> {
			return super.fetch({
				...options,
				responseType: 'blob',
			});
		}

		protected onFetchSuccess(result, options: ApiConnectorOptions<this>): void {
			super.onFetchSuccess(result, options);

			this._blob = result.res.data;
		}

		public get blob() {
			return this._blob;
		}
	};
}

export function Timestampable<T extends ModelClass<AbstractApiModel>>(Base: T) {
	const c = class Timestampable extends Base {
		public get createdAt(): Moment {
			return moment(this.get('createdAt', moment()));
		}

		public get updatedAt(): Moment {
			return moment(this.get('updatedAt', moment()));
		}

		public get createdAtFormatted(): string {
			return this.createdAt.format('L à LT');
		}

		public get updatedAtFormatted(): string {
			return this.updatedAt.format('L à LT');
		}

		constructor(...args: any[]) {
			super(...args);
		}
	};

	computed(c, 'createdAt');
	computed(c, 'updatedAt');

	return c;
}
