import { computed }                from 'mobx';
import InterventionPrivateApiModel from '../../abstracts/InterventionPrivateApiModel';

export default class TaskZoneResourceDocumentTypeModel extends InterventionPrivateApiModel {
	public _filters: ModelFiltersExtended<{
		'taskZone': id;
	}> = {};

	static get path(): string {
		return '/task_zone_resource_document_types/{?id}';
	}

	@computed
	public get resourceDocumentTypeId() {
		return this.get('resourceDocumentType.id', 0);
	}

	public _sorts: ModelSortsExtended<{
		'id': string;
		'resourceDocumentType.position': string;
		'updatedAt': string;
	}> = {};
}
