import QuotationModel       from 'Models/sales/QuotationModel';
import doc                  from 'decorators/doc';
import model                from 'decorators/model';
import { Timestampable }    from 'helpers/traits';
import { Blamable }         from 'helpers/traits';
import SalesPrivateApiModel from 'modelx/models/abstracts/SalesPrivateApiModel';

@model.staticLabel('Clôture de devis')
@model.urnResource('contract_iteration_closing')
@doc.path('/quotation_closings/{?id}')
export default class QuotationClosingModel extends Blamable(Timestampable(SalesPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'quotation': id;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
	}> = {};

	@doc.modelUrn(QuotationModel) declare quotation: QuotationModel;
	@doc.date declare closingDate: Moment;
}
