import CommentModel                from 'Models/comment/CommentModel';
import TaskZoneModel               from 'Models/intervention/TaskZoneModel';
import doc                         from 'decorators/doc';
import model                       from 'decorators/model';
import { Timestampable }           from 'helpers/traits';
import { Blamable }                from 'helpers/traits';
import InterventionPrivateApiModel from 'modelx/models/abstracts/InterventionPrivateApiModel';

@model.staticLabel('RiskAssessment')
@model.urnResource('risk_assessment')
@doc.path('/risk_assessments/{?id}')
export default class RiskAssessmentModel extends Blamable(Timestampable(InterventionPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'createdAt[after]': string;
		'createdAt[before]': string;
		'createdAt[strictly_after]': string;
		'createdAt[strictly_before]': string;
		'taskZone': id;
		'updatedAt[after]': string | Date;
		'updatedAt[before]': string | Date;
		'updatedAt[strictly_after]': string | Date;
		'updatedAt[strictly_before]': string | Date;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'updatedAt': string;
	}> = {};

	@doc.model(TaskZoneModel) declare taskZone: TaskZoneModel;

	@model.commentResolvable('risk_assessment_special_remark')
	public declare specialRemark: CommentModel;
	@model.commentResolvable('risk_assessment_supporting_note')
	public declare supportingNote: CommentModel;
}