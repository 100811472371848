export default function (value: string, masculine = true) {
	return target => {
		Object.defineProperty(target, 'isStaticLabelMasculine', {
			configurable: false,
			enumerable: false,
			value: masculine,
			writable: false,
		});

		Object.defineProperty(target, 'staticLabel', {
			configurable: false,
			enumerable: false,
			value,
			writable: false,
		});
	};
}