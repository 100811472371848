import PagedCollection         from 'Collections/PagedCollection';
import StaffMemberModel        from 'Models/rh/StaffMemberModel';
import StaffModel              from 'Models/rh/StaffModel';
import Col                     from 'antd/lib/col';
import Row                     from 'antd/lib/row';
import AbstractForm            from 'components/AbstractForm/AbstractForm';
import Form                    from 'components/Form';
import Input                   from 'components/Input';
import { ROW_SM_GUTTER }       from 'constants/Sizes';
import React                   from 'react';
import { authenticationStore } from 'stores';
import { notificationSuccess } from 'tools/notification';

export interface IStaffPasswordFormProps {
	onSuccess?: (model: StaffModel) => void;
}

export default class StaffPasswordForm extends AbstractForm<IStaffPasswordFormProps> {

	public render() {
		return (
			<Form onFinish={this._onFinish} ref={this.formRef}>
				<Row gutter={ROW_SM_GUTTER}>
					<Col>
						<Form.Item label="Nouveau mot de passe" name="plainPassword" required={true}>
							<Input placeholder="Mot de passe" type="password" />
						</Form.Item>
					</Col>
					<Col>
						<Form.Item
							label="Confirmer le mot de passe"
							name="confirmPassword"
							rules={[
								{ message: 'Veuillez confirmer le mot de passe', required: true },
								{ validator: this._checkConfirmPlainPassword },
							]}
						>
							<Input placeholder="Confirmer le mot de passe" type="password" />
						</Form.Item>
					</Col>
				</Row>
			</Form>
		);
	}

	private _checkConfirmPlainPassword = (rule, value, callback) => {
		if (
			value &&
			value !== this.formRef.current?.getFieldValue('plainPassword')
		) {
			callback('Le mot de passe ne correspond pas');
		} else {
			callback();
		}
	};

	private _onFinish = async values => {
		const { onSuccess } = this.props;

		this.setSubmitting(true);

		try {
			const staffMemberCollection = new PagedCollection(StaffMemberModel);
			await staffMemberCollection.setFilter('id', authenticationStore.session.ownerId).setItemsPerPage(1).list();
			const staffMember = staffMemberCollection.first();

			if (staffMember) {
				const staff = new StaffModel({ id: staffMember.getId('staff') });

				await staff.patch(values);

				if (onSuccess) {
					onSuccess(staff);
				}

				notificationSuccess(`Le mot de passe a bien été modifié`);
			} else {
				throw new Error('Une erreur est survenue pendant la modification du mot de passe');
			}

		} catch (err) {
			this._onError(err);
		} finally {
			this.setSubmitting(false);
		}
	};
}
