import { resolvable }                from '@mathquis/modelx-resolvables';
import TaskModel                     from 'Models/intervention/TaskModel';
import QuotationItemEventStatusModel from 'Models/sales/QuotationItemEventStatusModel';
import QuotationItemModel            from 'Models/sales/QuotationItemModel';
import nestedResolvable              from 'decorators/nestedResolvable';
import { urn }                       from 'helpers/ModelDictionary';
import { Timestampable }             from 'helpers/traits';
import { Blamable }                  from 'helpers/traits';
import { computed }                  from 'mobx';
import moment                        from 'moment';
import SalesPrivateApiModel          from '../../abstracts/SalesPrivateApiModel';

@urn('$', 'sales', 'quotation_item_event')
export default class QuotationItemEventModel extends Blamable(Timestampable(SalesPrivateApiModel)) {

	static get path(): string {
		return '/quotation_item_events/{?id}';
	}

	public _filters: ModelFiltersExtended<{
		'quotationItem': id;
		'quotationItem.quotation': id;
		'quotationItemEventStatus.reference': string;
	}> = {};

	@resolvable(QuotationItemModel, { attributeName: 'quotationItem' })
	declare quotationItem: QuotationItemModel;

	@resolvable(QuotationItemEventStatusModel, { attributeName: 'quotationItemEventStatus' })
	declare quotationItemEventStatus: QuotationItemEventStatusModel;

	@computed
	public get scheduleEndDate(): Moment {
		return moment(this.get('scheduleEndDate', moment()));
	}

	@computed
	public get scheduleStartDate(): Moment {
		return moment(this.get('scheduleStartDate', moment()));
	}

	@resolvable(TaskModel, { attributeName: 'taskUrn' })
	declare task: TaskModel;

	@nestedResolvable()
	public get quotation() {
		return this.quotationItem.quotation;
	}
}
