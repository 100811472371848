import ViolationTypeModel     from 'Models/vehicle/ViolationTypeModel';
import doc                    from 'decorators/doc';
import model                  from 'decorators/model';
import VehiclePrivateApiModel from '../../abstracts/VehiclePrivateApiModel';

@model.staticLabel('Infraction', false)
@model.urnResource('violation')
@doc.path('/violations/{?id}')
export default class ViolationModel extends VehiclePrivateApiModel {
	public _filters: ModelFiltersExtended<{
		'reference': VehicleViolationReference;
		'violationType': id;
	}> = {};

	public _sorts: ModelSortsExtended<unknown> = {};

	@doc.string declare label: string;
	@doc.string declare reference: VehicleViolationReference;
	@doc.model(ViolationTypeModel) declare violationType: ViolationTypeModel;
}