import React from 'react';

type IconSvgProps = {
	className?: string;
	color?: string;
	size?: number;
	svg: unknown;
};

const defaultSize = 20;
const defaultClassNames = 'anticon icon-svg';

export default ({ className, color, size, svg }: IconSvgProps) => {
	const classNames = className ? className + ' ' + defaultClassNames : defaultClassNames;

	return (
		<i className={classNames}>
			{!!svg && <div style={{
				backgroundColor: color,
				height: size || defaultSize,
				mask: `url(${svg}) no-repeat center`,
				maskImage: `url(${svg})`,
				WebkitMaskImage: `url(${svg})`,
				width: size || defaultSize,
			}} />}
		</i>
	);
};