import model                       from 'decorators/model';
import moonSvg                     from 'icons/moon.svg';
import InterventionPrivateApiModel from '../../abstracts/InterventionPrivateApiModel';

@model.staticLabel('Tag')
@model.urnResource('tag')
export default class TagModel extends InterventionPrivateApiModel {
	public _filters: ModelFiltersExtended<{
		'targetUrn': string,
	}> = {};
	public _sorts: ModelSortsExtended<unknown> = {};

	static get path(): string {
		return '/stonecode_tag_taggable_tags/{?id}';
	}

	public get targetUrn(): Urn {
		return this.get('targetUrn');
	}

	public get tagReference(): TagInterventionReference {
		return this.get('tagReference');
	}

	public get tagValue(): TagInterventionScheduleOptionValue {
		return this.get('tagValue');
	}

	public get tagReferenceLabel(): string {
		switch (this.tagReference) {
			case 'schedule_option':
				return 'Option de planification';
			case 'block_status':
				return 'État du blocage';
		}
	}

	public get tagValueLabel(): string {
		switch (this.tagValue) {
			case 'multi_day':
				return 'Intervention de nuit';
			default:
				return this.tagReferenceLabel;
		}
	}

	public getTagValueIcon() {
		switch (this.tagValue) {
			case 'multi_day':
				return moonSvg;
		}
	}
}
