import { urn }              from 'helpers/ModelDictionary';
import { computed }         from 'mobx';
import SalesPrivateApiModel from '../../abstracts/SalesPrivateApiModel';

export type QuotationActorGroupReference = 'QUOTATION_ACTOR_GROUP_COMMERCIAL' | 'QUOTATION_ACTOR_GROUP_BUYING';

@urn('$', 'sales', 'quotation_actor_group')
export default class QuotationActorGroupModel extends SalesPrivateApiModel {
	public _filters: ModelFiltersExtended<{
		reference: QuotationActorGroupReference;
	}> = {};

	static get path(): string {
		return '/quotation_actor_groups/{?id}';
	}

	public static cacheDuration = 3600;

	@computed
	public get label(): string {
		return this.get('label');
	}

	@computed
	public get reference(): string {
		return this.get('reference');
	}
}
