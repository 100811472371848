import { DownOutlined }  from '@ant-design/icons/lib';
import Dropdown          from 'antd/lib/dropdown';
import { MenuProps }     from 'antd/lib/menu';
import AppForm           from 'components/AppForm';
import FormModal         from 'components/FormModal/FormModal';
import StaffPasswordForm from 'forms/StaffPasswordForm';
import { observer }      from 'mobx-react';
import React             from 'react';
import { appStore }      from 'stores';
import navigate          from 'tools/navigate';

import LogoutLink        from './LogoutLink';
import '../css/AppUserMenu.scss';

interface IAppUserMenuProps {
	style?: React.CSSProperties;
}

@observer
export default class AppUserMenu extends React.Component<IAppUserMenuProps> {

	public formRef = React.createRef<FormModal>();

	public state = { userPasswordFormVisible: false };

	public render() {
		const { style } = this.props;

		const items: MenuProps['items'] = [
			{
				key: '2',
				label: `Téléchargements`,
				onClick: () => navigate.toFiles(true),
			},
			{
				type: 'divider',
			},
			{
				key: '0',
				label: `Changer mon mot de passe`,
				onClick: () => AppForm.open(StaffPasswordForm, {}, {
					title: `Modifier le mot de passe`,
					width: 400,
				}),
			},
			{
				type: 'divider',
			},
			{
				key: '1',
				label: <LogoutLink />,
			},
		];

		return (
			<div className="AppUserMenu__container" style={style}>
				<Dropdown menu={{ items }} trigger={['click']}>
					<div className="AppUserMenu__title">
						<div className="AppUserMenu__name">
							{appStore.staffMember.fullName}
						</div>
						<DownOutlined />
					</div>
				</Dropdown>
			</div>
		);
	}
}
