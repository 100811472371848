import { urn }                     from 'helpers/ModelDictionary';
import InterventionPrivateApiModel from '../../abstracts/InterventionPrivateApiModel';

export type ResourceTypeReference = 'vehicle' | 'technician';

@urn('$', 'intervention', 'resource_type')
export default class ResourceTypeModel extends InterventionPrivateApiModel {

	static get path(): string {
		return '/resource_types/{?id}';
	}

	public static cacheDuration = 3600;

	public get label(): string {
		return this.get('label', '');
	}

	public get reference(): ResourceTypeReference {
		return this.get('reference', '');
	}
}