import PaymentModeTypeModel   from 'Models/invoice/PaymentModeTypeModel';
import PartitionModel         from 'Models/partition/PartitionModel';
import doc                    from 'decorators/doc';
import model                  from 'decorators/model';
import { Timestampable }      from 'helpers/traits';
import { Blamable }           from 'helpers/traits';
import InvoicePrivateApiModel from 'modelx/models/abstracts/InvoicePrivateApiModel';

@model.staticLabel('Mode de paiement')
@model.urnResource('payment_mode')
@doc.path('/payment_modes/{?id}')
export default class PaymentModeModel extends Blamable(Timestampable(InvoicePrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'createdAt[after]': string;
		'createdAt[before]': string;
		'createdAt[strictly_after]': string;
		'createdAt[strictly_before]': string;
		'partitionUrn': string;
		'paymentModeType': id;
		'updatedAt[after]': string | Date;
		'updatedAt[before]': string | Date;
		'updatedAt[strictly_after]': string | Date;
		'updatedAt[strictly_before]': string | Date;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'position': string;
		'updatedAt': string;
	}> = {};

	@doc.modelUrn(PartitionModel) declare partition: PartitionModel;
	@doc.model(PaymentModeTypeModel) declare paymentModeType: PaymentModeTypeModel;
}