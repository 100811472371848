import { urn }                from 'helpers/ModelDictionary';
import { Blamable }           from 'helpers/traits';
import { Timestampable }      from 'helpers/traits';
import InvoicePrivateApiModel from '../../abstracts/InvoicePrivateApiModel';

@urn('$', 'invoice', 'currency')
export default class CurrencyModel extends Blamable(Timestampable(InvoicePrivateApiModel)) {

	static get path(): string {
		return '/currencies/{?id}';
	}

	public _sorts: ModelSortsExtended<{
		'id': string;
		'label': string;
	}> = {};

	public static cacheDuration = 3600;

	public get code(): string {
		return this.get('code', '');
	}

	public get label(): string {
		return this.get('label', '');
	}
}