import { ApiConnector } from 'Connectors/ApiConnector';
import ConfigProxy      from 'tools/ConfigProxy';
import AbstractApiModel from './AbstractApiModel';

export const commentConnector = new ApiConnector({
	baseURL: ConfigProxy.getServiceConfig('comment', 'api_endpoint'),
});

export default class CommentApiModel extends AbstractApiModel {
	static get connector() {
		return commentConnector;
	}

	public static serviceName: ServiceName = 'comment';
}
