import doc                  from 'decorators/doc';
import model                from 'decorators/model';
import { Timestampable }    from 'helpers/traits';
import { Blamable }         from 'helpers/traits';
import SalesPrivateApiModel from 'modelx/models/abstracts/SalesPrivateApiModel';

@model.staticLabel('Bon de commande')
@model.urnResource('purchase_order')
@doc.path('/purchase_orders/{?id}')
export default class PurchaseOrderModel extends Blamable(Timestampable(SalesPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'purchaseOrderTargets': id;
		'purchaseOrderTargets.targetUrn': Urn;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
	}> = {};

	@doc.date declare date: Moment;
	@doc.number declare amount: number;
	@doc.string declare reference: SalesPurchaseOrderReference;
}
