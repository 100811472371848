import { computed }                from 'mobx';
import InterventionPrivateApiModel from '../../abstracts/InterventionPrivateApiModel';

export default class AnomaliesFlagModel extends InterventionPrivateApiModel {
	public _filters: ModelFiltersExtended<{
		intervention: id;
	}> = {};

	static get path(): string {
		return '/anomalies_flags/{?id}';
	}

	@computed
	public get interventionId(): id {
		return this.get('intervention.id', 0);
	}

	@computed
	public get flag(): number {
		return this.get('flag', 0);
	}

	@computed
	public get count(): number {
		return this.get('count', 0);
	}

}
