import lazyWithRetry from 'tools/lazyWithRetry';

const LoginPage = lazyWithRetry(() => import(
	/* webpackChunkName: "LoginPage" */
	'../../pages/LoginPage/LoginPage'));

const ChangePasswordPage = lazyWithRetry(() => import(
	/* webpackChunkName: "ChangePasswordPage" */
	'../../pages/ChangePasswordPage/ChangePasswordPage'));

const ResetPasswordPage = lazyWithRetry(() => import(
	/* webpackChunkName: "ResetPasswordPage" */
	'../../pages/ResetPasswordPage/ResetPasswordPage'));

export default [
	{
		component: LoginPage,
		exact: true,
		path: '/login',
	},
	{
		component: ChangePasswordPage,
		exact: true,
		path: '/change_password/:token',
	},
	{
		component: ResetPasswordPage,
		exact: true,
		path: '/reset_password',
	},
];
