// eslint-disable-next-line max-classes-per-file
import { resolvable }              from '@mathquis/modelx-resolvables';
import PartitionModel              from 'Models/partition/PartitionModel';
import StaffMemberModel            from 'Models/rh/StaffMemberModel';
import VehicleModel                from 'Models/vehicle/VehicleModel';
import resolvableUrn               from 'decorators/resolvableUrn';
import { urn }                     from 'helpers/ModelDictionary';
import { Blamable }                from 'helpers/traits';
import { Timestampable }           from 'helpers/traits';
import { computed }                from 'mobx';
import { getIdFromUrn }            from 'tools/UrnTools';
import { getEntityFromUrn }        from 'tools/UrnTools';
import AbstractApiModel            from '../../abstracts/AbstractApiModel';
import InterventionPrivateApiModel from '../../abstracts/InterventionPrivateApiModel';

export interface IEntityModel extends AbstractApiModel {
	fullName: string,
	urn: Urn
}

export class EntityEmptyModel extends AbstractApiModel implements IEntityModel {
	public get fullName(): string {
		return 'Chargement...';
	}
}

@urn('$', 'intervention', 'owner_resource')
export default class OwnerResourceModel extends Blamable(Timestampable(InterventionPrivateApiModel)) {

	static get path(): string {
		return '/owner_resources/{?id}';
	}

	public _filters: ModelFiltersExtended<{
		'createdAt[after]': string;
		'createdAt[before]': string;
		'createdAt[strictly_after]': string;
		'createdAt[strictly_before]': string;
		'entityUrn': string;
		'partitionUrn': string;
		'updatedAt[after]': string;
		'updatedAt[before]': string;
		'updatedAt[strictly_after]': string;
		'updatedAt[strictly_before]': string;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'updatedAt': string;
	}> = {};

	@computed
	public get isVehicle() {
		return getEntityFromUrn(this.entityUrn) === 'vehicle';
	}

	@computed
	public get isTechnician() {
		return getEntityFromUrn(this.entityUrn) === 'staff_member';
	}

	public get entityUrn(): string {
		return this.get('entityUrn.@urn', '') || this.get('entityUrn', '');
	}

	@computed
	public get entityId(): id {
		return parseInt(getIdFromUrn(this.entityUrn));
	}

	@resolvableUrn({ attributeName: 'entityUrn' })
	declare entity: EntityEmptyModel | StaffMemberModel | VehicleModel;

	@resolvable(PartitionModel, { attributeName: 'partitionUrn' })
	declare partition: PartitionModel;
}