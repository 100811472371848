import lazyWithRetry from 'tools/lazyWithRetry';

const ContractIterationAdministrativeNotificationListPage = lazyWithRetry(() => import(
	/* webpackChunkName: "notificationDashboardPage" */
	'../../pages/Anomaly/ContractIterationAdministrativeAnomaliesListPage/ContractIterationAdministrativeAnomaliesListPage'));

const QuotationAdministrativeNotificationListPage = lazyWithRetry(() => import(
	/* webpackChunkName: "notificationDashboardPage" */
	'../../pages/Anomaly/QuotationAdministrativeAnomaliesListPage/QuotationAdministrativeAnomaliesListPage'));

const OperationalNotificationListPage = lazyWithRetry(() => import(
	/* webpackChunkName: "notificationDashboardPage" */
	'../../pages/Anomaly/OperationalAnomaliesListPage/OperationalAnomaliesListPage'));

export default [
	{
		component: ContractIterationAdministrativeNotificationListPage,
		exact: true,
		path: '/alerts/contracts/administrative',
	},
	{
		component: QuotationAdministrativeNotificationListPage,
		exact: true,
		path: '/alerts/quotations/administrative',
	},
	{
		component: OperationalNotificationListPage,
		exact: true,
		path: '/alerts/operational',
	},
];
