import RightModel           from 'Models/rights/RightModel';
import doc                  from 'decorators/doc';
import model                from 'decorators/model';
import { urn }              from 'helpers/ModelDictionary';
import SalesPrivateApiModel from '../../abstracts/SalesPrivateApiModel';

export type ContactTypeReference = 'buying';

@doc.path('/contact_types/{?id}')
@model.cacheDuration()
@urn('$', 'sales', 'contact_type')
export default class ContactTypeModel extends SalesPrivateApiModel {
	public _filters: ModelFiltersExtended<{
		'reference': ContactTypeReference;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'label': string;
	}> = {};

	@doc.string declare description: string;
	@doc.string declare label: string;
	@doc.string declare reference: ContactTypeReference;
	@doc.modelUrn(RightModel) declare right: RightModel;
}
