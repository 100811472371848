import CompanyModel             from 'Models/directory/CompanyModel';
import doc                      from 'decorators/doc';
import model                    from 'decorators/model';
import { Timestampable }        from 'helpers/traits';
import { Blamable }             from 'helpers/traits';
import { computed }             from 'mobx';
import { getCountryName }       from 'tools/Countries';
import DirectoryPrivateApiModel from '../../abstracts/DirectoryPrivateApiModel';

@model.staticLabel('CompanyAddress')
@model.urnResource('company_address')
@doc.path('/company_addresses/{?id}')
export default class CompanyAddressModel extends Blamable(Timestampable(DirectoryPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'company': id;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
	}> = {};

	@doc.string declare address: string;
	@doc.string declare addressBis: string;
	@doc.string declare city: string;
	@doc.model(CompanyModel) declare company: CompanyModel;
	@doc.string declare country: string;
	@doc.string declare zipCode: string;

	@computed
	public get countryName(): string {
		return getCountryName(this.country);
	}

	@computed
	public get fullAddress() {
		return [this.address, this.zipCode, this.city, this.countryName].filter(s => !!s).join(' ');
	}
}