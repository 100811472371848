import { resolvable }         from '@mathquis/modelx-resolvables';
import ContractIterationModel from 'Models/sales/ContractIterationModel';
import doc                    from 'decorators/doc';
import model                  from 'decorators/model';
import SalesPrivateApiModel   from 'modelx/models/abstracts/SalesPrivateApiModel';

@model.staticLabel('ContractIterationReconduction')
@model.urnResource('contract_iteration_reconduction')
@doc.path('/contract_iteration_reconductions/{?id}')
export default class ContractIterationReconductionModel extends SalesPrivateApiModel {
	public _filters: ModelFiltersExtended<{
		contractIterationReconducted: id;
		contractIterationReconduction: id;
	}> = {};

	public _sorts: ModelSortsExtended<unknown> = {};

	@resolvable(ContractIterationModel, { attributeName: 'contractIterationReconducted' })
	declare contractIterationReconducted: ContractIterationModel;

	@resolvable(ContractIterationModel, { attributeName: 'contractIterationReconduction' })
	declare contractIterationReconduction: ContractIterationModel;

	@doc.string declare revisionClause: string;
}
