import CommentModel                      from 'Models/comment/CommentModel';
import OperationTypeModel                from 'Models/intervention/OperationTypeModel';
import AccountingAnalyticCodeModel       from 'Models/invoice/AccountingAnalyticCodeModel';
import AccountingCodeModel               from 'Models/invoice/AccountingCodeModel';
import DivisionalClassModel              from 'Models/invoice/DivisionalClassModel';
import ActivityTypeActivityCategoryModel from 'Models/sales/ActivityTypeActivityCategoryModel';
import doc                               from 'decorators/doc';
import model                             from 'decorators/model';
import { Timestampable }                 from 'helpers/traits';
import { Blamable }                      from 'helpers/traits';
import SalesPrivateApiModel              from 'modelx/models/abstracts/SalesPrivateApiModel';

@model.staticLabel('Type de prestation')
@model.urnResource('activity_type')
@doc.path('/activity_types/{?id}')
export default class ActivityTypeModel extends Blamable(Timestampable(SalesPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'accountingAnalyticCodeUrn': Urn;
		'accountingCodeUrn': Urn;
		'activities': id;
		'activities.partitionUrn': Urn;
		'activityTypeActivityCategories.activityCategory': id;
		'activityTypeActivityCategories.activityCategory.reference': SalesActivityCategoryReference;
		'archived': '0' | '1';
		'createdAt[after]': string;
		'createdAt[before]': string;
		'createdAt[strictly_after]': string;
		'createdAt[strictly_before]': string;
		'divisionalClassUrn': Urn;
		'exists[activityTypeActivityCategories]': '0' | '1';
		'updatedAt[after]': string | Date;
		'updatedAt[before]': string | Date;
		'updatedAt[strictly_after]': string | Date;
		'updatedAt[strictly_before]': string | Date;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'label': string;
		'updatedAt': string;
	}> = {};

	@doc.modelUrn(AccountingAnalyticCodeModel) declare accountingAnalyticCode: AccountingAnalyticCodeModel;
	@doc.modelUrn(AccountingCodeModel) declare accountingCode: AccountingCodeModel;
	@doc.boolean declare archived: boolean;
	@doc.string declare description: string;
	@doc.modelUrn(DivisionalClassModel) declare divisionalClass: DivisionalClassModel;
	@doc.string declare label: string;
	@doc.modelUrn(OperationTypeModel) declare operationType: OperationTypeModel;

	@model.reverseResolvable({ urn: '$:sales:activity_type_activity_category' })
	public declare activityTypeActivityCategory: ActivityTypeActivityCategoryModel;

	@model.commentResolvable('activity_methodology')
	public declare methodology: CommentModel;
}
