import { computed }         from 'mobx';
import { getIdFromUrn }     from 'tools/UrnTools';
import SalesPrivateApiModel from '../../abstracts/SalesPrivateApiModel';

export default class ContractIterationStatusHistoryModel extends SalesPrivateApiModel {
	public _filters: ModelFiltersExtended<{
		contractIteration: id;
	}> = {};

	static get path(): string {
		return '/contract_iteration_status_histories/{?id}';
	}

	@computed
	public get comment(): string {
		return this.get('comment');
	}

	@computed
	public get createdAt(): string {
		return this.get('createdAt');
	}

	@computed
	public get createdBy(): string {
		return this.get('createdBy');
	}

	public get createdById(): id {
		const createdById = this.get('createdBy') ? getIdFromUrn(this.get('createdBy')) : '0';
		return parseInt(createdById);
	}

	@computed
	public get contractIterationStatusId(): id {
		return this.get('contractIterationStatus.id');
	}

	@computed
	public get rejectionReason(): string {
		return this.get('rejectionReason', '');
	}

	@computed
	public get messageToCommercialContact(): string {
		return this.get('messageToCommercialContact', '');
	}
}
