import lazyWithRetry from 'tools/lazyWithRetry';

const HomePage = lazyWithRetry(() => import(
	/* webpackChunkName: "homePage" */
	'../../pages/HomePage/HomePage'));

export default [
	{
		component: HomePage,
		exact: true,
		path: '/',
	},
];
