import TaskZoneModel               from 'Models/intervention/TaskZoneModel';
import AccountingAnalyticCodeModel from 'Models/invoice/AccountingAnalyticCodeModel';
import BillableModel               from 'Models/invoice/BillableModel';
import DivisionalClassModel        from 'Models/invoice/DivisionalClassModel';
import SourceModel                 from 'Models/invoice/SourceModel';
import VatModel                    from 'Models/invoice/VatModel';
import doc                         from 'decorators/doc';
import model                       from 'decorators/model';
import { Timestampable }           from 'helpers/traits';
import { Blamable }                from 'helpers/traits';
import { computed }                from 'mobx';
import InvoicePrivateApiModel      from 'modelx/models/abstracts/InvoicePrivateApiModel';
import { getIdFromUrn }            from 'tools/UrnTools';

@model.staticLabel('Article')
@model.urnResource('billable_item')
@doc.path('/billable_items/{?id}')
export default class BillableItemModel extends Blamable(Timestampable(InvoicePrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'billable': id;
		'createdAt[after]': string;
		'createdAt[before]': string;
		'createdAt[strictly_after]': string;
		'createdAt[strictly_before]': string;
		'locationUrn': string;
		'updatedAt[after]': string | Date;
		'updatedAt[before]': string | Date;
		'updatedAt[strictly_after]': string | Date;
		'updatedAt[strictly_before]': string | Date;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'description': string;
		'id': string;
		'label': string;
		'quantity': string;
		'updatedAt': string;
	}> = {};

	@doc.model(AccountingAnalyticCodeModel) declare accountingAnalyticCode: AccountingAnalyticCodeModel;
	@doc.model(BillableModel) declare billable: BillableModel;
	@doc.string declare description: string;
	@doc.model(DivisionalClassModel) declare divisionalClass: DivisionalClassModel;
	@doc.string declare label: string;
	@doc.string declare note: string;
	@doc.number declare priceExclTax: number;
	@doc.number declare priceInclTax: number;
	@doc.number declare priceVat: number;
	@doc.string declare quantity: string;
	@doc.modelUrn(SourceModel) declare source: SourceModel;
	@doc.number declare totalPriceExclTax: number;
	@doc.number declare totalPriceInclTax: number;
	@doc.number declare totalPriceVat: number;
	@doc.model(VatModel) declare vat: VatModel;

	@model.resolvable(TaskZoneModel, { attributeName: 'locationUrn' })
	declare location: TaskZoneModel;

	@computed
	public get billableId(): id {
		return this.get('billable.id');
	}

	@computed get taskZoneId(): id {
		return parseInt(getIdFromUrn(this.get('locationUrn')));
	}
}