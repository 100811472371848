import doc                 from 'decorators/doc';
import model               from 'decorators/model';
import { Timestampable }   from 'helpers/traits';
import { Blamable }        from 'helpers/traits';
import FilePrivateApiModel from '../../abstracts/FilePrivateApiModel';

@model.staticLabel('Source')
@model.urnResource('source')
@doc.path('/stonecode_file_sources/{?id}')
export default class SourceModel extends Blamable(Timestampable(FilePrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'entityUrn': string;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'updatedAt': string;
	}> = {};

	public get entityUrn() {
		return this.get('entityUrn');
	}
}
// TODO DOC - Ajouter la propriété "entity"
