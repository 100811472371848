import doc from 'decorators/doc';
import model from 'decorators/model';
import { computed }                from 'mobx';
import InterventionPrivateApiModel from '../../abstracts/InterventionPrivateApiModel';

export const TaskStatusColors: Record<TaskStatusReference, string> = {
	done: '#118E55',
	done_not_complete: '#F59A22',
	suspended: 'red',
	to_do: '#3473E2',
};

@model.staticLabel('Statut')
@model.urnResource('task_status')
@doc.path('/task_statuses/{?id}')
export default class TaskStatusModel extends InterventionPrivateApiModel {
	public _sorts: ModelSortsExtended<{
		'id': string;
	}> = {};

	public static cacheDuration = 3600;

	@doc.string declare label:string;
	@doc.string declare reference:TaskStatusReference;

	@computed
	public get color(): string {
		return TaskStatusColors[this.reference];
	}
}
