import CompanyModel              from 'Models/directory/CompanyModel';
import SupplierEnterpriseModel   from 'Models/supplier/SupplierEnterpriseModel';
import doc                       from 'decorators/doc';
import model                     from 'decorators/model';
import { Timestampable }         from 'helpers/traits';
import { Blamable }              from 'helpers/traits';
import SupplierPrivateApiModel   from '../../abstracts/SupplierPrivateApiModel';

@model.staticLabel('Agence - Fournisseur', false)
@model.urnResource('supplier_company')
@doc.path('/supplier_companies/{?id}')
export default class SupplierCompanyModel extends Blamable(Timestampable(SupplierPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'companyUrn': string;
		'createdAt[after]': string;
		'createdAt[before]': string;
		'createdAt[strictly_after]': string;
		'createdAt[strictly_before]': string;
		'defaultPurchaseCondition': id;
		'defaultPurchaseCondition.paymentMethod': id;
		'defaultPurchaseCondition.paymentMethod.reference': SupplierPaymentMethodReference;
		'supplierEnterprise': id;
		'supplierEnterprise.supplierEnterpriseStatus': id;
		'supplierEnterprise.supplierEnterpriseStatus.reference': SupplierSupplierEnterpriseStatusReference;
		'updatedAt[after]': string | Date;
		'updatedAt[before]': string | Date;
		'updatedAt[strictly_after]': string | Date;
		'updatedAt[strictly_before]': string | Date;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'updatedAt': string;
	}> = {};

	@doc.modelUrn(CompanyModel) declare company: CompanyModel;
	@doc.model(SupplierEnterpriseModel) declare supplierEnterprise: SupplierEnterpriseModel;
}