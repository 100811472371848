import { resolvable }                from '@mathquis/modelx-resolvables';
import ClientModel                   from 'Models/directory/ClientModel';
import ClientPartitionModel          from 'Models/directory/ClientPartitionModel';
import CompanyModel                  from 'Models/directory/CompanyModel';
import EnterpriseModel               from 'Models/directory/EnterpriseModel';
import PartitionModel                from 'Models/partition/PartitionModel';
import SubPartitionModel             from 'Models/partition/SubPartitionModel';
import OwnerEnterprisePartitionModel from 'Models/sales/OwnerEnterprisePartitionModel';
import OwnerSubPartitionModel        from 'Models/sales/OwnerSubPartitionModel';
import { urn }                       from 'helpers/ModelDictionary';
import { Timestampable }             from 'helpers/traits';
import { Blamable }                  from 'helpers/traits';
import SalesPrivateApiModel          from '../../abstracts/SalesPrivateApiModel';

@urn('$', 'sales', 'owner')
export default class OwnerModel extends Blamable(Timestampable(SalesPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'clientPartitionUrn': string;
		'clientUrn': string;
		'companyUrn': string;
		'ownerEnterprisePartition.enterprisePartitionUrn': string;
		'enterpriseUrn': string;
		'ownerSubPartition': id;
		'ownerSubPartition.partitionUrn': string;
		'ownerSubPartition.subPartitionUrn': string;
	}> = {};

	static get path(): string {
		return '/owners/{?id}';
	}

	@resolvable(ClientModel, { attributeName: 'clientUrn' })
	declare client: ClientModel;

	@resolvable(ClientPartitionModel, { attributeName: 'clientPartitionUrn' })
	declare clientPartition: ClientPartitionModel;

	@resolvable(CompanyModel, { attributeName: 'companyUrn' })
	declare company: CompanyModel;

	@resolvable(EnterpriseModel, { attributeName: 'enterpriseUrn' })
	declare enterprise: EnterpriseModel;

	@resolvable(OwnerEnterprisePartitionModel, { attributeName: 'ownerEnterprisePartition' })
	declare ownerEnterprisePartition: OwnerEnterprisePartitionModel;

	@resolvable(OwnerSubPartitionModel, { attributeName: 'ownerSubPartition' })
	declare ownerSubPartition: OwnerSubPartitionModel;

	@resolvable(PartitionModel, { attributeName: 'partitionUrn' })
	declare partition: PartitionModel;

	@resolvable(SubPartitionModel, { attributeName: 'subPartitionUrn' })
	declare subPartition: SubPartitionModel;
}
