import doc from 'decorators/doc';
import model from 'decorators/model';
import VehiclePrivateApiModel from '../../abstracts/VehiclePrivateApiModel';

@model.cacheDuration()
@model.staticLabel('Type de véhicule')
@model.urnResource('vehicle_type')
@doc.path('/vehicle_types/{?id}')
export default class VehicleTypeModel extends VehiclePrivateApiModel {
	public _filters: ModelFiltersExtended<{
		'label': id;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'label': string;
		'position': string;
	}> = {};

	@doc.string declare label: string;
	@doc.number declare position: number;
	@doc.string declare reference: VehicleVehicleTypeReference;
}