import { LoggedApiConnector } from 'Connectors/LoggedApiConnector';
import ConfigProxy            from 'tools/ConfigProxy';
import SuggestionApiModel     from './SuggestionApiModel';

export const suggestionLoggedConnector = new LoggedApiConnector({
	baseURL: ConfigProxy.getServiceConfig('suggestion', 'api_endpoint'),
});

export default class SuggestionPrivateApiModel extends SuggestionApiModel {
	public static get connector() {
		return suggestionLoggedConnector;
	}
}
