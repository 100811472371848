import { resolvable }             from '@mathquis/modelx-resolvables';
import ClientModel                from 'Models/directory/ClientModel';
import StaffMemberModel           from 'Models/rh/StaffMemberModel';
import ActivityCategoryModel      from 'Models/sales/ActivityCategoryModel';
import OwnerModel                 from 'Models/sales/OwnerModel';
import QuotationBillingGroupModel from 'Models/sales/QuotationBillingGroupModel';
import QFAIQuotationOptionModel   from 'Models/sales/QuotationFrameworkAgreementIterationQuotationOptionModel';
import QuotationStatusModel       from 'Models/sales/QuotationStatusModel';
import doc                        from 'decorators/doc';
import model                      from 'decorators/model';
import { Blamable }               from 'helpers/traits';
import { Timestampable }          from 'helpers/traits';
import { computed }               from 'mobx';
import SalesPrivateApiModel       from 'modelx/models/abstracts/SalesPrivateApiModel';
import moment                     from 'moment';

@model.staticLabel('Devis')
@model.urnResource('quotation')
@doc.path('/quotations/{?id}')
export default class QuotationModel extends Blamable(Timestampable(SalesPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'activityCategory': id;
		'anomaliesFlags.flag[between]': string;
		'anomaliesFlags.flag[gt]': string;
		'anomaliesFlags.flag[gte]': string;
		'anomaliesFlags.flag[lt]': string;
		'anomaliesFlags.flag[lte]': string;
		'archived': string;
		'autoliquidation': string;
		'createdAt[after]': string;
		'createdAt[before]': string;
		'createdAt[strictly_after]': string;
		'createdAt[strictly_before]': string;
		'endDate[after]': string | Date;
		'endDate[before]': string | Date;
		'endDate[strictly_after]': string | Date;
		'endDate[strictly_before]': string | Date;
		'number': string;
		'onPurchaseOrder': string;
		'owner.clientUrn': string;
		'owner.companyUrn': string;
		'owner.ownerEnterprisePartition.enterprisePartitionUrn': string;
		'owner.enterpriseUrn': string;
		'owner.ownerSubPartition.ownerPartition.partitionUrn': string;
		'owner.ownerSubPartition.subPartitionUrn': string;
		'quotationActors.actorUrn': string,
		'quotationBillingGroup.billingFrequencyUrn': string;
		'quotationFrameworkAgreementIterationQuotationOptions.frameworkAgreementIterationQuotationOption.frameworkAgreementIteration': id,
		'items.itemActivities.locationUrn': string;
		'items.itemActivities.activity.activityType.activityTypeActivityCategories.activityCategory': id;
		'quotationStatus': id;
		'quotationStatus.reference': SalesQuotationStatusReference;
		'startDate[after]': string | Date;
		'startDate[before]': string | Date;
		'startDate[strictly_after]': string | Date;
		'startDate[strictly_before]': string | Date;
		'validatorUrn': Urn;
		'zipCode': string;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'endDate': string;
		'number': string;
		'startDate': string;
		'totalPriceExclTax': string;
		'totalPriceInclTax': string;
		'zipCode': string;
	}> = {};

	public get additionalDesignation(): string {
		return this.get('additionalDesignation', '');
	}

	public get address(): string {
		return this.get('address', '');
	}

	public get addressBis(): string {
		return this.get('addressBis', '');
	}

	public get archived(): boolean {
		return this.get('archived', false);
	}

	public get autoliquidation(): boolean {
		return this.get('autoliquidation', false);
	}

	public get billedAmountOldSystem(): number {
		return this.get('billedAmountOldSystem', 0);
	}

	public get city(): string {
		return this.get('city', '');
	}

	public get clientReference(): string {
		return this.get('clientReference', '');
	}

	@model.resolvable(ClientModel, { attributeName: 'clientUrn' })
	declare client: ClientModel;

	@computed
	public get endDateIsPassed(): boolean {
		return this.endDate.isSameOrBefore(moment());
	}

	public get comment(): string {
		return this.get('comment', '');
	}

	public get country(): string {
		return this.get('country', '');
	}

	public get dc4Mode(): boolean {
		return this.get('dc4Mode', false);
	}

	public get discount(): number {
		return this.get('discount', 0);
	}

	public get duration(): number {
		return this.get('duration', 0);
	}

	@computed
	public get endDate(): Moment {
		return moment(this.get('endDate', moment()));
	}

	@computed
	public get expiresAt(): Moment {
		return moment(this.get('expiresAt', moment()));
	}

	public get note(): string {
		return this.get('note', '');
	}

	public get number(): string {
		return this.get('number', '');
	}

	public get oldNumber(): string {
		return this.get('oldNumber', '');
	}

	public get onPurchaseOrder(): boolean {
		return this.get('onPurchaseOrder', false);
	}

	public get quotationNeedsAdjustment(): boolean {
		return this.get('quotationNeedsAdjustment', false);
	}

	@computed
	public get startDate(): Moment {
		return moment(this.get('startDate', moment()));
	}

	@computed
	public get startDateString(): string {
		return this.get('startDate', '');
	}

	@computed
	public get endDateString(): string {
		return this.get('endDate', '');
	}

	@computed
	public get suspensionEndDate(): Moment {
		return moment(this.get('suspensionEndDate', moment()));
	}

	@computed
	public get suspensionStartDate(): Moment {
		return moment(this.get('suspensionStartDate', moment()));
	}

	@computed
	public get fullNumber(): string {
		let fullNumber = this.number;

		if (this.oldNumber) {
			fullNumber = fullNumber + ' (' + this.oldNumber + ')';
		}

		return fullNumber;
	}

	@computed
	public get isRecent() {
		return this.createdAt.diff(moment(), 'days') <= 30;
	}

	public get title(): string {
		return this.get('title', '');
	}

	public get totalOverchargedWhenClosed(): number {
		return this.get('totalOverchargedWhenClosed', 0);
	}

	public get totalPriceExclTax(): number {
		return this.get('totalPriceExclTax', 0);
	}

	public get totalPriceInclTax(): number {
		return this.get('totalPriceInclTax', 0);
	}

	public get zipCode(): string {
		return this.get('zipCode', '');
	}

	public get activityCategoryIri(): string {
		return this.get('activityCategory.@id');
	}

	public get quotationStatusId(): id {
		return this.get('quotationStatus.id');
	}

	public get quotationBillingGroupId(): id {
		return this.get('quotationBillingGroup.id');
	}

	public get ownerId(): id {
		return this.get('owner.id');
	}

	public get amendmentOfId(): id {
		return this.get('amendmentOf.id', '');
	}

	public get validity(): string {
		return this.get('validity', '');
	}

	@model.resolvable(OwnerModel, { attributeName: 'owner' })
	declare owner: OwnerModel;

	@model.resolvable(QuotationBillingGroupModel, { attributeName: 'quotationBillingGroup' })
	declare quotationBillingGroup: QuotationBillingGroupModel;

	@model.resolvable(ActivityCategoryModel, { attributeName: 'activityCategory', cache: true })
	declare activityCategory: ActivityCategoryModel;

	@model.resolvable(QuotationStatusModel, { attributeName: 'quotationStatus', cache: true })
	declare quotationStatus: QuotationStatusModel;

	@model.resolvableUrn({ attributeName: 'staffMemberUrn' })
	declare staffMember: StaffMemberModel;

	@resolvable(StaffMemberModel, { attributeName: 'validatorUrn' })
	declare validator: StaffMemberModel;

	@model.nestedResolvable()
	public get clientPartition() {
		return this.owner.clientPartition;
	}

	@model.nestedResolvable()
	public get company() {
		return this.owner.clientPartition.company;
	}

	@model.reverseResolvable<QFAIQuotationOptionModel>({
		urn: '$:sales:quotation_framework_agreement_iteration_quotation_option',
	})
	public declare quotationFrameworkAgreementIterationQuotationOption: QFAIQuotationOptionModel;

	@model.nestedResolvable()
	public get frameworkAgreementIteration() {
		return this.quotationFrameworkAgreementIterationQuotationOption
			.frameworkAgreementIterationQuotationOption
			.frameworkAgreementIteration;
	}

	@model.nestedResolvable()
	public get quotationOption() {
		return this.quotationFrameworkAgreementIterationQuotationOption
			.frameworkAgreementIterationQuotationOption
			.quotationOption;
	}

	@computed
	public get displayDate() {
		return `${this.startDate.format('L')} au ${this.endDate.format('L')}`;
	}

	@model.nestedResolvable()
	public get subPartition() {
		return this.owner.ownerSubPartition.subPartition;
	}

	@computed
	public get quotationStatusUrn(): string {
		return this.get('quotationStatus.@urn');
	}
}
