import OperationCategoryModel      from 'Models/intervention/OperationCategoryModel';
import OperationStatusModel        from 'Models/intervention/OperationStatusModel';
import OperationTypeModel          from 'Models/intervention/OperationTypeModel';
import doc                         from 'decorators/doc';
import model                       from 'decorators/model';
import { Timestampable }           from 'helpers/traits';
import { Blamable }                from 'helpers/traits';
import InterventionPrivateApiModel from 'modelx/models/abstracts/InterventionPrivateApiModel';

@model.staticLabel(`Type d'opération`)
@model.urnResource('operation')
@doc.path('/operations/{?id}')
export default class OperationModel extends Blamable(Timestampable(InterventionPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'createdAt[after]': string;
		'createdAt[before]': string;
		'createdAt[strictly_after]': string;
		'createdAt[strictly_before]': string;
		'operationCategory': id;
		'operationCategory.partitionUrn': Urn;
		'operationType': id;
		'operationType.archived': '0' | '1';
		'updatedAt[after]': string | Date;
		'updatedAt[before]': string | Date;
		'updatedAt[strictly_after]': string | Date;
		'updatedAt[strictly_before]': string | Date;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'operationType.label': string;
		'updatedAt': string;
	}> = {};

	@doc.string declare certificateContent: string;
	@doc.number declare nbPhotos: number;
	@doc.model(OperationCategoryModel) declare operationCategory: OperationCategoryModel;
	@doc.model(OperationStatusModel) declare operationStatus: OperationStatusModel;
	@doc.model(OperationTypeModel) declare operationType: OperationTypeModel;

	public get operationCategoryId() {
		return this.getId('operationCategory' as never);
	}
}