import SourceModel         from 'Models/file/SourceModel';
import TypeModel           from 'Models/file/TypeModel';
import doc                 from 'decorators/doc';
import model               from 'decorators/model';
import { Timestampable }   from 'helpers/traits';
import { Blamable }        from 'helpers/traits';
import FilePrivateApiModel from '../../abstracts/FilePrivateApiModel';

@model.staticLabel('TypeSource')
@model.urnResource('type_source')
@doc.path('/stonecode_file_type_sources/{?id}')
export default class TypeSourceModel extends Blamable(Timestampable(FilePrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'locked': '0' | '1';
		'source': id;
		'source.entityUrn': id;
		'type': id;
		'type.deprecated': '0' | '1';
		'type.reference': FileTypeReference;
		'type.service': id;
		'type.service.serviceUrn': string;
		'type.system': '0' | '1';
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'updatedAt': string;
	}> = {};

	@doc.boolean declare locked: boolean;
	@doc.model(SourceModel) declare source: SourceModel;
	@doc.model(TypeModel) declare type: TypeModel;

	public get sourceId() {
		return this.get('source.id');
	}
}
