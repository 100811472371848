/* eslint-disable @typescript-eslint/no-explicit-any */

import { Model }            from '@mathquis/modelx';
import { ConnectorResults } from '@mathquis/modelx';
import { ConnectorResult }  from '@mathquis/modelx';
import { Connector }        from '@mathquis/modelx';
import { Collection }       from '@mathquis/modelx';
import { AxiosInstance }    from 'axios';
import { AxiosError }       from 'axios';
import axios                from 'axios';
import ModelCache           from 'helpers/ModelCache';
import qs                   from 'qs';
import notificationApiError from 'tools/notificationApiError';

export type ListOptions = {
	filters?: any;
	limit?: number;
	offset?: number;
	sort?: { o: string; w: string };
}

export class ApiConnector extends Connector {
	protected client: AxiosInstance;

	private _on401: () => void;

	private _on403: () => void;

	constructor(options: any) {
		super(options);

		this._on401 = () => null;

		this._on403 = () => null;

		this.client = axios.create({
			...this.options,
			headers: {
				Accept: 'application/ld+json',
				'Content-Type': 'application/ld+json',
				...this.options.headers,
			},
			responseType: 'json',
		});
	}

	public async destroy(model: Model, options: Record<string, unknown> = {}) {
		const response = await this.request(model.path, {
			method: 'delete',
			...options,
		});

		ModelCache.removeCacheForModel(model);

		return new ConnectorResult(model.attributes, response);
	}

	public async fetch(model: Model, options: Record<string, unknown> = {}) {
		const cachedResponse = ModelCache.getModelResponse(model, options);

		if (cachedResponse) {
			return new ConnectorResult(cachedResponse || {}, { data: cachedResponse });
		}

		const response: any = await this.request(model.path, { method: 'get', ...options });

		const connectorResult = new ConnectorResult(response?.data || {}, response);

		ModelCache.saveModelResponse(model, response, options);

		return connectorResult;
	}

	// Collection methods
	public async list<T extends Model>(
		collection: Collection<T>,
		options: ListOptions = {},
	) {
		const cachedResponse = ModelCache.getCollectionResponse(collection, options);

		if (cachedResponse) {
			return new ConnectorResults(cachedResponse.data?.['hydra:member'], cachedResponse);
		}

		const response = (await this.request(collection.path, {
			...options,
			method: 'get',
		})) as any;

		const connectorResults = new ConnectorResults(response?.data?.['hydra:member'], response);

		ModelCache.saveCollectionResponse(collection, response, options);

		return connectorResults;
	}

	public on401(callback: () => void): this {
		this._on401 = callback;

		return this;
	}

	public async save(model: Model, options: any = {}) {
		const data = options.patchAttributes || model.untransformedAttributes;
		const path = options.path || model.path;

		const response: any = await this.request(path, {
			data: options.stringifyAttributes ? qs.stringify(data) : data,
			method: model.id ? 'put' : 'post',
			...options,
		});

		ModelCache.removeCacheForModel(model);

		return new ConnectorResult(response?.data || {}, response);
	}

	protected onRequestError(err: AxiosError) {
		if (this._on401 && err?.request?.status === 401) {
			this._on401();
		}

		if (this._on403 && err?.request?.status === 403) {
			notificationApiError(err, { displayFullUrl: true });
			this._on403();
		}

		throw err;
	}

	protected async request(path: string, options: Record<string, unknown> = {}) {
		try {
			return await this.client(path.replace(/\/$/, ''), { ...options });
		} catch (err) {
			return this.onRequestError(err);
		}
	}
}
