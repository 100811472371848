import cacheDuration        from 'decorators/cacheDuration';
import collectionResolvable from 'decorators/collectionResolvable';
import commentResolvable    from 'decorators/commentResolvable';
import computedModels       from 'decorators/computedModels';
import fileResolvable       from 'decorators/fileResolvable';
import nestedResolvable     from 'decorators/nestedResolvable';
import resolvableUrn        from 'decorators/resolvableUrn';
import resolvable           from 'decorators/resolvable';
import reverseResolvable    from 'decorators/reverseResolvable';
import staticLabel          from 'decorators/staticLabel';
import urnResource          from 'decorators/urnResource';
import urn                  from 'decorators/urn';

export default {
	cacheDuration,
	collectionResolvable,
	commentResolvable,
	computedModels,
	fileResolvable,
	nestedResolvable,
	resolvable,
	resolvableUrn,
	reverseResolvable,
	staticLabel,
	urn,
	urnResource,
};