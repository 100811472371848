export type GenderReference = 'MALE' | 'FEMALE';

export const STAFF_GENDER_MALE = {
	civility: 'M.',
	label: 'Homme',
	reference: 'MALE',
};

export const STAFF_GENDER_FEMALE = {
	civility: 'Mme.',
	label: 'Femme',
	reference: 'FEMALE',
};

export default [
	STAFF_GENDER_MALE,
	STAFF_GENDER_FEMALE,
];
