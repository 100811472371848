import doc                         from 'decorators/doc';
import model                       from 'decorators/model';
import InterventionPrivateApiModel from 'modelx/models/abstracts/InterventionPrivateApiModel';

@model.cacheDuration()
@model.staticLabel('InterventionGroupStatus')
@model.urnResource('intervention_group_status')
@doc.path('/intervention_group_statuses/{?id}')
export default class InterventionGroupStatusModel extends InterventionPrivateApiModel {
	public _filters: ModelFiltersExtended<{
		'reference': InterventionInterventionGroupStatusReference;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'label': string;
	}> = {};

	@doc.string declare label: string;
	@doc.string declare reference: InterventionInterventionGroupStatusReference;

	public get color() {
		switch (this.reference) {
			case 'to_schedule':
				return '#F59A22';
			case 'scheduled':
				return '#3473E2';
			case 'pre_validated':
				return '#F59A22';
			case 'in_progress':
				return '#3473e226';
			case 'completed':
				return 'green';
			case 'suspended':
				return 'purple';
			case 'cancelled':
				return 'red';
		}
	}
}
