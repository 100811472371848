import { urn }                     from 'helpers/ModelDictionary';
import { Timestampable }           from 'helpers/traits';
import { Blamable }                from 'helpers/traits';
import InterventionPrivateApiModel from '../../abstracts/InterventionPrivateApiModel';

export type InterventionNoteTypeReference = 'issue_reporting' | 'internal_technician_comment';

@urn('$', 'intervention', 'intervention_note_type')
export default class InterventionNoteTypeModel extends Blamable(Timestampable(InterventionPrivateApiModel)) {

	static get path(): string {
		return '/intervention_note_types/{?id}';
	}

	public _filters: ModelFiltersExtended<{
		'reference': InterventionNoteTypeReference;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'label': string;
		'reference': string;
		'updatedAt': string;
	}> = {};

	public static cacheDuration = 3600;

	public get description(): string {
		return this.get('description', '');
	}

	public get label(): string {
		return this.get('label', '');
	}

	public get reference(): InterventionNoteTypeReference {
		return this.get('reference', '');
	}
}