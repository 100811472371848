import { LoggedApiConnector } from 'Connectors/LoggedApiConnector';
import ConfigProxy            from 'tools/ConfigProxy';
import SupplierApiModel       from './SupplierApiModel';

export const supplierLoggedApiConnector = new LoggedApiConnector({
	baseURL: ConfigProxy.getServiceConfig('supplier', 'api_endpoint'),
});

export default class SupplierPrivateApiModel extends SupplierApiModel {
	public static get connector() {
		return supplierLoggedApiConnector;
	}
}
