import { computed }         from 'mobx';
import SalesPrivateApiModel from '../../abstracts/SalesPrivateApiModel';

export default class QuotationItemActivityChargeableModel extends SalesPrivateApiModel {
	public _filters: ModelFiltersExtended<{
		'quotationItemActivity.quotationItem': id;
		'quotationItemActivity.quotationItem.quotation': id;
	}> = {};

	static get path(): string {
		return '/quotation_item_activity_chargeables/{?id}';
	}

	@computed
	public get budgetQuantity(): number {
		return this.get('budgetQuantity');
	}

	@computed
	public get budgetPrice(): number {
		return this.get('budgetPrice');
	}

	@computed
	public get chargeableId(): id {
		return this.get('chargeable.id');
	}

	@computed
	public get chargeableIri(): string {
		return this.get('chargeable.@id');
	}

	@computed
	public get chargeableModeId(): id {
		return this.get('chargeableMode.id');
	}

	@computed
	public get chargeableModeIri(): string {
		return this.get('chargeableMode.@id');
	}

	@computed
	public get quotationItemActivityId(): id {
		return this.get('quotationItemActivity.id');
	}

	@computed
	public get required(): boolean {
		return this.get('required');
	}

	@computed
	public get vatUrn(): string {
		return this.get('vatUrn');
	}
}
