import doc                     from 'decorators/doc';
import model                   from 'decorators/model';
import { Timestampable }       from 'helpers/traits';
import { Blamable }            from 'helpers/traits';
import { computed }            from 'mobx';
import { getCountryName }      from 'tools/Countries';
import SupplierPrivateApiModel from '../../abstracts/SupplierPrivateApiModel';

@model.staticLabel('Adresse de livraison', false)
@model.urnResource('order_shipping_address')
@doc.path('/order_shipping_addresses/{?id}')
export default class OrderShippingAddressModel extends Blamable(Timestampable(SupplierPrivateApiModel)) {
	public _filters: ModelFiltersExtended<unknown> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
	}> = {};

	@doc.string declare address: string;
	@doc.string declare addressBis: string;
	@doc.string declare city: string;
	@doc.string declare country: string;
	@doc.string declare details: string;
	@doc.string declare name: string;
	@doc.string declare zipCode: string;

	@computed
	public get countryName(): string {
		return getCountryName(this.country);
	}

	@computed
	public get fullAddress() {
		return [this.address, this.zipCode, this.city, this.countryName].filter(s => !!s).join(' ');
	}
}