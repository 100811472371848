import doc                  from 'decorators/doc';
import model                from 'decorators/model';
import { Timestampable }    from 'helpers/traits';
import SalesPrivateApiModel from 'modelx/models/abstracts/SalesPrivateApiModel';

@model.cacheDuration()
@model.staticLabel('Statut')
@model.urnResource('framework_agreement_iteration_status')
@doc.path('/framework_agreement_iteration_statuses/{?id}')
export default class FrameworkAgreementIterationStatusModel extends Timestampable(SalesPrivateApiModel) {
	public _filters: ModelFiltersExtended<{
		'frameworkAgreementIterationStatusHistories': id;
		'frameworkAgreementIterations': id;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
	}> = {};

	@doc.string declare label: string;
	@doc.string declare reference: SalesFrameworkAgreementIterationStatusReference;

	public get color() {
		switch (this.reference) {
			case 'pending':
				return 'blue';
			case 'to_validate':
				return 'orange';
			case 'validated':
				return 'lightgreen';
			case 'activated':
				return 'green';
			case 'suspended':
				return 'red';
			case 'closed':
				return 'black';
		}
	}
}