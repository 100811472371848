import { ApiConnector } from 'Connectors/ApiConnector';
import ConfigProxy      from '../../../tools/ConfigProxy';
import AbstractApiModel from './AbstractApiModel';

export const partitionConnector = new ApiConnector({
	baseURL: ConfigProxy.getServiceConfig('partition', 'api_endpoint'),
});

export default class PartitionApiModel extends AbstractApiModel {
	public static get connector() {
		return partitionConnector;
	}

	public static serviceName: ServiceName = 'partition';
}
