import { resolvable }              from '@mathquis/modelx-resolvables';
import InterventionModel           from 'Models/intervention/InterventionModel';
import ResourceModel                                from 'Models/intervention/ResourceModel';
import ResourceTypeModel, { ResourceTypeReference } from 'Models/intervention/ResourceTypeModel';
import { urn }                                      from 'helpers/ModelDictionary';
import { Blamable }                from 'helpers/traits';
import { Timestampable }           from 'helpers/traits';
import { computed }                from 'mobx';
import InterventionPrivateApiModel from '../../abstracts/InterventionPrivateApiModel';

@urn('$', 'intervention', 'intervention_resource')
export default class InterventionResourceModel extends Blamable(Timestampable(InterventionPrivateApiModel)) {

	static get path(): string {
		return '/intervention_resources/{?id}';
	}

	public _filters: ModelFiltersExtended<{
		'createdAt[after]': string;
		'createdAt[before]': string;
		'createdAt[strictly_after]': string;
		'createdAt[strictly_before]': string;
		'intervention': id;
		'resource': id;
		'resource.ownerResource.entityUrn': string;
		'resource.ownerSubPartition': id;
		'resource.ownerSubPartition.partitionUrn': string;
		'resource.ownerSubPartition.subPartitionUrn': string;
		'resource.resourceType': id;
		'resource.resourceType.reference': ResourceTypeReference;
		'updatedAt[after]': string;
		'updatedAt[before]': string;
		'updatedAt[strictly_after]': string;
		'updatedAt[strictly_before]': string;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'updatedAt': string;
	}> = {};

	@computed
	public get interventionId(): id {
		return this.get('intervention.id', 0);
	}

	@computed
	public get resourceId(): id {
		return this.get('resource.id', 0);
	}

	@computed
	public get resourceUrn(): string {
		return this.get('resource.@urn', '');
	}

	@computed
	public get resourceTypeId(): id {
		return this.get('resourceType.id', 0);
	}

	@resolvable(InterventionModel, { attributeName: 'intervention' })
	declare intervention: InterventionModel;

	@resolvable(ResourceModel, { attributeName: 'resource' })
	declare resource: ResourceModel;

	@resolvable(ResourceTypeModel, { attributeName: 'resourceType' })
	declare resourceType: ResourceTypeModel;
}