import PartitionModel          from 'Models/partition/PartitionModel';
import doc                     from 'decorators/doc';
import model                   from 'decorators/model';
import { Timestampable }       from 'helpers/traits';
import { Blamable }            from 'helpers/traits';
import SupplierPrivateApiModel from '../../abstracts/SupplierPrivateApiModel';

@model.staticLabel('OwnerPartition')
@model.urnResource('owner_partition')
@doc.path('/owner_partitions/{?id}')
export default class OwnerPartitionModel extends Blamable(Timestampable(SupplierPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'partitionUrn': string;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'updatedAt': string;
	}> = {};

	@doc.modelUrn(PartitionModel) declare partition: PartitionModel;
}