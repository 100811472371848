import * as Sentry                       from '@sentry/browser';
import { COLLAPSED_BOXES_STORAGE_KEY }   from 'components/DashboardBox/DashboardBox';
import { HIDDEN_COLUMNS_STORAGE_KEY }    from 'components/ListCollection';
import FilterManager                     from 'helpers/FilterManager';
import ModelCache                        from 'helpers/ModelCache';
import { reaction }                      from 'mobx';
import BillingGroupListStore             from 'stores/BillingGroupListStore';
import EnterpriseDashboardStore          from 'stores/EnterpriseDashboardStore';
import EnterpriseHierarchyStore          from 'stores/EnterpriseHierarchyStore';
import EnterprisePartitionDashboardStore from 'stores/EnterprisePartitionDashboardStore';
import InvoiceDashboardStore             from 'stores/InvoiceDashboardStore';
import LayoutSidebarStore                from 'stores/LayoutSidebarStore';
import StaffMemberDashboardStore         from 'stores/StaffMemberDashboardStore';
import TaskDashboardStore                from 'stores/TaskDashboardStore';
import browserHistory                    from 'tools/browserHistory';
import notificationApiError              from 'tools/notificationApiError';
import AppStore                          from './AppStore';
import AuthenticationStore               from './AuthenticationStore';
import BillableDashboardStore            from './BillableDashboardStore';
import ClientDashboardStore              from './ClientDashboardStore';
import ContactDashboardStore             from './ContactDashboardStore';
import ContractIterationDashboardStore   from './ContractIterationDashboardStore';
import InterventionDashboardStore        from './InterventionDashboardStore';
import InterventionPlanningSidebarStore  from './InterventionPlanningSidebarStore';
import InterventionPlanningStore         from './InterventionPlanningStore';
import QuotationDashboardStore           from './QuotationDashboardStore';
import TaskZoneDashboardStore            from './TaskZoneDashboardStore';

export const appStore = new AppStore();
export const authenticationStore = new AuthenticationStore();
export const billableDashboardStore = new BillableDashboardStore();
export const billingGroupListStore = new BillingGroupListStore();
export const clientDashboardStore = new ClientDashboardStore();
export const enterpriseHierarchyStore = new EnterpriseHierarchyStore();
export const enterprisePartitionDashboardStore = new EnterprisePartitionDashboardStore();
export const contactDashboardStore = new ContactDashboardStore();
export const contractIterationDashboardStore = new ContractIterationDashboardStore();
export const interventionDashboardStore = new InterventionDashboardStore();
export const planningSidebar1Store = new InterventionPlanningSidebarStore(['to_schedule', 'to_reschedule']);
export const planningSidebar2Store = new InterventionPlanningSidebarStore(['to_schedule', 'to_reschedule'], true);
export const planningSidebar3Store = new InterventionPlanningSidebarStore(['to_schedule', 'to_reschedule'], false, true);
export const interventionPlanningStore = new InterventionPlanningStore();
export const invoiceDashboardStore = new InvoiceDashboardStore();
export const layoutSidebarStore = new LayoutSidebarStore();
export const quotationDashboardStore = new QuotationDashboardStore();
export const taskDashboardStore = new TaskDashboardStore();
export const taskZoneDashboardStore = new TaskZoneDashboardStore();
export const staffMemberDashboardStore = new StaffMemberDashboardStore();
export const enterpriseDashboardStore = new EnterpriseDashboardStore();

const dashboardStores = {
	billableDashboardStore,
	clientDashboardStore,
	contactDashboardStore,
	contractIterationDashboardStore,
	enterpriseDashboardStore,
	enterprisePartitionDashboardStore,
	interventionDashboardStore,
	invoiceDashboardStore,
	quotationDashboardStore,
	staffMemberDashboardStore,
	taskDashboardStore,
	taskZoneDashboardStore,
};

const stores = {
	appStore,
	authenticationStore,
	billingGroupListStore,
	enterpriseHierarchyStore,
	interventionPlanningStore,
	layoutSidebarStore,
	planningSidebar1Store,
	planningSidebar2Store,
	planningSidebar3Store,
	...dashboardStores,
};

const clearStores = (s: Record<string, { clear }> = stores) =>
	Object.values(s).forEach((store) => store.clear());

const onAuthenticatedAsync = async (): Promise<void> => {
	try {
		Sentry.setUser({
			id: authenticationStore.session.ownerId.toString(),
			username: appStore.staffMember.getUrn('staff'),
		});

		appStore.staffMember.setId(authenticationStore.session.ownerId);

		await Promise.all([
			appStore.load(),
		]);
	} catch (err) {
		notificationApiError(err);
	}
};

const onLogout = () => {
	clearStores();
	ModelCache.destroy();
	FilterManager.destroy();
	localStorage.removeItem(HIDDEN_COLUMNS_STORAGE_KEY);
	localStorage.removeItem(COLLAPSED_BOXES_STORAGE_KEY);

	for (const key in localStorage) {
		if (
			key.startsWith('PLANNING_SIDEBAR_1_') // sidebar 1 du planning
			|| key.startsWith('PLANNING_SIDEBAR_2_') // sidebar 2 du planning
			|| key.startsWith('PLANNING_SIDEBAR_3_') // sidebar 3 du planning
			|| key.startsWith('intervention_planning_') // les plannings
		) {
			localStorage.removeItem(key);
		}
	}

	Sentry.setUser({});
};

// Lorsqu'un utilisateur se connecte ou se déconnecte
reaction(
	() => authenticationStore.isAuthenticated,
	() => authenticationStore.isAuthenticated ? onAuthenticatedAsync() : onLogout(),
);

// Initialisation au premier chargement
Promise.all([authenticationStore.session.fetch()])
	.catch(() => null)
	.then(() => authenticationStore.onLoginSuccess())
	.catch(() => null)
	.finally(() => authenticationStore.setIsReady(true));

// Reinitialisation des stores de dashboard lorsque l'url change
let lastLocationPathname = window.location.pathname;
browserHistory.listen(location => {
	if (location.pathname !== lastLocationPathname) {
		lastLocationPathname = location.pathname;
		clearStores(dashboardStores);
	}
});

// Quand la sous-partition change
reaction(() => appStore.subPartitionId, async () => {
	await appStore.fetchPlannings();
});

export default stores;
