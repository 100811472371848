import BillingPeriodStatusModel from 'Models/invoice/BillingPeriodStatusModel';
import PartitionModel           from 'Models/partition/PartitionModel';
import doc from 'decorators/doc';
import model from 'decorators/model';
import { Timestampable }        from 'helpers/traits';
import { Blamable }             from 'helpers/traits';
import InvoicePrivateApiModel   from '../../abstracts/InvoicePrivateApiModel';

@model.staticLabel('Période de facturation', false)
@model.urnResource('billing_period')
@doc.path('/billing_periods/{?id}')
export default class BillingPeriodModel extends Blamable(Timestampable(InvoicePrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'billingPeriodStatus': id;
		'billingPeriodStatus.reference': InvoiceBillingPeriodStatusReference;
		'createdAt[after]': string;
		'createdAt[before]': string;
		'createdAt[strictly_after]': string;
		'createdAt[strictly_before]': string;
		'endDate[after]': string | Date;
		'endDate[before]': string | Date;
		'endDate[strictly_after]': string | Date;
		'endDate[strictly_before]': string | Date;
		'partitionUrn': string;
		'startDate[after]': string | Date;
		'startDate[before]': string | Date;
		'startDate[strictly_after]': string | Date;
		'startDate[strictly_before]': string | Date;
		'updatedAt[after]': string | Date;
		'updatedAt[before]': string | Date;
		'updatedAt[strictly_after]': string | Date;
		'updatedAt[strictly_before]': string | Date;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'billingPeriodStatus.label': string;
		'endDate': string;
		'id': string;
		'invoiceNumber': string;
		'startDate': string;
		'updatedAt': string;
	}> = {};

	@doc.number declare amountTaxExcl: number;
	@doc.number declare amountTaxIncl: number;
	@doc.number declare amountVat: number;
	@doc.model(BillingPeriodStatusModel) declare billingPeriodStatus: BillingPeriodStatusModel;
	@doc.date declare endDate: Moment;
	@doc.number declare invoiceNumber: number;
	@doc.number declare invoiceOffsetNumber: number;
	@doc.modelUrn(PartitionModel) declare partition: PartitionModel;
	@doc.number declare proformaNumber: number;
	@doc.date declare startDate: Moment;
}