import { PagedCollection }                from 'Collections/PagedCollection';
import ClientModel                        from 'Models/directory/ClientModel';
import CompanyAddressModel                from 'Models/directory/CompanyAddressModel';
import CompanyContactModel                from 'Models/directory/CompanyContactModel';
import ContactModel                       from 'Models/directory/ContactModel';
import SalesContactModel                  from 'Models/sales/ContactModel';
import ContactTypeModel                   from 'Models/sales/ContactTypeModel';
import ContractIterationClosingModel      from 'Models/sales/ContractIterationClosingModel';
import ContractIterationItemActivityModel from 'Models/sales/ContractIterationItemActivityModel';
import ContractIterationItemModel         from 'Models/sales/ContractIterationItemModel';
import ContractIterationModel             from 'Models/sales/ContractIterationModel';
import ContractIterationStatusModel       from 'Models/sales/ContractIterationStatusModel';
import ContractModel                      from 'Models/sales/ContractModel';
import QuotationClosingModel              from 'Models/sales/QuotationClosingModel';
import QuotationItemActivityModel         from 'Models/sales/QuotationItemActivityModel';
import QuotationItemModel                 from 'Models/sales/QuotationItemModel';
import QuotationModel                     from 'Models/sales/QuotationModel';
import QuotationStatusModel               from 'Models/sales/QuotationStatusModel';
import { ApiCollection }                  from 'modelx/collections/ApiCollection';
import AbstractModelXStore                from 'stores/AbstractModelXStore';
import { whenAsync }                      from 'tools/modelxTools';

export default class ClientDashboardStore extends AbstractModelXStore {

	public buyingContactTypeCollection = new ApiCollection(ContactTypeModel);
	public buyingSalesContactCollection = new ApiCollection(SalesContactModel);
	public buyingSalesContactContactCollection = new ApiCollection(ContactModel);

	public companyAddress = new CompanyAddressModel();
	public companyContact = new CompanyContactModel();

	public contractCollection = new ApiCollection(ContractModel);
	public contractIterationClosingCollection = new ApiCollection(ContractIterationClosingModel);
	public contractIterationItemActivityCollection = new ApiCollection(ContractIterationItemActivityModel);
	public contractIterationItemCollection = new ApiCollection(ContractIterationItemModel);
	public contractIterationPagedCollection = new PagedCollection(ContractIterationModel);
	public contractIterationStatusCollection = new ApiCollection(ContractIterationStatusModel);

	public quotationClosingCollection = new ApiCollection(QuotationClosingModel);
	public quotationItemActivityCollection = new ApiCollection(QuotationItemActivityModel);
	public quotationItemCollection = new ApiCollection(QuotationItemModel);
	public quotationPagedCollection = new PagedCollection(QuotationModel);
	public quotationStatusCollection = new ApiCollection(QuotationStatusModel);

	public fetchContactsCollection = async (client: ClientModel) => {
		await Promise.all([
			this.buyingSalesContactCollection
				.setSort('position')
				.setFilter('owner.clientUrn', client.urn)
				.list()
				.then(async () => {
					await this.buyingSalesContactContactCollection
						.listByFromCollection(this.buyingSalesContactCollection, 'contactId', 'id');
				}),
		]);
	};

	public async initAsync(client: ClientModel) {
		this.clear();

		await whenAsync(() => client.clientPartition.isLoaded);

		await Promise.all([
			new ApiCollection(CompanyAddressModel)
				.listBy([client.clientPartition.getId('company')], 'company')
				.then(coll => this.companyAddress.set(coll.first()?.attributes)),

			new ApiCollection(CompanyContactModel)
				.listBy([client.clientPartition.getId('company')], 'company')
				.then(coll => this.companyContact.set(coll.first()?.attributes)),

			this.fetchContactsCollection(client),

			this.buyingContactTypeCollection.list({ cache: 3600 }),

			this.loadContractAndQuotationCollection(client.urn),
		]);
	}

	public loadContractAndQuotationCollection = (clientUrn: string, page = 1, itemsPerPage = 10) => {
		return Promise.all([
			this.loadContractPagedCollection(clientUrn, page, itemsPerPage),
			this.loadQuotationPagedCollection(clientUrn, page, itemsPerPage),
		]);
	};

	public loadContractPagedCollection = (clientUrn: string, page = 1, itemsPerPage = 10) => {
		return Promise.all([
			this.contractIterationPagedCollection
				.setPage(page)
				.setItemsPerPage(itemsPerPage)
				.setFilters({
					'archived': '0',
					'contract.owner.clientUrn': clientUrn,
				})
				.setSort('createdAt', false)
				.list()
				.then(async () => {
					await Promise.all([
						this.contractIterationClosingCollection
							.listByFromCollection(this.contractIterationPagedCollection, 'id', 'contractIteration'),

						this.contractCollection
							.listByFromCollection(this.contractIterationPagedCollection, 'contractId', 'id'),

						this.contractIterationItemCollection
							.listByFromCollection(this.contractIterationPagedCollection, 'id', 'contractIteration')
							.then(async () => {
								await this.contractIterationItemActivityCollection
									.listByFromCollection(
										this.contractIterationItemCollection,
										'id',
										'contractIterationItem',
									);
							}),
					]);
				}),


			this.contractIterationStatusCollection.list({ cache: 3600 }),
		]);
	};

	public loadQuotationPagedCollection = (clientUrn: string, page = 1, itemsPerPage = 10) => {
		return Promise.all([
			this.quotationPagedCollection
				.setPage(page)
				.setItemsPerPage(itemsPerPage)
				.setFilters({
					'archived': '0',
					'owner.clientUrn': clientUrn,
				})
				.setSort('createdAt', false)
				.list()
				.then(async () => {
					await this.quotationClosingCollection
						.listByFromCollection(this.quotationPagedCollection, 'id', 'quotation');
					
					await this.quotationItemCollection
						.listByFromCollection(this.quotationPagedCollection, 'id', 'quotation');

					await this.quotationItemActivityCollection
						.listByFromCollection(this.quotationItemCollection, 'id', 'quotationItem');
				}),

			this.quotationStatusCollection.list({ cache: 3600 }),
		]);
	};
}
