import RiskAssessmentModel        from 'Models/intervention/RiskAssessmentModel';
import TaskZoneModel              from 'Models/intervention/TaskZoneModel';
import VatModel                   from 'Models/invoice/VatModel';
import ActivityModel              from 'Models/sales/ActivityModel';
import ContractIterationItemModel from 'Models/sales/ContractIterationItemModel';
import doc                        from 'decorators/doc';
import model                      from 'decorators/model';
import { Timestampable }          from 'helpers/traits';
import { Blamable }               from 'helpers/traits';
import SalesPrivateApiModel       from 'modelx/models/abstracts/SalesPrivateApiModel';

@model.staticLabel('ContractIterationItemActivity')
@model.urnResource('contract_iteration_item_activity')
@doc.path('/contract_iteration_item_activities/{?id}')
export default class ContractIterationItemActivityModel extends Blamable(Timestampable(SalesPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'contractIterationItem': id;
		'contractIterationItem.contractIteration': id;
		'locationUrn': Urn;
		'tags[serviceUrn][tagReference]': id;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'position': boolean;
		'contractIterationItem.position': boolean;
	}> = {};

	@doc.model(ActivityModel) declare activity: ActivityModel;
	@doc.model(ContractIterationItemModel) declare contractIterationItem: ContractIterationItemModel;
	@doc.string declare label: string;
	@doc.string declare materialQuantity: string;
	@doc.string declare note: string;
	@doc.number declare position: number;
	@doc.number declare priceExclTax: number;
	@doc.modelUrn(RiskAssessmentModel) declare riskAssessment: RiskAssessmentModel;
	@doc.number declare totalPriceExclTax: number;
	@doc.number declare totalPriceInclTax: number;
	@doc.string declare vatRate: string;
	@doc.modelUrn(VatModel) declare vat: VatModel;


	@model.resolvable(TaskZoneModel, { attributeName: 'locationUrn' })
	declare location: TaskZoneModel;
}
