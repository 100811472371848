import ContractModel        from 'Models/sales/ContractModel';
import doc                  from 'decorators/doc';
import model                from 'decorators/model';
import SalesPrivateApiModel from 'modelx/models/abstracts/SalesPrivateApiModel';

@model.staticLabel('Reconduction')
@model.urnResource('reconductability')
@doc.path('/reconductabilities/{?id}')
export default class ReconductabilityModel extends SalesPrivateApiModel {
	public _filters: ModelFiltersExtended<{
		'contract': id;
	}> = {};

	public _sorts: ModelSortsExtended<unknown> = {};

	@doc.model(ContractModel) declare contract: ContractModel;
	@doc.number declare maxReconductions: number;
	@doc.number declare nbReconductions: number;
	@doc.string declare revisionClause: string;
}
