import { AbstractResolvableModel } from '@mathquis/modelx-resolvables';
import { ucfirst }                 from 'tools/jsTools';

type MethodStaticLabel = 'the' | 'a' | 'first' | 'last' | 'theFirst' | 'theLast' | 'plural' | 'this';
type ReturnCase = 'u' | 'l' | 'uf';

export default abstract class AbstractApiStaticLabelModel extends AbstractResolvableModel {
	public static get pluralStaticLabel() {
		return plural(this.prototype.constructor['staticLabel']);
	}

	public static get staticLabel(): string {
		return (this.constructor as never)['staticLabel'] || this.prototype.modelName;
	}

	public static get isStaticLabelMasculine() {
		return !!(this.constructor as never)['isStaticLabelMasculine'];
	}

	public readonly isStaticLabelMasculine = !!(this.constructor as never)['isStaticLabelMasculine'];
	public readonly staticLabel: string = (this.constructor as never)['staticLabel'] || this.modelName;

	public getStaticLabel(
		name: MethodStaticLabel | '' = '',
		returnCase: ReturnCase = 'l',
		isPlural = false,
	) {
		return this.constructor['getStaticLabel'](name, returnCase, isPlural);
	}

	public get modelLabel(): string {
		const properties = ['fullName', 'lastName', 'firstName', 'label', 'title', 'name', 'code', 'reference', 'id'];
		const property = properties.find(v => this[v]);
		return property ? (this[property] || '') : '';
	}

	public static get theStaticLabel() {
		return `${this._thePrefix}${this.staticLabel.toLowerCase()}`;
	}

	public static get thisStaticLabel() {
		return `${this.isStaticLabelMasculine ? 'ce' : 'cette'} ${this.staticLabel.toLowerCase()}`;
	}

	public static get aStaticLabel(): string {
		const prefix = this.prototype.constructor['isStaticLabelMasculine'] ? 'un' : 'une';
		return `${prefix} ${this.staticLabel.toLowerCase()}`;
	}

	public get theStaticLabel(): string {
		return this.constructor['theStaticLabel'];
	}

	public get aStaticLabel(): string {
		return this.constructor['aStaticLabel'];
	}

	public static get firstStaticLabel() {
		const prefix = this.isStaticLabelMasculine ? 'premier' : 'première';
		return `${prefix} ${this.staticLabel.toLowerCase()}`;
	}

	public static get theFirstStaticLabel() {
		const prefix = this.isStaticLabelMasculine ? 'le' : 'la';
		return `${prefix} ${this.firstStaticLabel}`;
	}

	public static get lastStaticLabel() {
		const prefix = this.isStaticLabelMasculine ? 'dernier' : 'dernière';
		return `${prefix} ${this.staticLabel.toLowerCase()}`;
	}

	public static get theLastStaticLabel() {
		const prefix = this.isStaticLabelMasculine ? 'le' : 'la';
		return `${prefix} ${this.lastStaticLabel}`;
	}

	public static getStaticLabel(
		name: MethodStaticLabel | '' = '',
		returnCase: ReturnCase = 'l',
		isPlural = false,
	) {
		let label: string;

		switch (name) {
			case 'a':
				label = this.aStaticLabel;
				break;
			case 'the':
				label = this.theStaticLabel;
				break;
			case 'this':
				label = this.thisStaticLabel;
				break;
			case 'first':
				label = this.firstStaticLabel;
				break;
			case 'last':
				label = this.lastStaticLabel;
				break;
			case 'theFirst':
				label = this.theFirstStaticLabel;
				break;
			case 'theLast':
				label = this.theLastStaticLabel;
				break;
			case 'plural':
				label = this.pluralStaticLabel;
				break;
			default:
				label = this.staticLabel;
		}

		switch (returnCase) {
			case 'u':
				label = label.toUpperCase();
				break;
			case 'l':
				label = label.toLowerCase();
				break;
			case 'uf':
				label = ucfirst(label);
				break;
		}

		return isPlural ? plural(label) : label;
	}

	private static get _thePrefix() {
		const prefix = this.prototype.constructor['isStaticLabelMasculine'] ? 'le ' : 'la ';
		const startsWith = ['a', 'e', 'i', 'o', 'u', 'y', 'é', 'hi'];
		const label = this.staticLabel.toLowerCase();

		if (startsWith.some(s => label.startsWith(s))) {
			return `l'`;
		}

		return prefix;
	}
}

const plural = (str: string) => {
	const arr = str.split(' ').map(s1 => {
		return s1.split('-').map(s => {
			if (s.endsWith('al')) {
				s = s.replace(/al$/g, 'aux');
			} else if (s.length >= 3 && !s.endsWith('s')) {
				s = s + 's';
			} else if (s === 'au') {
				s = 'aux';
			}

			return s;
		}).join('-');
	});
	return arr.join(' ');
};
