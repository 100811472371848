import React                   from 'react';

import { authenticationStore } from 'stores';
import '../css/LogoutLink.scss';

const LogoutLink = () => {
	return (
		<div className="logout-link" onClick={() => authenticationStore.logout()}>
			Déconnexion
		</div>
	);
};

export default LogoutLink;
