import doc                         from 'decorators/doc';
import model                       from 'decorators/model';
import { Timestampable }           from 'helpers/traits';
import NotificationPrivateApiModel from '../../abstracts/NotificationPrivateApiModel';

@model.staticLabel('Source')
@model.urnResource('source')
@doc.path('/stonecode_notification_sources/{?id}')
export default class SourceModel extends Timestampable(NotificationPrivateApiModel) {
	public _filters: ModelFiltersExtended<{
		'sourceUrn': string;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
	}> = {};


	// TODO DOC - Ajouter la propriété "source"
}
