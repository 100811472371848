import CommentModel                from 'Models/comment/CommentModel';
import AccountingAnalyticCodeModel from 'Models/invoice/AccountingAnalyticCodeModel';
import AccountingCodeModel         from 'Models/invoice/AccountingCodeModel';
import PartitionModel              from 'Models/partition/PartitionModel';
import ActivityStatusModel         from 'Models/sales/ActivityStatusModel';
import ActivityTypeModel           from 'Models/sales/ActivityTypeModel';
import doc                         from 'decorators/doc';
import model                       from 'decorators/model';
import { Timestampable }           from 'helpers/traits';
import { Blamable }                from 'helpers/traits';
import SalesPrivateApiModel        from 'modelx/models/abstracts/SalesPrivateApiModel';

@model.staticLabel('Type de prestation')
@model.urnResource('activity')
@doc.path('/activities/{?id}')
export default class ActivityModel extends Blamable(Timestampable(SalesPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'activityType': id;
		'activityType.accountingAnalyticCodeUrn': Urn;
		'activityType.accountingCodeUrn': Urn;
		'activityType.activityTypeActivityCategories.activityCategory': Urn,
		'activityType.activityTypeActivityCategories.activityCategory.enabled': '0' | '1';
		'activityType.activityTypeActivityCategories.activityCategory.reference': string,
		'activityType.archived': '0' | '1';
		'activityType.operationTypeUrn': string;
		'partitionUrn': Urn;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'activityType.label': string;
	}> = {};

	@doc.modelUrn(AccountingAnalyticCodeModel) declare accountingAnalyticCode: AccountingAnalyticCodeModel;
	@doc.modelUrn(AccountingCodeModel) declare accountingCode: AccountingCodeModel;
	@doc.model(ActivityStatusModel) declare activityStatus: ActivityStatusModel;
	@doc.model(ActivityTypeModel) declare activityType: ActivityTypeModel;
	@doc.string declare label: string;
	@doc.modelUrn(PartitionModel) declare partition: PartitionModel;
	@doc.number declare priceExclTax: number;

	@model.commentResolvable('partition_activity_methodology')
	public declare methodology: CommentModel;

	// TODO DOC - Ajouter la propriété "accountingCode"
}
