import doc                     from 'decorators/doc';
import model                   from 'decorators/model';
import SupplierPrivateApiModel from '../../abstracts/SupplierPrivateApiModel';

@model.cacheDuration()
@model.staticLabel('Statut')
@model.urnResource('order_group_status')
@doc.path('/order_group_statuses/{?id}')
export default class OrderGroupStatusModel extends SupplierPrivateApiModel {
	public _filters: ModelFiltersExtended<{
		'reference': SupplierOrderGroupStatusReference;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
	}> = {};

	@doc.string declare label: string;
	@doc.string declare reference: SupplierOrderGroupStatusReference;

	public get color() {
		switch (this.reference) {
			case 'in_preparation':
				return 'lightblue';
			case 'orderable':
				return 'blue';
			case 'ordered':
				return 'lightgreen';
			case 'delivered':
			case 'terminated':
				return 'green';
			case 'cancelled':
				return 'red';
		}
	}
}
