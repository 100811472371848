import lazyWithRetry from 'tools/lazyWithRetry';

const ClientDashboardPage = lazyWithRetry(() => import(
	/* webpackChunkName: "clientDashboardPage" */
	`../../pages/Client/ClientDashboardPage`));

const ClientListPage = lazyWithRetry(() => import(
	/* webpackChunkName: "clientListPage" */
	`../../pages/Client/ClientListPage`));

export default [
	{
		component: ClientListPage,
		exact: true,
		path: '/sub_partitions/:subPartitionId/clients',
	},
	{
		component: ClientDashboardPage,
		exact: true,
		path: '/sub_partitions/:subPartitionId/clients/:clientId',
	},
];