import React       from 'react';
import ConfigProxy from 'tools/ConfigProxy';

const logo = {
	'$:partition:partition:1': new URL('/logos/logo-300-qhs.png', import.meta.url).href,
	'$:partition:partition:10': new URL('/logos/logo-300-mapclim.png', import.meta.url).href,
	'$:partition:partition:11': new URL('/logos/logo-300-d2h.png', import.meta.url).href,
	'$:partition:partition:12': new URL('/logos/logo-300-zaack.png', import.meta.url).href,
	'$:partition:partition:13': new URL('/logos/logo-300-nanosense.png', import.meta.url).href,
	'$:partition:partition:14': new URL('/logos/logo-300-addd.png', import.meta.url).href,
	'$:partition:partition:15': new URL('/logos/logo-300-agetep.png', import.meta.url).href,
	'$:partition:partition:16': new URL('/logos/logo-300-igc.png', import.meta.url).href,
	'$:partition:partition:17': new URL('/logos/logo-300-inter.png', import.meta.url).href,
	'$:partition:partition:18': new URL('/logos/logo-300-airplusnetouest.png', import.meta.url).href,
	'$:partition:partition:2': new URL('/logos/logo-300-dicop.png', import.meta.url).href,
	'$:partition:partition:3': new URL('/logos/logo-300-hpr.png', import.meta.url).href,
	'$:partition:partition:4': new URL('/logos/logo-300-hpra.png', import.meta.url).href,
	'$:partition:partition:5': new URL('/logos/logo-300-cg2d.png', import.meta.url).href,
	'$:partition:partition:6': new URL('/logos/logo-300-rcontrolclim.png', import.meta.url).href,
	'$:partition:partition:7': new URL('/logos/logo-300-igienair.png', import.meta.url).href,
	'$:partition:partition:8': new URL('/logos/logo-300-adiams.png', import.meta.url).href,
	'$:partition:partition:9': new URL('/logos/logo-300-airsn.png', import.meta.url).href,
};

export default class LoginHeader extends React.Component {
	public render() {
		const logoSrc = logo[ConfigProxy.get('PARTITION_URN')];

		return (
			<div>
				<img
					alt={`logo ${ConfigProxy.get('PARTITION_NAME')}`}
					src={logoSrc}
					style={{ height: 180, marginBottom: 20 }}
				/>
			</div>
		);
	}
}
