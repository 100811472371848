import lazyWithRetry from 'tools/lazyWithRetry';

const QuotationRequestListPage = lazyWithRetry(() => import(
	/* webpackChunkName: "quotationRequestListPage" */
	'../../pages/QuotationRequest/QuotationRequestListPage'));

const QuotationRequestDashboardPage = lazyWithRetry(() => import(
	/* webpackChunkName: "quotationRequestDashboardPage" */
	'../../pages/QuotationRequest/QuotationRequestDashboardPage'));

export default [
	{
		component: QuotationRequestListPage,
		exact: true,
		path: '/sub_partitions/:subPartitionId/quotation_requests',
	},
	{
		component: QuotationRequestDashboardPage,
		exact: true,
		path: '/quotation_requests/:quotationRequestId',
	},
];
