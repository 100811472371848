import { computed }                from 'mobx';
import InterventionPrivateApiModel from '../../abstracts/InterventionPrivateApiModel';

export default class ResourceDocumentTypeModel extends InterventionPrivateApiModel {
	public _filters: ModelFiltersExtended<{ partitionUrn: string }> = {};

	static get path(): string {
		return '/resource_document_types/{?id}';
	}

	@computed
	public get label(): string {
		return this.get('label', '');
	}

	public _sorts: ModelSortsExtended<{
		'id': string;
		'position': string;
		'updatedAt': string;
	}> = {};
}
