import RightModel           from 'Models/rights/RightModel';
import doc                  from 'decorators/doc';
import model                from 'decorators/model';
import SalesPrivateApiModel from 'modelx/models/abstracts/SalesPrivateApiModel';

@model.staticLabel(`Groupe`)
@model.urnResource('contract_actor_group')
@doc.path('/contract_actor_groups/{?id}')
export default class ContractActorGroupModel extends SalesPrivateApiModel {
	public _filters: ModelFiltersExtended<{
		'reference': SalesContractActorGroupReference;
	}> = {};

	public _sorts: ModelSortsExtended<unknown> = {};

	@doc.string declare label: string;
	@doc.string declare reference: SalesContractActorGroupReference;
	@doc.modelUrn(RightModel) declare right: RightModel;
}