import lazyWithRetry from 'tools/lazyWithRetry';

const CompanyListPage = lazyWithRetry(() => import(
	/* webpackChunkName: "companyListPage" */
	'../../pages/Company/CompanyListPage'));

export default [
	{
		component: CompanyListPage,
		exact: true,
		path: '/companies',
	},
];