import { resolvable }              from '@mathquis/modelx-resolvables';
import ClientModel                 from 'Models/directory/ClientModel';
import TaskModel                   from 'Models/intervention/TaskModel';
import TaskOperationModel          from 'Models/intervention/TaskOperationModel';
import TaskZoneModel               from 'Models/intervention/TaskZoneModel';
import ActivityModel               from 'Models/sales/ActivityModel';
import ContractIterationModel      from 'Models/sales/ContractIterationModel';
import OwnerModel                  from 'Models/sales/OwnerModel';
import QuotationModel              from 'Models/sales/QuotationModel';
import QRFAIQuotationOptionModel   from 'Models/sales/QuotationRequestFrameworkAgreementIterationQuotationOptionModel';
import QuotationRequestStatusModel from 'Models/sales/QuotationRequestStatusModel';
import doc                         from 'decorators/doc';
import model                       from 'decorators/model';
import nestedResolvable            from 'decorators/nestedResolvable';
import { Blamable }                from 'helpers/traits';
import { Timestampable }           from 'helpers/traits';
import { computed }                from 'mobx';
import SalesPrivateApiModel        from 'modelx/models/abstracts/SalesPrivateApiModel';
import moment                      from 'moment';

@model.staticLabel('Urgence', false)
@model.urnResource('quotation_request')
@doc.path('/quotation_requests/{?id}')
export default class QuotationRequestModel extends Blamable(Timestampable(SalesPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'activity': id;
		'contractIteration': id;
		'locationUrn': string;
		'number': number;
		'owner': id;
		'owner.clientUrn': string;
		'owner.companyUrn': string;
		'owner.ownerEnterprisePartition.enterprisePartitionUrn': string;
		'owner.enterpriseUrn': string;
		'owner.ownerSubPartition.ownerPartition.partitionUrn': string;
		'owner.ownerSubPartition.subPartitionUrn': string;
		'quotation': id;
		'quotationRequestStatus': id;
		'quotationRequestStatus.reference': SalesQuotationRequestStatusReference;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'number': string;
		'quotation.number': string;
		'quotationRequestStatus.label': string;
		'quotationRequestStatus.position': string;
		'updatedAt': string;
	}> = {};

	@doc.model(ActivityModel) declare activity: ActivityModel;
	@doc.modelUrn(ClientModel) declare client: ClientModel;
	@doc.model(ContractIterationModel) declare contractIteration: ContractIterationModel;
	@doc.string declare description: string;
	@doc.number declare number: number;
	@doc.model(OwnerModel) declare owner: OwnerModel;
	@doc.model(QuotationModel) declare quotation: QuotationModel;
	@doc.model(QuotationRequestStatusModel) declare quotationRequestStatus: QuotationRequestStatusModel;
	@doc.date declare scheduleEndDate: Moment;
	@doc.date declare scheduleStartDate: Moment;
	@doc.modelUrn(TaskOperationModel) declare taskOperation: TaskOperationModel;
	@doc.modelUrn(TaskModel) declare task: TaskModel;
	@doc.string declare title: string;

	@resolvable(TaskZoneModel, { attributeName: 'locationUrn' })
	declare location: TaskZoneModel;

	@computed
	public get fullName() {
		return `${this.title} (${this.number})`;
	}

	@computed
	public get displayDate() {
		return `${this.scheduleStartDate.format('L')} au ${this.scheduleEndDate.format('L')}`;
	}

	@nestedResolvable()
	public get quotationStatus() {
		return this.quotation.quotationStatus;
	}

	@nestedResolvable()
	public get subPartition() {
		return this.owner.ownerSubPartition.subPartition;
	}

	@nestedResolvable()
	public get company() {
		return this.location.owner.client.clientPartition.company;
	}

	@computed
	public get isRecent() {
		return this.createdAt.diff(moment(), 'days') <= 30;
	}

	@model.reverseResolvable<QRFAIQuotationOptionModel>({
		urn: '$:sales:quotation_request_framework_agreement_iteration_quotation_option',
	})
	public declare quotationRequestFrameworkAgreementIterationQuotationOption: QRFAIQuotationOptionModel;

	@model.nestedResolvable()
	public get frameworkAgreementIteration() {
		return this.quotationRequestFrameworkAgreementIterationQuotationOption
			.frameworkAgreementIterationQuotationOption
			.frameworkAgreementIteration;
	}
}