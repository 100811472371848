import NotificationLevelModel      from 'Models/notification/NotificationLevelModel';
import NotificationStatusModel     from 'Models/notification/NotificationStatusModel';
import SourceModel                 from 'Models/notification/SourceModel';
import doc                         from 'decorators/doc';
import model                       from 'decorators/model';
import { Timestampable }           from 'helpers/traits';
import { Blamable }                from 'helpers/traits';
import NotificationPrivateApiModel from 'modelx/models/abstracts/NotificationPrivateApiModel';

@model.staticLabel('Notification', false)
@model.urnResource('notification')
@doc.path('/stonecode_notification_notifications/{?id}')
export default class NotificationModel extends Blamable(Timestampable(NotificationPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'notificationChannels': id;
		'notificationChannels.channel': id;
		'notificationChannels.channel.reference': NotificationChannelReference;
		'notificationChannels.notificationSubscribers.subscriber': id;
		'notificationChannels.notificationSubscribers.subscriber.resourceUrn': string;
		'notificationContexts': id;
		'notificationContexts.context': id;
		'notificationContexts.context.contextReference': id;
		'notificationContexts.context.contextReference.reference': NotificationContextReferenceReference;
		'notificationContexts.context.contextValue': id;
		'notificationContexts.context.contextValue.value': id;
		'notificationLevel': id;
		'notificationLevel.reference': NotificationNotificationLevelReference;
		'notificationStatus': id;
		'notificationStatus.reference': NotificationNotificationStatusReference;
		'recipients': id;
		'scheduledAt[after]': string;
		'scheduledAt[before]': string;
		'scheduledAt[strictly_after]': string;
		'scheduledAt[strictly_before]': string;
		'source': id;
		'source.sourceUrn': string;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'scheduledAt': string;
		'title': string;
	}> = {};

	@doc.string declare message: string;
	@doc.model(NotificationLevelModel) declare notificationLevel: NotificationLevelModel;
	@doc.model(NotificationStatusModel) declare notificationStatus: NotificationStatusModel;
	@doc.string declare reference: NotificationNotificationReference;
	@doc.date declare scheduledAt: Moment;
	@doc.date declare sentAt?: Moment;
	@doc.model(SourceModel) declare source: SourceModel;
	@doc.string declare title: string;
	@doc.string declare url: string;
}
// TODO DOC - Ajouter la propriété "sentBy"
