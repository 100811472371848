import EnterpriseModel        from 'Models/directory/EnterpriseModel';
import PaymentModeModel       from 'Models/invoice/PaymentModeModel';
import doc                    from 'decorators/doc';
import model                  from 'decorators/model';
import { Timestampable }      from 'helpers/traits';
import { Blamable }           from 'helpers/traits';
import InvoicePrivateApiModel from 'modelx/models/abstracts/InvoicePrivateApiModel';

@model.staticLabel('InvoicingOwner')
@model.urnResource('invoicing_owner')
@doc.path('/invoicing_owners/{?id}')
export default class InvoicingOwnerModel extends Blamable(Timestampable(InvoicePrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'accountingCode': id;
		'enterpriseUrn': string;
		'invoicingGroups.billingGroups.invoices.billingPeriod': id;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'accountingCode': string;
		'id': string;
		'updatedAt': string;
	}> = {};

	@doc.string declare accountingCode: string;
	@doc.number declare dueDateDelay: number;
	@doc.modelUrn(EnterpriseModel) declare enterprise: EnterpriseModel;
	@doc.model(PaymentModeModel) declare paymentMode: PaymentModeModel;
}