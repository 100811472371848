/* eslint-disable @typescript-eslint/ban-ts-comment */

import ApiCollection      from 'Collections/ApiCollection';
import ModelDictionary    from 'helpers/ModelDictionary';
import { reaction }       from 'mobx';
import AbstractApiModel   from 'modelx/models/abstracts/AbstractApiModel';
import importModelFromUrn from 'tools/importModelFromUrn';

type apiModel = object & AbstractApiModel;

export default <T extends AbstractApiModel, D = AbstractApiModel>(params: {
	filters: (m: D) => ModelFilters<T>; // Filtres qui seront appliqués sur la collection
	urn: string; // On utilise un urn pour éviter les "circulars references"
}) => {
	return function (target: AbstractApiModel, key) {

		importModelFromUrn(params.urn as Urn);

		const collectionKey = `collection_${key}`;

		function getter(this: apiModel) {

			// Si la propriété n'a jamais été appelée, la collection n'existe pas
			if (!this[collectionKey]) {
				// Création d'une collection
				this[collectionKey] = new ApiCollection(ModelDictionary.get(params.urn));

				// Création d'un événement qui se déclenchera quand le model sera chargé à nouveau
				reaction(() => this.isLoading, () => {
					if (this.isLoading) {
						// Permet de réinitialiser la collection pour qu'un fetch soit déclenché à nouveau
						// (la prochaine fois qu'on appelle la propriété)
						this[collectionKey].clear();
					}
				});
			}

			const collection = this[collectionKey] as ApiCollection<T>;

			// Si la collection n'est pas fetchée
			if (!this.isLoading && this.isLoaded && !collection.isLoaded && !collection.isLoading) {
				// Fetch de la collection
				collection
					.setFilters(params.filters ? params.filters(this as never) : {})
					.list()
					.then(() => null);
			}

			return collection;
		}

		Object.defineProperty(target, key, {
			configurable: true,
			enumerable: true,
			get: getter,
		});
	};
};